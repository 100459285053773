// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                       from 'react';
import {
    doc,
    getDoc,
    getFirestore,
}                                       from 'firebase/firestore';
import {
    useParams,
    useNavigate,
    useLocation,
}                                       from 'react-router-dom';

// ===== Components =====

import Spinner                          from '../Spinner';

// ===== Hooks =====

import {
    useTimeout,
}                                       from '../../hooks';

// ===== Services =====

import {
    updateUserInDB,
    recordUserAction,
}                                       from '../../services';

// ===== Enums =====

import {
    PAGE_ROUTE,
    USER_ACTION_TYPE,
    FIRESTORE_COLLECTION,
    VERIFY_STATUS_TYPE,
}                                       from '../../enums';

// ===== Interfaces =====

import {
    IUserItem,
}                                       from '../../interfaces';

// ===== Styles =====

import {
    Container,
}                                       from './styles';

interface Props {
    user: IUserItem | null,
    currentSessionId: string | null,
    setVerifyId: React.Dispatch<React.SetStateAction<string | null>>,
    setVerifyStatus: React.Dispatch<React.SetStateAction<VERIFY_STATUS_TYPE>>
}
function VerifyView({
    user,
    currentSessionId,
    setVerifyId,
    setVerifyStatus,
}: Props): JSX.Element {
    // ==== General Constants =====

    const AWAIT_USER_ID_TIMEOUT_DURATION = 1000;

    // ===== React Router =====

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    // ===== State =====

    // Stores user item data
    const [awaitingUserId, setAwaitingUserId] = useState<boolean>(false);
    const [executedVerifyRequest, setExecutedVerifyRequest] = useState<boolean>(false);

    // ===== Methods ======

    const verifyUserEmail = async (): Promise<void> => {
        if (!params.userId) throw Error('Expected User ID while attempting to verify user email address, but ID was not found.');
        const db = getFirestore();
        const usersCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.users
            : FIRESTORE_COLLECTION.stagingUsers;
        const userRef = doc(db, usersCollection, params.userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
            // Change user verified value
            await updateUserInDB({
                userId: params.userId,
                verified: true,
            });

            // verify that value has changed
            const userVerifySnap = await getDoc(userRef);
            if (userVerifySnap.exists()) {
                const userVerifyItem = userVerifySnap.data() as IUserItem;
                if (!('verified' in userVerifyItem)) {
                    // User Item doesn't have a verified property when it should
                    // Verify Unsuccsessful
                    setVerifyStatus(VERIFY_STATUS_TYPE.missingProperty);
                } else if (
                    'verified' in userVerifyItem
                    && !userVerifyItem.verified
                ) {
                    // User still unverifiedd
                    // Verify Unsuccessful
                    setVerifyStatus(VERIFY_STATUS_TYPE.stillUnverified);
                } else if (
                    'mailingListSubscription' in userVerifyItem
                    && userVerifyItem.verified
                ) {
                    // User verified
                    // Verify Successful
                    setVerifyStatus(VERIFY_STATUS_TYPE.success);
                } else {
                    // Uncaught Error
                    // Verify Unsuccessful
                    setVerifyStatus(VERIFY_STATUS_TYPE.uncaughtError);
                }
            } else {
                // User not found
                // Verify Unsucessful
                setVerifyStatus(VERIFY_STATUS_TYPE.userNotFound);
            }

            navigate(
                `/${PAGE_ROUTE.verifyResult}`,
                {
                    state: {
                        prevPath: location.pathname,
                    },
                },
            );
        } else {
            // User not found
            // Verify Unsucessful
            setVerifyStatus(VERIFY_STATUS_TYPE.userNotFound);

            navigate(
                `/${PAGE_ROUTE.verifyResult}`,
                {
                    state: {
                        prevPath: location.pathname,
                    },
                },
            );
        }
    };

    // ===== Side Effects =====

    /**
     * Record user entering page
     */
    useEffect(() => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewVerifyResultPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    }, [
        user,
        currentSessionId,
    ]);

    /**
     * Verify user email
     */
    useEffect(() => {
        if (params.userId && !executedVerifyRequest) {
            setVerifyId(params.userId);
            clearAwaitUserId();
            verifyUserEmail();
            setExecutedVerifyRequest(true);
        } else if (!awaitingUserId) {
            // Initiate timeout that routes to VerifyResultView
            setAwaitingUserId(true);
            clearAwaitUserId();
            timeoutAwaitUserId();
        }
    }, [
        params,
        executedVerifyRequest,
    ]);

    /**
     * Manages delay of routing to VerifyResultView
     * after waiting for User ID
     */
    const {
        start: timeoutAwaitUserId,
        clear: clearAwaitUserId,
    } = useTimeout(() => {
        // User not received during expected timeframe
        // Verify Unsuccessful
        setVerifyStatus(VERIFY_STATUS_TYPE.timeout);
        navigate(
            `/${PAGE_ROUTE.verifyResult}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    }, AWAIT_USER_ID_TIMEOUT_DURATION);

    return (
        <Container>
            <Spinner large lightBackground />
        </Container>
    );
}

export default VerifyView;

// ===== Packages =====

import React, {
    useEffect,
    useState,
}                                               from 'react';
import styled                                   from 'styled-components';
import { useSelected }                          from 'slate-react';

// ===== Services =====

import {
    updateAnnotationMediaInDB,
}                                               from '../../../services';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    FIRESTORE_COLLECTION,
    MEDIA_TYPE,
}                                               from '../../../enums';
import {
    FIGURE_CONTENT_TYPE,
}                                               from '../elements/figureElement/enums';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
    HOVER_TARGET_CLASSNAME,
}                                               from '../../../constants/generalConstants';
import CURSOR_SIGN                              from '../../../constants/cursorSigns';
import FONT_TYPE                                from '../../../constants/fontType';

Caption.defaultProps = {
    readOnly: false,
};
interface Props {
    id: string,
    text: string,
    contentType: FIGURE_CONTENT_TYPE,
    placeholder: string,
    readOnly?: boolean,
    children: React.ReactElement,
    attributes: any,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function Caption({
    id,
    text,
    children,
    contentType,
    attributes,
    placeholder,
    readOnly = false,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== State =====
    const selected = useSelected();
    const [lastTextValue, setLastTextValue] = useState<string>(text);

    // ===== Methods ====

    const onMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.input,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    // ===== Side Effects =====

    useEffect(() => {
        if (text !== lastTextValue) {
            // Update text value in database
            // Update DB
            const collection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.media
                : FIRESTORE_COLLECTION.stagingMedia;
            let annotationMediaType: MEDIA_TYPE;
            switch (contentType) {
            case FIGURE_CONTENT_TYPE.youtube:
            case FIGURE_CONTENT_TYPE.spotify:
            case FIGURE_CONTENT_TYPE.twitter:
            case FIGURE_CONTENT_TYPE.vimeo:
                annotationMediaType = MEDIA_TYPE.embedding;
                break;
            default:
                annotationMediaType = MEDIA_TYPE.image;
            }
            updateAnnotationMediaInDB({
                collection,
                id,
                annotationMediaType,
                caption: text,
            });
            setLastTextValue(text);
        }
    }, [text]);

    return (
        <Container
            {...attributes}
            selected={selected}
            readOnly={readOnly}
            className={HOVER_TARGET_CLASSNAME}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
            {text.length === 0
            && !readOnly
            && (
                <PlaceholderText>
                    {placeholder}
                </PlaceholderText>
            )}
        </Container>
    );
}

// ===== Styled Components =====

const Container = styled.figcaption`
    position: relative;
    margin-top: ${`${0.625 * BODY_FONT_SIZE}px`};
    width: 100%;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.9em;
    font-weight: 300;
    color: ${({ theme }) => theme.color.neutral800};
    text-align: center;
    cursor: text;
`;

const PlaceholderText = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1em;
    color: ${({ theme }) => theme.color.neutral700};
    text-align: center;
    pointer-events: none;
`;

export default Caption;

// ===== Enums =====

import {
    VERIFY_STATUS_TYPE,
}                               from '../enums';

const getVerifyEmailMessage = (
    status: VERIFY_STATUS_TYPE,
): string => {
    let message: string;
    switch (status) {
    case VERIFY_STATUS_TYPE.uninitiated:
        message = 'Email verification request was not made. Please try again.';
        break;
    case VERIFY_STATUS_TYPE.stillUnverified:
        message = 'Your account is still unverified. Please try again.';
        break;
    case VERIFY_STATUS_TYPE.missingProperty:
        message = 'Your account is missing critical information required to verify your email. Please contact verascope to resolve the issue.';
        break;
    case VERIFY_STATUS_TYPE.success:
        message = 'Your account email address has been successfully verified.';
        break;
    case VERIFY_STATUS_TYPE.userNotFound:
        message = 'No verascope account exists for the provided user. Please contact verascope if there are any unresolved matters.';
        break;
    case VERIFY_STATUS_TYPE.timeout:
        message = 'No verascope account was provided. Please try again.';
        break;
    case VERIFY_STATUS_TYPE.uncaughtError:
        message = 'Unrecognized error occurred. Please try again.';
        break;
    default:
        message = 'Unrecognized error occurred. Please try again.';
        break;
    }

    return message;
};

export default getVerifyEmailMessage;

// ===== Packages =====

import styled                           from 'styled-components';

// ===== Constants =====

import FONT_TYPE                        from '../../constants/fontType';
import {
    CART_AND_CHECKOUT_FONT_SIZE_LARGE,
    CART_AND_CHECKOUT_FONT_SIZE_SMALL,
    BODY_FONT_SIZE,
}                                       from '../../constants/generalConstants';

const ACTION_BUTTON_HEIGHT = 3.125 * BODY_FONT_SIZE;

interface CheckpointFormContainerProps {
    extraPadding?: boolean,
}
export const CheckpointFormContainer = styled.div<CheckpointFormContainerProps>`
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding-bottom: ${({ extraPadding }) => `${extraPadding ? 18.75 * BODY_FONT_SIZE : 3.125 * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.small} {
        padding: ${`${0.625 * BODY_FONT_SIZE}px`};
        padding-bottom: ${({ extraPadding }) => `${extraPadding ? 18.75 * BODY_FONT_SIZE : 3.125 * BODY_FONT_SIZE}px`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding: ${`${0.625 * BODY_FONT_SIZE}px`};
        padding-bottom: ${`${3.125 * BODY_FONT_SIZE}px`};
        overflow-y: hidden;
        height: auto;
    }
`;

export const InputGroupContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

interface InputContainerProps {
    paddingRight?: boolean,
    paddingLeft?: boolean,
    noMargin?: boolean,
}
export const InputContainer = styled.div<InputContainerProps>`
    padding-right: ${({ paddingRight }) => `${paddingRight ? 0.3125 * BODY_FONT_SIZE : 0}px`};
    margin-left: ${({ paddingLeft }) => `${paddingLeft ? 0.3125 * BODY_FONT_SIZE : 0}px`};
    margin-bottom: ${({ noMargin }) => `${noMargin ? 0 : 0.625 * BODY_FONT_SIZE}px`};
    width: 100%;
`;

interface CheckpointTitleProps {
    noMarginTop?: boolean,
}
export const CheckpointTitle = styled.h2<CheckpointTitleProps>`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;
    line-height: 2em;
    margin: 0;
    margin-top: ${({ noMarginTop }) => `${noMarginTop ? 0 : 2.5 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${1.25 * BODY_FONT_SIZE}px`};
    

    ${({ theme }) => theme.mediaQuery.small} {
        margin-top: ${`${1.25 * BODY_FONT_SIZE}px`};
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        margin-top: ${`${1.25 * BODY_FONT_SIZE}px`};
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
        text-align: center;
        line-height: 1.8em;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        flex-direction: column-reverse;
    }
`;

export const BackButtonIconContainer = styled.div`
    position: relative;
    left: ${`${0.625 * BODY_FONT_SIZE}px`};
    width: ${`${1.875 * BODY_FONT_SIZE}px`};
    height: ${`${1.875 * BODY_FONT_SIZE}px`};
    margin-right: ${`${0.9375 * BODY_FONT_SIZE}px`};
    transform: rotate(180deg);

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
    }

    & path, & rect {
        fill: ${({ theme }) => theme.verascopeColor.purple100};
    }
`;

interface BackButtonProps {
    detectTouchDevice: boolean,
}
export const BackButton = styled.div<BackButtonProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: ${`${ACTION_BUTTON_HEIGHT}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;
    margin-top: ${`${1.25 * BODY_FONT_SIZE}px`};
    margin-right: ${`${2.5 * BODY_FONT_SIZE}px`};
    padding: 0px;

    &:hover {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            color: ${theme.verascopeColor.orange200};
        `)}
    }

    &:hover ${BackButtonIconContainer} {
        left: 0px;

        & path, & rect {
            fill: ${({ theme }) => theme.verascopeColor.orange200};
        }
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
        margin-top: ${`${0.625 * BODY_FONT_SIZE}px`};
        margin-right: 0px;
        justify-content: center;
    }
`;

export const BackButtonTextContainer = styled.h3``;

interface ActionButtonProps {
    noHover: boolean,
    detectTouchDevice: boolean,
}
export const ActionButton = styled.button<ActionButtonProps>`
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${`${ACTION_BUTTON_HEIGHT}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE + 0.1}em`};
    color: ${({ theme }) => theme.color.white};
    text-transform: uppercase;
    background: ${({ theme }) => theme.verascopeColor.orange200};
    margin-top: ${`${1.25 * BODY_FONT_SIZE}px`};

    ${({ noHover, detectTouchDevice, theme }) => !noHover && `
        &:hover {
        ${(!detectTouchDevice && `
            background: ${theme.verascopeColor.orange100};
        `)}
        }
    `}

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.1}em`};
    }
`;

export const ActionButtonLoadingContainer = styled.div`
    & img {
        width: ${`${1.25 * BODY_FONT_SIZE}px`};
    }
`;

export const ActionButtonLoadingMessageText = styled.h4`
    margin: 0;
    padding: 0;
    margin-top: ${`${0.3125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.2}em`};
    color: ${({ theme }) => theme.color.white};
    text-transform: none;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2}em`};
    }
`;

export const CheckpointSubtitle = styled.h3`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.2}em`};
    color: ${({ theme }) => theme.verascopeColor.purple300};
    text-transform: uppercase;
    margin: 0;
    margin-top: ${`${1.25 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${1.25 * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2}em`};
        line-height: 1.8em;
        text-align: center;
    }
`;

export const PaymentWidgetContainer = styled.div`
    width: 100%;
`;

interface BillingAddressContainerProps {
    physicalItemsInCart: boolean,
}
export const BillingAddressContainer = styled.div<BillingAddressContainerProps>`
    padding: ${({ physicalItemsInCart }) => `${physicalItemsInCart ? 0.9375 * BODY_FONT_SIZE : 0}px`};
    padding-bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};
    border: ${({ physicalItemsInCart, theme }) => (physicalItemsInCart
        ? `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple100}`
        : 'none'
    )};
    border-top: none;
`;

export const ReviewShippingContainer = styled.div``;

export const ReviewShippingDetailContainer = styled.div`
    padding: ${`${0.9375 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${0.9375 * BODY_FONT_SIZE}px`};
    border: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple100}`};
`;

interface ReviewShippingDetailProps {
    noSeparator?: boolean,
}
export const ReviewShippingDetail = styled.div<ReviewShippingDetailProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: ${`0px ${0.3125 * BODY_FONT_SIZE}px`};
    ${({ noSeparator, theme }) => !noSeparator && `
        padding-bottom: ${0.625 * BODY_FONT_SIZE}px;
        margin-bottom: ${0.9375 * BODY_FONT_SIZE}px;
        border-bottom: ${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple100};
    `}
`;

export const ReviewShippingDetailTitle = styled.h3`
    margin: 0;
    padding: 0;
    flex: ${`0 0 ${10.625 * BODY_FONT_SIZE}px`};
    width: ${`${10.625 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.2}em`};
    color: ${({ theme }) => theme.verascopeColor.purple300};
    text-transform: uppercase;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2}em`};
        flex: ${`0 0 ${6.25 * BODY_FONT_SIZE}px`};
        width: ${`${6.25 * BODY_FONT_SIZE}px`};
        line-height: 1.8em;
    }
`;

export const ReviewShippingDetailBody = styled.div`
    position: relative;
    top: ${`-${0.25 * BODY_FONT_SIZE}px`};
    flex: 1 1 auto;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: 0px;
    }
`;

export const ReviewShippingDetailBodyLine = styled.h4`
    margin: 0;
    padding: 0;
    padding-right: ${`${3.4375 * BODY_FONT_SIZE}px`};
    width: 100%;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;
    line-height: 1.8em;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

export const ReviewShippingDetailBodyLink = styled.a`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple400};
    text-transform: uppercase;
    line-height: 1.8em;
    text-decoration: none;
    cursor: none;

    &:hover {
        color: ${({ theme }) => theme.verascopeColor.orange200};
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

export const ReviewShippingDetailBodyLineHighlight = styled.span`
    color: ${({ theme }) => theme.verascopeColor.purple400};
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.02}em`};

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.02}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.02}em`};
    }
`;

export const ReviewShippingDetailEditButtonContainer = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    line-height: 0.7em;
    background: ${({ theme }) => theme.color.white};
`;

interface ReviewShippingDetailEditButtonProps {
    detectTouchDevice: boolean,
}
export const ReviewShippingDetailEditButton = styled.button<ReviewShippingDetailEditButtonProps>`
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.1}em`};
    color: ${({ theme }) => theme.verascopeColor.orange200};
    text-transform: uppercase;
    background: ${({ theme }) => theme.color.white};

    &:hover {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            color: ${theme.verascopeColor.purple400};
        `)}
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }
`;

export const ReviewTransactionDetailContainer = styled.div`
    padding: ${`${0.9375 * BODY_FONT_SIZE}px`};
    border: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple100}`};
`;

export const ReviewTransactionDetail = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: ${`0px ${0.3125 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${0.9375 * BODY_FONT_SIZE}px`};
`;

export const ReviewTransactionDetailTitle = styled.h3`
    margin: 0;
    padding: 0;
    flex: ${`0 0 ${10.625 * BODY_FONT_SIZE}px`};
    width: ${`${10.625 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.2}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2}em`};
        flex: ${`0 0 ${6.25 * BODY_FONT_SIZE}px`};
        width: ${`${6.25 * BODY_FONT_SIZE}px`};
        line-height: 1.8em;
    }
`;

export const ReviewTransactionDetailBody = styled.h4`
    margin: 0;
    padding: 0;
    flex: 1 1 auto;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple400};
    text-transform: uppercase;
    text-align: right;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

export const ReviewTransactionTotal = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: ${`${1.25 * BODY_FONT_SIZE}px`};
    border-top: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple100}`};
`;

export const ReviewTransactionTotalTitle = styled.h2`
    margin: 0;
    padding: 0;
    flex: ${`0 0 ${18.75 * BODY_FONT_SIZE}px`};
    width: ${`${18.75 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
        flex: ${`0 0 ${6.25 * BODY_FONT_SIZE}px`};
        width: ${`${6.25 * BODY_FONT_SIZE}px`};
    }
`;

export const ReviewTransactionTotalBody = styled.h4`
    margin: 0;
    padding: 0;
    flex: 1 1 auto;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple400};
    text-transform: uppercase;
    text-align: right;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

interface BillingContainerProps {
    visible: boolean,
}
export const BillingContainer = styled.div<BillingContainerProps>`
    height: ${({ visible }) => (visible ? 'auto' : '0px')};
    overflow: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

interface ReviewContainerProps {
    visible: boolean,
}
export const ReviewContainer = styled.div<ReviewContainerProps>`
    height: ${({ visible }) => (visible ? 'auto' : '0px')};
    overflow: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

interface MailingListContainerProps {
    largeMarginTop: boolean,
}
export const MailingListContainer = styled.div<MailingListContainerProps>`
    margin-top: ${({ largeMarginTop }) => `${largeMarginTop ? 1.875 * BODY_FONT_SIZE : 0.625 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${1.875 * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
`;

export const SavePaymentMethodContainer = styled.div`
    margin-top: ${`${0.625 * BODY_FONT_SIZE}px`};
    
    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
`;

interface PaymentElementContainerProps {
    visible: boolean,
}
export const PaymentElementContainer = styled.div<PaymentElementContainerProps>`
    width: ${`calc(100% - ${0.3125 * BODY_FONT_SIZE}px)`};
    margin: ${`${1.25 * BODY_FONT_SIZE}px 0px`};
    height: ${({ visible }) => (visible ? 'auto' : '0px')};
    overflow: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

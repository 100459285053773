// ===== Interfaces =====

import { ICursorSign }    from '../interfaces';

// ===== Images =====

import ClickSign                from '../images/click.svg';
import GrabSign                 from '../images/grab.svg';
import MoveSign                 from '../images/move.svg';
import ChatSign                 from '../images/chat.svg';
import CautionSign              from '../images/caution.svg';
import HorizontalSteerSign      from '../images/horizontal-steer.svg';
import HighlightSign            from '../images/highlight.svg';
import ExpandStackSign          from '../images/expand-stack.svg';
import MoreIcon                 from '../images/more.svg';
import ExpandZoomSign           from '../images/plus.svg';
import ContractZoomSign         from '../images/minus.svg';

const CURSOR_SIGN: ICursorSign = {
    grab: GrabSign,
    move: MoveSign,
    type: ChatSign,
    click: ClickSign,
    highlight: HighlightSign,
    horizontalSteer: HorizontalSteerSign,
    caution: CautionSign,
    expandStack: ExpandStackSign,
    more: MoreIcon,
    expandZoom: ExpandZoomSign,
    contractZoom: ContractZoomSign,
};

export default CURSOR_SIGN;

// ===== Packages =====

import {
    doc,
    getFirestore,
    runTransaction,
}                           from 'firebase/firestore';

// ===== Interfaces =====

import {
    IAnnotationQuote,
    IAnnotationValue,
    IAnnotationItemDeletion,
}                           from '../interfaces';

interface Args {
    collection: string,
    id: string,
    dictationFilePaths?: string[],
    history?: IAnnotationValue[],
    media?: string[],
    quoteHistory?: IAnnotationQuote[],
    postId?: string,
    deleted?: IAnnotationItemDeletion,
    userId?: string,
    views?: string[],
    plays?: string[],
    updated?: number[],
    comments?: string[],
}
const updateAnnotationInDB = async ({
    collection,
    id,
    dictationFilePaths,
    history,
    media,
    quoteHistory,
    postId,
    deleted,
    userId,
    views,
    plays,
    updated,
    comments,
}: Args): Promise<void> => {
    const db = getFirestore();
    const ref = doc(db, collection, id);
    try {
        return await runTransaction(db, async (transaction) => {
            if (dictationFilePaths) transaction.update(ref, { dictationFilePaths });
            if (history) transaction.update(ref, { history });
            if (media) transaction.update(ref, { media });
            if (quoteHistory) transaction.update(ref, { quoteHistory });
            if (postId) transaction.update(ref, { postId });
            if (deleted) transaction.update(ref, { deleted });
            if (userId) transaction.update(ref, { userId });
            if (views) transaction.update(ref, { views });
            if (plays) transaction.update(ref, { plays });
            if (updated) transaction.update(ref, { updated });
            if (comments) transaction.update(ref, { comments });
        });
    } catch (e) {
        throw Error('Encountered an error updating annotation.');
    }
};

export default updateAnnotationInDB;

// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
}                                                   from 'react';
import styled                                       from 'styled-components';
import { Link }                                     from 'react-router-dom';
import { ReactSVG }                                 from 'react-svg';
import { Transition }                               from 'react-transition-group';

// ===== Components =====

import Tooltip                                      from '../../Tooltip';
import { GenericButton }                            from '../../../styles';

// ===== Assets =====

import Spinner                                      from '../../../images/editor/spinner.svg';
import ChevronIcon                                  from '../../../images/editor/chevron.svg';

// ===== Constants =====

import {
    BUTTON_TYPE,
    TOOLTIP_TYPE,
}                                                   from '../../../enums';
import {
    BUTTON_CONTAINER_LIGHTNESS_VALUE,
    BUTTON_TEXT_LIGHTNESS_VALUE,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_OFFSET_DURATION,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    FADE_IN_STAGGER_TRANSITION_DURATION,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    BUTTON_EXPANSION_DURATION,
    BUTTON_BORDER_THICKNESS,
    NESTED_BUTTON_BODY_PADDING,
    BUTTON_BODY_LIGHTNESS_VALUE,
    BODY_FONT_SIZE,
    NESTED_BUTTON_MARGIN_BOTTOM,
    DEFAULT_AUDIO_VOLUME,
}                                                   from '../../../constants/generalConstants';
import FONT_TYPE                                    from '../../../constants/fontType';

// ===== Services =====

import {
    fontColorDiscriminator,
    hexToRgb,
    rgbToHsl,
    setColorLightness,
    validateURL,
    validHexCode,
    detectTouchDevice,
    playAudio,
}                                                   from '../../../services';

// ===== Sounds =====

import NestedButtonContainerExpand                  from '../../../sounds/swoosh_in.mp3';
import NestedButtonContainerContract                from '../../../sounds/swoosh_out.mp3';

// ===== Styles =====

import { theme as themeObj }                        from '../../../themes/theme-context';

Button.defaultProps = {
    text: '',
    icon: null,
    onClick: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    onMouseDown: undefined,
    onTouchStart: undefined,
    disabled: false,
    style: undefined,
    loading: false,
    children: undefined,
    background: undefined,
    id: undefined,
    className: undefined,
    height: 40,
    nestedButtonHeight: 25,
    width: undefined,
    center: false,
    boxShadow: false,
    link: null,
    tooltip: {
        active: false,
        text: '',
        side: TOOLTIP_TYPE.top,
    },
    setRef: undefined,
    hasSound: false,
};
interface Props {
    text?: string,
    icon?: string | null,
    type: BUTTON_TYPE,
    onClick?: (e: React.MouseEvent) => void,
    onMouseEnter?: (e: React.MouseEvent) => void,
    onMouseLeave?: (e: React.MouseEvent) => void,
    onMouseDown?: (e: React.MouseEvent) => void,
    onTouchStart?: (e: React.TouchEvent) => void,
    disabled?: boolean,
    style?: any,
    loading?: boolean,
    children?: React.ReactNode; // Allow multiple children
    background?: string,
    id?: string,
    className?: string,
    height?: number,
    nestedButtonHeight?: number,
    width?: number,
    center?: boolean,
    boxShadow?: boolean,
    link?: {
        href: string,
        download?: string,
        newTab: boolean,
    } | null,
    tooltip?: {
        active: boolean,
        text: string,
        side: TOOLTIP_TYPE,
    },
    setRef?: (ref: HTMLButtonElement) => void,
    hasSound?: boolean,
}
function Button({
    text = '',
    icon = null,
    type = BUTTON_TYPE.solid,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
    onTouchStart,
    disabled = false,
    style,
    loading = false,
    children,
    background,
    id = undefined,
    height = 2.5 * BODY_FONT_SIZE,
    nestedButtonHeight,
    width,
    center = false,
    boxShadow = false,
    link = null,
    tooltip = {
        active: false,
        text: '',
        side: TOOLTIP_TYPE.top,
    },
    setRef,
    className,
    hasSound,
}: Props): JSX.Element {
    // ===== General Constants =====

    const neutralColors = {
        [themeObj.color.neutral1000]: 1000,
        [themeObj.color.neutral900]: 900,
        [themeObj.color.neutral800]: 800,
        [themeObj.color.neutral700]: 700,
        [themeObj.color.neutral600]: 600,
        [themeObj.color.neutral500]: 500,
        [themeObj.color.neutral400]: 400,
        [themeObj.color.neutral300]: 300,
        [themeObj.color.neutral200]: 200,
        [themeObj.color.neutral100]: 100,
        [themeObj.color.neutral50]: 50,
    };

    // ===== Refs =====

    const buttonRef = useRef<HTMLButtonElement>(null);

    // ----- Sound Clips
    const nestedButtonContainerExpandClip = useRef<HTMLAudioElement>(new Audio());
    const nestedButtonContainerContractClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    const [nestedBodyVisible, setNestedBodyVisible] = useState<boolean>(false);

    // ===== Methods =====

    const handleNestedButtonToggle = (e: React.MouseEvent | React.TouchEvent): void => {
        e.stopPropagation();

        setNestedBodyVisible(!nestedBodyVisible);
        if (
            !nestedBodyVisible
            && hasSound
            && nestedButtonContainerExpandClip.current
        ) {
            nestedButtonContainerExpandClip.current.pause();
            nestedButtonContainerExpandClip.current.currentTime = 0;
            playAudio(nestedButtonContainerExpandClip.current);
        } else if (
            nestedBodyVisible
            && hasSound
            && nestedButtonContainerContractClip.current
        ) {
            nestedButtonContainerContractClip.current.pause();
            nestedButtonContainerContractClip.current.currentTime = 0;
            playAudio(nestedButtonContainerContractClip.current);
        }
    };

    // ===== Animation Constants =====

    const BUTTON_REVEAL_DELAY_DURATION = 0;

    // ===== Side Effects =====

    useEffect(() => {
        if (buttonRef.current && setRef) {
            setRef(buttonRef.current);
        }
    }, [buttonRef.current]);

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            nestedButtonContainerExpandClip.current
            && nestedButtonContainerContractClip.current
        ) {
            // Dialog Expand
            nestedButtonContainerExpandClip.current.volume = DEFAULT_AUDIO_VOLUME;
            nestedButtonContainerExpandClip.current.src = NestedButtonContainerExpand;

            // Dialog Contract
            nestedButtonContainerContractClip.current.volume = DEFAULT_AUDIO_VOLUME;
            nestedButtonContainerContractClip.current.src = NestedButtonContainerContract;
        }

        return function cleanup() {
            if (nestedButtonContainerExpandClip.current) nestedButtonContainerExpandClip.current.remove();
            if (nestedButtonContainerContractClip.current) nestedButtonContainerContractClip.current.remove();
        };
    }, []);

    // ===== Rendering =====

    const RenderButtonContent = useMemo(() => {
        let textColor;
        let iconColor;
        if (style && style.backgroundColor) {
            textColor = fontColorDiscriminator(style.backgroundColor);
            iconColor = fontColorDiscriminator(style.backgroundColor);
        } else if (style && style.color && type === BUTTON_TYPE.line) {
            textColor = style.color;
            iconColor = style.color;
        } else if (background && type === BUTTON_TYPE.solid) {
            textColor = fontColorDiscriminator(background);
            iconColor = fontColorDiscriminator(background);
            if (background in neutralColors) {
                iconColor = themeObj.color[
                    `neutral${neutralColors[background] > 500
                        ? Math.max(neutralColors[background] - 300, 100)
                        : Math.min(neutralColors[background] + 300, 800)
                    }`
                ];
                textColor = themeObj.color[
                    neutralColors[background] > 500
                        ? 'white'
                        : `neutral${Math.min(neutralColors[background] + 500, 800)}`
                ];
            }
        } else if (background && type === BUTTON_TYPE.line) {
            textColor = background;
            iconColor = background;
            if (background in neutralColors) {
                iconColor = themeObj.color[
                    `neutral${neutralColors[background] > 500
                        ? Math.max(neutralColors[background] - 300, 100)
                        : Math.min(neutralColors[background] + 300, 800)
                    }`
                ];
                textColor = themeObj.color[
                    neutralColors[background] > 500
                        ? 'white'
                        : `neutral${Math.min(neutralColors[background] + 500, 800)}`
                ];
            }
        } else if (background && type === BUTTON_TYPE.secret) {
            textColor = background && setColorLightness(
                background,
                BUTTON_TEXT_LIGHTNESS_VALUE,
            );
            iconColor = background;
            if (background in neutralColors) {
                iconColor = themeObj.color[
                    `neutral${neutralColors[background] > 500
                        ? Math.max(neutralColors[background] - 300, 100)
                        : Math.min(neutralColors[background] + 300, 800)
                    }`
                ];
                textColor = themeObj.color[
                    neutralColors[background] > 500
                        ? 'white'
                        : `neutral${Math.min(neutralColors[background] + 500, 800)}`
                ];
            }
        } else if (background && type !== BUTTON_TYPE.line) {
            textColor = fontColorDiscriminator(background);
            iconColor = fontColorDiscriminator(background);
            if (background in neutralColors) {
                iconColor = themeObj.color[
                    `neutral${neutralColors[background] > 500
                        ? Math.max(neutralColors[background] - 300, 100)
                        : Math.min(neutralColors[background] + 300, 800)
                    }`
                ];
                textColor = themeObj.color[
                    neutralColors[background] > 500
                        ? 'white'
                        : `neutral${Math.min(neutralColors[background] + 500, 800)}`
                ];
            }
        }

        return (
            <ButtonContents
                height={height}
                loading={loading}
                center={center}
            >
                {loading
                    ? (
                        <SpinnerContainer
                            height={(3 * height) / 5}
                            src={Spinner}
                            alt="spinner"
                        />
                    ) : (
                        <>
                            {!!icon
                            && (
                                <ButtonIconContainer
                                    height={height}
                                    background={iconColor}
                                    disabled={disabled}
                                >
                                    {icon.includes('.svg')
                                        ? (
                                            <ReactSVG
                                                src={icon}
                                                alt="button icon"
                                            />
                                        ) : (
                                            <Icon
                                                src={icon}
                                                alt=""
                                            />
                                        )}
                                </ButtonIconContainer>
                            )}
                            {text.toString().length > 0
                            && (
                                <ButtonText
                                    height={height}
                                    color={textColor}
                                    disabled={disabled}
                                    fontFamily={style && 'fontFamily' in style
                                        ? style.fontFamily
                                        : null}
                                    fontSize={style && 'fontSize' in style
                                        ? style.fontSize
                                        : null}
                                    fontColor={style && 'fontColor' in style
                                        ? style.fontColor
                                        : null}
                                >
                                    {text}
                                </ButtonText>
                            )}
                            {type === BUTTON_TYPE.nested
                            && !disabled
                            && text
                            && (
                                <NestedButtonTrigger
                                    expanded={nestedBodyVisible}
                                    background={background}
                                >
                                    <ReactSVG
                                        src={ChevronIcon}
                                        alt="button icon"
                                    />
                                </NestedButtonTrigger>
                            )}
                        </>
                    )}
            </ButtonContents>
        );
    }, [
        text,
        icon,
        type,
        loading,
        height,
        style,
        center,
        disabled,
        background,
        nestedBodyVisible,
    ]);

    const RenderButtonChildren = useMemo(() => {
        const bodyColor = background
            ? setColorLightness(
                background,
                BUTTON_BODY_LIGHTNESS_VALUE,
            )
            : '#000000';
        const numChildren = React.Children.toArray(children).filter((o) => o).length;

        return (
            type === BUTTON_TYPE.nested
            && !(disabled || loading)
            && !!nestedButtonHeight
                ? (
                    <NestedBodyContainer
                        buttonHeight={height}
                        disabled={disabled || loading}
                        bodyColor={bodyColor}
                        borderColor={background}
                        expanded={nestedBodyVisible}
                    >
                        {React.Children
                            .toArray(children)
                            .filter((o) => o)
                            .map((child, index) => (
                                <Transition
                                    in={nestedBodyVisible}
                                    timeout={{
                                        enter: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + FADE_IN_STAGGER_TRANSITION_DURATION
                                            + BUTTON_REVEAL_DELAY_DURATION,
                                        ),
                                        exit: 0,
                                    }}
                                    appear
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    {
                                        (state) => (
                                            <ChildContainer
                                                style={{
                                                    ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                        direction: 'down',
                                                        offset: 5,
                                                    }),
                                                    ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                        direction: 'down',
                                                        offset: 5,
                                                        numItems: numChildren,
                                                        index,
                                                        enterDelay: BUTTON_REVEAL_DELAY_DURATION,
                                                    })[state],
                                                }}
                                            >
                                                {child}
                                            </ChildContainer>
                                        )
                                    }
                                </Transition>
                            ))}
                    </NestedBodyContainer>
                ) : undefined
        );
    }, [
        type,
        loading,
        disabled,
        children,
        background,
        nestedBodyVisible,
    ]);

    // Assume link is not null
    // because it won't be where it is used
    const LinkButtonContents = useMemo(() => (
        link
        && link.href
        && validateURL(link.href)
            ? (
                <ExternalLink
                    href={link.href}
                    {...(link.download
                        ? {
                            download: link.download,
                            target: '_blank',
                            rel: 'noopener noreferrer',
                        }
                        : {}
                    )}
                    {...(link.newTab ? { target: '_blank' } : {})}
                >
                    {tooltip.active
                    && (
                        <Tooltip
                            text={tooltip.text}
                            side={tooltip.side}
                        />
                    )}
                    {RenderButtonContent}
                    {RenderButtonChildren}
                </ExternalLink>
            )
            : (
                <Link
                    to={link?.href || '/'}
                >
                    {tooltip.active
                    && (
                        <Tooltip
                            text={tooltip.text}
                            side={tooltip.side}
                        />
                    )}
                    {RenderButtonContent}
                    {RenderButtonChildren}
                </Link>
            )
    ), [
        link,
        tooltip,
        RenderButtonContent,
        RenderButtonChildren,
    ]);

    if (disabled) {
        return (
            <DisabledButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                center={center}
                loading={loading}
                background={background}
                hasIcon={!!icon}
                hasText={text.toString().length}
                style={style}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && onMouseDown
                    ? { onMouseDown }
                    : {}
                )}
                {...(!disabled && !loading && onTouchStart
                    ? { onTouchStart }
                    : {}
                )}
                {...(!disabled && !loading && !onMouseDown && onClick
                    ? { onClick }
                    : {}
                )}
            >
                {tooltip.active
                && (
                    <Tooltip
                        text={tooltip.text}
                        side={tooltip.side}
                    />
                )}
                {RenderButtonContent}
                {RenderButtonChildren}
            </DisabledButton>
        );
    }

    if (type === BUTTON_TYPE.floating && link) {
        return (
            <FloatingButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                loading={loading}
                background={background}
                hasIcon={!!icon}
                hasText={text.toString().length}
                style={style}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && onMouseDown
                    ? { onMouseDown }
                    : {}
                )}
                {...(!disabled && !loading && onTouchStart
                    ? { onTouchStart }
                    : {}
                )}
                {...(!disabled && !loading && !onMouseDown && onClick
                    ? { onClick }
                    : {}
                )}
            >
                {LinkButtonContents}
            </FloatingButton>
        );
    }

    if (type === BUTTON_TYPE.floating) {
        return (
            <FloatingButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                loading={loading}
                background={background}
                hasIcon={!!icon}
                hasText={text.toString().length}
                style={style}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && onMouseDown
                    ? { onMouseDown }
                    : {}
                )}
                {...(!disabled && !loading && onTouchStart
                    ? { onTouchStart }
                    : {}
                )}
                {...(!disabled && !loading && !onMouseDown && onClick
                    ? { onClick }
                    : {}
                )}
            >
                {tooltip.active
                && (
                    <Tooltip
                        text={tooltip.text}
                        side={tooltip.side}
                    />
                )}
                {RenderButtonContent}
                {RenderButtonChildren}
            </FloatingButton>
        );
    }

    if (type === BUTTON_TYPE.line && link) {
        return (
            <LineButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                loading={loading}
                background={background}
                hasIcon={!!icon}
                hasText={text.toString().length}
                style={style}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && onMouseDown
                    ? { onMouseDown }
                    : {}
                )}
                {...(!disabled && !loading && onTouchStart
                    ? { onTouchStart }
                    : {}
                )}
                {...(!disabled && !loading && !onMouseDown && onClick
                    ? { onClick }
                    : {}
                )}
            >
                {LinkButtonContents}
            </LineButton>
        );
    }

    if (type === BUTTON_TYPE.line) {
        return (
            <LineButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                loading={loading}
                background={background}
                hasIcon={!!icon}
                hasText={text.toString().length}
                style={style}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && onMouseDown
                    ? { onMouseDown }
                    : {}
                )}
                {...(!disabled && !loading && onTouchStart
                    ? { onTouchStart }
                    : {}
                )}
                {...(!disabled && !loading && !onMouseDown && onClick
                    ? { onClick }
                    : {}
                )}
            >
                {tooltip.active
                && (
                    <Tooltip
                        text={tooltip.text}
                        side={tooltip.side}
                    />
                )}
                {RenderButtonContent}
                {RenderButtonChildren}
            </LineButton>
        );
    }

    if (type === BUTTON_TYPE.solid && link) {
        return (
            <SolidButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                loading={loading}
                background={background}
                boxShadow={boxShadow}
                hasIcon={!!icon}
                hasText={text.toString().length}
                style={style}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && onMouseDown
                    ? { onMouseDown }
                    : {}
                )}
                {...(!disabled && !loading && onTouchStart
                    ? { onTouchStart }
                    : {}
                )}
                {...(!disabled && !loading && !onMouseDown && onClick
                    ? { onClick }
                    : {}
                )}
            >
                {LinkButtonContents}
            </SolidButton>
        );
    }

    if (type === BUTTON_TYPE.solid) {
        return (
            <SolidButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                loading={loading}
                background={background}
                boxShadow={boxShadow}
                hasIcon={!!icon}
                hasText={text.toString().length}
                style={style}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && onMouseDown
                    ? { onMouseDown }
                    : {}
                )}
                {...(!disabled && !loading && onTouchStart
                    ? { onTouchStart }
                    : {}
                )}
                {...(!disabled && !loading && !onMouseDown && onClick
                    ? { onClick }
                    : {}
                )}
            >
                {tooltip.active
                && (
                    <Tooltip
                        text={tooltip.text}
                        side={tooltip.side}
                    />
                )}
                {RenderButtonContent}
                {RenderButtonChildren}
            </SolidButton>
        );
    }

    if (type === BUTTON_TYPE.secret && link) {
        let secretButtonHoverColor: string | undefined;
        if (background && background in neutralColors) {
            secretButtonHoverColor = themeObj.color[
                `neutral${Math.min(neutralColors[background] + 100, 1000)}`
            ];
        }
        return (
            <SecretButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                loading={loading}
                background={background}
                hasIcon={!!icon}
                hasText={text.toString().length}
                secretButtonHoverColor={secretButtonHoverColor}
                style={style}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && onMouseDown
                    ? { onMouseDown }
                    : {}
                )}
                {...(!disabled && !loading && onTouchStart
                    ? { onTouchStart }
                    : {}
                )}
                {...(!disabled && !loading && !onMouseDown && onClick
                    ? { onClick }
                    : {}
                )}
            >
                {LinkButtonContents}
            </SecretButton>
        );
    }

    if (type === BUTTON_TYPE.secret) {
        let secretButtonHoverColor: string | undefined;
        if (background && background in neutralColors) {
            secretButtonHoverColor = themeObj.color[
                `neutral${Math.min(neutralColors[background] + 100, 1000)}`
            ];
        }
        return (
            <SecretButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                loading={loading}
                background={background}
                hasIcon={!!icon}
                hasText={text.toString().length}
                secretButtonHoverColor={secretButtonHoverColor}
                style={style}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && onMouseDown
                    ? { onMouseDown }
                    : {}
                )}
                {...(!disabled && !loading && onTouchStart
                    ? { onTouchStart }
                    : {}
                )}
                {...(!disabled && !loading && !onMouseDown && onClick
                    ? { onClick }
                    : {}
                )}
            >
                {tooltip.active
                && (
                    <Tooltip
                        text={tooltip.text}
                        side={tooltip.side}
                    />
                )}
                {RenderButtonContent}
                {RenderButtonChildren}
            </SecretButton>
        );
    }

    if (type === BUTTON_TYPE.nested && link && !!nestedButtonHeight) {
        const numChildren = React.Children.toArray(children).filter((o) => o).length;
        return (
            <NestedButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                loading={loading}
                background={background}
                boxShadow={boxShadow}
                hasIcon={!!icon}
                hasText={text.toString().length}
                style={style}
                {...(!(disabled || loading)
                    ? {
                        expanded: nestedBodyVisible,
                        expandedHeight: (numChildren * (nestedButtonHeight + NESTED_BUTTON_MARGIN_BOTTOM)) - NESTED_BUTTON_MARGIN_BOTTOM + 2 * NESTED_BUTTON_BODY_PADDING + 0.375 * BODY_FONT_SIZE,
                    }
                    : {}
                )}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && detectTouchDevice(document)
                    ? { onTouchStart: handleNestedButtonToggle }
                    : {}
                )}
                {...(!disabled && !loading && !detectTouchDevice(document)
                    ? { onMouseDown: handleNestedButtonToggle }
                    : {}
                )}
            >
                {LinkButtonContents}
            </NestedButton>
        );
    }

    if (type === BUTTON_TYPE.nested && !!nestedButtonHeight) {
        const numChildren = React.Children.toArray(children).filter((o) => o).length;

        return (
            <NestedButton
                ref={buttonRef}
                {...(id ? { id } : {})}
                {...(className ? { className } : {})}
                {...(center
                    ? { center: true }
                    : {}
                )}
                height={height}
                width={width}
                loading={loading}
                background={background}
                boxShadow={boxShadow}
                hasIcon={!!icon}
                hasText={text.toString().length}
                style={style}
                {...(!(disabled || loading)
                    ? {
                        expanded: nestedBodyVisible,
                        expandedHeight: (numChildren * (nestedButtonHeight + NESTED_BUTTON_MARGIN_BOTTOM)) - NESTED_BUTTON_MARGIN_BOTTOM + 2 * NESTED_BUTTON_BODY_PADDING + 0.375 * BODY_FONT_SIZE,
                    }
                    : {}
                )}
                {...(onMouseEnter
                    ? { onMouseEnter }
                    : {}
                )}
                {...(onMouseLeave
                    ? { onMouseLeave }
                    : {}
                )}
                {...(!disabled && !loading && detectTouchDevice(document)
                    ? { onTouchStart: handleNestedButtonToggle }
                    : {}
                )}
                {...(!disabled && !loading && !detectTouchDevice(document)
                    ? { onMouseDown: handleNestedButtonToggle }
                    : {}
                )}
            >
                {tooltip.active
                && (
                    <Tooltip
                        text={tooltip.text}
                        side={tooltip.side}
                    />
                )}
                {RenderButtonContent}
                {RenderButtonChildren}
            </NestedButton>
        );
    }

    return (
        <GenericButton
            ref={buttonRef}
            {...(id ? { id } : {})}
            {...(className ? { className } : {})}
            height={height}
            loading={loading}
            background={background}
            hasIcon={!!icon}
            hasText={text.toString().length}
            style={style}
            {...(onMouseEnter
                ? { onMouseEnter }
                : {}
            )}
            {...(onMouseLeave
                ? { onMouseLeave }
                : {}
            )}
            {...(!disabled && !loading && onMouseDown
                ? { onMouseDown }
                : {}
            )}
            {...(!disabled && !loading && onTouchStart
                ? { onTouchStart }
                : {}
            )}
            {...(!disabled && !loading && !onMouseDown && onClick
                ? { onClick }
                : {}
            )}
        >
            {tooltip.active
            && (
                <Tooltip
                    text={tooltip.text}
                    side={tooltip.side}
                />
            )}
            {RenderButtonContent}
            {RenderButtonChildren}
        </GenericButton>
    );
}

// ===== Styled Components =====

interface ButtonContentsProps {
    loading: boolean,
    height: number | null,
    center?: boolean,
}
const ButtonContents = styled.section<ButtonContentsProps>`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${({ loading }) => (loading ? 'center' : 'flex-start')};
    height: ${({ height }) => `${height || 2.5 * BODY_FONT_SIZE}px`};
    ${({ center }) => center && `
        padding-left: 0;
        padding-right: 0;
    `};
`;

const ExternalLink = styled.a`
    text-decoration: none;
    color: inherit;
    cursor: none;
`;

interface ButtonIconContainerProps {
    height: number,
    disabled: boolean,
    background: string | undefined,
}
const ButtonIconContainer = styled.div<ButtonIconContainerProps>`
    display: flex;
    align-items: center;
    height: ${({ height }) => `${0.6 * height}px`};
    width: ${({ height }) => `${0.6 * height}px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({
        background,
        theme,
        disabled,
    }) => {
        if (disabled) {
            return theme.color.neutral800;
        }

        if (background && validHexCode(background)) {
            return background;
        }
        return theme.color.neutral600;
    }};
        transition: fill 0.3s, transform 0.3s;
    }
`;

const Icon = styled.img`
    width: 100%;
`;

interface ButtonTextProps {
    fontSize: number | null,
    fontFamily: string | null,
    fontColor: string | null,
    height: number,
    disabled: boolean,
}
const ButtonText = styled.h3<ButtonTextProps>`
    font-family: ${({ fontFamily }) => fontFamily || FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontSize, height }) => (fontSize ? `${fontSize}px` : `${0.4 * height}px`)};
    font-weight: 500;
    color: ${({
        fontColor,
        color,
        theme,
        disabled,
    }) => {
        if (fontColor) {
            return fontColor;
        }

        if (disabled) {
            return theme.color.neutral800;
        }

        if (color && validHexCode(color)) {
            return color;
        }
        return theme.color.neutral700;
    }};
    text-align: center;
    white-space: pre;
    margin: 0;
`;

interface SpinnerContainerProps {
    height: number,
}
const SpinnerContainer = styled.img<SpinnerContainerProps>`
    height: ${({ height }) => `${height}px`};
    animation: rotate 2s linear infinite;
    margin: 0;
`;

interface DisabledButtonProps {
    width: number | undefined,
    center?: boolean,
    // Generic Button Props
    background: string | undefined,
    height: number | null,
    hasIcon: boolean,
    hasText: number,
    loading: boolean,
}
const DisabledButton = styled(GenericButton)<DisabledButtonProps>`
    height: ${({ height }) => `${height}px`};
    border-radius: ${({ height }) => `${height ? 0.5 * height : 0}px`};
    background-color: ${({ theme }) => theme.color.neutral300};
    transition: ${({ theme }) => `height ${BUTTON_EXPANSION_DURATION}ms ${theme.motion.eagerEasing}`};
    ${({ width }) => typeof width === 'number' && `
        width: ${width}px;
    `};
    ${({ center }) => center && `
        align-items: center;
    `};


    & section {
        opacity: 0.4;
    }
`;

interface LineButtonProps {
    width: number | undefined,
    center?: boolean,
    // Generic Button Props
    background: string | undefined,
    height: number | null,
    hasIcon: boolean,
    hasText: number,
    loading: boolean,
}
const LineButton = styled(GenericButton)<LineButtonProps>`
    height: ${({ height }) => `${height}px`};
    border-radius: ${({ height }) => (height ? `${0.5 * height}px` : '0px')};
    box-sizing: border-box;
    border: ${({ background, theme }) => (background && validHexCode(background)
        ? `${0.0625 * BODY_FONT_SIZE}px solid ${setColorLightness(background, BUTTON_CONTAINER_LIGHTNESS_VALUE)}`
        : `${0.0625 * BODY_FONT_SIZE}px solid ${theme.color.neutral400}`
    )};
    background-color: transparent;
    ${({ width }) => typeof width === 'number' && `
        width: ${width}px;
    `};
    ${({ center }) => center && `
        align-items: center;
    `};
    transition: border-radius 0.3s, color 0.3s;

    & h3 {
        color: ${({ theme }) => theme.color.neutral800};
    }

    &:hover {
        border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    }
`;

interface NestedButtonTriggerProps {
    expanded: boolean,
    background: string | undefined,
}
const NestedButtonTrigger = styled.div<NestedButtonTriggerProps>`
    position: relative;
    top: ${`${0.1 * BODY_FONT_SIZE}px`};
    left: ${`${0.35 * BODY_FONT_SIZE}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${`${1.5625 * BODY_FONT_SIZE}px`};
    height: ${`${1.5625 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.78125 * BODY_FONT_SIZE}px`};
    padding: ${`${0.1875 * BODY_FONT_SIZE}px`};
    background-color: 'transparent';
    transition: background-color 0.3s;

    & div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & svg {
        width: 100%;
        height: 100%;
        transform: ${({ expanded }) => (expanded
        ? 'rotate(270deg)'
        : 'rotate(90deg)'
    )};
        fill: ${({ background, theme }) => {
        if (background && validHexCode(background)) {
            const rgb = hexToRgb(background);
            const hsl = rgbToHsl({
                r: rgb!.r,
                g: rgb!.g,
                b: rgb!.b,
            });
            return setColorLightness(background, Math.max(hsl.l - 10, 0));
        }
        return theme.color.neutral400;
    }};
    }
`;

interface SolidButtonProps {
    width: number | undefined,
    boxShadow: boolean | null,
    center?: boolean,
    // Generic Button Props
    background: string | undefined,
    height: number | null,
    hasIcon: boolean,
    hasText: number,
    loading: boolean,
}
const SolidButton = styled(GenericButton)<SolidButtonProps>`
    height: ${({ height }) => `${height}px`};
    border-radius: ${({ height }) => (height ? `${0.5 * height}px` : '0px')};
    background-color: ${({ theme, background }) => (background && validHexCode(background)
        ? background
        : theme.color.neutral200
    )};
    ${({ width }) => typeof width === 'number' && `
        width: ${width}px;
    `};
    ${({ boxShadow, theme }) => boxShadow && `
        box-shadow: ${theme.color.boxShadow100};
    `};
    ${({ center }) => center && `
        align-items: center;
    `};

    &:hover {
        ${({ boxShadow, theme, background }) => {
        if (boxShadow) {
            return `box-shadow: ${theme.color.boxShadow300};`;
        }

        if (background && validHexCode(background)) {
            const rgb = hexToRgb(background);
            const hsl = rgbToHsl({
                r: rgb!.r,
                g: rgb!.g,
                b: rgb!.b,
            });
            return `background-color: ${setColorLightness(background, Math.max(hsl.l - 10, 0))};`;
        }

        return `background-color: ${theme.color.neutral300};`;
    }};

    & ${NestedButtonTrigger} svg {
            fill: ${({ background, theme }) => {
        if (background && validHexCode(background)) {
            const rgb = hexToRgb(background);
            const hsl = rgbToHsl({
                r: rgb!.r,
                g: rgb!.g,
                b: rgb!.b,
            });
            return setColorLightness(background, Math.max(hsl.l - 20, 0));
        }
        return theme.color.neutral500;
    }}
        }
    }
`;

interface FloatingButtonProps {
    width: number | undefined,
    center?: boolean,
    // Generic Button Props
    background: string | undefined,
    height: number | null,
    hasIcon: boolean,
    hasText: number,
    loading: boolean,
}
const FloatingButton = styled(GenericButton)<FloatingButtonProps>`
    background-color: ${({ theme, background }) => background || theme.color.white};
    height: ${({ height }) => `${height}px`};
    border-radius: ${({ height }) => (height ? `${0.5 * height}px` : '0px')};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transition: box-shadow 0.3s;
    ${({ width }) => typeof width === 'number' && `
        width: ${width}px;
    `};
    ${({ center }) => center && `
        align-items: center;
    `};

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }
`;

interface SecretButtonProps {
    width: number | undefined,
    center?: boolean,
    secretButtonHoverColor: string | undefined,
    // Generic Button Props
    background: string | undefined,
    height: number,
    hasIcon: boolean,
    hasText: number,
    loading: boolean,
}
const SecretButton = styled(GenericButton)<SecretButtonProps>`
    height: ${({ height }) => `${height}px`};
    border-radius: ${({ height }) => `${0.5 * height}px`};
    box-sizing: border-box;
    background-color: transparent;
    transition: background-color 0.3s, color 0.3s;
    ${({ width }) => typeof width === 'number' && `
        width: ${width}px;
    `};
    ${({ center }) => center && `
        align-items: center;
    `};

    & h3 {
        color: ${({ theme }) => theme.color.neutral800};
    }

    &:hover {
        background-color: ${({ background, secretButtonHoverColor, theme }) => {
        if (secretButtonHoverColor) {
            return secretButtonHoverColor;
        }

        if (background && validHexCode(background)) {
            const rgb = hexToRgb(background);
            const hsl = rgbToHsl({
                r: rgb!.r,
                g: rgb!.g,
                b: rgb!.b,
            });
            return setColorLightness(background, Math.min(hsl.l + 30, 95));
        }

        return theme.color.neutral200;
    }};
    }
`;

interface NestedButtonProps {
    expanded?: boolean,
    expandedHeight?: number,
    // Solid Button Props
    width: number | undefined,
    boxShadow: boolean | null,
    center?: boolean,
    // Generic Button Props
    background: string | undefined,
    height: number,
    hasIcon: boolean,
    hasText: number,
    loading: boolean,
}
const NestedButton = styled(SolidButton)<NestedButtonProps>`
    height: ${({ height, expanded, expandedHeight }) => (expanded && expandedHeight
        ? `${height + expandedHeight}px`
        : `${height}px`
    )};
    border-radius: ${({ height }) => `${0.5 * height}px`};
    transition: ${({ theme }) => `border-radius ${BUTTON_EXPANSION_DURATION}ms ${theme.motion.eagerEasing}`};

    & section {
        padding: 0px;
    }
`;

interface NestedBodyContainerProps {
    buttonHeight: number,
    expanded: boolean,
    disabled: boolean | undefined,
    borderColor: string | undefined,
    bodyColor: string,
}
const NestedBodyContainer = styled.div<NestedBodyContainerProps>`
    position: relative;
    width: ${`calc(100% - ${0.0625 * BODY_FONT_SIZE}px)`};
    background: ${({ disabled, bodyColor, theme }) => (disabled
        ? theme.color.neutral100
        : bodyColor
    )};
    border-radius: ${({ expanded, buttonHeight }) => (expanded
        ? `${0.5 * buttonHeight}px`
        : '0px'
    )};
    border: ${({
        borderColor,
        expanded,
        disabled,
        theme,
    }) => `${expanded ? BUTTON_BORDER_THICKNESS : 0}px solid ${disabled
        ? theme.color.neutral300
        : borderColor
    }`};
    padding: ${({ expanded }) => (expanded ? `${NESTED_BUTTON_BODY_PADDING}px` : '0px')};

    & > *:not(:last-child) {
        margin-bottom: ${`${NESTED_BUTTON_MARGIN_BOTTOM}px`};
    }
`;

const ChildContainer = styled.div``;

export default Button;

// ===== Packages =====

import React                                    from 'react';
import styled                                   from 'styled-components';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
}                                               from '../../../constants/generalConstants';

// ===== Styles =====

import { basicEditorFontStyles }                from '../../../styles';

interface Props {
    children: React.ReactElement,
    attributes: any,
}
function BlockQuote({ children, attributes }: Props): JSX.Element {
    return (
        <Container {...attributes}>
            {children}
        </Container>
    );
}

// ===== Styled Components =====

const Container = styled.blockquote`
    ${basicEditorFontStyles}
    font-size: 1.2em;
    color: ${({ theme }) => theme.color.purple100};
    font-style: italic;
    padding: ${`${0.9375 * BODY_FONT_SIZE}px 0px`};
    padding-left: ${`${1.875 * BODY_FONT_SIZE}px`};
    border-left: ${({ theme }) => `${0.3125 * BODY_FONT_SIZE}px solid ${theme.color.neutral900}`};

    ${({ theme }) => theme.mediaQuery.small} {
        margin-left: 0px;
        margin-right: 0px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 1em;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: ${`${0.9375 * BODY_FONT_SIZE}px 0px`};
    }
`;

export default BlockQuote;

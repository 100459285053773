// ===== Packages =====

import React, {
    useState,
    useMemo,
    useEffect,
}                                       from 'react';
import { ReactSVG }                     from 'react-svg';
import { Transition }                   from 'react-transition-group';
import moment                           from 'moment-timezone';
import {
    useMatch,
    useNavigate,
    useLocation,
    useSearchParams,
}                                       from 'react-router-dom';

// ===== Components =====

import {
    Button,
}                                       from '../Editor/helpers';

// ===== Services =====

import {
    detectTouchDevice,
    recordUserAction,
}                                       from '../../services';

// ===== Interfaces =====

import {
    INotificationItem,
    INotificationGroupItem,
    IUserItem,
}                                       from '../../interfaces';

// ===== Enums =====

import {
    NOTIFICATION_TYPE,
    NOTIFICATION_GROUP_TYPE,
    BUTTON_TYPE,
    PAGE_ROUTE,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    USER_ACTION_TYPE,
    READER_PARAMS_TYPE,
}                                       from '../../enums';
import { ZOOM_LEVEL }                   from '../Editor/elements/enums';

// ===== Images =====

import CrossIcon                        from '../../images/cross.svg';
import CommentIcon                      from '../../images/comment.svg';
import SmileyIcon                       from '../../images/editor/smiley.svg';
import AccordionTriangleIcon            from '../../images/accordion-triangle.svg';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
    BODY_FONT_SIZE,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    USER_PROFILE_AVATAR_LENGTH_MULTIPLIER,
    FADE_IN_STAGGER_OFFSET_DURATION,
    FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_TRANSITION_DURATION,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    FADE_IN_OUT_DEFAULT_STYLE,
    FADE_IN_OUT_TRANSITION_STYLES,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';

// ===== Styles =====

import {
    Container,
    NotificationCollector,
    NotificationTypeIconContainer,
    NotificationTitleContainer,
    NotificationImageContainer,
    NotificationContentContainer,
    NotificationText,
    NotificationBold,
    NotificationTimestamp,
    ClearAllButtonContainer,
    NotificationExpandContainer,
    NotificationContainer,
    RemoveNotificationButtonContainer,
    RemoveNotificationButton,
    ImageContainer,
    ContentContainer,
}                                       from './styles';
import {
    UserAvatar,
}                                       from '../../styles';
import { theme }                        from '../../themes/theme-context';

interface Props {
    user: IUserItem | null,
    currentSessionId: string | null,
    group: INotificationGroupItem,
    notificationAuthors: Map<string, IUserItem>,
    notificationAvatars: Map<string, string>,
    dismissNotification: (notif: INotificationItem) => Promise<void>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function NotificationGroup({
    user,
    currentSessionId,
    group,
    notificationAuthors,
    notificationAvatars,
    dismissNotification,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== Refs =====

    const CLEAR_ALL_BUTTON_HEIGHT_MULTIPLIER = 1.5625; // em

    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();
    const annotationIsFocused = useMatch(`${PAGE_ROUTE.book}/:annotationId`);
    const [searchParams] = useSearchParams();

    // ===== State =====

    // indicates whether group is expanded
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    // stores the height of expanded container
    const [expandedContainerHeight, setExpandedContainerHeight] = useState<number>(0);

    // ===== Animation Constants =====

    // ===== Methods =====

    const onNotificationGroupMouseEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.notificationGroup,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onNotificationGroupMouseLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onClearNotificationsButtonMouseEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.clearNotificationsButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onClearNotificationsButtonMouseLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
        onCursorEnter(
            CURSOR_TARGET.notificationGroup,
            [CURSOR_SIGN.click],
        );
    };

    const onRemoveNotificationButtonMouseEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.removeNotificationButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onRemoveNotificationButtonMouseLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
        onCursorEnter(
            CURSOR_TARGET.notification,
            [CURSOR_SIGN.click],
        );
    };

    const onNotificationItemMouseEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.notification,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onNotificationItemMouseLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const computeExpandedContainerHeight = (): void => {
        let updatedExpandedContainerHeight = 0;
        group.items.forEach((notif) => {
            const notifNode = document.getElementById(notif.id);
            if (notifNode) {
                updatedExpandedContainerHeight += notifNode.offsetHeight;
            }
        });
        if (expandedContainerHeight !== updatedExpandedContainerHeight) {
            setExpandedContainerHeight(updatedExpandedContainerHeight);
        }
    };

    const toggleExpand = (): void => {
        setIsExpanded(!isExpanded);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.toggleNotificationGroup,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    };

    const clearAllGroupNotifications = (): void => {
        const notificationPromises: Promise<void>[] = [];
        for (let i = 0; i < group.items.length; i += 1) {
            const notif = group.items[i];
            notificationPromises.push(dismissNotification(notif));
        }
        Promise.all(notificationPromises).then(() => {
            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.clearAllNotificationGroup,
                    userId: user.id,
                    sessionId: currentSessionId,
                });
            }
        });
    };

    const getLinkPath = (notif: INotificationItem): string => {
        switch (notif.type) {
        case NOTIFICATION_TYPE.annotationComment:
            if (
                notif.payload.annotationId
                && notif.payload.chapterIndex !== undefined
                && notif.payload.sectionIndex !== undefined
            ) {
                let newSearchParams = searchParams;
                newSearchParams = new URLSearchParams([
                    // must always be zoom level five (because no annotations in other levels)
                    [READER_PARAMS_TYPE.zoom.toString(), ZOOM_LEVEL.five.toString()],
                    [READER_PARAMS_TYPE.chapter.toString(), (notif.payload.chapterIndex + 1).toString()],
                    [READER_PARAMS_TYPE.section.toString(), (notif.payload.sectionIndex + 1).toString()],
                ]);
                return `/${PAGE_ROUTE.book}/${notif.payload.annotationId}?${newSearchParams.toString()}`;
            }

            throw Error(`Notification ${notif.id} did not have a link to annotation.`);
        default:
            return '/';
        }
    };

    const handleNotificationClick = async (notif: INotificationItem, linkPath: string): Promise<void> => {
        await dismissNotification(notif);
        navigate(
            linkPath,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    // ===== Side Effects =====

    useEffect(() => {
        if (expandedContainerHeight === 0) {
            computeExpandedContainerHeight();
        }
    }, [group.items]);

    // ===== Memoization =====

    const groupTypeText = useMemo(() => {
        switch (group.type) {
        case NOTIFICATION_GROUP_TYPE.annotation:
            return 'An annotation you\'re following in ';
        default:
            return 'You ';
        }
    }, [
        group,
    ]);

    const groupTypeVerb = useMemo(() => {
        switch (group.type) {
        case NOTIFICATION_GROUP_TYPE.annotation:
            return ' has ';
        default:
            return 'have ';
        }
    }, [
        group,
    ]);

    const groupItemTypeText = useMemo(() => {
        switch (group.itemType) {
        case NOTIFICATION_TYPE.annotationComment:
            return 'comment';
        default:
            return 'notification';
        }
    }, [
        group,
    ]);

    // ===== Rendering =====

    const renderNotification = (notif: INotificationItem): React.ReactElement => (
        <>
            <RemoveNotificationButtonContainer>
                <RemoveNotificationButton
                    show
                    isTouchDevice={detectTouchDevice(document)}
                    className={HOVER_TARGET_CLASSNAME}
                    {...(detectTouchDevice(document) ? {
                        onTouchStart: (e: React.TouchEvent) => onRemoveNotificationButtonMouseEnter(e),
                    } : {
                        onMouseEnter: (e: React.MouseEvent) => onRemoveNotificationButtonMouseEnter(e),
                    })}
                    {...(detectTouchDevice(document) ? {
                        onTouchEnd: (e: React.TouchEvent) => onRemoveNotificationButtonMouseLeave(e),
                    } : {
                        onMouseLeave: (e: React.MouseEvent) => onRemoveNotificationButtonMouseLeave(e),
                    })}
                    onMouseDown={() => dismissNotification(notif)}
                >
                    <ReactSVG
                        src={CrossIcon}
                    />
                </RemoveNotificationButton>
            </RemoveNotificationButtonContainer>
            <ImageContainer>
                {!!notificationAvatars.get(notif.userId)
                || (
                    notificationAuthors.get(notif.userId)
                    && !!notificationAuthors.get(notif.userId)!.photoURL
                )   ? (
                        <UserAvatar
                            url={
                                notificationAvatars.get(notif.userId)
                                || notificationAuthors.get(notif.userId)!.photoURL!
                            } // Has to be present if notificationAvatar is false
                            length={USER_PROFILE_AVATAR_LENGTH_MULTIPLIER * BODY_FONT_SIZE}
                        />
                    ) : (
                        <ReactSVG
                            src={SmileyIcon}
                        />
                    )}
            </ImageContainer>
            <ContentContainer>
                <NotificationText>
                    <NotificationBold>
                        {notif.title}
                    </NotificationBold>
                    {'\n'}
                    {`"${notif.message}"`}
                    {'\n'}
                    <NotificationTimestamp>
                        {`${moment.utc(notif.timestamp).fromNow()}`}
                    </NotificationTimestamp>
                </NotificationText>
            </ContentContainer>
        </>
    );

    return (
        <Container>
            <NotificationCollector
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e: React.TouchEvent) => onNotificationGroupMouseEnter(e),
                } : {
                    onMouseEnter: (e: React.MouseEvent) => onNotificationGroupMouseEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e: React.TouchEvent) => onNotificationGroupMouseLeave(e),
                } : {
                    onMouseLeave: (e: React.MouseEvent) => onNotificationGroupMouseLeave(e),
                })}
                onMouseDown={toggleExpand}
                showClearButton={isExpanded}
            >
                <NotificationTypeIconContainer
                    small
                    rotate={isExpanded}
                >
                    <ReactSVG
                        src={AccordionTriangleIcon}
                    />
                </NotificationTypeIconContainer>
                <NotificationImageContainer>
                    <ReactSVG
                        src={CommentIcon}
                    />
                </NotificationImageContainer>
                <NotificationContentContainer>
                    <NotificationTitleContainer>
                        <NotificationText>
                            {groupTypeText}
                            {group.groupName && (
                                <NotificationBold>
                                    {group.groupName}
                                </NotificationBold>
                            )}
                            {groupTypeVerb}
                            <NotificationBold>
                                {group.items.length}
                            </NotificationBold>
                            {` new ${groupItemTypeText}${group.items.length > 1 ? 's' : ''}. `}
                        </NotificationText>
                    </NotificationTitleContainer>
                </NotificationContentContainer>
                <ClearAllButtonContainer
                    isTouchDevice={detectTouchDevice(document)}
                >
                    <Button
                        type={BUTTON_TYPE.solid}
                        className={HOVER_TARGET_CLASSNAME}
                        background={theme.verascopeColor.purple400}
                        height={CLEAR_ALL_BUTTON_HEIGHT_MULTIPLIER * BODY_FONT_SIZE}
                        text="Clear"
                        {...(isExpanded
                            ? { onMouseDown: clearAllGroupNotifications }
                            : {}
                        )}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: (e: React.TouchEvent) => onClearNotificationsButtonMouseEnter(e),
                        } : {
                            onMouseEnter: (e: React.MouseEvent) => onClearNotificationsButtonMouseEnter(e),
                        })}
                        {...(detectTouchDevice(document) ? {
                            onTouchEnd: (e: React.TouchEvent) => onClearNotificationsButtonMouseLeave(e),
                        } : {
                            onMouseLeave: (e: React.MouseEvent) => onClearNotificationsButtonMouseLeave(e),
                        })}
                    />
                </ClearAllButtonContainer>
            </NotificationCollector>
            <NotificationExpandContainer
                expand={isExpanded}
                expandContainerHeight={expandedContainerHeight}
            >
                {group.items
                    .sort((a, b) => new Date(a.timestamp).getTime()
                        - new Date(b.timestamp).getTime())
                    .map((notif, i) => {
                        const linkPath = getLinkPath(notif);
                        const numNotifs = group.items.length;
                        return (
                            <Transition
                                key={notif.id}
                                in={isExpanded && !notif.read}
                                timeout={!notif.read
                                    ? {
                                        enter: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            (i * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ),
                                        exit: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            ((numNotifs - Math.max(i - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ),
                                    } : {
                                        enter: FADE_IN_TRANSITION_DURATION,
                                        exit: FADE_IN_TRANSITION_DURATION,
                                    }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {
                                    (state) => (
                                        <NotificationContainer
                                            id={notif.id}
                                            key={notif.id}
                                            className={HOVER_TARGET_CLASSNAME}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchStart: (e: React.TouchEvent) => onNotificationItemMouseEnter(e),
                                            } : {
                                                onMouseEnter: (e: React.MouseEvent) => onNotificationItemMouseEnter(e),
                                            })}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchEnd: (e: React.TouchEvent) => onNotificationItemMouseLeave(e),
                                            } : {
                                                onMouseLeave: (e: React.MouseEvent) => onNotificationItemMouseLeave(e),
                                            })}
                                            onMouseDown={() => handleNotificationClick(notif, linkPath)}
                                            {...(!annotationIsFocused
                                                && notif.type === NOTIFICATION_TYPE.annotationComment
                                                ? {}
                                                : {
                                                    style: !notif.read
                                                        ? {
                                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                                direction: 'right',
                                                                offset: 50,
                                                            }),
                                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                                direction: 'right',
                                                                offset: 50,
                                                                numItems: numNotifs,
                                                                index: i,
                                                            })[state],
                                                        } : {
                                                            ...FADE_IN_OUT_DEFAULT_STYLE({
                                                                direction: 'right',
                                                            }),
                                                            ...FADE_IN_OUT_TRANSITION_STYLES({
                                                                direction: 'right',
                                                            })[state],
                                                        },
                                                }
                                            )}
                                        >
                                            {renderNotification(notif)}
                                        </NotificationContainer>
                                    )
                                }
                            </Transition>
                        );
                    })}
            </NotificationExpandContainer>
        </Container>
    );
}

export default NotificationGroup;

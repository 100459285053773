// ===== Packages =====

import {
    doc,
    updateDoc,
    getFirestore,
}                           from 'firebase/firestore';

// ===== Enums =====

import {
    FIRESTORE_COLLECTION,
}                               from '../enums';
import {
    ZOOM_LEVEL,
}                               from '../components/Editor/elements/enums';

// ===== Interfaces =====

import {
    IAddress,
    IAnnotationCommentSubscription,
    ICartItem,
    IMailingListSubscription,
}                               from '../interfaces';

interface Props {
    userId: string,
    email?: string,
    avatarFilePath?: string,
    firstName?: string,
    lastName?: string,
    phoneNumber?: string,
    photoURL?: string,
    annotations?: string[],
    sessions?: string[],
    postAccess?: string,
    verified?: boolean,
    zoom?: ZOOM_LEVEL,
    mailingListSubscription?: IMailingListSubscription,
    commentSubscription?: IAnnotationCommentSubscription,
    hideAnnotations?: boolean,
    affiliatedIds?: string[],
    cart?: ICartItem[],
    addresses?: IAddress[],
}
const updateUserInDB = async ({
    userId,
    email,
    avatarFilePath,
    firstName,
    lastName,
    phoneNumber,
    photoURL,
    annotations,
    sessions,
    postAccess,
    verified,
    zoom,
    mailingListSubscription,
    commentSubscription,
    hideAnnotations,
    affiliatedIds,
    cart,
    addresses,
}: Props): Promise<void> => {
    const db = getFirestore();
    const usersCollection = process.env.NODE_ENV === 'production'
        ? FIRESTORE_COLLECTION.users
        : FIRESTORE_COLLECTION.stagingUsers;

    const updateObj: Record<
        string,
        number | string | string[] | boolean | IMailingListSubscription | ICartItem[] | IAddress[]
    > = {};
    if (email) {
        updateObj.email = email;
    }
    if (avatarFilePath) {
        updateObj.avatarFilePath = avatarFilePath;
    }
    if (firstName) {
        updateObj.firstName = firstName;
    }
    if (lastName) {
        updateObj.lastName = lastName;
    }
    if (phoneNumber) {
        updateObj.phoneNumber = phoneNumber;
    }
    if (photoURL) {
        updateObj.photoURL = photoURL;
    }
    if (annotations) {
        updateObj.annotations = annotations;
    }
    if (sessions) {
        updateObj.sessions = sessions;
    }
    if (postAccess) {
        updateObj.postAccess = postAccess;
    }
    if (zoom) {
        updateObj.zoom = zoom.valueOf();
    }
    if (affiliatedIds) {
        updateObj.affiliatedIds = affiliatedIds;
    }
    if (cart) {
        updateObj.cart = cart;
    }
    if (addresses) {
        updateObj.addresses = addresses;
    }

    if (mailingListSubscription) {
        updateObj.mailingListSubscription = mailingListSubscription;
    }

    if (commentSubscription) {
        updateObj.commentSubscription = commentSubscription;
    }

    if (hideAnnotations !== undefined) {
        updateObj.hideAnnotations = hideAnnotations;
    }

    if (verified !== undefined) {
        updateObj.verified = verified;
    }

    if (Object.keys(updateObj).length > 0) {
        await updateDoc(doc(db, usersCollection, userId), {
            ...updateObj,
        });
    }
};

export default updateUserInDB;

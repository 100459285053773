// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
}                                   from '../../constants/generalConstants';
import FONT_TYPE                    from '../../constants/fontType';

interface ContainerProps {
    successfullyVerifiedEmail: boolean,
}
export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ successfullyVerifiedEmail, theme }) => (successfullyVerifiedEmail
        ? theme.verascopeColor.green100
        : theme.verascopeColor.red100
    )};
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const LogomarkContainer = styled.div`
    width: 25vw;
    min-width: ${`${12.5 * BODY_FONT_SIZE}px`};
    max-width: ${`${37.5 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${3.75 * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        margin-bottom: ${`${2.5 * BODY_FONT_SIZE}px`};
    }
`;

export const StatusHeader = styled.h1`
    margin: ${`0px ${3.125 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${1.875 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: 1.5em;
    color: ${({ theme }) => theme.verascopeColor.white};
    text-align: center;
    line-height: 1.5em;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 1.3em;
        margin-bottom: ${`${1.25 * BODY_FONT_SIZE}px`};
    }
`;

interface StatusMessageProps {
    successfullyVerifiedEmail: boolean,
}
export const StatusMessage = styled.p<StatusMessageProps>`
    margin: 0;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1.2em;
    font-weight: 500;
    color: ${({ successfullyVerifiedEmail, theme }) => (successfullyVerifiedEmail
        ? theme.verascopeColor.green300
        : theme.verascopeColor.red300
    )};
    line-height: 1.5em;
    max-width: 40ch;
    text-align: center;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 1em;
    }
`;

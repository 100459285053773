// ===== Packages =====

import React, {
    useMemo,
}                                       from 'react';
import { Transition }                   from 'react-transition-group';

// ===== Components =====

import {
    Button,
}                                       from '../Editor/helpers';

// ===== Enums =====

import {
    BUTTON_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    detectTouchDevice,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IPostItem,
}                                       from '../../interfaces';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
    HOVER_TARGET_CLASSNAME,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    READER_LOCALIZING_NAVIGATOR_ENTER_DURATION,
    READER_LOCALIZING_NAVIGATOR_EXIT_DURATION,
}                                       from '../../constants/generalConstants';

// ===== Images =====

import RightArrowIcon                   from '../../images/arrow-right.svg';
import LeftArrowIcon                    from '../../images/arrow-left.svg';

// ===== Styles =====

import {
    Container,
    NavigationTip,
    LocalizingNavigator,
    LocalizingTextContainer,
    LocalizingSecondaryText,
    LocalizingPrimaryText,
    PostProgressBar,
    ButtonContainer,
}                                       from './styles';
import { theme as themeObj }            from '../../themes/theme-context';

ReaderLocalizingNavigator.defaultProps = {
    previousPageText: undefined,
    nextPageText: undefined,
    chapterIndex: undefined,
    sectionIndex: undefined,
};
interface Props {
    visible: boolean,
    previousPageText?: string,
    nextPageText?: string,
    post: IPostItem | undefined,
    chapterIndex?: number,
    sectionIndex?: number,
    initialProgress: number,
    readerLocalizingNavigatorRef: React.MutableRefObject<HTMLDivElement | null>,
    onMouseEnter: (e: React.MouseEvent) => void,
    onMouseLeave: (e: React.MouseEvent) => void,
    onNavigatePreviousPage: () => void,
    onNavigateNextPage: () => void,
}
function ReaderLocalizingNavigator({
    visible,
    previousPageText,
    nextPageText,
    post,
    chapterIndex,
    sectionIndex,
    initialProgress,
    readerLocalizingNavigatorRef,
    onMouseEnter,
    onMouseLeave,
    onNavigatePreviousPage,
    onNavigateNextPage,
}: Props): JSX.Element {
    // ===== Constants =====
    // Width of the navigator button
    const NAVIGATOR_BUTTON_WIDTH = 1.875 * BODY_FONT_SIZE;
    // Height of the navigator button
    const NAVIGATOR_BUTTON_HEIGHT = 1.875 * BODY_FONT_SIZE;

    // ===== Methods =====

    // ===== Memoization =====

    const primaryText = useMemo(() => {
        if (
            post
            && chapterIndex !== undefined
            && sectionIndex !== undefined
        ) {
            return `${sectionIndex + 1}. ${post?.chapters![chapterIndex].sections![sectionIndex].title}`;
        }

        if (post && chapterIndex !== undefined) {
            return `${chapterIndex + 1}. ${post?.chapters![chapterIndex].title}`;
        }

        return post?.title || '';
    }, [
        post,
        chapterIndex,
        sectionIndex,
    ]);

    return (
        <Transition
            in={visible}
            timeout={{
                enter: READER_LOCALIZING_NAVIGATOR_ENTER_DURATION,
                exit: READER_LOCALIZING_NAVIGATOR_EXIT_DURATION,
            }}
            appear
            mountOnEnter
            unmountOnExit
        >
            {(state) => (
                <Container
                    style={{
                        ...FADE_IN_DEFAULT_STYLE({
                            direction: 'up',
                            offset: 10,
                            duration: READER_LOCALIZING_NAVIGATOR_ENTER_DURATION,
                            easing: themeObj.motion.eagerEasing,
                            horizontalCenter: true,
                        }),
                        ...FADE_IN_TRANSITION_STYLES({
                            direction: 'up',
                            offset: 10,
                            horizontalCenter: true,
                        })[state],
                    }}
                >
                    {previousPageText && (
                        <NavigationTip
                            isLeft
                            title={nextPageText}
                        >
                            {previousPageText}
                        </NavigationTip>
                    )}
                    <LocalizingNavigator>
                        <PostProgressBar
                            ref={readerLocalizingNavigatorRef}
                            initialProgress={initialProgress}
                        />
                        {!!previousPageText && (
                            <ButtonContainer
                                isLeft
                            >
                                <Button
                                    className={HOVER_TARGET_CLASSNAME}
                                    type={BUTTON_TYPE.floating}
                                    background={themeObj.verascopeColor.purple400}
                                    height={NAVIGATOR_BUTTON_HEIGHT}
                                    width={NAVIGATOR_BUTTON_WIDTH}
                                    icon={LeftArrowIcon}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: onNavigatePreviousPage,
                                    } : {
                                        onMouseDown: onNavigatePreviousPage,
                                    })}
                                />
                            </ButtonContainer>
                        )}
                        <LocalizingTextContainer
                            marginLeft={previousPageText ? NAVIGATOR_BUTTON_WIDTH : 0}
                            marginRight={nextPageText ? NAVIGATOR_BUTTON_WIDTH : 0}
                        >
                            {post
                            && chapterIndex !== undefined
                            && sectionIndex !== undefined && (
                                <LocalizingSecondaryText>
                                    {`${chapterIndex !== undefined ? `${chapterIndex + 1}. ` : ''}${post!.chapters![chapterIndex].title}`}
                                </LocalizingSecondaryText>
                            )}
                            <LocalizingPrimaryText>
                                {primaryText}
                            </LocalizingPrimaryText>
                        </LocalizingTextContainer>
                        {!!nextPageText && (
                            <ButtonContainer>
                                <Button
                                    className={HOVER_TARGET_CLASSNAME}
                                    type={BUTTON_TYPE.floating}
                                    background={themeObj.verascopeColor.orange200}
                                    height={NAVIGATOR_BUTTON_HEIGHT}
                                    width={NAVIGATOR_BUTTON_WIDTH}
                                    icon={RightArrowIcon}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: onNavigateNextPage,
                                    } : {
                                        onMouseDown: onNavigateNextPage,
                                    })}
                                />
                            </ButtonContainer>
                        )}
                    </LocalizingNavigator>
                    {nextPageText && (
                        <NavigationTip
                            title={nextPageText}
                        >
                            {nextPageText}
                        </NavigationTip>
                    )}
                </Container>
            )}
        </Transition>
    );
}

export default ReaderLocalizingNavigator;

// ** IMPORTANT **: Keep in sync with functions/src/index.ts
export enum PAGE_ROUTE {
    about = 'about',
    // ** IMPORTANT **: Keep in sync with functions/src/index.ts/dynamicMetaTags (regex)
    book = 'book',
    physicalBook = 'physical-book',
    characters = 'team',
    // ** IMPORTANT **: Keep in sync with functions/src/index.ts/dynamicMetaTags (regex)
    treasureChest = 'chest',
    socialEmergence = 'social-emergence',
    unsubscribe = 'unsubscribe',
    unsubscribeResult = 'unsubscribed',
    verify = 'verify',
    verifyResult = 'verified',
    cart = 'cart',
    checkout = 'checkout',
    landing = '',
}

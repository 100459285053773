// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
}                                   from '../../constants/generalConstants';
import FONT_TYPE                    from '../../constants/fontType';

const NOTIFICATION_HEIGHT_MULTIPLIER = 3.125; // em
const NOTIFICATION_ACCORDION_EXPAND_DURATION = 150;
const NOTIFICATION_MARGIN_BOTTOM_MULTIPLIER = 1.25; // em;
const NOTIFICATION_TYPE_ICON_CONTAINER_WIDTH_MULTIPLIER = 1.5; // em
const NOTIFICATION_TYPE_ICON_CONTAINER_PADDING_MULTIPLIER = 0.625; // em
const NOTIFICATION_TYPE_ICON_CONTAINER_SVG_WIDTH_REGULAR_MULTIPLIER = 1.5625; // em
const NOTIFICATION_TYPE_ICON_CONTAINER_SVG_WIDTH_SMALL_MULTIPLIER = 1.25; // em
const NOTIFICATION_TYPE_ICON_CONTAINER_SVG_HEIGHT_REGULAR_MULTIPLIER = 1.5625; // em
const NOTIFICATION_TYPE_ICON_CONTAINER_SVG_HEIGHT_SMALL_MULTIPLIER = 1.25; // em
const NOTIFICATION_TITLE_CONTAINER_MIN_HEIGHT_MULTIPLIER = 3.125; // em
const NOTIFICATION_IMAGE_CONTAINER_LENGTH_MULTIPLIER = 2.5; // em
const NOTIFICATION_CONTENT_CONTAINER_WIDTH_BUFFER_MULTIPLIER = 4; // em
const NOTIFICATION_CONTENT_CONTAINER_PADDING_LEFT_MULTIPLIER = 0.875; // em
const CLEAR_ALL_BUTTON_CONTAINER_RIGHT_MULTIPLIER = 0.25; // em
const NOTIFICATION_EXPAND_CONTAINER_MULTIPLIER = 0.625; // em
const NOTIFICATION_CONTAINER_MAX_WIDTH_MULTIPLIER = 31.25; // em
const REMOVE_NOTIFICATION_BUTTON_CONTAINER_MARGIN_TOP_MULTIPLIER = 0.625; // em
const REMOVE_NOTIFICATION_BUTTON_CONTAINER_MARGIN_LEFT_MULTIPLIER = 0.1; // em
const REMOVE_NOTIFICATION_BUTTON_LENGTH_MULTIPLIER = 1.875; // em;
const REMOVE_NOTIFICATION_BUTTON_SVG_LENGTH_MULTIPLIER = 1.25; // em
const IMGAE_CONTAINER_PADDING_MULTIPLIER = 0.625; // em
const CONTENT_CONTAINER_WIDTH_OFFSET_MULTIPLIER = 4.5; // em
const CONTENT_CONTAINER_MIN_HEIGHT_MULTIPLIER = 3.125; // em
const CONTENT_CONTAINER_PADDING_MULTIPLIER = 0.625; // em
const CONTENT_CONTAINER_PADDING_LEFT_MULTIPLIER = 0.9375; // em
const NOTIFICATION_IMAGE_CONTAINER_PADDING_MULTIPLIER = 0.625; // em

export const Container = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.white};
    overflow: hidden;
`;

interface NotificationProps {
    margin?: boolean,
}
export const Notification = styled.div<NotificationProps>`
    position: relative;
    width: 100%;
    margin-bottom: ${({ margin }) => (margin
        ? `${NOTIFICATION_MARGIN_BOTTOM_MULTIPLIER * BODY_FONT_SIZE}px`
        : '0px'
    )};
`;

interface NotificationCollectorProps {
    showClearButton: boolean,
}
export const NotificationCollector = styled(Notification)<NotificationCollectorProps>`
    display: block;
    &:hover section {
        opacity: ${({ showClearButton }) => (showClearButton
        ? 1
        : 0
    )};
    }
`;

interface NotificationTypeIconContainerProps {
    rotate?: boolean,
    small?: boolean,
}
export const NotificationTypeIconContainer = styled.div<NotificationTypeIconContainerProps>`
    display: inline-block;
    width: ${`${NOTIFICATION_TYPE_ICON_CONTAINER_WIDTH_MULTIPLIER * BODY_FONT_SIZE + 0.78125 * BODY_FONT_SIZE}px`};
    height: 100%;
    vertical-align: top;
    transform: ${({ rotate }) => (rotate
        ? 'rotate(90deg)'
        : 'rotate(0deg)'
    )};
    padding: ${`${NOTIFICATION_TYPE_ICON_CONTAINER_PADDING_MULTIPLIER * BODY_FONT_SIZE}px`};
    transition: transform 0.3s;

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
    }

    & svg {
        display: inline-block;
        width: ${({ small }) => `${(small
        ? NOTIFICATION_TYPE_ICON_CONTAINER_SVG_WIDTH_SMALL_MULTIPLIER
        : NOTIFICATION_TYPE_ICON_CONTAINER_SVG_WIDTH_REGULAR_MULTIPLIER
    ) * BODY_FONT_SIZE}px`};
        height: ${({ small }) => `${(small
        ? NOTIFICATION_TYPE_ICON_CONTAINER_SVG_HEIGHT_SMALL_MULTIPLIER
        : NOTIFICATION_TYPE_ICON_CONTAINER_SVG_HEIGHT_REGULAR_MULTIPLIER) * BODY_FONT_SIZE
    }px`};
        fill: ${({ theme }) => theme.color.neutral500};
    }
`;

export const NotificationTitleContainer = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    min-height: ${`${NOTIFICATION_TITLE_CONTAINER_MIN_HEIGHT_MULTIPLIER * BODY_FONT_SIZE}px`};
    width: 100%;
`;

export const NotificationImageContainer = styled.div`
    position: relative;
    display: inline-block;
    height: ${`${NOTIFICATION_IMAGE_CONTAINER_LENGTH_MULTIPLIER * BODY_FONT_SIZE}px`};
    vertical-align: top;
    padding: ${`${NOTIFICATION_IMAGE_CONTAINER_PADDING_MULTIPLIER * BODY_FONT_SIZE}px 0px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral600};
    }
`;

export const NotificationContentContainer = styled.div`
    display: inline-block;
    width: ${`calc(100% - ${NOTIFICATION_CONTENT_CONTAINER_WIDTH_BUFFER_MULTIPLIER * BODY_FONT_SIZE}px)`};
    padding-left: ${`${NOTIFICATION_CONTENT_CONTAINER_PADDING_LEFT_MULTIPLIER * BODY_FONT_SIZE}px`};
`;

export const NotificationText = styled.div`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    font-weight: 500;
    color: ${({ theme }) => theme.color.neutral700};
    line-height: 1.5em;
    width: 100%;
    white-space: pre-wrap;
    vertical-align: middle;
`;

export const NotificationBold = styled.span`
    font-weight: 600;
    color: ${({ theme }) => theme.color.neutral800};
`;

export const NotificationTimestamp = styled.span`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 300;
    font-size: 0.9em;
    color: ${({ theme }) => theme.color.neutral600};
`;

interface ClearAllButtonContainerProps {
    isTouchDevice: boolean,
}
export const ClearAllButtonContainer = styled.section<ClearAllButtonContainerProps>`
    position: absolute;
    top: 50%;
    right: ${`${CLEAR_ALL_BUTTON_CONTAINER_RIGHT_MULTIPLIER * BODY_FONT_SIZE}px`};
    transform: translateY(-50%);
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    font-weight: 400;
    color: ${({ theme }) => theme.color.neutral500};
    opacity: 0;
    background: ${({ theme }) => theme.color.white};
    transition: opacity 0.3s, visibility 0.3s;
    overflow: hidden;
`;

interface NotificationExpandContainerProps {
    expandContainerHeight: number,
    expand: boolean,
}
export const NotificationExpandContainer = styled.div<NotificationExpandContainerProps>`
    position: relative;
    /* height: ${({ expandContainerHeight, expand }) => (expand
        ? `${expandContainerHeight}px`
        : '0px'
    )}; */
    transition: ${({ theme }) => `
        height ${NOTIFICATION_ACCORDION_EXPAND_DURATION}ms ${theme.motion.eagerEasing},
        padding-top ${NOTIFICATION_ACCORDION_EXPAND_DURATION}ms ${theme.motion.eagerEasing},
        margin-top ${NOTIFICATION_ACCORDION_EXPAND_DURATION}ms ${theme.motion.eagerEasing}
    `};
    margin-top: ${({ expand }) => (expand
        ?  `${NOTIFICATION_EXPAND_CONTAINER_MULTIPLIER * BODY_FONT_SIZE}px`
        : '0px'
    )};
`;

export const NotificationContainer = styled.div`
    position: relative;
    min-height: ${`${NOTIFICATION_HEIGHT_MULTIPLIER * BODY_FONT_SIZE}px`};
    background: ${({ theme }) => theme.color.white};
    max-width: ${`${NOTIFICATION_CONTAINER_MAX_WIDTH_MULTIPLIER * BODY_FONT_SIZE}px`};

    &:hover button {
        opacity: 1;
    }
`;

export const RemoveNotificationButtonContainer = styled.div`
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    overflow: hidden;
    background: ${({ theme }) => theme.color.white};
    margin-top: ${`${REMOVE_NOTIFICATION_BUTTON_CONTAINER_MARGIN_TOP_MULTIPLIER * BODY_FONT_SIZE}px`};
    margin-left: ${`${REMOVE_NOTIFICATION_BUTTON_CONTAINER_MARGIN_LEFT_MULTIPLIER * BODY_FONT_SIZE}px`};
`;

interface RemoveNotificationButtonProps {
    show: boolean,
    isTouchDevice: boolean,
}
export const RemoveNotificationButton = styled.button<RemoveNotificationButtonProps>`
    display: ${({ show }) => (show
        ? 'block'
        : 'none'
    )};
    position: relative;
    border-radius: ${`${(REMOVE_NOTIFICATION_BUTTON_LENGTH_MULTIPLIER / 2) * BODY_FONT_SIZE}px`};
    background: ${({ theme }) => theme.color.neutral300};
    padding: 0px;
    border: none;
    outline: none;
    opacity: ${({ isTouchDevice }) => (isTouchDevice ? 1 : 0)};
    width: ${`${REMOVE_NOTIFICATION_BUTTON_LENGTH_MULTIPLIER * BODY_FONT_SIZE}px`};
    height: ${`${REMOVE_NOTIFICATION_BUTTON_LENGTH_MULTIPLIER * BODY_FONT_SIZE}px`};
    cursor: none;
    transition: background 0.3s, opacity 0.3s;

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: ${`${REMOVE_NOTIFICATION_BUTTON_SVG_LENGTH_MULTIPLIER * BODY_FONT_SIZE}px`};
        height: ${`${REMOVE_NOTIFICATION_BUTTON_SVG_LENGTH_MULTIPLIER * BODY_FONT_SIZE}px`};
        transition: fill 0.3s;
        fill: ${({ theme }) => theme.color.neutral600};
    }

    &:hover {
        background: ${({ theme }) => theme.color.neutral500};
    }

    &:hover svg {
        fill: ${({ theme }) => theme.color.neutral800};
    }
`;

export const ImageContainer = styled.div`
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    height: 100%;
    padding-top: ${`${IMGAE_CONTAINER_PADDING_MULTIPLIER * BODY_FONT_SIZE}px`};
    padding-left: ${`${IMGAE_CONTAINER_PADDING_MULTIPLIER * BODY_FONT_SIZE}px`};
`;

export const ContentContainer = styled.div`
    position: relative;
    display: inline-block;
    width: ${`calc(100% - ${CONTENT_CONTAINER_WIDTH_OFFSET_MULTIPLIER  * BODY_FONT_SIZE}px)`};
    min-height: ${`${CONTENT_CONTAINER_MIN_HEIGHT_MULTIPLIER * BODY_FONT_SIZE}px`};
    padding-bottom: ${`${CONTENT_CONTAINER_PADDING_MULTIPLIER * BODY_FONT_SIZE}px`};
    padding-left: ${`${CONTENT_CONTAINER_PADDING_LEFT_MULTIPLIER * BODY_FONT_SIZE}px`};
    vertical-align: top;
    height: 100%;
`;

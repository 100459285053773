// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                               from 'react';
import styled                                   from 'styled-components';
import emoji                                    from 'emoji.json';
import { ReactSVG }                             from 'react-svg';
import { Waypoint }                             from 'react-waypoint';
import ReactList                                from 'react-list';

// ===== Components =====

import { Emoji }                                from '.';
import {
    PlaceholderContainer,
    PlaceholderSubtitle,
}                                               from '../../../styles';

// ===== Services =====

import {
    hexToRgb,
    rgbToHex,
    hslToRgb,
    rgbToHsl,
    getIconObject,
    fontColorDiscriminator,
    playAudio,
}                                               from '../../../services';

// ===== Interfaces =====

import { IEmoji }                               from '../../../interfaces';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
}                                                   from '../../../enums';

// ===== Sounds =====

import InputClick                               from '../../../sounds/button_click.mp3';

// ===== Images =====

import FlowerIcon                               from '../../../images/editor/flower.svg';
import MagnifyingGlass                          from '../../../images/editor/magnifying-glass.svg';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
    DEFAULT_AUDIO_VOLUME,
    HOVER_TARGET_CLASSNAME,
}                                               from '../../../constants/generalConstants';
import CURSOR_SIGN                              from '../../../constants/cursorSigns';
import EMOJI_CATEGORY_ICONS                     from '../../../constants/emojiCategoryIcons';
import FONT_TYPE                                from '../../../constants/fontType';

// ===== Styles =====

import { theme as themeObj }                    from '../../../themes/theme-context';

EmojiSelector.defaultProps = {
    setInputRef: undefined,
    currentEmoji: undefined,
    onCursorEnter: undefined,
    onCursorLeave: undefined,
};
interface Props {
    hasSound: boolean,
    color: string,
    width: number,
    compact: boolean,
    setInputRef?: (ref: HTMLElement) => void,
    currentEmoji?: string, // Relative when you have a state varaible that holds an emoji that is passed down
    responsiveLayout: boolean,
    handleEmojiSelect: (emojiObj?: IEmoji) => void,
    onCursorEnter?: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave?: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
}
function EmojiSelector({
    hasSound,
    color = themeObj.color.neutral300,
    width,
    compact = false,
    setInputRef,
    currentEmoji,
    responsiveLayout = false,
    handleEmojiSelect,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
}: Props): JSX.Element {
    // ===== General Constants =====

    const EMOJI_CATEGORY_TITLE_HEIGHT = 2.5 * BODY_FONT_SIZE;
    const SEARCH_CONTAINER_HEIGHT = 2.8125 * BODY_FONT_SIZE;
    const EMOJI_ICON_LENGTH = 1.875 * BODY_FONT_SIZE;
    const EMOJI_SEARCH_TITLE_HEIGHT = 1.875 * BODY_FONT_SIZE;

    // ===== Refs =====

    const categoryListRef = useRef<ReactList>(null);
    const searchInputRef = useRef<HTMLInputElement | null>(null);

    // ----- Sound Clips
    const inputClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State ====

    // Stores a mapping between each emoji category and it's emojis
    const [emojiCategories, setEmojiCategories] = useState<Map<string, IEmoji[]>>(new Map());
    // Stores the heights of each emoji category container
    const [categoryHeights, setCategoryHeights] = useState<number[]>([]);
    // Stores the current category based on scroll position
    const [currentEmojiCategory, setCurrentEmojiCategory] = useState<string>('Smileys & Emotion');
    // Stores the current search string
    const [searchString, setSearchString] = useState<string>('');
    // Stores a list of search results based on the search string
    const [searchResults, setSearchResults] = useState<IEmoji[]>([]);
    // Stores the index of a category we desire to visit
    const [indexToVisit, setIndexToVisit] = useState<number | null>(null);

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (inputClickClip.current) {
            // Input Click
            inputClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            inputClickClip.current.src = InputClick;
        }

        return function cleanup() {
            if (inputClickClip.current) inputClickClip.current.remove();
        };
    }, []);

    useEffect(() => {
        if (searchInputRef.current && setInputRef) {
            setInputRef(searchInputRef.current);
        }
    }, [searchInputRef.current]);

    // Prepare Emoji Categories
    useEffect(() => {
        const categories: Map<string, IEmoji[]> = new Map();
        for (let i = 0; i < emoji.length; i += 1) {
            const emojiCategory: string = emoji[i].category;
            if (Array.from(categories.keys()).reduce(
                (seen, category) => category === emojiCategory || seen,
                false,
            )) {
                categories.get(emojiCategory)?.push(emoji[i]);
            } else {
                categories.set(emojiCategory, [emoji[i]]);
            }
        }
        setEmojiCategories(categories);

        const heights: number[] = [];
        Array.from(categories.keys()).forEach((category) => {
            let effectiveWidth = width;

            if (compact) {
                effectiveWidth = width;
            } else if (!responsiveLayout) {
                effectiveWidth = 0.65 * width;
            } else if (responsiveLayout) {
                effectiveWidth = width - 40;
            }

            const columnCount = Math.floor(effectiveWidth / EMOJI_ICON_LENGTH);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const rowCount = Math.ceil(categories.get(category)!.length / columnCount);
            const rowHeight = EMOJI_ICON_LENGTH * rowCount + EMOJI_CATEGORY_TITLE_HEIGHT;
            heights.push(rowHeight);
        });

        setCategoryHeights(heights);
    }, []);

    // Compute Emoji Search Results
    useEffect(() => {
        if (searchString.length > 0) {
            const sum: IEmoji[] = Array.from(emojiCategories.keys())
                .reduce((all: IEmoji[], cat: string) => {
                    const updatedAll = [
                        ...all,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        ...emojiCategories.get(cat)!,
                    ];
                    return updatedAll;
                }, []);
            const results: IEmoji[] = sum
                .filter((emojiObj: IEmoji) => emojiObj.keywords.includes(searchString));
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    }, [searchString]);

    useEffect(() => {
        if (typeof indexToVisit === 'number') {
            visitCategory(indexToVisit);
            setIndexToVisit(null);
        }
    }, [indexToVisit]);

    // ===== Methods =====

    const handleSearchEmoji = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchString(e.target.value);
    };

    // Get RGB Representation
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const themeRGB = hexToRgb(color)!;

    // Get HSL Representation
    const themeHSL = rgbToHsl({
        r: themeRGB.r,
        g: themeRGB.g,
        b: themeRGB.b,
    });

    // Convert to Hex Code to RGB
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const selectedRGB = hexToRgb(themeObj.color.neutral200)!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const hoverRGB = hexToRgb(themeObj.color.neutral100)!;

    // Convert RGB to HSL
    const selectedHSL = rgbToHsl({
        r: selectedRGB.r,
        g: selectedRGB.g,
        b: selectedRGB.b,
    });
    const hoverHSL = rgbToHsl({
        r: hoverRGB.r,
        g: hoverRGB.g,
        b: hoverRGB.b,
    });

    // Convert HSL to RGB with Updated V value
    const modifiedSelectedRGB = hslToRgb({
        h: themeHSL.h,
        s: selectedHSL.s,
        l: selectedHSL.l,
    });
    const modifiedHoverRGB = hslToRgb({
        h: themeHSL.h,
        s: hoverHSL.s,
        l: hoverHSL.l,
    });

    // Get background for tag
    const selectedColor = rgbToHex({
        r: modifiedSelectedRGB.r,
        g: modifiedSelectedRGB.g,
        b: modifiedSelectedRGB.b,
    });

    const hoverColor = rgbToHex({
        r: modifiedHoverRGB.r,
        g: modifiedHoverRGB.g,
        b: modifiedHoverRGB.b,
    });

    /**
     * Navigates Emoji Selector to desired category
     * @param  {[type]} category [particular emoji category to navigate to]
     */
    const visitCategory = (index: number): void => {
        if (categoryListRef.current) {
            categoryListRef.current.scrollTo(index);
        }
    };

    const onEmojiCategoryMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.emojiCategory,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onEmojiCategoryMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onEmojiMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.emoji,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onEmojiMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorToolbarButton,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onSearchInputMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.input,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onSearchInputMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onSearchInputFocus = (): void => {
        setInputFocused(true);

        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    const onSearchInputBlur = (): void => {
        setInputFocused(false);
    };

    // ===== Rendering =====

    const getListItem = (index: number): React.ReactElement => {
        const category = Array.from(emojiCategories.keys())[index];

        return (
            <Waypoint
                topOffset="30%"
                bottomOffset="30%"
                onEnter={() => setCurrentEmojiCategory(category)}
            >
                <CategoryEmojiContainer
                    id={category}
                >
                    <CategoryTitleContainer
                        height={EMOJI_CATEGORY_TITLE_HEIGHT}
                    >
                        <CategoryTitleIcon>
                            <ReactSVG
                                src={EMOJI_CATEGORY_ICONS[category]}
                            />
                        </CategoryTitleIcon>
                        {category}
                    </CategoryTitleContainer>
                    <EmojiGrid>
                        {emojiCategories.get(category)?.map((emojiObj: IEmoji) => (
                            <EmojiContainer
                                key={emoji.char}
                                className={HOVER_TARGET_CLASSNAME}
                                emojiIconLength={EMOJI_ICON_LENGTH}
                                onMouseEnter={onEmojiCategoryMouseEnter}
                                onMouseLeave={onEmojiCategoryMouseLeave}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    handleEmojiSelect(emojiObj);
                                }}
                                selected={
                                    currentEmoji
                                    === emojiObj.char
                                }
                                themeColor={color}
                            >
                                <Emoji
                                    char={emojiObj.char}
                                />
                            </EmojiContainer>
                        ))}
                    </EmojiGrid>
                </CategoryEmojiContainer>
            </Waypoint>
        );
    };

    const getListItemSize = (index: number): number => categoryHeights[index];

    return (
        <Container
            compact={compact}
        >
            <CategoryListContainer
                compact={compact}
                responsiveLayout={responsiveLayout}
                emojiIconLength={EMOJI_ICON_LENGTH}
            >
                {!compact && (
                    <NoIconButtonContainer
                        className={HOVER_TARGET_CLASSNAME}
                        responsiveLayout={responsiveLayout}
                        hoverColor={hoverColor}
                        emojiIconLength={EMOJI_ICON_LENGTH}
                        onMouseEnter={onButtonMouseEnter}
                        onMouseLeave={onButtonMouseLeave}
                        onMouseDown={() => handleEmojiSelect()}
                    >
                        <NoIconButtonIcon>
                            <ReactSVG
                                src={FlowerIcon}
                                style={{
                                    fill: themeObj.color.neutral400,
                                }}
                            />
                        </NoIconButtonIcon>
                        <NoIconButtonText
                            responsiveLayout={responsiveLayout}
                        >
                            No Icon
                        </NoIconButtonText>
                    </NoIconButtonContainer>
                )}
                {Array.from(emojiCategories.keys()).map((category, index) => {
                    const Icon = EMOJI_CATEGORY_ICONS[category];
                    let categoryBackground;
                    let categoryText;

                    if (currentEmojiCategory === category) {
                        categoryBackground = selectedColor;
                    } else {
                        categoryBackground = themeObj.color.white;
                    }

                    if (currentEmoji && currentEmoji.length > 0
                        && getIconObject(currentEmoji).category === category) {
                        categoryText = color;
                    } else {
                        categoryText = fontColorDiscriminator(categoryBackground);
                    }

                    return (
                        <CategoryListItem
                            key={category}
                            className={HOVER_TARGET_CLASSNAME}
                            compact={compact}
                            background={categoryBackground}
                            selectedColor={selectedColor}
                            hoverColor={hoverColor}
                            emojiIconLength={EMOJI_ICON_LENGTH}
                            selected={!!currentEmoji && getIconObject(currentEmoji).category === category}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            onMouseDown={(e: React.MouseEvent) => {
                                e.preventDefault();
                                if (searchString.length > 0) {
                                    // clear search string
                                    setSearchString('');
                                    // clear input
                                    if (searchInputRef.current) searchInputRef.current.value = '';
                                    // set index we desire to visit
                                    setIndexToVisit(index);
                                } else {
                                    visitCategory(index);
                                }
                            }}
                        >
                            <CategoryListItemIcon
                                compact={compact}
                                color={currentEmoji && currentEmoji.length > 0
                                && getIconObject(currentEmoji).category === category
                                    ? categoryText
                                    : themeObj.color.neutral600}
                                emojiIconLength={EMOJI_ICON_LENGTH}
                            >
                                <ReactSVG
                                    src={Icon}
                                />
                            </CategoryListItemIcon>
                            {!compact && (
                                <CategoryListItemText
                                    color={categoryText}
                                    responsiveLayout={responsiveLayout}
                                    emojiIconLength={EMOJI_ICON_LENGTH}
                                >
                                    {category}
                                </CategoryListItemText>
                            )}
                        </CategoryListItem>
                    );
                })}
            </CategoryListContainer>
            <EmojiBodyContainer
                compact={compact}
                responsiveLayout={responsiveLayout}
                emojiIconLength={EMOJI_ICON_LENGTH}
            >
                <SearchContainer
                    height={SEARCH_CONTAINER_HEIGHT}
                >
                    <SearchIconContainer>
                        <ReactSVG
                            src={MagnifyingGlass}
                        />
                    </SearchIconContainer>
                    <SearchInput
                        type="text"
                        ref={searchInputRef}
                        className={HOVER_TARGET_CLASSNAME}
                        placeholder="Search Emoji..."
                        onChange={handleSearchEmoji}
                        onMouseEnter={onSearchInputMouseEnter}
                        onMouseLeave={onSearchInputMouseLeave}
                        onFocus={onSearchInputFocus}
                        onBlur={onSearchInputBlur}
                    />
                </SearchContainer>
                {searchString.length > 0
                    && (
                        <SearchTitleContainer
                            height={EMOJI_SEARCH_TITLE_HEIGHT}
                        >
                            <CategoryTitleIcon>
                                <ReactSVG
                                    src={MagnifyingGlass}
                                />
                            </CategoryTitleIcon>
                            Search Results
                        </SearchTitleContainer>
                    )}
                <EmojiCatalogContainer
                    compact={compact}
                    isSearching={searchString.length > 0}
                    emojiSearchTitleHeight={EMOJI_SEARCH_TITLE_HEIGHT}
                    searchContainerHeight={SEARCH_CONTAINER_HEIGHT}
                >
                    {Array.from(emojiCategories.keys()).length > 0
                    && searchString.length === 0
                        ? (
                            <ReactList
                                ref={categoryListRef}
                                type="variable"
                                pageSize={4}
                                threshold={0}
                                itemRenderer={getListItem}
                                itemSizeGetter={getListItemSize}
                                length={Array.from(emojiCategories.keys()).length}
                            />
                        ) : (
                            <CategoryEmojiContainer>
                                <EmojiGrid>
                                    {searchResults.length > 0
                                        ? (
                                            searchResults
                                                .map((emojiObj: IEmoji) => (
                                                    <EmojiContainer
                                                        key={emoji.char}
                                                        className={HOVER_TARGET_CLASSNAME}
                                                        selected={
                                                            currentEmoji
                                                            === emojiObj.char
                                                        }
                                                        themeColor={color}
                                                        emojiIconLength={EMOJI_ICON_LENGTH}
                                                        onMouseEnter={onEmojiMouseEnter}
                                                        onMouseLeave={onEmojiMouseLeave}
                                                        onMouseDown={(e) => {
                                                            e.preventDefault();
                                                            handleEmojiSelect(emojiObj);
                                                        }}
                                                    >
                                                        <Emoji
                                                            char={emojiObj.char}
                                                        />
                                                    </EmojiContainer>
                                                ))
                                        ) : (
                                            <PlaceholderContainer>
                                                <SearchPlaceholderSubtitle>
                                                    No emojis found.
                                                </SearchPlaceholderSubtitle>
                                            </PlaceholderContainer>
                                        )}
                                </EmojiGrid>
                            </CategoryEmojiContainer>
                        )}
                </EmojiCatalogContainer>
            </EmojiBodyContainer>
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    compact: boolean,
}
const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: ${({ compact }) => (compact
        ? 'column'
        : 'row'
    )};
    height: 100%;
    width: 100%;
    background: inherit;
`;

interface EmojiBodyContainerProps {
    compact: boolean,
    responsiveLayout: boolean,
    emojiIconLength: number,
}
const EmojiBodyContainer = styled.div<EmojiBodyContainerProps>`
    display: flex;
    flex-direction: column;
    height: ${({ compact, emojiIconLength }) => (compact
        ? `calc(100% - ${emojiIconLength}px)`
        : '100%'
    )};
    flex: ${({ responsiveLayout, compact }) => (!responsiveLayout && !compact
        ? '0.65'
        : '0 1 auto'
    )};
    width: ${({ responsiveLayout, compact }) => {
        if (compact) {
            return '100%';
        }

        if (!responsiveLayout) {
            return 'auto';
        }

        return `calc(100% - ${2.5 * BODY_FONT_SIZE}px)`;
    }};
    background: inherit;
`;

interface EmojiCatalogContainerProps {
    compact: boolean,
    isSearching: boolean,
    searchContainerHeight: number,
    emojiSearchTitleHeight: number,
}
const EmojiCatalogContainer = styled.div<EmojiCatalogContainerProps>`
    position: relative;
    background: inherit;
    overflow-y: scroll;
    ${({
        compact,
        isSearching,
        searchContainerHeight,
        emojiSearchTitleHeight,
    }) => {
        if (compact && !isSearching) {
            return `height: calc(100% - ${searchContainerHeight}px);`;
        }

        if (compact && isSearching) {
            return `height: calc(100% - ${
                searchContainerHeight
            }px - ${
                emojiSearchTitleHeight
            }px);`;
        }

        if (!compact && !isSearching) {
            return `height: calc(100% - ${searchContainerHeight}px);`;
        }

        if (!compact && isSearching) {
            return `height: calc(100% - ${
                emojiSearchTitleHeight
            }px - ${
                searchContainerHeight
            }px);`;
        }
    }};

    & > div, & > div > div, & > div > div > div {
        background: inherit;
    }

    &::-webkit-scrollbar {
        width: 0;
    }
`;

interface CategoryListContainerProps {
    compact: boolean,
    responsiveLayout: boolean,
    emojiIconLength: number,
}
const CategoryListContainer = styled.ul<CategoryListContainerProps>`
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: ${({ theme }) => theme.color.white};
    overflow: hidden;
    ${({
        compact,
        responsiveLayout,
        theme,
        emojiIconLength,
    }) => (compact
        ? `
            display: flex;
            flex-direction: row;
            height: ${emojiIconLength}px;
            box-shadow: ${theme.color.boxShadow100};
        `
        : `
            flex: ${!responsiveLayout
            ? '0.35'
            : '0 1 auto'
        };
            width: ${!responsiveLayout
            ? 'auto'
            : `${2.5 * BODY_FONT_SIZE}px`
        };
            border-right: ${0.0625 * BODY_FONT_SIZE}px solid ${theme.color.neutral200};
            border-bottom-left-radius: ${1.25 * BODY_FONT_SIZE}px;

            ${theme.mediaQuery.extraSmall} {
                flex: 0 1 auto;
                width: ${2.5 * BODY_FONT_SIZE}px;
            }
            
        `
    )};
`;

interface CategoryListItemProps {
    compact: boolean,
    background: string,
    selected: boolean,
    hoverColor: string,
    selectedColor: string,
    emojiIconLength: number,
}
const CategoryListItem = styled.li<CategoryListItemProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: ${({ emojiIconLength }) => `${emojiIconLength}px`};
    background-color: ${({ background }) => background};

    transition: background-color 0.3s;
    cursor: none;
    ${({ compact, theme }) => !compact
        && `
            border-bottom: ${0.0625 * BODY_FONT_SIZE}px solid ${theme.color.neutral200};
        `};

    &:hover {
        background-color: ${({
        selected,
        selectedColor,
        hoverColor,
    }) => (!selected
        ? hoverColor
        : selectedColor
    )}}
`;

interface CategoryListItemIconProps {
    compact: boolean,
    emojiIconLength: number,
}
const CategoryListItemIcon = styled.div<CategoryListItemIconProps>`
    height: ${({ emojiIconLength }) => `${emojiIconLength}px`};
    width: ${({ emojiIconLength }) => `${emojiIconLength}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
    margin-left: ${({ compact }) => `${compact
        ? 0
        : 0.3125 * BODY_FONT_SIZE
    }px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        fill: ${({ color }) => color};
        width: ${({ emojiIconLength }) => `${emojiIconLength}px`};
        height: ${({ emojiIconLength }) => `${emojiIconLength}px`};
        transition: fill 0.3s;
    }
`;

interface CategoryListItemTextProps {
    emojiIconLength: number,
    responsiveLayout: boolean,
}
const CategoryListItemText = styled.div<CategoryListItemTextProps>`
    display: ${({ responsiveLayout }) => (!responsiveLayout
        ? 'flex'
        : 'none'
    )};
    flex-direction: row;
    align-items: center;
    width: ${({ emojiIconLength }) => `calc(100% -${emojiIconLength}px)`};
    height: ${({ emojiIconLength }) => `${emojiIconLength}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.9em;
    line-height: 0.9em;
    font-weight: 500;
    color: ${({ color }) => color};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: none;
    }
`;

const CategoryEmojiContainer = styled.div`
    width: 100%;
    background: inherit;
`;

interface CategoryTitleContainerProps {
    height: number,
}
const CategoryTitleContainer = styled.div<CategoryTitleContainerProps>`
    display: flex;
    align-items: center;
    height: ${({ height }) => `${height}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.9em;
    line-height: 1em;
    font-weight: 700;
    color: ${({ theme }) => theme.color.neutral700};
    padding: ${`0 ${0.9375 * BODY_FONT_SIZE}px`};
    padding-top: ${`${0.9375 * BODY_FONT_SIZE}px`};
    padding-left: ${`${0.3125 * BODY_FONT_SIZE}px`};

    &:first-of-type {
        padding-top: 0px;
    }
`;

const CategoryTitleIcon = styled.div`
    display: inline-block;
    height: ${`${1.25 * BODY_FONT_SIZE}px`};
    width: ${`${1.25 * BODY_FONT_SIZE}px`};
    margin-right: ${`${0.625 * BODY_FONT_SIZE}px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        fill: ${({ theme }) => theme.color.neutral600};
        width: 100%;
        height: 100%;
        transition: fill 0.3s;
    }
`;

const EmojiGrid = styled.div`
    margin: 0 auto;
    margin-top: ${`${0.3125 * BODY_FONT_SIZE}px`};
    background: inherit;
`;

interface EmojiContainerProps {
    selected: boolean,
    themeColor: string,
    emojiIconLength: number,
}
const EmojiContainer = styled.div<EmojiContainerProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${({ emojiIconLength }) => `${emojiIconLength}px`};
    height: ${({ emojiIconLength }) => `${emojiIconLength}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
    font-size: 1.2em;
    line-height: 1.2em;
    text-align: center;
    background-color: ${({ selected, themeColor }) => (selected
        ? themeColor
        : 'transparent'
    )};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    cursor: none;
    transition: background-color 0.3s;

    &:hover {
    background-color: ${({ selected, themeColor, theme }) => (selected
        ? themeColor
        : theme.color.neutral400
    )};
    }
`;

interface NoIconButtonContainerProps {
    responsiveLayout: boolean,
    hoverColor: string,
    emojiIconLength: number,
}
const NoIconButtonContainer = styled.li<NoIconButtonContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: ${({ responsiveLayout, emojiIconLength }) => `${!responsiveLayout
        ? 5.625 * BODY_FONT_SIZE
        : emojiIconLength
    }px`};
    height: ${`${1.25 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.625 * BODY_FONT_SIZE}px`};
    margin: ${`${0.3125 * BODY_FONT_SIZE}px auto`};
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => `${0.0625 * BODY_FONT_SIZE}px solid ${theme.color.neutral200}`};
    transition: background-color 0.3s;
    cursor: none;

    &:hover {
        background-color: ${({ hoverColor }) => hoverColor};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: ${({ emojiIconLength }) => `${emojiIconLength}px`};
    }
`;

const NoIconButtonIcon = styled.div`
    height: ${`${1.25 * BODY_FONT_SIZE}px`};
    width: ${`${1.25 * BODY_FONT_SIZE}px`};
    padding: ${`${0.15625 * BODY_FONT_SIZE}px`};
    margin-left: ${`${0.3125 * BODY_FONT_SIZE}px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: ${`${1.25 * BODY_FONT_SIZE}px`};
        height: ${`${1.25 * BODY_FONT_SIZE}px`};
        transition: fill 0.3s;
    }
`;

interface NoIconButtonTextProps {
    responsiveLayout: boolean,
}
const NoIconButtonText = styled.div<NoIconButtonTextProps>`
    position: relative;
    top: ${`${0.0625 * BODY_FONT_SIZE}px`};
    display: ${({ responsiveLayout }) => (!responsiveLayout
        ? 'flex'
        : 'none'
    )};
    flex-direction: row;
    align-items: center;
    width: ${`${3.75 * BODY_FONT_SIZE}px`};
    height: ${`${1.25 * BODY_FONT_SIZE}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    line-height: 0.8em;
    font-weight: 500;
    color: ${({ theme }) => theme.color.neutral600};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: none;
    }
`;

interface SearchContainerProps {
    height: number,
}
const SearchContainer = styled.div<SearchContainerProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${({ height }) => `${height}px`};
    padding: ${`${0.46875 * BODY_FONT_SIZE}px ${0.625 * BODY_FONT_SIZE}px`};
`;

interface SearchTitleContainerProps {
    height: number,
}
const SearchTitleContainer = styled(CategoryTitleContainer)<SearchTitleContainerProps>`
    height: ${({ height }) => `${height}px`};
    padding-top: 0px;
`;

const SearchIconContainer = styled.div`
    position: absolute;
    left: ${`${0.625 * BODY_FONT_SIZE}px`};
    border-radius: ${`${6.25 * BODY_FONT_SIZE}px`};
    width: ${`${2.1875 * BODY_FONT_SIZE}px`};
    height: ${`${1.875 * BODY_FONT_SIZE}px`};
    padding: ${`${0.4375 * BODY_FONT_SIZE}px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral600};
    }
`;

const SearchInput = styled.input`
    height: ${`${1.875 * BODY_FONT_SIZE}px`};
    width: 100%;
    padding: ${`0px ${1.25 * BODY_FONT_SIZE}px`};
    padding-left: ${`${2.1875 * BODY_FONT_SIZE}px`};
    border-radius: ${`${1.25 * BODY_FONT_SIZE}px`};
    border: none;
    outline: none;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.9em;
    color: ${({ theme }) => theme.color.neutral900};
    background-color: ${({ theme }) => theme.color.neutral100};
    cursor: none;

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral400};
        font-weight: 300;
    }
`;

const SearchPlaceholderSubtitle = styled(PlaceholderSubtitle)`
    margin-top: 0px;
`;

export default EmojiSelector;

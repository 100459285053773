// ===== Packages =====

import styled                   from 'styled-components';

// ===== Constants =====

import {
    CURSOR_Z_INDEX,
    BODY_FONT_SIZE,
}                               from '../../constants/generalConstants';
import FONT_TYPE                from '../../constants/fontType';

// ===== Services =====

import {
    fontColorDiscriminator,
}                               from '../../services';

export const TooltipParent = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${CURSOR_Z_INDEX + 1};
`;

interface TooltipContainerProps {
    active: boolean,
    large: boolean,
    background?: string,
}
export const TooltipContainer = styled.div<TooltipContainerProps>`
    position: absolute;
    visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
    opacity: ${({ active }) => (active ? 1 : 0)};
    width: ${({ large }) => (large ? `${9.375 * BODY_FONT_SIZE}px` : 'max-content')};
    padding: ${({ large }) => (large ? `${0.625 * BODY_FONT_SIZE}px` : `${0.375 * BODY_FONT_SIZE}px ${0.625 * BODY_FONT_SIZE}px`)};
    text-transform: none;
    min-width: ${`${5 * BODY_FONT_SIZE}px`};
    max-width: ${`${15.625 * BODY_FONT_SIZE}px`};
    background-color: ${({ background, theme }) => background || theme.verascopeColor.purple300};
    color: ${({ background, theme }) => (background
        ? fontColorDiscriminator(background)
        : theme.color.white
    )};
    text-align: center;
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    transition: opacity 200ms ease-in-out;
    box-sizing: border-box;
    z-index: 1000;
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
`;

export const BottomTooltip = styled(TooltipContainer)`
    top: ${`calc(100% + ${0.625 * BODY_FONT_SIZE}px)`};
    left: calc(50%);
    transform: translateX(-50%);
`;

export const TopTooltip = styled(TooltipContainer)`
    bottom: 125%;
    left: calc(50%);
    transform: translateX(-50%);

    & span {
        top: 100%;
        transform: translate(-50%, -50%) rotate(225deg);
    }
`;

export const LeftTooltip = styled(TooltipContainer)`
    top: calc(50%);
    transform: translateY(-50%);
    right: ${`calc(100% + ${0.9375 * BODY_FONT_SIZE}px)`};

    & span {
        right: ${`-${0.1875 * BODY_FONT_SIZE}px`};
        left: unset;
        top: 50%;
        transform: translateY(-50%) rotate(135deg);
    }
`;

export const RightBottomTooltip = styled(TooltipContainer)`
    top: 115%;
    left: calc(25%);

    & span {
        right: 0;
        top: 50%;
        transform: translateX(-50%) rotate(45deg);
    }
`;

export const LeftBottomTooltip = styled(TooltipContainer)`
    top: 115%;
    right: 25%;

    &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        right: 15%;
        border-width: ${`${0.3125 * BODY_FONT_SIZE}px`};
        border-style: solid;
        border-color: ${({ theme }) => `transparent transparent ${
        theme.verascopeColor.purple300
    } transparent;`}
    }
`;

export const RightTooltip = styled(TooltipContainer)`
    top: calc(50%);
    transform: translateY(-50%);
    left: ${`calc(100% + ${0.625 * BODY_FONT_SIZE}px)`};

    & span {
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;

export const TitleTooltip = styled(TooltipContainer)`
    left: ${`calc(100% + ${0.625 * BODY_FONT_SIZE}px)`};
    text-align: left;
    top: ${`${1.5 * BODY_FONT_SIZE}px`};
    padding:${`${0.5 * BODY_FONT_SIZE}px`};
    min-width: 0;
    max-width: 80%;
`;

interface CaretProps {
    background?: string,
}
export const Caret = styled.span<CaretProps>`
    clip: ${`rect(-${0.1875 * BODY_FONT_SIZE}px ${0.6875 * BODY_FONT_SIZE}px ${0.6875 * BODY_FONT_SIZE}px -${0.1875 * BODY_FONT_SIZE}px)`};
    top: ${`-${0.3125 * BODY_FONT_SIZE}px`};
    background: ${({ background, theme }) => background || theme.verascopeColor.purple300};
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);

    &::before {
        box-sizing: border-box;
        content: "";
        display: block;
        height: ${`${0.625 * BODY_FONT_SIZE}px`};
        transform: rotate(45deg);
        width: ${`${0.625 * BODY_FONT_SIZE}px`};
    }
`;

export const Text = styled.div`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: ${`${0.75 * BODY_FONT_SIZE}px`};
    position: relative;
    line-height: ${`${1.125 * BODY_FONT_SIZE}px`};
    white-space: pre-wrap;
`;

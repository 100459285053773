// ===== Packages =====

import {
    doc,
    getFirestore,
    runTransaction,
}                           from 'firebase/firestore';

interface Args {
    collection: string,
    id: string,
    userId?: string,
    title?: string,
    message?: string,
    read?: boolean,
    payload?: any,
}
const updateNotificationInDB = async ({
    collection,
    id,
    userId,
    title,
    message,
    read,
    payload,
}: Args): Promise<void> => {
    const db = getFirestore();
    const ref = doc(db, collection, id);
    try {
        return await runTransaction(db, async (transaction) => {
            if (userId) transaction.update(ref, { userId });
            if (title) transaction.update(ref, { title });
            if (message) transaction.update(ref, { message });
            if (read) transaction.update(ref, { read });
            if (payload) transaction.update(ref, { payload });
        });
    } catch (e) {
        throw Error('Encountered an error updating notification.');
    }
};

export default updateNotificationInDB;

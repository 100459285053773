// ===== Packages =====

import styled               from 'styled-components';

// ===== Services =====

import {
    setColorLightness,
}                           from '../../services';

// ===== Constants =====

import {
    CONTENTS_TABLE_Z_INDEX,
    CONTENTS_TABLE_TOGGLE_BUTTON_CONTAINER_X_OFFSET_SMALL_VIEWPORT,
    CONTENTS_TABLE_HOVER_LIGHTNESS_VALUE,
    READER_VIEW_OVERLAY_Z_INDEX,
    PROGRESS_BAR_LIGHTNESS_VALUE,
    BODY_FONT_SIZE,
}                           from '../../constants/generalConstants';
import FONT_TYPE            from '../../constants/fontType';

interface ContentsTableProps {
    widthExpanded: number,
    widthContracted: number,
    margin: number,
    heightExpanded: number,
    topExpanded: number,
    contentsTableVisible: boolean,
    showWarning: boolean,
    notifyUserToSignUpAfterAnnotationCreationAttempt: boolean,
    transitionDuration: number,
}
export const ContentsTable = styled.div<ContentsTableProps>`
    position: fixed;
    top: ${({ topExpanded }) => `${topExpanded}px`}; // Modified directly using ref in ReaderView
    height: ${({ heightExpanded, margin }) => `${(heightExpanded - 2 * margin)}px`}; // Modified directly using ref in ReaderView
    width: ${({
        contentsTableVisible,
        widthExpanded,
        widthContracted,
    }) => `${contentsTableVisible ? widthExpanded : widthContracted}px`};
    margin: ${({ margin }) => `${margin}px`};
    background: ${({ contentsTableVisible, showWarning, theme }) => {
        if (showWarning) {
            return theme.verascopeColor.red300;
        }

        if (contentsTableVisible) {
            return theme.color.offWhite;
        }

        return theme.color.neutral200; // Same as ContentsTableAccordionIcon
    }};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    box-shadow: ${({ contentsTableVisible, theme }) => (contentsTableVisible
        ? theme.color.boxShadow100
        : theme.color.boxShadow000
    )};
    overflow-y: scroll;
    z-index: ${({ notifyUserToSignUpAfterAnnotationCreationAttempt }) => (notifyUserToSignUpAfterAnnotationCreationAttempt
        ? READER_VIEW_OVERLAY_Z_INDEX - 1
        : CONTENTS_TABLE_Z_INDEX)};
    transition: ${({ theme, transitionDuration }) => `
        width ${transitionDuration}ms ${theme.motion.eagerEasing},
        background ${transitionDuration}ms ${theme.motion.eagerEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
`;

interface ContentsTableToggleButtonContainerProps {
    isVisible: boolean,
    tableVisible: boolean,
    leftExpanded: number,
    leftContracted: number,
    height: number,
    topExpanded: number,
    notifyUserToSignUpAfterAnnotationCreationAttempt: boolean,
    transitionDuration: number,
}
export const ContentsTableToggleButtonContainer = styled.div<ContentsTableToggleButtonContainerProps>`
    position: fixed;
    left: ${({
        tableVisible,
        leftExpanded,
        leftContracted,
    }) => `${tableVisible ? leftExpanded : leftContracted}px`};
    top: ${({ topExpanded }) => `${topExpanded}px`}; // Modified directly using ref in ReaderView
    background: ${({ theme }) => theme.color.white};
    border-radius: ${({ height }) => `${height / 2}px`};
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    z-index: ${({ notifyUserToSignUpAfterAnnotationCreationAttempt }) => (notifyUserToSignUpAfterAnnotationCreationAttempt
        ? READER_VIEW_OVERLAY_Z_INDEX - 1
        : CONTENTS_TABLE_Z_INDEX)};
    transition: ${({ theme, transitionDuration }) => `
        left ${transitionDuration}ms ${theme.motion.eagerEasing},
        top ${transitionDuration}ms ${theme.motion.eagerEasing},
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & svg {
        transform: ${({ tableVisible }) => `rotate(${tableVisible ? 180 : 0}deg)`};
        transition: ${({ theme, transitionDuration }) => `
            transform ${transitionDuration}ms ${theme.motion.eagerEasing}
        `};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        // top: Modified directly using ref in ReaderView
        left: ${({
        tableVisible,
        leftExpanded,
        leftContracted,
    }) => `${tableVisible
        ? leftExpanded + CONTENTS_TABLE_TOGGLE_BUTTON_CONTAINER_X_OFFSET_SMALL_VIEWPORT
        : leftContracted}px`};
    }
`;

export const ContentsTableProgressBarContainer = styled.div`
    position: absolute;
    top: 0; // Modified directly using ref in ReaderView
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

interface ContentsTableProgressBarProps {
    contentsTableVisible: boolean,
    transitionDuration: number,
}
export const ContentsTableProgressBar = styled.div<ContentsTableProgressBarProps>`
    position: absolute;
    top: 0;
    left: 0;
    height: 0%; // Modified directly using ref in ReaderView
    width: 100%;
    background: ${({ theme }) => setColorLightness(
        theme.verascopeColor.purple200,
        PROGRESS_BAR_LIGHTNESS_VALUE,
    )};
    opacity: ${({ contentsTableVisible }) => (contentsTableVisible ? 0.2 : 0.6)};
    transition: ${({ transitionDuration, theme }) => `
        opacitty ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
`;

interface ContentsTableTitleProps {
    fontMultiplier: number,
    paddingTop: number,
    lineHeight: number,
}
export const ContentsTableTitle = styled.h4<ContentsTableTitleProps>`
    margin: 0;
    padding: ${({ paddingTop }) => `${paddingTop}px ${0.9375 * BODY_FONT_SIZE}px`};
    padding-bottom: 0px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 500;
    line-height: ${({ lineHeight }) => `${lineHeight}em`};
    color: ${({ theme }) => theme.color.purple200};
    text-transform: uppercase;
`;

export const ContentsTableChaptersContainer = styled.div`
    background: inherit;
`;

export const ContentsTableOutlineContainer = styled.div`
    padding: ${`${0.625 * BODY_FONT_SIZE}px ${0.9375 * BODY_FONT_SIZE}px`};
    background: inherit;
`;

export const ContentsTableChapter = styled.div``;

interface ContentsTableChapterHeadProps {
    selected: boolean,
    hoverable: boolean,
    padding: number,
    transitionDuration: number,
}
export const ContentsTableChapterHead = styled.div<ContentsTableChapterHeadProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    border-left: ${({ selected, theme }) => (selected
        ? `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple400}`
        : `${0.1875 * BODY_FONT_SIZE}px solid transparent`
    )};
    background: transparent;
    padding: ${({ padding }) => `${padding}px`};
    transition: ${({ transitionDuration, theme }) => `
        background ${transitionDuration}ms ${theme.motion.standardEasing}
    `};

    ${({ hoverable, theme }) => hoverable && `
        &:hover {
            background: ${setColorLightness(
        theme.verascopeColor.purple200,
        CONTENTS_TABLE_HOVER_LIGHTNESS_VALUE,
    )};
        }
    `}
`;

export const ContentsTableChapterTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

interface ContentsTableChapterTitleProps {
    selected: boolean,
    fontMultiplier: number,
    lineHeight: number,
    transitionDuration: number,
}
export const ContentsTableChapterTitle = styled.div<ContentsTableChapterTitleProps>`
    margin: 0;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: ${({ selected }) => (selected
        ? 700
        : 700
    )};
    color: ${({ selected, theme }) => (selected
        ? theme.verascopeColor.purple400
        : theme.verascopeColor.purple100
    )};
    line-height: ${({ lineHeight }) => `${lineHeight}em`};
    transition: ${({ transitionDuration, theme }) => `
        color ${transitionDuration}ms ${theme.motion.standardEasing},
        font-weight ${transitionDuration}ms ${theme.motion.standardEasing}
    `};
`;

interface ContentsTableLockIconProps {
    selected: boolean,
}
export const ContentsTableLockIcon = styled.div<ContentsTableLockIconProps>`
    position: relative;
    top: ${`${0.05 * BODY_FONT_SIZE}px`};
    display: inline-block;
    vertical-align: text-top;
    width: ${`${1 * BODY_FONT_SIZE}px`};
    height: ${`${1 * BODY_FONT_SIZE}px`};
    margin-left: ${`${0.15 * BODY_FONT_SIZE}px`};

    & svg, & div {
        position: relative;
        height: 100%;
        width: 100%;
        margin: 0;
    }

    & path {
        fill: ${({ selected, theme }) => (selected
        ? theme.verascopeColor.purple400
        : theme.verascopeColor.gray400
    )};
    }
`;

interface ContentsTableChapterDescriptionProps {
    selected: boolean,
    fontMultiplier: number,
    lineHeight: number,
    transitionDuration: number,
}
export const ContentsTableChapterDescription = styled.p<ContentsTableChapterDescriptionProps>`
    margin: 0;
    margin-left: ${`${0.9375 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 400;
    color: ${({ selected, theme }) => (selected
        ? theme.verascopeColor.purple500
        : theme.verascopeColor.gray400
    )};
    line-height: ${({ lineHeight }) => `${lineHeight}em`};
    transition: ${({ transitionDuration, theme }) => `
        color ${transitionDuration}ms ${theme.motion.standardEasing}
    `};
`;

interface ContentsTableAccordionIconProps {
    selected: boolean,
    expanded: boolean,
    transitionDuration: number,
}
export const ContentsTableAccordionIcon = styled.div<ContentsTableAccordionIconProps>`
    width: ${`${1.25 * BODY_FONT_SIZE}px`};
    height: ${`${1.25 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.625 * BODY_FONT_SIZE}px`};
    background: ${({ theme }) => theme.color.offWhite}; // Same as ContentsTable
    transform: ${({ expanded }) => `rotate(${expanded ? 90 : 0}deg)`};
    transition: ${({ transitionDuration, theme }) => `
        transform ${transitionDuration}ms ${theme.motion.eagerEasing},
        fill ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & svg, & div {
        position: relative;
        height: 100%;
        width: 100%;
        margin: 0;
    }

    & path {
        fill: ${({ selected, theme }) => (selected
        ? theme.verascopeColor.purple400
        : theme.color.neutral700
    )};
    }

    &:hover {
        & path {
            fill: ${({ theme }) => theme.verascopeColor.purple400};
        }
    }
`;

export const ContentsTableChapterAnnotationContainer = styled.div`
    position: absolute;
    right: ${`${0.9375 * BODY_FONT_SIZE}px`};
    bottom: ${`${0.625 * BODY_FONT_SIZE}px`};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

interface ContentsTableChapterAnnotationIconProps {
    selected: boolean,
    transitionDuration: number,
}
export const ContentsTableChapterAnnotationIcon = styled.div<ContentsTableChapterAnnotationIconProps>`
    height: ${`${0.9375 * BODY_FONT_SIZE}px`};
    width: ${`${0.9375 * BODY_FONT_SIZE}px`};
    transition: ${({ transitionDuration, theme }) => `
        fill ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & svg, & div {
        position: relative;
        height: 100%;
        width: 100%;
        margin: 0;
    }

    & path {
        fill: ${({ selected, theme }) => (selected
        ? theme.verascopeColor.purple400
        : theme.verascopeColor.gray400
    )};
    }
`;

interface ContentsTableChapterAnnotationTextProps {
    selected: boolean,
    transitionDuration: number,
}
export const ContentsTableChapterAnnotationText = styled.span<ContentsTableChapterAnnotationTextProps>`
    margin-left: ${`${0.3125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    font-weight: ${({ selected }) => (selected
        ? 700
        : 500
    )};
    color: ${({ selected, theme }) => (selected
        ? theme.verascopeColor.purple400
        : theme.verascopeColor.gray200
    )};
    transition: ${({ transitionDuration, theme }) => `
        color ${transitionDuration}ms ${theme.motion.standardEasing}
    `};
`;

interface ContentsTableChapterBodyProps {
    visible: boolean,
    heightExpanded: number,
    transitionDuration: number,
    transitionDelayDuration: number,
}
export const ContentsTableChapterBody = styled.div<ContentsTableChapterBodyProps>`
    padding-left: ${`${1.875 * BODY_FONT_SIZE}px`};
    height: ${({ heightExpanded, visible }) => `${visible ? heightExpanded : 0}px`};
    transition: ${({
        visible,
        transitionDuration,
        transitionDelayDuration,
        theme,
    }) => `
        height ${transitionDuration}ms ${visible ? 0 : transitionDelayDuration}ms ${theme.motion.standardEasing}
    `};
`;

interface ContentsTableChapterSectionProps {
    selected: boolean,
    transitionDuration: number,
}
export const ContentsTableChapterSection = styled.div<ContentsTableChapterSectionProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: ${({ transitionDuration, theme }) => `
        background ${transitionDuration}ms ${theme.motion.standardEasing}
    `};

    &:hover {
        background: ${({ theme }) => setColorLightness(
        theme.verascopeColor.purple200,
        CONTENTS_TABLE_HOVER_LIGHTNESS_VALUE,
    )};
    }
`;

interface ContentsTableChapterSectionTextProps {
    selected: boolean,
    fontMultiplier: number,
    lineHeight: number,
    padding: number,
    transitionDuration: number,
}
export const ContentsTableChapterSectionText = styled.div<ContentsTableChapterSectionTextProps>`
    margin: 0;
    padding: ${({ padding }) => `${padding}px`};
    padding-left: ${`${0.625 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: ${({ selected }) => (selected
        ? 700
        : 400
    )};
    color: ${({ selected, theme }) => (selected
        ? theme.verascopeColor.purple400
        : theme.verascopeColor.purple100
    )};
    line-height: ${({ lineHeight }) => `${lineHeight}em`};
    border-left: ${({ selected, theme }) => (selected
        ? `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple400}`
        : `${0.1875 * BODY_FONT_SIZE}px solid transparent`
    )};
    transition: ${({ transitionDuration, theme }) => `
        color ${transitionDuration}ms ${theme.motion.standardEasing},
        font-weight ${transitionDuration}ms ${theme.motion.standardEasing}
    `};
`;

interface ContentsTableChapterSectionLineProps {
    visible: boolean,
    selected: boolean,
}
export const ContentsTableChapterSectionLine = styled.div<ContentsTableChapterSectionLineProps>`
    flex: 1;
    position: relative;
    top: ${`${0.125 * BODY_FONT_SIZE}px`};
    height: ${({ visible }) => `${visible ? 1 : 0}px`};
    margin: ${`0px ${0.3125 * BODY_FONT_SIZE}px`};
    background: ${({ selected, theme }) => (selected
        ? theme.verascopeColor.purple400
        : theme.color.cream200
    )};
`;

export const ContentsTableChapterSectionAnnotationContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: ${`${0.9375 * BODY_FONT_SIZE}px`};
`;

interface ContentsTableChapterSectionAnnotationIconProps {
    selected: boolean,
    transitionDuration: number,
}
export const ContentsTableChapterSectionAnnotationIcon = styled(ContentsTableChapterAnnotationIcon)<ContentsTableChapterSectionAnnotationIconProps>``;

interface ContentsTableChapterSectionAnnotationTextProps {
    selected: boolean,
    transitionDuration: number,
}
export const ContentsTableChapterSectionAnnotationText = styled(ContentsTableChapterAnnotationText)<ContentsTableChapterSectionAnnotationTextProps>``;

interface AddChapterButtonProps {
    transitionDuration: number,
}
export const AddChapterButton = styled.div<AddChapterButtonProps>`
    position: relative;
    width: ${`${1.875 * BODY_FONT_SIZE}px`};
    height: ${`${1.875 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.9375 * BODY_FONT_SIZE}px`};
    background-color: ${({ theme }) => theme.verascopeColor.orange200};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transition: ${({ theme, transitionDuration }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.standardEasing}
    `};
`;

export const AddChapterButtonIcon = styled.div`
    display: inline-block;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${`${1.375 * BODY_FONT_SIZE}px`};
    height: ${`${1.375 * BODY_FONT_SIZE}px`};
    transition: ${({ theme }) => `transform 300ms ${theme.motion.standardEasing}`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        fill: ${({ theme }) => theme.color.white};
        width: 100%;
        height: 100%;
    }

    ${AddChapterButton}:hover & {
        transform: translate(-50%, -50%) rotate(180deg);
    }
`;

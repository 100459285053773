// ===== Packages =====

import {
    Node,
    Editor,
    Transforms,
    Range,
    Point,
    Path,
    NodeEntry,
    BaseRange,
    Element,
    Text,
}                                   from 'slate';

// ===== Components =====

import {
    LineHelpers,
}                                   from './linesPlugin';

// ===== Elements =====

import _ZoomLevelElement      from '../elements/zoomLevelElement';

// ===== Interfaces =====

import {
    ZoomLevelNode,
}                                   from '../interfaces';

// ===== Enums =====

import {
    ZOOM_LEVEL,
    ZOOM_LEVEL_TYPE,
}                                   from '../elements/enums';

export const ZoomLevelElement = _ZoomLevelElement;

const getSelectionZoomLevels = (editor: Editor): NodeEntry<Node>[] => Array.from(Editor.nodes(editor, {
    match: ZoomLevelElement.isZoomLevelElement,
}));

const selectionHasHigherZoomLevels = (editor: Editor, level: ZOOM_LEVEL): boolean => {
    const selectionZoomLevelNodes = getSelectionZoomLevels(editor);
    if (!level) return false;
    const hasHigherZoomLevels = selectionZoomLevelNodes.reduce((total, [, zoomLevelPath]) => {
        const zoomLevel = Node.get(editor, zoomLevelPath) as ZoomLevelNode;
        if (zoomLevel.level > level) {
            return true && total;
        }

        return false;
    }, true);

    return hasHigherZoomLevels;
};

const hasSelectionZoomLevels = (editor: Editor): boolean => {
    const [match] = getSelectionZoomLevels(editor);
    return !!match;
};

const addZoomLevelToSelection = (editor: Editor, level: ZOOM_LEVEL): void => {
    const { selection } = editor;
    if (!selection) { return; }
    const lines = LineHelpers.getSelectionLines(editor);

    // Wrap nodes with new zoom level
    if (lines.length > 1) {
        const firstNode = Editor.first(editor, selection);
        const lastNode = Editor.last(editor, selection);
        const [, firstNodePath] = firstNode as NodeEntry;
        const [, lastNodePath] = lastNode as NodeEntry;
        const blockStartPoint = Editor.start(editor, firstNodePath);
        const blockEndPoint = Editor.end(editor, lastNodePath);
        const entireBlockRange = {
            anchor: blockStartPoint,
            focus: blockEndPoint,
        };
        return Transforms.wrapNodes(editor, ZoomLevelElement.newZoomLevelElement({
            level,
            levelType: ZOOM_LEVEL_TYPE.block,
        }), { at: entireBlockRange });
    }

    // Test to see if we've selected an entire paragraph
    const selectionLength = Math.abs(selection.focus.offset - selection.anchor.offset);
    const lineLength = (((lines[0] as NodeEntry)[0] as Element).children[0] as Text).text.length;
    const selectionTextLength = Editor.string(editor, selection).length;

    if (selectionLength === lineLength || selectionTextLength === lineLength) {
        const [line] = lines;
        const [, linePath] = line as NodeEntry;
        const blockStartPoint = Editor.start(editor, linePath);
        const blockEndPoint = Editor.end(editor, linePath);
        const entireBlockRange = {
            anchor: blockStartPoint,
            focus: blockEndPoint,
        };
        return Transforms.wrapNodes(editor, ZoomLevelElement.newZoomLevelElement({
            level,
            levelType: ZOOM_LEVEL_TYPE.block,
        }), { at: entireBlockRange });
    }

    // Transforms.wrapNodes will wrap the selection If no location is specified
    // Which is why we don't have to specify selection
    Transforms.wrapNodes(editor, ZoomLevelElement.newZoomLevelElement({
        level,
        levelType: ZOOM_LEVEL_TYPE.inline,
    }), { split: true });
};

const removeZoomLevelFromSelection = (editor: Editor): void => {
    const [zoomLevelEntry] = Array.from(Editor.nodes(editor, {
        match: ZoomLevelElement.isZoomLevelElement,
    }));
    if (zoomLevelEntry) {
        const [, zoomLevelPath] = zoomLevelEntry;
        return Transforms.unwrapNodes(editor, { at: zoomLevelPath });
    }
};

const toggleZoom = (editor: Editor, level: ZOOM_LEVEL): void => {
    if (hasSelectionZoomLevels(editor) && !selectionHasHigherZoomLevels(editor, level)) {
        const zoomLevelEntries = Array.from(Editor.nodes(editor, {
            match: ZoomLevelElement.isZoomLevelElement,
        }));

        if (zoomLevelEntries.length === 1) {
            const [[, zoomLevelPath]] = zoomLevelEntries;
            const zoomLevel = Node.get(editor, zoomLevelPath) as ZoomLevelNode;
            if (zoomLevel.level === level) {
                removeZoomLevelFromSelection(editor);
            }
        } else {
            addZoomLevelToSelection(editor, level);
        }
    } else {
        addZoomLevelToSelection(editor, level);
    }
};

export const ZoomLevelHelpers = {
    getSelectionZoomLevels,
    addZoomLevelToSelection,
    removeZoomLevelFromSelection,
    hasSelectionZoomLevels,
    toggleZoom,
    selectionHasHigherZoomLevels,
};

const normalizeZoomLevel = (
    editor: Editor,
    node: ZoomLevelNode,
    path: Path,
): void => {
    if (ZoomLevelElement.isBlockZoomLevel(node) && !Editor.isBlock(editor, node)) {
        return Transforms.unwrapNodes(editor, { at: path });
    }

    if (ZoomLevelElement.isInlineZoomLevel(node) && Editor.isBlock(editor, node)) {
        return Transforms.unwrapNodes(editor, { at: path });
    }

    const zoomIsValid = ZoomLevelElement.isValidZoomLevel(node.level);
    if (!zoomIsValid) { return Transforms.unwrapNodes(editor, { at: path }); }

    if (Node.string(node).length === 0) { return Transforms.unwrapNodes(editor, { at: path }); }
};

const handleCursorIsAtEndOfZoomLevelWhenBreakInserted = (
    editor: Editor,
    insertBreak: () => void,
): boolean | undefined => {
    const { selection } = editor;
    if (Range.isExpanded(selection as Range)) { return; }

    const [zoomLevelMatch] = Array.from(Editor.nodes(editor, {
        match: ZoomLevelElement.isZoomLevelElement,
    }));
    if (!zoomLevelMatch) { return; }

    const [, zoomLevelPath] = zoomLevelMatch;
    const [endZoomLeaf, endZoomLeafPath] = Editor.leaf(editor, zoomLevelPath, { edge: 'end' });
    const endOfZoomPoint = {
        path: endZoomLeafPath,
        offset: Node.string(endZoomLeaf).length,
    };
    const cursorIsAtEndOfZoom = Point.equals((selection as BaseRange).anchor, endOfZoomPoint);

    if (cursorIsAtEndOfZoom) {
        insertBreak();
        Transforms.move(editor);
        return true;
    }

    return false;
};

export const withZoomLevels = (editor: Editor, _readOnly?: boolean): Editor => {
    const {
        normalizeNode, isInline, isVoid, insertBreak,
    } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path) as ZoomLevelNode;

        if (ZoomLevelElement.isZoomLevelElement(node)) {
            return normalizeZoomLevel(editor, node, path);
        }

        return normalizeNode(entry);
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => (ZoomLevelElement.isZoomLevelElement(element)
        ? ZoomLevelElement.isInlineZoomLevel(element)
        : isInline(element));

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => (ZoomLevelElement.isZoomLevelElement(node) ? false : isVoid(node));

    // eslint-disable-next-line no-param-reassign
    editor.insertBreak = () => {
        if (handleCursorIsAtEndOfZoomLevelWhenBreakInserted(editor, insertBreak)) {
            return;
        }

        return insertBreak();
    };

    return editor;
};

// ===== Packages =====

import {
    Node,
    Element,
    Editor,
    Range,
    Path,
    Transforms,
    NodeEntry,
}                       from 'slate';

// ===== Elements =====

import _DividerElement  from '../elements/dividerElement';

// ===== Interfaces =====

import {
    EditorNode,
}                       from '../interfaces';

export const DividerElement = _DividerElement;

const insertDivider = (editor: Editor): void => {
    const { selection } = editor;
    if (Range.isCollapsed(selection as Range)) {
        const cursorPath = Range.start(editor.selection as Range).path;
        const [_, currentEditorChildPath] = Editor.parent(editor, cursorPath, { depth: 2 });
        const nextEditorChild = Path.next(currentEditorChildPath);
        Transforms.insertNodes(editor, DividerElement.newDividerElement(), { at: nextEditorChild });
    }
};

const getSelectionDividers = (editor: Editor): NodeEntry<Node>[] => Array.from(Editor.nodes(editor, { match: DividerElement.isDividerElement }));

const hasSelectedDivider = (editor: Editor): boolean => {
    const [match] = Array.from(Editor.nodes(editor, { match: DividerElement.isDividerElement }));
    return !!match;
};

const toggleDivider = (editor: Editor): void => {
    if (hasSelectedDivider(editor)) {
        removeDivider(editor);
    } else {
        insertDivider(editor);
    }
};

const removeDivider = (editor: Editor): void => {
    const cursorIsInsideDivider = Range.isCollapsed(editor.selection as Range) && hasSelectedDivider(editor);
    if (cursorIsInsideDivider) {
        Transforms.removeNodes(editor);
    }
};

export const DividerHelpers = {
    getSelectionDividers,
    insertDivider,
    hasSelectedDivider,
    removeDivider,
    toggleDivider,
};

export const withDividers = (editor: Editor, _readOnly?: boolean): Editor => {
    const {
        normalizeNode, isVoid, isInline,
    } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [_, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path) as EditorNode;

        const isDividerElement = Element.isElement(node) && node.type === DividerElement.TYPE;
        if (!isDividerElement) { return normalizeNode(entry); }

        const [parent] = Editor.parent(editor, path);
        const dividerIsImmediateChildOfEditor = Editor.isEditor(parent);
        if (!dividerIsImmediateChildOfEditor) { return Transforms.removeNodes(editor, { at: path, voids: true }); }
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => (DividerElement.isDividerElement(element) ? false : isInline(element));

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => (DividerElement.isDividerElement(node) ? true : isVoid(node));

    return editor;
};

// ===== Packages =====

import React, {
    useState,
    useMemo,
    useCallback,
    useRef,
    useEffect,
}                               from 'react';
import {
    css,
    FlattenSimpleInterpolation,
    Keyframes,
}                               from 'styled-components';

// ===== Hooks =====

import {
    useTimeout,
}                               from '../../hooks';

// ===== Services =====

import {
    playAudio,
    detectTouchDevice,
    getMoveIndexLeftKeyframe,
    getMoveIndexRightKeyframe,
}                               from '../../services';

// ===== Interfacs =====

import {
    IToggleButtonItem,
}                               from '../../interfaces';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    KEYFRAME_ANCHOR_TYPE,
}                               from '../../enums';

// ===== Sounds =====

import Toggle                   from '../../sounds/snap.mp3';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
    DEFAULT_AUDIO_VOLUME,
    MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION,
    MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION,
}                               from '../../constants/generalConstants';
import CURSOR_SIGN              from '../../constants/cursorSigns';

// ===== Styles =====

import {
    Container,
    OptionButton,
    OptionButtonText,
}                               from './styles';
import {
    MovingButtonBackground,
}                               from '../../styles';
import { theme }                from '../../themes/theme-context';

ToggleButton.defaultProps = {
    disabled: false,
    background: undefined,
    smallButtonWidth: undefined,
    smallButtonHeight: undefined,
};
interface Props {
    disabled?: boolean,
    background?: string,
    hasSound: boolean,
    selectedIndex: number,
    items: IToggleButtonItem[],
    buttonWidth: number,
    smallButtonWidth?: number,
    buttonHeight: number,
    smallButtonHeight?: number,
    buttonMargin: number,
    transitionDuration: number,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function ToggleButton({
    disabled,
    background,
    hasSound,
    selectedIndex,
    items,
    buttonWidth,
    smallButtonWidth,
    buttonHeight,
    smallButtonHeight,
    buttonMargin,
    transitionDuration,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== General Constants =====

    const MOVING_BUTTON_INITIAL_INDEX = selectedIndex + 1;

    // ----- Sound Clips
    const toggleClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    // stores desired state of pivot button background
    const [movingButtonBackground, setMovingButtonBackground] = useState<{ active: boolean, desiredIndex: number}>({
        active: false,
        desiredIndex: 0,
    });
    // stores the current index of the pivot button background
    const [movingButtonCurrentIndex, setMovingButtonCurrentIndex] = useState<number>(MOVING_BUTTON_INITIAL_INDEX);
    // stores the next index of the movingButtonCurrentIndex to be set following a timeout
    const [movingButtonNextIndex, setMovingButtonNextIndex] = useState<number>(MOVING_BUTTON_INITIAL_INDEX + 1);

    // ===== Methods =====

    const onButtonMouseEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.toggleOptionButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onButtonMouseLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const handleOptionSelect = (
        index: number,
        e: React.MouseEvent | React.TouchEvent,
    ): void => {
        // execute option callback
        items[index].handleClick(index, e);

        e.stopPropagation();
        // set next moving button index used
        // in the timeout
        setMovingButtonNextIndex(index + 1);
        // clear any revious delay
        clearDelayChangeMovingButtonIndex();
        // start new delay
        delayChangeMovingButtonIndex();
        // start moving the button by setting desired index
        setMovingButtonBackground({
            active: true,
            desiredIndex: index + 1,
        });

        // Play Sound
        if (hasSound && toggleClip.current) {
            toggleClip.current.pause();
            toggleClip.current.currentTime = 0;
            playAudio(toggleClip.current);
        }
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            toggleClip.current
        ) {
            // Toggle
            toggleClip.current.volume = DEFAULT_AUDIO_VOLUME;
            toggleClip.current.src = Toggle;
        }

        return function cleanup() {
            if (toggleClip.current) toggleClip.current.remove();
        };
    }, []);

    const {
        start: delayChangeMovingButtonIndex,
        clear: clearDelayChangeMovingButtonIndex,
    } = useTimeout(
        useCallback(() => {
            setMovingButtonBackground({
                active: true,
                desiredIndex: movingButtonNextIndex,
            });
            // Set the new index
            setMovingButtonCurrentIndex(movingButtonNextIndex);
        }, [
            movingButtonNextIndex,
        ]),
        Math.min(
            Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex) * MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION,
            MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION,
        ),
    );

    // ===== Memoization =====

    const movingButtonIndex = useMemo(() => movingButtonCurrentIndex - 1, [movingButtonCurrentIndex]);

    // ===== Rendering =====

    const buttonAnimations = useMemo(() => {
        let animations: Map<string, Keyframes> = new Map();
        for (let i = 1; i < items.length; i += 1) {
            const moveIndexLeft = getMoveIndexLeftKeyframe(
                buttonWidth,
                i,
                buttonMargin,
                KEYFRAME_ANCHOR_TYPE.left,
            );
            const moveIndexRight = getMoveIndexRightKeyframe(
                buttonWidth,
                i,
                buttonMargin,
                KEYFRAME_ANCHOR_TYPE.left,
            );
            animations = animations.set(
                `move${i}Right`,
                moveIndexRight,
            );
            animations = animations.set(
                `move${i}Left`,
                moveIndexLeft,
            );
        }

        return animations;
    }, [
        selectedIndex,
    ]);

    let animation: FlattenSimpleInterpolation | null = null;
    if (movingButtonBackground.active) {
        const keyframe = buttonAnimations.get(
            `move${
                Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex)
            }${
                movingButtonBackground.desiredIndex > movingButtonCurrentIndex
                    ? 'Right'
                    : 'Left'
            }`,
        );

        // Error: https://stackoverflow.com/questions/67601508/why-do-my-styled-component-keyframes-error-with-ts-styled-plugin9999-in-react
        // eslint-disable-next-line max-len
        animation = css`${keyframe} ${Math.min(Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex) * MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION, MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION)}ms${` ${theme.motion.delayEasing}`}`;
    }

    return (
        <Container
            buttonCount={items.length}
            buttonMargin={buttonMargin}
            buttonWidth={buttonWidth}
            buttonHeight={buttonHeight}
            background={background}
        >
            <MovingButtonBackground
                solid
                visible
                disabled={disabled}
                color={theme.verascopeColor.purple400}
                top={buttonMargin}
                buttonHeight={buttonHeight}
                buttonWidth={buttonWidth}
                animation={animation}
                spacing={buttonMargin}
                xOffset={buttonMargin / 2}
                index={movingButtonIndex}
                transitionDuration={transitionDuration}
            />
            {items.map((item, index) => (
                <OptionButton
                    key={item.text}
                    className={HOVER_TARGET_CLASSNAME}
                    disabled={disabled || false}
                    selected={selectedIndex === index}
                    hoverDuration={transitionDuration}
                    index={index}
                    buttonMargin={buttonMargin}
                    buttonWidth={buttonWidth}
                    buttonHeight={buttonHeight}
                    smallButtonWidth={smallButtonWidth || buttonWidth}
                    smallButtonHeight={smallButtonHeight || buttonHeight}
                    {...(!disabled
                        ? {
                            onMouseEnter: onButtonMouseEnter,
                            onMouseLeave: onButtonMouseLeave,
                        } : {})}
                    {...(detectTouchDevice(document) ? {
                        onTouchStart: (e: React.TouchEvent) => handleOptionSelect(index, e),
                    } : {
                        onMouseDown: (e: React.MouseEvent) => handleOptionSelect(index, e),
                    })}
                >
                    <OptionButtonText
                        transitionDuration={transitionDuration}
                    >
                        {item.text}
                    </OptionButtonText>
                </OptionButton>
            ))}
        </Container>
    );
}

export default ToggleButton;

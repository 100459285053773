// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import {
    CART_BUTTON_HEIGHT_MULTIPLIER,
    CART_ICON_CONTAINER_PADDING_MULTIPLIER,
    CART_ICON_MARGIN_MULTIPLIER,
    USER_PROFILE_AVATAR_LENGTH_MULTIPLIER,
    BODY_FONT_SIZE,
    NOTIFICATION_DIALOG_Z_INDEX,
    NAVBAR_MARGIN_TOP_MULTIPLIER,
    USER_PROFILE_DIALOG_RIGHT_CONTRACTED_MULTIPLIER,
    NOTIFICATION_DIALOG_MARGIN_MULTIPLIER,
    USER_PROFILE_DIALOG_Z_INDEX,
}                                   from '../../constants/generalConstants';
import FONT_TYPE                    from '../../constants/fontType';

const DIALOG_BODY_CONTAINER_PADDING_MULTIPLIER = 0.6; // em
const DIALOG_BODY_CONTAINER_BORDER_RADIUS_MULTIPLIER = 0.3125; // em
const NO_NOTIFICATION_CONTAINER_MARGIN_MULTIPLIER = 0.625; // em
const NO_NOTIFICATIONS_ICON_LENGTH_MULTIPLIER = 1.5; // em
const NOTIFICATION_BADGE_TOP_MULTIPLIER = -0.15; // em
const NOTIFICATION_BADGE_RIGHT_MULTIPLIER = 0.3125; // em
const NOTIFICATION_BADGE_PADDING_REGULAR_MULTIPLIER = 0.25; // em
const NOTIFICATION_BADGE_PADDING_RIGHT_MULTIPLIER = 0.15625; // em
const NOTIFICATION_BADGE_PADDING_BOTTOM_MULTIPLIER = 0.15625; // em

interface ContainerProps {
    isExpanded: boolean,
    isRecognizedUser: boolean,
    lightBackground: boolean,
    userProfileDialogExpanded: boolean,
    transitionDuration: number,
}
export const Container = styled.div<ContainerProps>`
    position: fixed;
    top: ${`${NAVBAR_MARGIN_TOP_MULTIPLIER + 0.1}em`};
    right: ${({ isRecognizedUser }) => `${isRecognizedUser
        ? `${USER_PROFILE_DIALOG_RIGHT_CONTRACTED_MULTIPLIER + USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + CART_BUTTON_HEIGHT_MULTIPLIER + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER + CART_ICON_MARGIN_MULTIPLIER}em`
        : `${0}px`}`};
    background: ${({ lightBackground, theme }) => (lightBackground ? theme.color.white : 'none')};
    border-radius: ${`${1.25 * BODY_FONT_SIZE}px`};
    z-index: ${NOTIFICATION_DIALOG_Z_INDEX};
    transition: ${({ transitionDuration, theme }) => `
        right ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        z-index: ${({ userProfileDialogExpanded }) => {
        if (userProfileDialogExpanded) {
            return USER_PROFILE_DIALOG_Z_INDEX - 1;
        }

        return NOTIFICATION_DIALOG_Z_INDEX;
    }};
    }   
`;

interface BellIconContainerProps {
    height: number,
    detectTouchDevice: boolean,
    lightBackground?: boolean,
}
export const BellIconContainer = styled.div<BellIconContainerProps>`
    position: relative;
    right: 0px;
    width: ${({ height }) => `${height + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER * BODY_FONT_SIZE}px`};
    height: ${({ height }) => `${height + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER * BODY_FONT_SIZE}px`};
    border-radius: ${({ height }) => `${(height + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER * BODY_FONT_SIZE) / 2}px`};
    padding: ${`${CART_ICON_CONTAINER_PADDING_MULTIPLIER * BODY_FONT_SIZE}px`};
    margin: ${`0px ${NOTIFICATION_DIALOG_MARGIN_MULTIPLIER * BODY_FONT_SIZE}px`};
    background: ${({ lightBackground, theme }) => (lightBackground
        ? theme.color.white
        : 'transparent'
    )};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    
    & svg {
        width: 100%;
        height: 100%;
        overflow: visible;
    }

    & path, & rect {
        fill: ${({ theme, lightBackground }) => (lightBackground
        ? theme.verascopeColor.purple200
        : theme.color.white
    )};
        transform: scale(1);
        transform-origin: 50% 50% 0;
    }

    &:hover path, &:hover rect {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            fill: ${theme.verascopeColor.yellow200};
            transform: scale(1.5);
        `)}
    }
`;

interface DialogBodyContainerProps {
    expandedTop: number,
    contractedTop: number,
    borderRadius: number,
    width: number,
    isExpanded: boolean,
    isRecognizedUser: boolean,
    transitionDuration: number,
}
export const DialogBodyContainer = styled.div<DialogBodyContainerProps>`
    position: absolute;
    top: ${({ isExpanded, expandedTop, contractedTop }) => `${isExpanded ? expandedTop : contractedTop}px`};
    right: 0px;
    background: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    border-top-right-radius: 0px;
    width: ${({ width }) => `${width}px`};
    padding: ${`${DIALOG_BODY_CONTAINER_PADDING_MULTIPLIER * BODY_FONT_SIZE}px`};
    opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
    pointer-events: ${({ isExpanded }) => (isExpanded ? 'auto' : 'none')};
    transition: ${({ transitionDuration, theme }) => `
        top ${transitionDuration}ms ${theme.motion.eagerEasing},
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        right: ${({ isRecognizedUser }) => `${isRecognizedUser
        ? `-${USER_PROFILE_DIALOG_RIGHT_CONTRACTED_MULTIPLIER + USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + CART_BUTTON_HEIGHT_MULTIPLIER + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER + CART_ICON_MARGIN_MULTIPLIER}em`
        : `${0}px`}`};
        width: 100vw;
        border-top-right-radius: ${`${DIALOG_BODY_CONTAINER_BORDER_RADIUS_MULTIPLIER * BODY_FONT_SIZE}px`};
        border-top-left-radius: ${`${DIALOG_BODY_CONTAINER_BORDER_RADIUS_MULTIPLIER * BODY_FONT_SIZE}px`};
    }   
`;

interface DialogBodyProps {
    isExpanded: boolean,
    opacityDuration: number,
    transitionDelayDuration: number,
}
export const DialogBody = styled.div<DialogBodyProps>`
    opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
    transition: ${({
        isExpanded,
        opacityDuration,
        transitionDelayDuration,
        theme,
    }) => `
        opacity ${opacityDuration}ms ${isExpanded ? transitionDelayDuration : 0}ms ${theme.motion.standardEasing}
    `};
`;

interface NoNotificationsContainerProps {
    height?: number,
}
export const NoNotificationsContainer = styled.div<NoNotificationsContainerProps>`
    height: ${({ height }) => `${`${height}px` || 'auto'}`};
    margin: ${`${NO_NOTIFICATION_CONTAINER_MARGIN_MULTIPLIER * BODY_FONT_SIZE}px 0px`};
`;

export const NoNotificationsIcon = styled.div`
    height: ${`${NO_NOTIFICATIONS_ICON_LENGTH_MULTIPLIER * BODY_FONT_SIZE}px`};
    width: ${`${NO_NOTIFICATIONS_ICON_LENGTH_MULTIPLIER * BODY_FONT_SIZE}px`};
    margin: 0 auto;

    & > div {
        height: 100%;
        width: 100%;
    }

    & svg {
        fill: ${({ theme }) => theme.color.neutral600};
    }
`;

export const NoNotificationsText = styled.p`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.8em;
    line-height: 1.5em;
    text-align: center;
    color: ${({ theme }) => theme.color.neutral700};
    max-width: 30ch;
    margin: 0 auto;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: auto;
        max-width: 40ch;
    }
`;

export const SpinnerContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

interface NotificationBadgeProps {
    count: number,
    transitionDuration: number,
}
export const NotificationBadge = styled.div<NotificationBadgeProps>`
    position: absolute;
    top: ${`${NOTIFICATION_BADGE_TOP_MULTIPLIER * BODY_FONT_SIZE}px`};
    right: ${`${NOTIFICATION_BADGE_RIGHT_MULTIPLIER * BODY_FONT_SIZE}px`};
    padding: ${`${NOTIFICATION_BADGE_PADDING_REGULAR_MULTIPLIER * BODY_FONT_SIZE}px`};
    padding-right: ${`${NOTIFICATION_BADGE_PADDING_RIGHT_MULTIPLIER * BODY_FONT_SIZE}px`};
    padding-bottom: ${`${NOTIFICATION_BADGE_PADDING_BOTTOM_MULTIPLIER * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: 0.7em;
    color: ${({ theme }) => theme.verascopeColor.white};
    background: ${({ theme }) => theme.verascopeColor.red200};
    --cartItemCounter: ${({ count }) => count};
    counter-reset: cartItemCounter var(--cartItemCounter);
    pointer-events: none;
    transition: ${({ transitionDuration }) => `--cartItemCounter ${transitionDuration}ms`};

    &::after {
        content: counter(cartItemCounter);
    }
`;

// ===== Packages =====

import styled                           from 'styled-components';

// ===== Services =====

import {
    hexToRgb,
}                                       from '../../services';

// ===== Enums =====

import {
    SOCIAL_EMERGENCE_CONNECTION_TYPE,
}                                       from '../../enums';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
}                                       from '../../constants/generalConstants';
import FONT_TYPE                        from '../../constants/fontType';

const HEADER_HEIGHT = 5.625 * BODY_FONT_SIZE;
const VIEWPORT_OFFSET = 0.625 * BODY_FONT_SIZE;
const ADJACENCY_MATRIX_CELL_MARGIN = 0.15625 * BODY_FONT_SIZE;
const ADJACENCT_MATRIX_PADDING = ADJACENCY_MATRIX_CELL_MARGIN;
const AGENT_PROPERTY_CONTAINER_PADDING = ADJACENCT_MATRIX_PADDING;
const AGENT_PROPERTIES_CONTAINER_PADDING = AGENT_PROPERTY_CONTAINER_PADDING;
const AGENT_NAMES_CONTAINER_MARGIN_RIGHT = ADJACENCY_MATRIX_CELL_MARGIN;
const AGENT_NAMES_CONTAINER_PADDING = ADJACENCY_MATRIX_CELL_MARGIN;
const AGENT_NAME_WIDTH = 8.4375 * BODY_FONT_SIZE;
const AGENT_NAME_INPUT_HEIGHT = 2.1875 * BODY_FONT_SIZE;
const SETTINGS_CONTAINER_BORDER_RADIUS = 1.25 * BODY_FONT_SIZE;
const SETTINGS_TOOLTIP_WIDTH = 10 * BODY_FONT_SIZE;
const SETTINGS_TOOLTIP_HEIGHT = 1.875 * BODY_FONT_SIZE;
const SETTINGS_TOOLTIP_OFFSET = 0.3125 * BODY_FONT_SIZE;
const SETTINGS_TOOLTIP_FADE_IN_OFFSET = 0.9375 * BODY_FONT_SIZE;
const SETTINGS_CONTAINER_MARGIN = 0.3125 * BODY_FONT_SIZE;
const SETTINGS_PADDING_BOTTOM_LARGE_SCREEN = 2.5 * BODY_FONT_SIZE;
const SETTINGS_PADDING_BOTTOM_SMALL_SCREEN = 3.75 * BODY_FONT_SIZE;
const SETTINGS_PADDING_SIDE = 0.3125 * BODY_FONT_SIZE;

export const Container = styled.div`
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ theme }) => theme.color.offWhite};
`;

export const HeaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
`;

export const BodyContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    padding-top: ${`${HEADER_HEIGHT}px`};
`;

export const DialogContentsContainer = styled.div``;

interface DialogCloseButtonProps {
    transitionDuration: number,
}
export const DialogCloseButton = styled.button<DialogCloseButtonProps>`
    position: absolute;
    top: ${`${0.625 * BODY_FONT_SIZE}px`};
    right: ${`${0.625 * BODY_FONT_SIZE}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${`${1.875 * BODY_FONT_SIZE}px`};
    height: ${`${1.875 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.9375 * BODY_FONT_SIZE}px`};
    background: transparent;
    transform: scale(1);
    padding: ${`${0.15625 * BODY_FONT_SIZE}px`};
    border: none;
    cursor: none;
    transition: ${({
        transitionDuration,
        theme,
    }) => `
        transform ${transitionDuration}ms ${theme.motion.overshoot},
        background ${transitionDuration}ms ${theme.motion.overshoot}
    `};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.white};
    }

    &:hover {
        transform: scale(1.1);
        background: ${({ theme }) => theme.verascopeColor.purple400};
    }
`;

export const DialogHeader = styled.h2`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 1.5em;
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    padding: ${`${0.625 * BODY_FONT_SIZE}px`};
    padding-top: 0px;
    margin: 0;
    margin-bottom: ${`${0.625 * BODY_FONT_SIZE}px`};
`;

export const DialogBody = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DialogAgentBodyItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
`;

export const DialogAgentBodyItemProperty = styled.h3`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 0.9em;
    color: ${({ theme }) => theme.verascopeColor.purple500};
    margin: 0;
`;

export const DialogAgentBodyItemValue = styled.p`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.9em;
    color: ${({ theme }) => theme.color.white};
    margin: 0;
`;

interface SettingsTooltipProps {
    settingsExpanded: boolean,
    settingsExpanding: boolean,
    settingsContracting: boolean,
    transitionDuration: number,
}
export const SettingsTooltip = styled.div<SettingsTooltipProps>`
    position: absolute;
    top: ${({
        settingsExpanded,
    }) => {
        if (settingsExpanded) {
            return `-${SETTINGS_TOOLTIP_HEIGHT + SETTINGS_TOOLTIP_OFFSET}px`;
        }

        return '50%';
    }};
    right: ${({
        settingsExpanded,
    }) => {
        if (settingsExpanded) {
            return '50%';
        }

        return `-${SETTINGS_TOOLTIP_WIDTH + SETTINGS_TOOLTIP_OFFSET - SETTINGS_TOOLTIP_FADE_IN_OFFSET}px`;
    }};
    transform: ${({ settingsExpanded }) => {
        if (settingsExpanded) {
            return 'translateX(50%)';
        }

        return 'translateY(-50%)';
    }};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.verascopeColor.purple300};
    width: ${`${SETTINGS_TOOLTIP_WIDTH}px`};
    height: ${`${SETTINGS_TOOLTIP_HEIGHT}px`};
    border-radius: ${`${SETTINGS_TOOLTIP_HEIGHT / 2}px`};
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    opacity: ${({
        settingsExpanded,
        settingsExpanding,
        settingsContracting,
    }) => {
        if (settingsExpanded && !(settingsExpanding || settingsContracting)) {
            return '1 !important'; // This will override behavior triggered by parent
        }

        return '0';
    }};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.white};
    pointer-events: none;
    transition: ${({
        theme,
        transitionDuration,
    }) => `
        opacity ${transitionDuration}ms ${theme.motion.standardEasing},
        top ${transitionDuration}ms ${theme.motion.standardEasing},
        right ${transitionDuration}ms ${theme.motion.standardEasing}
    `};
`;

interface SettingsContainerProps {
    expanded: boolean,
    settingsExpanding: boolean,
    settingsContracting: boolean,
    contractedLength: number,
    cellLength: number,
    agentPropertySocialCapitalLength: number,
    agentPropertyRankLength: number,
    agentCount: number,
    tallestAgentNameLabelHeight: number,
    maxCellScrollThreshold: number,
    zIndex: number,
    transitionDuration: number,
    expandDuration: number,
    exitDelayDuration: number,
}
export const SettingsContainer = styled.div<SettingsContainerProps>`
    position: absolute;
    bottom: ${`${VIEWPORT_OFFSET}px`};
    left: ${`${VIEWPORT_OFFSET}px`};
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: ${({
        expanded,
        agentCount,
        cellLength,
        contractedLength,
        agentPropertySocialCapitalLength,
        agentPropertyRankLength,
    }) => (expanded
        ? `${
            2 * SETTINGS_PADDING_SIDE
            + AGENT_NAME_WIDTH
            + AGENT_NAMES_CONTAINER_MARGIN_RIGHT
            + AGENT_NAMES_CONTAINER_PADDING
            + 2 * ADJACENCT_MATRIX_PADDING
            + agentCount * cellLength
            + agentPropertySocialCapitalLength
            + agentPropertyRankLength
        }px`
        : `${contractedLength}px`
    )};
    height: ${({
        expanded,
        agentCount,
        cellLength,
        contractedLength,
        tallestAgentNameLabelHeight,
    }) => (expanded
        ? `${SETTINGS_PADDING_BOTTOM_LARGE_SCREEN
            + SETTINGS_CONTAINER_MARGIN
            + cellLength * agentCount
            + tallestAgentNameLabelHeight}px`
        : `${contractedLength}px`
    )};
    max-width: ${({
        cellLength,
        maxCellScrollThreshold,
        agentPropertySocialCapitalLength,
        agentPropertyRankLength,
    }) => `${
        2 * SETTINGS_PADDING_SIDE
        + AGENT_NAME_WIDTH
        + AGENT_NAMES_CONTAINER_MARGIN_RIGHT
        + AGENT_NAMES_CONTAINER_PADDING
        + 2 * ADJACENCT_MATRIX_PADDING
        + maxCellScrollThreshold * cellLength
        + agentPropertySocialCapitalLength
        + agentPropertyRankLength
    }px`};
    max-height: ${({ cellLength, maxCellScrollThreshold }) => `${SETTINGS_PADDING_BOTTOM_LARGE_SCREEN + cellLength * maxCellScrollThreshold}px`};
    padding: ${`0px ${SETTINGS_PADDING_SIDE}px ${SETTINGS_PADDING_BOTTOM_LARGE_SCREEN}px ${SETTINGS_PADDING_SIDE}px`};
    border-radius: ${`${SETTINGS_CONTAINER_BORDER_RADIUS}px`};
    border-bottom-left-radius: ${`${1.25 * BODY_FONT_SIZE}px`};
    background: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    z-index: ${({ zIndex }) => zIndex};
    transition: ${({
        theme,
        expanded,
        settingsExpanding,
        settingsContracting,
        expandDuration,
        exitDelayDuration,
        transitionDuration,
    }) => `bottom ${transitionDuration}ms ${theme.motion.eagerEasing},
        left ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-radius ${transitionDuration}ms ${theme.motion.eagerEasing},
        background ${transitionDuration}ms ${theme.motion.eagerEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing},
        padding ${transitionDuration}ms ${theme.motion.eagerEasing},
        height ${expandDuration}ms ${
    (!expanded || (expanded && settingsExpanding))
        ? `${settingsContracting ? exitDelayDuration : 0}`
        : 0
}ms ${theme.motion.overshoot},
        width ${expandDuration}ms ${
    (!expanded || (expanded && settingsExpanding))
        ? `${settingsContracting ? exitDelayDuration : 0}`
        : 0
}ms ${theme.motion.overshoot}
    `};

    &:hover ${SettingsTooltip} {
        opacity: 1;
        top: ${({
        expanded,
    }) => {
        if (expanded) {
            return `-${SETTINGS_TOOLTIP_HEIGHT + SETTINGS_TOOLTIP_OFFSET}px`;
        }

        return '50%';
    }};
        right: ${({
        expanded,
    }) => {
        if (expanded) {
            return '50%';
        }

        return `-${SETTINGS_TOOLTIP_WIDTH + SETTINGS_TOOLTIP_OFFSET}px`;
    }};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        bottom: ${({
        expanded,
    }) => (expanded
        ? '0px'
        : `${VIEWPORT_OFFSET}px`
    )};
        left: ${({
        expanded,
    }) => (expanded
        ? '0px'
        : `${VIEWPORT_OFFSET}px`
    )};
        width: ${({
        expanded,
        contractedLength,
    }) => (expanded
        ? '100vw'
        : `${contractedLength}px`
    )};
        max-width: none;
        border-bottom-left-radius: ${({
        expanded,
    }) => (expanded
        ? '0px'
        : `${SETTINGS_CONTAINER_BORDER_RADIUS}px`
    )};
        border-bottom-right-radius: ${({
        expanded,
    }) => (expanded
        ? '0px'
        : `${SETTINGS_CONTAINER_BORDER_RADIUS}px`
    )};
        height: ${({
        expanded,
        agentCount,
        cellLength,
        contractedLength,
        tallestAgentNameLabelHeight,
    }) => (expanded
        ? `${SETTINGS_PADDING_BOTTOM_SMALL_SCREEN
            + SETTINGS_CONTAINER_MARGIN
            + cellLength * agentCount
            + tallestAgentNameLabelHeight}px`
        : `${contractedLength}px`
    )};
        max-height: ${({ cellLength, maxCellScrollThreshold }) => `${SETTINGS_PADDING_BOTTOM_SMALL_SCREEN + cellLength * maxCellScrollThreshold}px`};
        padding: ${({ expanded }) => (expanded
        ? `0px ${SETTINGS_PADDING_SIDE}px ${SETTINGS_PADDING_BOTTOM_SMALL_SCREEN}px ${SETTINGS_PADDING_SIDE}px`
        : `0px ${SETTINGS_PADDING_SIDE}px ${SETTINGS_PADDING_BOTTOM_LARGE_SCREEN}px ${SETTINGS_PADDING_SIDE}px`
    )};
    }
`;

interface SettingsButtonContainerProps {
    length: number,
    settingsExpanded: boolean,
    transitionDuration: number,
}
export const SettingsButtonContainer = styled.div<SettingsButtonContainerProps>`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    border-radius: ${({ length }) => `${length / 2}px`};
    background: ${({ theme }) => theme.color.white};
    margin: ${`${SETTINGS_CONTAINER_MARGIN}px`};
    transition: ${({
        theme,
        transitionDuration,
    }) => `bottom ${transitionDuration}ms ${theme.motion.eagerEasing},
        left ${transitionDuration}ms ${theme.motion.eagerEasing}
        `};
   
    ${({ theme }) => theme.mediaQuery.extraSmall} {
        bottom: ${({
        settingsExpanded,
    }) => (settingsExpanded
        ? `${VIEWPORT_OFFSET}px`
        : '0px'
    )};
        left: ${({
        settingsExpanded,
    }) => (settingsExpanded
        ? `${VIEWPORT_OFFSET}px`
        : '0px'
    )};
    }
`;

interface SettingsExpanderProps {
    length: number,
    expanded: boolean,
    expandDuration: number,
    transitionDuration: number,
}
export const SettingsExpander = styled.button<SettingsExpanderProps>`
    position: relative;
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    border-radius: ${({ length }) => `${length / 2}px`};
    background: ${({ expanded, theme }) => (expanded
        ? theme.verascopeColor.purple400
        : 'transparent'
    )};
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    transition: ${({
        theme,
        transitionDuration,
        expandDuration,
    }) => `
        background ${transitionDuration}ms ${theme.motion.eagerEasing},
        margin-right ${expandDuration}ms ${theme.motion.eagerEasing}
    `};
    cursor: none;

    & svg {
        position: relative;
        top: ${`${0.125 * BODY_FONT_SIZE}px`};
        height: ${`${1.25 * BODY_FONT_SIZE}px`};
        width: ${`${1.25 * BODY_FONT_SIZE}px`};
        fill: ${({ expanded, theme }) => (expanded
        ? theme.color.white
        : theme.color.neutral800
    )};
    transition: ${({
        theme,
        transitionDuration,
    }) => `fill ${transitionDuration}ms ${theme.motion.standardEasing}`};

    & path {
        transition: ${({
        theme,
        transitionDuration,
    }) => `fill ${transitionDuration}ms ${theme.motion.standardEasing}`};
    }
    }

    &:hover {
        background: ${({ theme, expanded }) => (expanded
        ? theme.verascopeColor.purple400
        : `rgba(
            ${hexToRgb(theme.color.neutral700)!.r},
            ${hexToRgb(theme.color.neutral700)!.g},
            ${hexToRgb(theme.color.neutral700)!.b},
            0.6
        )`
    )};

        & path {
            fill: ${({ theme }) => theme.color.white};
        }
    }
`;

interface AgentCountContainerProps {
    visible: boolean,
    leftOffset: number,
    fadeInOffset: number,
    expandDuration: number,
    enterDelayDuration: number,
    transitionDuration: number,
}
export const AgentCountContainer = styled.div<AgentCountContainerProps>`
    position: fixed;
    left: ${({ leftOffset }) => `${leftOffset + 2 * SETTINGS_CONTAINER_MARGIN + VIEWPORT_OFFSET}px`};
    bottom: ${`${SETTINGS_PADDING_SIDE + VIEWPORT_OFFSET}px`};
    transform: ${({
        visible,
        fadeInOffset,
    }) => (visible
        ? 'translateX(0px)'
        : `translateX(-${fadeInOffset}px)`
    )};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
    transition: ${({
        theme,
        visible,
        enterDelayDuration,
        expandDuration,
    }) => `
        transform ${expandDuration}ms ${visible
    ? enterDelayDuration
    : 0
}ms ${theme.motion.eagerEasing},
        opacity ${expandDuration}ms ${visible
    ? enterDelayDuration
    : 0
}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        left: 50%;
        transform: ${({
        visible,
        fadeInOffset,
    }) => (visible
        ? 'translateX(-50%)'
        : `translateX(calc(-50% - ${fadeInOffset}px))`
    )};
    }
`;

interface AgentCountInputContainerProps {
    length: number,
    transitionDuration: number,
}
export const AgentCountInputContainer = styled.div<AgentCountInputContainerProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: ${({ length }) => `${length}px`};
    width: ${`${6.25 * BODY_FONT_SIZE}px`};
    border-radius: ${({ length }) => `${length / 2}px`};
    background: ${({ theme }) => theme.color.white};
    padding: ${`${0.15625 * BODY_FONT_SIZE}px`};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transition: ${({ theme, transitionDuration }) => `box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing}`};
    
    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }
`;

interface AgentCountTextProps {
    transitionDuration: number,
}
export const AgentCountText = styled.h3<AgentCountTextProps>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 0.9em;
    color: ${({ theme }) => theme.color.neutral1100};
    text-align: center;
    background: ${({ theme }) => theme.color.white};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    padding: ${`0px ${0.9375 * BODY_FONT_SIZE}px`};
    margin: 0;
    transition: ${({ theme, transitionDuration }) => `
        background ${transitionDuration}ms ${theme.motion.standardEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.standardEasing},
        color ${transitionDuration}ms ${theme.motion.standardEasing}
    `};

    &:hover {
        background: ${({ theme }) => theme.verascopeColor.orange300};
        color: ${({ theme }) => theme.verascopeColor.orange100};
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }
`;

export const AgentCountInput = styled.input`
    height: 100%;
    border: none;
    outline: none;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 0.9em;
    color: ${({ theme }) => theme.color.neutral1000};
    text-align: center;
    background: ${({ theme }) => theme.color.white};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    padding: ${`0px ${0.3125 * BODY_FONT_SIZE}px`};
    cursor: none;
    
    &:focus {
        background: ${({ theme }) => theme.verascopeColor.orange300};
        color: ${({ theme }) => theme.verascopeColor.orange100};
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
        font-weight: 500;

        &::placeholder {
            color: ${({ theme }) => theme.verascopeColor.orange200};
        }
    }

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral500};
        font-weight: 300;
    }

    /* Remove Arrows */
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    -moz-appearance: textfield;
`;

interface AdjacencyMatrixColumnLabelContainerProps {
    cellLength: number,
    agentCount: number,
    agentPropertySocialCapitalLength: number,
    agentPropertyRankLength: number,
}
export const AdjacencyMatrixColumnLabelContainer = styled.div<AdjacencyMatrixColumnLabelContainerProps>`
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: ${({
        cellLength,
        agentCount,
        agentPropertySocialCapitalLength,
        agentPropertyRankLength,
    }) => `${
        AGENT_NAME_WIDTH
        + AGENT_NAMES_CONTAINER_MARGIN_RIGHT
        + AGENT_NAMES_CONTAINER_PADDING
        + cellLength * agentCount
        + 2 * ADJACENCT_MATRIX_PADDING
        + agentPropertySocialCapitalLength
        + agentPropertyRankLength
    }px`};
`;

interface AdjacencyMatrixColumnLabelProps {
    cellLength: number,
    unnamed: boolean,
}
export const AdjacencyMatrixColumnLabel = styled.h2<AdjacencyMatrixColumnLabelProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${({ cellLength }) => `${cellLength}px`};
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: ${({ unnamed }) => (unnamed ? 500 : 700)};
    font-size: 0.8em;
    color: ${({ unnamed, theme }) => (unnamed
        ? theme.color.neutral500
        : theme.color.neutral1000
    )};
    padding: ${`${0.625 * BODY_FONT_SIZE}px 0px`};
    margin: 0;
`;

export const AgentPropertyIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

interface AgentPropertyIconProps {
    length: number,
    iconLength: number,
    paddingLeft?: boolean,
}
export const AgentPropertyIcon = styled.div<AgentPropertyIconProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ length }) => `${length}px`};
    height: ${({ iconLength }) => `${iconLength}px`};
    padding: ${({ paddingLeft }) => (paddingLeft
        ? `${0.3125 * BODY_FONT_SIZE}px 0px ${0.3125 * BODY_FONT_SIZE}px ${0.3125 * BODY_FONT_SIZE}px`
        : `${0.3125 * BODY_FONT_SIZE}px`
    )};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral700};
    }
`;

export const AgentPropertiesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${`${AGENT_PROPERTIES_CONTAINER_PADDING}px`};
`;

interface AgentPropertyContainerProps {
    width: number,
    cellLength: number,
}
export const AgentPropertyContainer = styled.div<AgentPropertyContainerProps>`
    display: flex;
    flex-direction: row;
    width: ${({ width }) => `${width - 2 * AGENT_PROPERTY_CONTAINER_PADDING}px`};
    height: ${({ cellLength }) => `${cellLength}px`};
    border-bottom: ${({ theme }) => `${0.0625 * BODY_FONT_SIZE}px solid ${theme.color.neutral300}`};
    margin-left: ${`${2 * AGENT_PROPERTY_CONTAINER_PADDING}px`};
`;

interface AgentPropertyValueContainerProps {
    width: number,
}
export const AgentPropertyValueContainer = styled.div<AgentPropertyValueContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => `${width}px`};
    overflow: hidden;
`;

interface AgentPropertyValueProps {
    length: number,
}
export const AgentPropertyValue = styled.div<AgentPropertyValueProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.neutral1000};
`;

interface AdjacencyMatrixContainerProps {
    visible: boolean,
    scrollable: boolean,
    maxCellScrollThreshold: number,
    cellLength: number,
    agentPropertySocialCapitalLength: number,
    agentPropertyRankLength: number,
    fadeInOffset: number,
    expandDuration: number,
    enterDelayDuration: number,
}
export const AdjacencyMatrixContainer = styled.div<AdjacencyMatrixContainerProps>`
    overflow-x: ${({ scrollable }) => (scrollable ? 'scroll' : 'visible')};
    overflow-y: ${({ scrollable }) => (scrollable ? 'scroll' : 'visible')};
    max-width: ${({
        cellLength,
        maxCellScrollThreshold,
        agentPropertySocialCapitalLength,
        agentPropertyRankLength,
    }) => `${
        2 * SETTINGS_PADDING_SIDE
        + AGENT_NAME_WIDTH
        + AGENT_NAMES_CONTAINER_MARGIN_RIGHT
        + AGENT_NAMES_CONTAINER_PADDING
        + 2 * ADJACENCT_MATRIX_PADDING
        + maxCellScrollThreshold * cellLength
        + agentPropertySocialCapitalLength
        + agentPropertyRankLength
    }px`};
    max-height: ${({ cellLength, maxCellScrollThreshold }) => `${cellLength * maxCellScrollThreshold}px`};
    transform: ${({
        visible,
        fadeInOffset,
    }) => (visible
        ? 'translateX(0px)'
        : `translateX(-${fadeInOffset}px)`
    )};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
    transition: ${({
        theme,
        visible,
        enterDelayDuration,
        expandDuration,
    }) => `
        transform ${expandDuration}ms ${visible
    ? enterDelayDuration
    : 0
}ms ${theme.motion.eagerEasing},
        opacity ${expandDuration}ms ${visible
    ? enterDelayDuration
    : 0
}ms ${theme.motion.eagerEasing}
    `};
`;

export const RowNamedAdjacencyMatrix = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
`;

export const AgentNamesContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${`${AGENT_NAMES_CONTAINER_PADDING}px`};
    padding-left: 0px;
    margin-right: ${`${AGENT_NAMES_CONTAINER_MARGIN_RIGHT}px`};
`;

interface AgentNameInputProps {
    cellLength: number,
    transitionDuration: number,
}
export const AgentNameInput = styled.input<AgentNameInputProps>`
    width: ${`${AGENT_NAME_WIDTH}px`};
    height: ${`${AGENT_NAME_INPUT_HEIGHT}px`};
    border: none;
    outline: none;
    text-align: center;
    background: ${({ theme }) => theme.color.white};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    padding: ${`0px ${0.3125 * BODY_FONT_SIZE}px`};
    margin: ${({ cellLength }) => `${(cellLength - AGENT_NAME_INPUT_HEIGHT) / 2}px 0px`};
    box-shadow: ${({ theme }) => theme.color.insetBoxShadow200};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 0.9em;
    color: ${({ theme }) => theme.color.neutral1000};
    cursor: none;
    transition: ${({ theme, transitionDuration }) => `
        background ${transitionDuration}ms ${theme.motion.standardEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.standardEasing},
        color ${transitionDuration}ms ${theme.motion.standardEasing}
    `};

    &:hover {
        background: ${({ theme }) => theme.verascopeColor.orange300};
        color: ${({ theme }) => theme.verascopeColor.orange100};
    }

    &:focus {
        border: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.orange200}`};

        &:hover {
            background: ${({ theme }) => theme.color.white};
            color: ${({ theme }) => theme.color.neutral1000};
        }
    }

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral500};
        font-weight: 500;
        font-size: 0.8em;
    }

    /* Remove Arrows */
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    -moz-appearance: textfield;
`;

interface AdjacencyMatrixProps {
    agentCount: number,
}
export const AdjacencyMatrix = styled.div<AdjacencyMatrixProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ agentCount, theme }) => (agentCount > 0
        ? theme.verascopeColor.purple200
        : 'transparent'
    )};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    padding: ${`${ADJACENCT_MATRIX_PADDING}px`};
`;

export const AdjacencyMatrixRow = styled.div`
    display: flex;
    flex-direction: row;
`;

interface AdjacencyMatrixCellProps {
    rowIndex: number,
    colIndex: number,
    disable?: boolean,
    cellLength: number,
    agentCount: number,
    compact: boolean,
    background: string,
}
export const AdjacencyMatrixCell = styled.div<AdjacencyMatrixCellProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ cellLength }) => `${cellLength - 2 * ADJACENCY_MATRIX_CELL_MARGIN}px`};
    height: ${({ cellLength }) => `${cellLength - 2 * ADJACENCY_MATRIX_CELL_MARGIN}px`};
    background: ${({
        disable,
        background,
    }) => (disable
        ? 'transparent'
        : background
    )};
    box-shadow: ${({
        disable,
        theme,
    }) => (disable
        ? 'none'
        : theme.color.boxShadow300
    )};
    margin: ${`${ADJACENCY_MATRIX_CELL_MARGIN}px`};
    padding: ${({ compact }) => `${compact ? 0.125 * BODY_FONT_SIZE : 0.3125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 0.8em;
    color: ${({ disable, theme }) => (disable
        ? theme.verascopeColor.purple300
        : theme.verascopeColor.purple500
    )};
`;

interface AdjacencyMatrixCellInputProps {
    transitionDuration: number,
}
export const AdjacencyMatrixCellInput = styled.input<AdjacencyMatrixCellInputProps>`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    text-align: center;
    background: ${({ theme }) => theme.color.white};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    padding: ${`0px ${0.3125 * BODY_FONT_SIZE}px`};
    box-shadow: ${({ theme }) => theme.color.insetBoxShadow200};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    color: ${({ theme }) => theme.color.neutral1000};
    cursor: none;
    transition: ${({ theme, transitionDuration }) => `
        background ${transitionDuration}ms ${theme.motion.standardEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.standardEasing},
        color ${transitionDuration}ms ${theme.motion.standardEasing}
    `};

    &:hover {
        background: ${({ theme }) => theme.verascopeColor.orange300};
        color: ${({ theme }) => theme.verascopeColor.orange100};

        &::placeholder {
            color: ${({ theme }) => theme.verascopeColor.orange100};
        }
    }
    
    &:focus {
        border: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.orange200}`};

        &:hover {
            background: ${({ theme }) => theme.color.white};
            color: ${({ theme }) => theme.color.neutral1000};
        }
    }

    &::placeholder {
        position: relative;
        top: ${`-${0.125 * BODY_FONT_SIZE}px`};
        color: ${({ theme }) => theme.color.neutral500};
        font-weight: 400;
        font-size: 0.75em;

        transition: ${({ theme, transitionDuration }) => `
        color ${transitionDuration}ms ${theme.motion.standardEasing}
    `};
    }

    /* Remove Arrows */
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    -moz-appearance: textfield;
`;

export const AdjacencyMatrixPlaceholderText = styled.p`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.neutral500};
    white-space: nowrap;
    margin: ${`${0.625 * BODY_FONT_SIZE}px`};
`;

interface ResetNetworkButtonContainerProps {
    length: number,
    padding: number,
    zIndex: number,
}
export const ResetNetworkButtonContainer = styled.div<ResetNetworkButtonContainerProps>`
    position: absolute;
    right: ${`${VIEWPORT_OFFSET}px`};
    bottom: ${`${VIEWPORT_OFFSET}px`};
    height: ${({ length }) => `${length}px`};
    width: ${({ length }) => `${length}px`};
    border-radius: ${({ length }) => `${length / 2}px`};
    background: ${({ theme }) => theme.color.white};
    padding: ${({ padding }) => `${padding}px`};
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    z-index: ${({ zIndex }) => zIndex};
`;

interface AssembleGroupButtonContainerProps {
    assembleGroupModeIsActive: boolean,
    length: number,
    resetButtonLength: number,
    padding: number,
    zIndex: number,
}
export const AssembleGroupButtonContainer = styled.div<AssembleGroupButtonContainerProps>`
    position: absolute;
    right: ${`${VIEWPORT_OFFSET}px`};
    bottom: ${({ resetButtonLength }) => `${2 * VIEWPORT_OFFSET + resetButtonLength}px`};
    height: ${({ length }) => `${length}px`};
    width: ${({ length }) => `${length}px`};
    border-radius: ${({ length }) => `${length / 2}px`};
    background: ${({ assembleGroupModeIsActive, theme }) => (assembleGroupModeIsActive
        ? theme.verascopeColor.blue200
        : theme.color.white
    )};
    padding: ${({ padding }) => `${padding}px`};
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    z-index: ${({ zIndex }) => zIndex};

    ${({ assembleGroupModeIsActive, theme }) => assembleGroupModeIsActive && `
        & > button {
            background: ${theme.verascopeColor.blue200};
        }

        & > button:hover {
            background: ${theme.verascopeColor.blue200};
        }
    `}
`;

interface GraphContainerProps {
    assembleGroupModeIsActive: boolean,
    transitionDuration: number,
}
export const GraphContainer = styled.div<GraphContainerProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({
        assembleGroupModeIsActive,
        theme,
    }) => (assembleGroupModeIsActive
        ? theme.verascopeColor.blue300
        : theme.verascopeColor.purple100
    )};
    transition: ${({
        theme,
        transitionDuration,
    }) => `
        background ${transitionDuration}ms ${theme.motion.standardEasing}
    `};
`;

interface GraphModeContainerProps {
    resetButtonLength: number,
    zIndex: number,
}
export const GraphModeContainer = styled.div<GraphModeContainerProps>`
    position: absolute;
    bottom: ${`${VIEWPORT_OFFSET}px`};
    right: ${({
        resetButtonLength,
    }) => `${2 * VIEWPORT_OFFSET + resetButtonLength}px`};
    z-index: ${({ zIndex }) => zIndex};
`;

export const AssembledGroupContainer = styled.div`
    position: absolute;
    top: ${`${5.625 * BODY_FONT_SIZE}px`};
    right: ${`${VIEWPORT_OFFSET + 0.625 * BODY_FONT_SIZE}px`};
    border: ${({ theme }) => `${0.25 * BODY_FONT_SIZE}px solid${theme.verascopeColor.blue200}`};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    min-width: ${`${4.375 * BODY_FONT_SIZE}px`};
    max-width: ${`${9.375 * BODY_FONT_SIZE}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
`;

interface AssembledGroupProps {
    itemCount: number,
    itemHeight: number,
    maxHeight: number,
}
export const AssembledGroup = styled.div<AssembledGroupProps>`
    height: ${({ itemCount, itemHeight }) => `${itemCount * itemHeight}px`};
    max-height: ${({ maxHeight }) => `${maxHeight}px`};
    width: 100%;
    overflow-y: scroll;

    ${({
        itemCount,
        itemHeight,
        maxHeight,
        theme,
    }) => (itemCount * itemHeight > maxHeight
        ? `
        &::-webkit-scrollbar {
            width: ${0.315 * BODY_FONT_SIZE}px;
        }

        &::-webkit-scrollbar-track-piece {
            background-color: ${theme.verascopeColor.purple300};
            border-radius: ${0.15625 * BODY_FONT_SIZE}px;
        }

        &::-webkit-scrollbar-thumb:vertical {
            background-color: ${theme.verascopeColor.purple500};
            border-radius: ${0.15625 * BODY_FONT_SIZE}px;
        }
        `
        : ''
    )};
`;

export const AssembledGroupHeader = styled.h2`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 900;
    font-size: 0.9em;
    line-height: 0.9em;
    color: ${({ theme }) => theme.verascopeColor.blue100};
    text-align: center;
    margin: 0px;
    padding: ${`${0.625 * BODY_FONT_SIZE}px`};
`;

export const AssembledGroupItem = styled.h3`
    padding: ${`${0.5625 * BODY_FONT_SIZE}px`};
    margin: 0px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 0.9em;
    line-height: 0.9em;
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    text-overflow: ellipsis;
    height: ${`${1.875 * BODY_FONT_SIZE}px`};
    white-space: nowrap;
    overflow: hidden;
`;

interface AssembleGroupTooltipProps {
    visible: boolean,
    transitionDuration: number,
}
export const AssembleGroupTooltip = styled.div<AssembleGroupTooltipProps>`
    position: absolute;
    top: ${({ visible }) => `${visible ? -0.3125 * BODY_FONT_SIZE : 0}px`};
    right: 0px;
    transform: ${({ visible }) => {
        if (visible) {
            return 'translateY(-100%)';
        }

        return 'translateY(-80%)';
    }};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.verascopeColor.purple300};
    width: ${`${14.6875 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    padding: ${`${0.625 * BODY_FONT_SIZE}px`};
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    pointer-events: none;
    transition: ${({
        theme,
        transitionDuration,
    }) => `
        top ${transitionDuration}ms ${theme.motion.overshoot},
        transform ${transitionDuration}ms ${theme.motion.overshoot},
        opacity ${transitionDuration}ms ${theme.motion.overshoot}
    `};
`;

export const AssembledGroupDetailContainer = styled.div`
    position: absolute;
    left: 50%;
    bottom: ${`-${0.625 * BODY_FONT_SIZE}px`};
    transform: translate(-50%, 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const AssembledGroupDetail = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

interface AssembledGroupDetailIconProps {
    length: number,
}
export const AssembledGroupDetailIcon = styled.div<AssembledGroupDetailIconProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.verascopeColor.blue100};
    }
`;

export const AssembledGroupDetailValue = styled.h4`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 0.9em;
    color: ${({ theme }) => theme.color.white};
    margin: 0;
    margin-left: ${`${0.25 * BODY_FONT_SIZE}px`};
`;

export const AgentSelectedIndicatorContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
`;

interface AgentSelectedIndicatorProps {
    top: number,
    left: number,
    nodeLength: number,
    graphZoom: number,
}
export const AgentSelectedIndicator = styled.div<AgentSelectedIndicatorProps>`
    position: absolute;
    top: ${({
        top,
        nodeLength,
        graphZoom,
    }) => `${top - ((nodeLength / 10) * graphZoom) / 2}px`};
    left: ${({
        left,
        nodeLength,
        graphZoom,
    }) => `${left - ((nodeLength / 10) * graphZoom) / 2}px`};
    width: ${({
        nodeLength,
        graphZoom,
    }) => `${(nodeLength / 10) * graphZoom}px`};
    height: ${({
        nodeLength,
        graphZoom,
    }) => `${(nodeLength / 10) * graphZoom}px`};
    border-radius: ${({
        nodeLength,
        graphZoom,
    }) =>  `${((nodeLength / 10) * graphZoom) / 2}px`};
    border: ${({
        graphZoom,
        theme,
    }) => `${5 * graphZoom}px dotted ${theme.verascopeColor.blue200}`};
`;

export const ConnectionTypeKeyContainer = styled.div`
    position: fixed;
    top: ${`${HEADER_HEIGHT}px`};
    left: ${`${VIEWPORT_OFFSET}px`};
    display: flex;
    flex-direction: column;
`;

export const ConnectionTypeKeyItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

interface ConnectionTypeKeyItemIndicatorProps {
    type: SOCIAL_EMERGENCE_CONNECTION_TYPE,
}
export const ConnectionTypeKeyItemIndicator = styled.div<ConnectionTypeKeyItemIndicatorProps>`
    width: ${`${0.625 * BODY_FONT_SIZE}px`};
    height: ${`${0.625 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    margin: ${`${0.3125 * BODY_FONT_SIZE}px`};
    margin-left: 0px;
    background: ${({ type, theme }) => {
        switch (type) {
        case SOCIAL_EMERGENCE_CONNECTION_TYPE.indirect:
            return theme.verascopeColor.green200;
        case SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial:
            return theme.verascopeColor.yellow200;
        case SOCIAL_EMERGENCE_CONNECTION_TYPE.stranger:
            return theme.verascopeColor.red200;
        default:
            return theme.verascopeColor.purple400;
        }
    }};
`;

export const ConnectionTypeKeyItemText = styled.h3`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.white};
    margin: 0;
    margin-left: ${`${0.15625 * BODY_FONT_SIZE}px`};
`;

export const GraphDisablePlaceholder = styled.h2`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 1.2em;
    color: ${({ theme }) => theme.verascopeColor.orange300};
    padding: ${`${0.625 * BODY_FONT_SIZE}px`};
    margin: 0 auto;
`;

// ===== Packages =====

import {
    Node,
    Editor,
    Path,
    Range,
    Transforms,
    Text,
    Point,
    Location,
    NodeEntry,
}                                       from 'slate';

// ===== Elements =====

import {
    CaptionElement as _CaptionElement,
    FigureContentElement as _FigureContentElement,
    FigureElement as _FigureElement,
    GalleryElement as _GalleryElement,
    Orientations as _Orientations,
    FigureContentTypes as _FigureContentTypes,
    DELETE_FIGURE_NODE,
}                                           from '../elements/figureElement';
import { LineElement }                      from './linesPlugin';

// ===== Interfaces =====

import {
    EditorNode,
    FigureNode,
    FigureContentNode,
    GalleryNode,
    CaptionNode,
}                                           from '../interfaces';

// ===== Enums =====

import {
    FIGURE_CONTENT_TYPE,
    FIGURE_ORIENTATION,
}                                           from '../elements/figureElement/enums';

export const Orientations = _Orientations;
export const FigureContentTypes = _FigureContentTypes;
export const CaptionElement = _CaptionElement;
export const FigureContentElement = _FigureContentElement;
export const FigureElement = _FigureElement;
export const GalleryElement = _GalleryElement;

const insertFigure = (
    editor: Editor,
    id: string,
    url?: string,
    filePath?: string,
    caption?: string,
): void => {
    const { selection } = editor;

    const endTextPath = Range.end(selection as Range).path;
    const currentBlockPath = Path.parent(endTextPath);
    const nextBlockPath = Path.next(currentBlockPath);
    return Transforms.insertNodes(editor, FigureElement.newFigureElement({
        id,
        url,
        filePath,
        text: caption,
    }), { at: nextBlockPath });
};

const setFigureOrientation = (editor: Editor, orientation: FIGURE_ORIENTATION): void => {
    const { selection } = editor;

    if (Range.isExpanded(selection as Range)) { return; }

    const orientationIsValid = FigureElement.orientationIsValid(orientation);
    if (!orientationIsValid) { return; }

    const selectionStart = Range.start(selection as Range).path;
    const [figureAncestor] = Array
        .from(Node.ancestors(editor, selectionStart))
        .filter(([ancestor]) => FigureElement.isFigureElement(ancestor));
    if (figureAncestor) {
        const [, figurePath] = figureAncestor;
        return Transforms.setNodes<FigureNode>(editor, { orientation }, { at: figurePath });
    }
};

const setFigureUrlFilePath = (editor: Editor, url?: string, filePath?: string): void => {
    const { selection } = editor;

    if (Range.isExpanded(selection as Range)) { return; }

    if ((url && !FigureElement.urlIsValid(url)) && !filePath) { return; }

    const selectionStart = Range.start(selection as Range).path;
    const [figureAncestor] = Array
        .from(Node.ancestors(editor, selectionStart))
        .filter(([ancestor]) => FigureElement.isFigureElement(ancestor));
    if (figureAncestor) {
        const [, figurePath] = figureAncestor;

        // NOTE: assumes Figure is valid when command is called so first child should
        //  be an FigureContentElement
        const contentPath = [...figurePath, 0];

        if (url) return Transforms.setNodes<FigureContentNode>(editor, { url }, { at: contentPath });
        return Transforms.setNodes<FigureContentNode>(editor, { filePath }, { at: contentPath });
    }
};

const bundleFigureWithNewFigureIntoGallery = (editor: Editor, id: string, url?: string, filePath?: string): void => {
    const { selection } = editor;

    if (Range.isExpanded(selection as Range)) { return; }

    if ((url && !FigureElement.urlIsValid(url)) && !filePath) { return; }

    const selectionStart = Range.start(selection as Range).path;
    const [figureAncestor] = Array
        .from(Node.ancestors(editor, selectionStart))
        .filter(([ancestor]) => FigureElement.isFigureElement(ancestor));
    if (figureAncestor) {
        const [figure, figurePath] = figureAncestor;
        Transforms.removeNodes(editor, { at: figurePath });
        Transforms.insertNodes(editor, GalleryElement.newGalleryElement(figure as FigureNode, id, url, filePath), { at: figurePath });
    }
};

const shuffleGalleryOrder = (editor: Editor): void => {
    const { selection } = editor;

    if (Range.isExpanded(selection as Range)) { return; }

    const selectionStart = Range.start(selection as Range).path;
    const [galleryAncestor] = Array
        .from(Node.ancestors(editor, selectionStart))
        .filter(([ancestor]) => GalleryElement.isGalleryElement(ancestor));
    if (galleryAncestor) {
        const [, galleryPath] = galleryAncestor;

        // NOTE: assumes Gallery is valid when command is called so first child should
        //  be a FigureElement
        const firstFigurePath = [...galleryPath, 0];
        const secondFigurePath = [...galleryPath, 1];

        Transforms.moveNodes(editor, { at: secondFigurePath, to: firstFigurePath });
        Transforms.setNodes<FigureNode>(editor, { orientation: Orientations.left }, { at: firstFigurePath });
        Transforms.setNodes<FigureNode>(editor, { orientation: Orientations.right }, { at: secondFigurePath });
    }
};

const unbundleFigureFromGallery = (editor: Editor): void => {
    const { selection } = editor;

    if (Range.isExpanded(selection as Range)) { return; }

    const selectionStart = Range.start(selection as Range).path;
    const [galleryAncestor] = Array
        .from(Node.ancestors(editor, selectionStart))
        .filter(([ancestor]) => GalleryElement.isGalleryElement(ancestor));
    if (galleryAncestor) {
        const [, galleryPath] = galleryAncestor;
        Transforms.unwrapNodes(editor, { at: galleryPath });
    }
};

const getPerfectlySelectedFigure = (editor: Editor): [FigureNode, Path][] => {
    const { selection } = editor;

    if (!selection) { return []; }

    const selectionStart = Range.start(selection).path;
    const [startFigure] = Array
        .from(Node.ancestors(editor, selectionStart))
        .filter(([ancestor]) => FigureElement.isFigureElement(ancestor));

    const selectionEnd = Range.end(selection).path;
    const [endFigure] = Array
        .from(Node.ancestors(editor, selectionEnd))
        .filter(([ancestor]) => FigureElement.isFigureElement(ancestor));

    if (startFigure && endFigure) {
        const [figure, startFigurePath] = startFigure;
        const [, endFigurePath] = endFigure;
        if (Path.equals(startFigurePath, endFigurePath)) {
            return [[figure as FigureNode, startFigurePath]];
        }
    }

    return [];
};

// const hasSelectedFigure = (editor) => {
//     const [match] = getSelectedFigure(editor);
//     return !!match;
// };

const removeFigure = (editor: Editor): void => {
    const [figureEntry] = getPerfectlySelectedFigure(editor);
    if (figureEntry) {
        const [, figurePath] = figureEntry;
        Transforms.removeNodes(editor, { at: figurePath });
    }
};

const hasSelectedFigureType = (editor: Editor, contentType: FIGURE_CONTENT_TYPE): boolean => {
    const isFigureOfType = (node: EditorNode | Node): boolean => FigureElement.isFigureElement(node)
        && 'contentType' in node
        // eslint-disable-next-line dot-notation
        && node['contentType'] === contentType;
    const [match] = Array.from(Editor.nodes(editor, { match: isFigureOfType }));
    return !!match;
};

const getSelectedGallery = (editor: Editor): [GalleryNode, Path][] => {
    const { selection } = editor;

    const selectionStart = Range.start(selection as Range).path;
    const [startGallery] = Array
        .from(Node.ancestors(editor, selectionStart))
        .filter(([ancestor]) => GalleryElement.isGalleryElement(ancestor));

    const selectionEnd = Range.end(selection as Range).path;
    const [endGallery] = Array
        .from(Node.ancestors(editor, selectionEnd))
        .filter(([ancestor]) => GalleryElement.isGalleryElement(ancestor));

    if (startGallery && endGallery) {
        const [gallery, startGalleryPath] = startGallery;
        const [_, endGalleryPath] = endGallery;
        if (Path.equals(startGalleryPath, endGalleryPath)) {
            return [[gallery as GalleryNode, startGalleryPath]];
        }
    }

    return [];
};

const hasSelectedGallery = (editor: Editor): boolean => {
    const [match] = getSelectedGallery(editor);
    return !!match;
};

export const FigureHelpers = {
    hasSelectedFigureType,
    getPerfectlySelectedFigure,
    getSelectedGallery,
    insertFigure,
    setFigureOrientation,
    setFigureUrlFilePath,
    bundleFigureWithNewFigureIntoGallery,
    shuffleGalleryOrder,
    unbundleFigureFromGallery,
    hasSelectedGallery,
    removeFigure,

    hasSelectedImageFigure: (editor: Editor) => hasSelectedFigureType(editor, FigureContentTypes.image),
    hasSelectedYouTubeFigure: (editor: Editor) => hasSelectedFigureType(editor, FigureContentTypes.youtube),
    hasSelectedSpotifyFigure: (editor: Editor) => hasSelectedFigureType(editor, FigureContentTypes.spotify),
    hasSelectedTwitterFigure: (editor: Editor) => hasSelectedFigureType(editor, FigureContentTypes.twitter),
    hasSelectedVimeoFigure: (editor: Editor) => hasSelectedFigureType(editor, FigureContentTypes.vimeo),
};

const normalizeCaption = (
    editor: Editor,
    node: EditorNode,
    path: Path,
    normalizeNode: (entry: NodeEntry<Node>) => void,
): void => {
    if (!CaptionElement.isCaptionElement(node)) { return normalizeNode([node, path]); }

    const hasBlocks = Editor.hasBlocks(editor, node);
    if (hasBlocks) {
        const rightAfterFigurePath = Path.next(Path.parent(path));
        Array.from(Node.children(editor, path, { reverse: true })).forEach(([, childPath]) => (
            Transforms.moveNodes(editor, { at: childPath, to: rightAfterFigurePath })
        ));
    }

    const [parent] = Editor.parent(editor, path);
    const captionParentIsFigure = FigureElement.isFigureElement(parent);
    if (!captionParentIsFigure) {
        return Editor.withoutNormalizing(editor, () => {
            Transforms.unsetNodes(editor, ['isGalleryChild', 'orientation', 'contentType'], { at: path });
            Transforms.setNodes(editor, LineElement.newLineElement(), { at: path });
        });
    }
};

const normalizeContent = (
    editor: Editor,
    contentNode: FigureContentNode,
    path: Path,
    normalizeNode: (entry: NodeEntry<Node>) => void,
): void => {
    if (!FigureContentElement.isFigureContentElement(contentNode)) { return normalizeNode([contentNode, path]); }

    const [parent] = Editor.parent(editor, path);
    const contentParentIsFigure = FigureElement.isFigureElement(parent);
    if (!contentParentIsFigure) {
        return Transforms.wrapNodes(editor, FigureElement.newFigureElement({
            id: contentNode.id,
            url: contentNode.url,
            filePath: contentNode.filePath,
        }), { at: path });
    }

    if (!contentNode.filePath && contentNode.figureContentType === FIGURE_CONTENT_TYPE.image) {
        // no filePath delete the Figure (Image)
        return Transforms.removeNodes(editor, { at: Path.parent(path) });
    }

    if (!contentNode.url && contentNode.figureContentType !== FIGURE_CONTENT_TYPE.image) {
        // no url delete the Figure (embedding)
        return Transforms.removeNodes(editor, { at: Path.parent(path) });
    }

    // make sure that figureContentType is in sync with the URL
    const expectedFigureContentType = FigureContentElement.getTypeOfFigureContentUrl(contentNode.url);
    if (expectedFigureContentType === contentNode.figureContentType) {
        // do nothing
    } else {
        return Transforms.setNodes<FigureContentNode>(editor, { figureContentType: expectedFigureContentType }, { at: path });
    }
};

const keepFigureContentAndCaptionInSync = (
    editor: Editor,
    figureNode: FigureNode,
    path: Path,
): void => {
    const [content, caption] = figureNode.children;
    const entries: [FigureNode | FigureContentNode | CaptionNode, Path][] = [
        [figureNode, path],
        [content as FigureContentNode, [...path, 0]],
        [caption as CaptionNode, [...path, 1]],
    ];

    const isGalleryChild = GalleryElement.isGalleryElement(Node.parent(editor, path));
    const { orientation: targetOrientation } = figureNode;
    const { figureContentType: targetFigureContentType } = content as FigureContentNode;
    entries.forEach(([el, elPath]) => {
        // Content and Caption should always have the same orientation as
        //  their parent Figure
        if (el.orientation !== targetOrientation) {
            return Transforms.setNodes<FigureNode>(editor, { orientation: targetOrientation }, { at: elPath as Location });
        }

        // isGalleryChild should be present on Figure, Content, and Caption when
        //  Figure is inside Gallery, else isGalleryChild should not be present
        if (isGalleryChild && el.isGalleryChild !== true) {
            return Transforms.setNodes<FigureNode>(editor, { isGalleryChild }, { at: elPath });
        }
        if (!isGalleryChild && 'isGalleryChild' in el) {
            return Transforms.unsetNodes(editor, 'isGalleryChild', { at: elPath });
        }

        // make sure that Caption, Figure, and Content all have the same contentType
        if (el.figureContentType !== targetFigureContentType) {
            return Transforms.setNodes<FigureNode>(editor, { figureContentType: targetFigureContentType }, { at: elPath });
        }
    });
};

const normalizeFigure = (
    editor: Editor,
    node: FigureNode,
    path: Path,
    normalizeNode: (entry: NodeEntry<Node>) => void,
): void => {
    if (!FigureElement.isFigureElement(node)) { return normalizeNode([node, path]); }

    if (!FigureElement.implementsListableInterface(node)) {
        return Transforms.setNodes<FigureNode>(editor, { isListable: true }, { at: path });
    }

    const figureChildrenCount = node.children.length;

    // Figure is missing caption, add new Caption after Content
    if (figureChildrenCount === 1 && FigureContentElement.isFigureContentElement(node.children[0])) {
        const contentPath = [...path, 0];
        const afterContentPath = Path.next(contentPath);
        return Transforms.insertNodes(editor, CaptionElement.newCaptionElement({ id: node.id }), { at: afterContentPath });
    }

    // Figure is missing content, unwrap Figure
    if (figureChildrenCount === 1 && CaptionElement.isCaptionElement(node.children[0])) {
        return Transforms.unwrapNodes(editor, { at: path });
    }

    if (figureChildrenCount === 0) {
        return Transforms.removeNodes(editor, { at: path });
    }

    // move all non-content and non-caption nodes outside of the Fiture
    const rightAfterFigurePath = Path.next(path);
    Array.from(Node.children(editor, path)).forEach(([child, childPath]) => {
        if (!(FigureContentElement.isFigureContentElement(child) || CaptionElement.isCaptionElement(child))) {
            return Transforms.moveNodes(editor, { at: childPath, to: rightAfterFigurePath });
        }
    });

    if (figureChildrenCount > 2) {
        return Transforms.unwrapNodes(editor, { at: path });
    }

    const figureHasValidOrientation = FigureElement.orientationIsValid(node.orientation);
    if (!figureHasValidOrientation) {
        return Transforms.setNodes<FigureNode>(editor, { orientation: Orientations.center }, { at: path });
    }

    return keepFigureContentAndCaptionInSync(editor, node, path);
};

const normalizeGallery = (
    editor: Editor,
    node: GalleryNode,
    path: Path,
    normalizeNode: (entry: NodeEntry<Node>) => void,
): void => {
    if (!GalleryElement.isGalleryElement(node)) { return normalizeNode([node, path]); }

    const { children } = node;
    const galleryHasExactlyTwoChildrenThatAreFigures = children.length === 2
        && FigureElement.isFigureElement(children[0])
        && FigureElement.isFigureElement(children[1]);
    if (!galleryHasExactlyTwoChildrenThatAreFigures) {
        Editor.withoutNormalizing(editor, () => {
            const rightAfterGalleryPath = Path.next(path);
            children
                .map((child, index) => [child, [...path, index]])
                .reverse()
                .forEach(([child, childPath]) => {
                    // move each child Node to right after the Gallery
                    Transforms.moveNodes(editor, { at: childPath as Location, to: rightAfterGalleryPath });

                    // then synchronize any Figure children
                    if (FigureElement.isFigureElement(child as FigureNode)) {
                        keepFigureContentAndCaptionInSync(editor, child as FigureNode, childPath as Path);
                    }
                });
            // then delete the gallery
            Transforms.removeNodes(editor, { at: path });
        });
    }

    const firstFigureHasOrientationLeft = children[0].orientation === Orientations.left;
    if (!firstFigureHasOrientationLeft) {
        const firstFigurePath = [...path, 0];
        return Transforms.setNodes<FigureNode>(editor, { orientation: Orientations.left }, { at: firstFigurePath });
    }

    const secondFigureHasOrientationRight = children[1].orientation === Orientations.right;
    if (!secondFigureHasOrientationRight) {
        const secondFigurePath = [...path, 1];
        return Transforms.setNodes<FigureNode>(editor, { orientation: Orientations.right }, { at: secondFigurePath });
    }
};

const moveCursorOutsideCaption = (editor: Editor, captionPath: Path): void => {
    const [, figurePath] = Editor.parent(editor, captionPath);
    const nextText = Editor.next(editor, { at: figurePath, match: Text.isText });
    if (nextText) {
        const [, siblingPath] = nextText;
        const newSelection = {
            anchor: { path: siblingPath, offset: 0 },
            focus: { path: siblingPath, offset: 0 },
        };
        return Transforms.select(editor, newSelection);
    }
};

const markNodeForDeletion = (editor: Editor, nodePath: Path): void => {
    Transforms.setNodes<FigureNode>(editor, { [DELETE_FIGURE_NODE]: true }, { at: nodePath });
};

const deleteMarkedNodes = (editor: Editor): void => {
    const nodeShouldBeDeleted = (node: FigureNode | Node): boolean => (DELETE_FIGURE_NODE in node && node[DELETE_FIGURE_NODE]) || false;
    Transforms.removeNodes(editor, { at: [], match: nodeShouldBeDeleted });
};

const handleDeleteBackwardAtFigureEdgeMovesCursorIntoFigure = (editor: Editor): boolean | undefined => {
    const [lineInterfaceEntry] = Array.from(Editor.nodes(editor, { match: LineElement.implementsLineInterface }));
    if (!lineInterfaceEntry) { return; }

    const [, lineInterfacePath] = lineInterfaceEntry;
    const cursorPoint = Range.start(editor.selection as Range);
    const [, startLineInterfaceLeafPath] = Editor.leaf(editor, lineInterfacePath, { edge: 'start' });
    const startLineInterfacePoint = { path: startLineInterfaceLeafPath, offset: 0 };
    if (!Point.equals(startLineInterfacePoint, cursorPoint)) { return; }

    const previousSiblingEntry = Editor.previous(editor, { at: lineInterfacePath });
    if (!previousSiblingEntry) { return; }

    const [previousSibling] = previousSiblingEntry;
    if (!FigureElement.isFigureElement(previousSibling)) { return; }

    const [, figurePath] = previousSiblingEntry;
    const captionPath = [...figurePath, 1];
    const [endCaptionLeaf, endCaptionLeafPath] = Editor.leaf(editor, captionPath, { edge: 'end' });
    const endCaptionPoint = { path: endCaptionLeafPath, offset: endCaptionLeaf.text.length };
    Transforms.select(editor, endCaptionPoint);
    return true;
};

const handleDeleteForwardAtFigureEdgeMovesCursorIntoFigure = (editor: Editor): boolean | undefined => {
    const [lineInterfaceEntry] = Array.from(Editor.nodes(editor, { match: LineElement.implementsLineInterface }));
    if (!lineInterfaceEntry) { return; }

    const [, lineInterfacePath] = lineInterfaceEntry;
    const cursorPoint = Range.start(editor.selection as Range);
    const [endLineInterfaceLeaf, endLineInterfaceLeafPath] = Editor.leaf(editor, lineInterfacePath, { edge: 'end' });
    const endLineInterfacePoint = { path: endLineInterfaceLeafPath, offset: endLineInterfaceLeaf.text.length };
    if (!Point.equals(endLineInterfacePoint, cursorPoint)) { return; }

    const nextSiblingEntry = Editor.next(editor, { at: lineInterfacePath });
    if (!nextSiblingEntry) { return; }

    const [nextSibling] = nextSiblingEntry;
    if (!FigureElement.isFigureElement(nextSibling)) { return; }

    const [, figurePath] = nextSiblingEntry;
    const contentPath = [...figurePath, 0];
    const contentLeafPath = [...contentPath, 0];
    const contentPoint = { path: contentLeafPath, offset: 0 };
    Transforms.select(editor, contentPoint);
    return true;
};

const handleDeleteBackwardAtCaptionStart = (editor: Editor): boolean | undefined => {
    const { selection } = editor;
    if (Range.isCollapsed(selection as Range)) {
        const [captionEntry] = Array.from(Editor.nodes(editor, { match: CaptionElement.isCaptionElement }));
        if (captionEntry) {
            const cursorIsAtLeftMostCaptionPosition = (selection as Range).anchor.offset === 0;
            if (cursorIsAtLeftMostCaptionPosition) {
                const [, captionPath] = captionEntry;
                const [, figurePath] = Editor.parent(editor, captionPath);
                Transforms.removeNodes(editor, { at: figurePath });
                return true;
            }
        }
    }
};

const handleDeleteContentDeletesFigure = (
    editor: Editor,
    deleteFunc: () => void,
): boolean | undefined => {
    // find all contents in the selection
    const selectedFigureContentElements = Array.from(Editor.nodes(editor, { match: FigureContentElement.isFigureContentElement }));
    if (selectedFigureContentElements.length === 0) { return; }

    Editor.withoutNormalizing(editor, () => {
        selectedFigureContentElements.forEach(([, contentPath]) => {
            const [, figurePath] = Editor.parent(editor, contentPath);
            const next = Editor.next(editor, { at: contentPath });
            const [, captionPath] = next as NodeEntry;

            // mark the contents, their parent Figures, and their sibling Captions as needing to be deleted
            [contentPath, figurePath, captionPath].forEach((path) => markNodeForDeletion(editor, path));
        });
    });

    deleteFunc();
    deleteMarkedNodes(editor);
    return true;
};

export const withFigures = (editor: Editor, _readOnly?: boolean): Editor => {
    const {
        normalizeNode,
        isInline,
        isVoid,
        insertBreak,
        deleteBackward,
        deleteForward,
        deleteFragment,
    } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.insertBreak = () => {
        const { selection } = editor;

        if (selection) {
            const [captionEntry] = Array.from(Editor.nodes(editor, { match: CaptionElement.isCaptionElement }));
            if (captionEntry) {
                const [, captionPath] = captionEntry;
                return moveCursorOutsideCaption(editor, captionPath);
            }
        }

        return insertBreak();
    };

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path) as EditorNode;

        if (node.type === CaptionElement.TYPE) {
            return normalizeCaption(editor, node, path, normalizeNode);
        }

        if (node.type === FigureContentElement.TYPE) {
            return normalizeContent(editor, node as FigureContentNode, path, normalizeNode);
        }

        if (node.type === FigureElement.TYPE) {
            return normalizeFigure(editor, node as FigureNode, path, normalizeNode);
        }

        if (node.type === GalleryElement.TYPE) {
            return normalizeGallery(editor, node as GalleryNode, path, normalizeNode);
        }

        return normalizeNode(entry);
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => {
        if (CaptionElement.isCaptionElement(element)
            || FigureContentElement.isFigureContentElement(element)
            || FigureElement.isFigureElement(element)
            || GalleryElement.isGalleryElement(element)) {
            return false;
        }
        return isInline(element);
    };

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => {
        if (CaptionElement.isCaptionElement(node)
            || FigureElement.isFigureElement(node)
            || GalleryElement.isGalleryElement(node)) {
            return false;
        } if (FigureContentElement.isFigureContentElement(node)) {
            return true;
        }
        return isVoid(node);
    };

    // eslint-disable-next-line no-param-reassign
    editor.deleteBackward = (unit) => {
        if (handleDeleteBackwardAtCaptionStart(editor)) {
            return;
        }
        if (handleDeleteContentDeletesFigure(editor, () => deleteBackward(unit))) {
            return;
        }

        // If node is a Figure, it will enter this conditional, be selected
        // Then be deleted by the deleteBackward method
        if (handleDeleteBackwardAtFigureEdgeMovesCursorIntoFigure(editor)) {
            return deleteBackward(unit);
        }

        return deleteBackward(unit);
    };

    // eslint-disable-next-line no-param-reassign
    editor.deleteForward = (unit) => {
        if (handleDeleteContentDeletesFigure(editor, () => deleteForward(unit))) {
            return;
        }

        // If node is a Figure, it will enter this conditional, be selected
        // Then be deleted by the deleteBackward method
        if (handleDeleteForwardAtFigureEdgeMovesCursorIntoFigure(editor)) {
            return deleteBackward(unit);
        }

        return deleteForward(unit);
    };

    // eslint-disable-next-line no-param-reassign
    editor.deleteFragment = () => {
        if (handleDeleteContentDeletesFigure(editor, () => deleteFragment())) {
            return;
        }
        return deleteFragment();
    };

    return editor;
};

// ===== Packages =====

import React, {
    useEffect,
}                                       from 'react';
import {
    useNavigate,
    useLocation,
}                                       from 'react-router-dom';
import { ReactSVG }                     from 'react-svg';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    PAGE_ROUTE,
    VERIFY_STATUS_TYPE,
    USER_ACTION_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    recordUserAction,
    updatePageTitle,
    getVerifyEmailMessage,
    detectTouchDevice,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
}                                       from '../../interfaces';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';

// ===== Images =====

import VerascopeLogo                    from '../../images/verascope-logo-silhouette.svg';
import VerascopeLogomark                from '../../images/verascope-logomark.svg';

// ===== Styles =====

import {
    Container,
    ContentContainer,
    LogomarkContainer,
    StatusHeader,
    StatusMessage,
}                                       from './styles';
import {
    PageLogo,
    PageSubtitle,
    PageTitle,
}                                       from '../../styles';
import { theme }                        from '../../themes/theme-context';

interface Props {
    user: IUserItem | null,
    currentSessionId: string | null,
    verifyStatus: VERIFY_STATUS_TYPE,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function VerifyResultView({
    user,
    currentSessionId,
    verifyStatus,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();

    // ===== Methods =====

    const onLogoEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.logomark,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLogoLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onLogoClick = async (e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickHomeButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.verifyResult,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.landing}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    const successfullyVerifiedEmail = (): boolean => {
        switch (verifyStatus) {
        case VERIFY_STATUS_TYPE.success:
            return true;
        case VERIFY_STATUS_TYPE.uninitiated:
        case VERIFY_STATUS_TYPE.stillUnverified:
        case VERIFY_STATUS_TYPE.missingProperty:
        case VERIFY_STATUS_TYPE.userNotFound:
        case VERIFY_STATUS_TYPE.timeout:
        case VERIFY_STATUS_TYPE.uncaughtError:
        default:
            return false;
        }
    };

    // ===== Side Effects =====

    /**
     * Set Page Title
     */
    useEffect(() => {
        updatePageTitle(
            'Verify Email',
        );
    }, []);

    /**
     * Record user entering page
     */
    useEffect(() => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewVerifyResultPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    }, [
        user,
        currentSessionId,
    ]);

    return (
        <Container
            successfullyVerifiedEmail={successfullyVerifiedEmail()}
        >
            <PageLogo
                withEnterTransition
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e) => onLogoEnter(e),
                } : {
                    onMouseEnter: (e) => onLogoEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e) => onLogoLeave(e),
                } : {
                    onMouseLeave: (e) => onLogoLeave(e),
                })}
                onMouseDown={onLogoClick}
            >
                <ReactSVG
                    src={VerascopeLogo}
                />
            </PageLogo>
            <PageTitle
                withEnterTransition
            >
                VERIFY EMAIL
            </PageTitle>
            <PageSubtitle
                withEnterTransition
                color={successfullyVerifiedEmail()
                    ? theme.verascopeColor.green300
                    : theme.verascopeColor.red300}
            >
                {'Let\'s finish setting up your account'}
            </PageSubtitle>
            <ContentContainer>
                <LogomarkContainer>
                    <ReactSVG
                        src={VerascopeLogomark}
                    />
                </LogomarkContainer>
                <StatusHeader>
                    {`Email verification ${successfullyVerifiedEmail() ? 'successful' : 'unsuccessful'}`}
                </StatusHeader>
                <StatusMessage
                    successfullyVerifiedEmail={successfullyVerifiedEmail()}
                >
                    {getVerifyEmailMessage(verifyStatus)}
                </StatusMessage>
            </ContentContainer>
        </Container>
    );
}

export default VerifyResultView;

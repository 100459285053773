// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import FONT_TYPE                    from '../../constants/fontType';
import {
    CART_AND_CHECKOUT_FONT_SIZE_LARGE,
    CART_AND_CHECKOUT_FONT_SIZE_SMALL,
    BODY_FONT_SIZE,
}                                   from '../../constants/generalConstants';

const ORDER_SUMMARY_CONTAINER_WIDTH_LARGE = 25 * BODY_FONT_SIZE;
const ORDER_SUMMARY_CONTAINER_WIDTH_SMALL = 18.75 * BODY_FONT_SIZE;
const CART_ITEM_REMOVE_BUTTON_PADDING = 0.46875 * BODY_FONT_SIZE;
const CART_ITEM_QUANTITY_BUTTON_LENGTH = 1.25 * BODY_FONT_SIZE;

export const Container = styled.div`
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ theme }) => theme.color.white};
`;

export const ContentContainer = styled.div`
    position: absolute;
    top: ${`${5.625 * BODY_FONT_SIZE}px`};
    left: 0;
    width: 100vw;
    height: ${`calc(100vh - ${5.625 * BODY_FONT_SIZE}px)`};
    
    ${({ theme }) => theme.mediaQuery.extraSmall} {
        overflow-y: scroll;
    }
`;

export const PopulatedCart = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        flex-direction: column;
        height: auto;
    }
`;

export const EmptyCart = styled.div`
    position: relative;
    top: ${`${-5.625 * BODY_FONT_SIZE}px`};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const EmptyCartIcon = styled.div`
    width: 8vw;
    min-width: ${`${1.875 * BODY_FONT_SIZE}px`};
    max-width: ${`${3.75 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${1.25 * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        margin-bottom: ${`${0.625 * BODY_FONT_SIZE}px`};
    }
`;

export const EmptyCartTitle = styled.h1`
    margin: 0;
    margin-bottom: ${`${1.25 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE + 0.4}em`};
    color: ${({ theme }) => theme.verascopeColor.purple400};
    text-align: center;
    line-height: 1.5em;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.4}em`};
    }
`;

interface BuyBookButtonProps {
    detectTouchDevice: boolean,
}
export const BuyBookButton = styled.button<BuyBookButtonProps>`
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${`${9.375 * BODY_FONT_SIZE}px`};
    height: ${`${3.125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE + 0.1}em`};
    color: ${({ theme }) => theme.color.white};
    text-transform: uppercase;
    background: ${({ theme }) => theme.verascopeColor.purple100};
    margin-top: ${`${1.25 * BODY_FONT_SIZE}px`};

    &:hover {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            background: ${theme.verascopeColor.purple300};
        `)}
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.1}em`};
    }
`;

export const CartItemsContainer = styled.div`
    position: relative;
    flex: 1 1 auto;
    padding: ${`${1.25 * BODY_FONT_SIZE}px`};
    height: 100%;
    overflow-y: scroll;

    ${({ theme }) => theme.mediaQuery.small} {
        padding: ${`${0.625 * BODY_FONT_SIZE}px`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding: ${`${0.625 * BODY_FONT_SIZE}px`};
        height: auto;
        flex: 0 1 auto; // default
        overflow-y: hidden;
    }
`;

export const OrderSummaryContainer = styled.div`
    position: relative;
    flex: ${`0 0 ${ORDER_SUMMARY_CONTAINER_WIDTH_LARGE}px`};
    width: ${`${ORDER_SUMMARY_CONTAINER_WIDTH_LARGE}px`};
    padding: ${`${1.25 * BODY_FONT_SIZE}px`};
    height: 100%;
    overflow-y: scroll;

    ${({ theme }) => theme.mediaQuery.small} {
        padding: ${`${0.625 * BODY_FONT_SIZE}px`};
        flex: ${`0 0 ${ORDER_SUMMARY_CONTAINER_WIDTH_SMALL}px`};
        width: ${`${ORDER_SUMMARY_CONTAINER_WIDTH_SMALL}px`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding: ${`${0.625 * BODY_FONT_SIZE}px`};
        flex: 0 0 auto;
        width: 100%;
        height: auto;
        overflow-y: hidden;
        padding-bottom: ${`${5 * BODY_FONT_SIZE}px`};
    }
`;

export const Header = styled.div`
    position: relative;
    justify-content: space-between;
    width: 100%;
    padding-bottom: ${`${0.625 * BODY_FONT_SIZE}px`};
    border-bottom: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple100}`};
`;

export const HeaderText = styled.div`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE + 0.1}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;
    margin: 0;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

export const CartItemsCountText = styled.span`
    color: ${({ theme }) => theme.verascopeColor.purple400};
    margin-left: ${`${0.9375 * BODY_FONT_SIZE}px`};
`;

export const EmptyCartButtonContainer = styled.div`
    position: absolute;
    right: 0;
    top: -0.27em;
    background: ${({ theme }) => theme.color.white};

    ${({ theme }) => theme.mediaQuery.small} {
        top: -0.27em;
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: -0.27em;
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

interface EmptyCartButtonProps {
    detectTouchDevice: boolean,
}
export const EmptyCartButton = styled.button<EmptyCartButtonProps>`
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;
    background: ${({ theme }) => theme.color.white};

    &:hover {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            color: ${theme.verascopeColor.yellow200};
            transform: scale(1.2);
        `)}
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

export const CartItems = styled.div`

`;

interface CartItemContainerProps {
    small?: boolean,
}
export const CartItemContainer = styled.div<CartItemContainerProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: ${`${0.625 * BODY_FONT_SIZE}px`};
    padding-bottom: ${({ small }) => `${small ? 0.625 * BODY_FONT_SIZE : 1.25 * BODY_FONT_SIZE}px`};
    border-bottom: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple100}`};
`;

interface CartItemImageContainerProps {
    small?: boolean,
    hasImage?: boolean,
}
export const CartItemImageContainer = styled.div<CartItemImageContainerProps>`
    flex: ${({ small }) => `0 0 ${small ? 3.125 * BODY_FONT_SIZE : 5 * BODY_FONT_SIZE}px`};
    background: ${({ hasImage, theme }) => (hasImage
        ? 'transparent'
        : theme.color.neutral300)};
    width: ${({ small }) => `${small ? 3.125 * BODY_FONT_SIZE : 5 * BODY_FONT_SIZE}px`};
    height: ${({ small }) => `${small ? 3.125 * BODY_FONT_SIZE : 5 * BODY_FONT_SIZE}px`};
    padding: ${({ hasImage }) => `${!hasImage ? 0.625 * BODY_FONT_SIZE : 0}px`};
    margin-top: ${({ small }) => `${small ? 0 : 0.3125 * BODY_FONT_SIZE}px`};
    margin-right: ${({ small }) => `${small ? 0.9375 * BODY_FONT_SIZE : 1.5625 * BODY_FONT_SIZE}px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 80%;
        height: 80%;
    }

    & path, & rect {
        fill: ${({ theme }) => theme.verascopeColor.white};
    }
`;

interface CartItemImageProps {
    url: string,
}
export const CartItemImage = styled.div<CartItemImageProps>`
    background-image: ${({ url }) => (url ? `url(${url})` : 'none')};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`;

export const CartItemDetails = styled.div`
    flex: 1;
    background: ${({ theme }) => theme.color.white};
`;

interface CartItemNameProps {
    small?: boolean,
}
export const CartItemName = styled.div<CartItemNameProps>`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${({ small }) => `${small ? CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.2 : CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    line-height: 1.8em;
    text-transform: uppercase;
    padding-right: ${`${3.125 * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${({ small }) => `${small ? CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2 : CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${({ small }) => `${small ? CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2 : CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

interface CartItemPriceProps {
    small?: boolean,
    spaceBetween?: boolean,
}
export const CartItemPrice = styled.div<CartItemPriceProps>`
    ${({ spaceBetween }) => spaceBetween && `
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    `}
    margin-top: ${`${0.3125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${({ small }) => `${small ? CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.2 : CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple400};
    text-transform: uppercase;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${({ small }) => `${small ? CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2 : CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${({ small }) => `${small ? CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2 : CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

export const DiscountedCartItemPrice = styled.span`
    text-decoration: line-through;
`;

export const CartItemPriceAfterDiscount = styled.span`
    text-decoration: none;
    color: ${({ theme }) => theme.verascopeColor.orange200};
`;

interface CartItemRemoveButtonProps {
    detectTouchDevice: boolean,
}
export const CartItemRemoveButton = styled.button<CartItemRemoveButtonProps>`
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    position: absolute;
    top: ${`${0.3125 * BODY_FONT_SIZE}px`};
    right: 0px;
    width: ${`${1.875 * BODY_FONT_SIZE}px`};
    height: ${`${1.875 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.9375 * BODY_FONT_SIZE}px`};
    padding: ${`${CART_ITEM_REMOVE_BUTTON_PADDING}px`};
    background: ${({ theme }) => theme.color.white};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        overflow: visible;
    }

    & path, & rect {
        fill: ${({ theme }) => theme.verascopeColor.purple100};
        transform: scale(1);
        transform-origin: 50% 50% 0;
    }

    &:hover {
        background: ${({ theme }) => theme.verascopeColor.yellow200};
    }
`;

export const CartItemQuantityContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${({ theme }) => theme.color.white};
    width: 100%;
    min-height: ${`${CART_ITEM_QUANTITY_BUTTON_LENGTH}px`};
    margin-top: ${`${1.875 * BODY_FONT_SIZE}px`};
`;

export const CartItemQuantityText = styled.div`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.1}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;
    margin-right: ${`${0.9375 * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }
`;

interface CartItemQuantityButtonProps {
    isDisabled?: boolean,
    detectTouchDevice: boolean,
}
export const CartItemQuantityButton = styled.button<CartItemQuantityButtonProps>`
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    background: ${({ theme }) => theme.color.white};
    margin: ${`0px ${0.3125 * BODY_FONT_SIZE}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
    width: ${`${CART_ITEM_QUANTITY_BUTTON_LENGTH}px`};
    height: ${`${CART_ITEM_QUANTITY_BUTTON_LENGTH}px`};
    border-radius: ${`${0.625 * BODY_FONT_SIZE}px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        overflow: visible;
    }

    & path, & rect {
        fill: ${({ isDisabled, theme }) => (isDisabled
        ? theme.color.neutral300
        : theme.verascopeColor.purple100
    )};
        transform: scale(1);
        transform-origin: 50% 50% 0;
    }

    &:hover {
        background: ${({ theme }) => theme.verascopeColor.yellow200};
    }

    &:hover path, &:hover rect {
        ${({ detectTouchDevice, isDisabled, theme }) => (!detectTouchDevice && !isDisabled && `
            fill: ${theme.verascopeColor.purple100};
        `)}
    }
`;

interface CartItemQuantityCountProps {
    nonInteractable?: boolean,
}
export const CartItemQuantityCount = styled.div<CartItemQuantityCountProps>`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.1}em`};
    color: ${({ nonInteractable, theme }) => (nonInteractable
        ? theme.verascopeColor.purple100
        : theme.verascopeColor.orange200
    )};
    text-transform: uppercase;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }
`;

export const CartItemQuantityPriceContainer = styled.div`
    position: absolute;
    right: ${`${CART_ITEM_REMOVE_BUTTON_PADDING}px`};
    bottom: 0;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple400};
    text-transform: uppercase;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

export const OrderSummary = styled.div`

`;

export const OrderSummaryEntries = styled.div`
    padding-top: ${`${0.3125 * BODY_FONT_SIZE}px`};
`;

interface OrderSummaryEntryProps {
    divider?: boolean,
}
export const OrderSummaryEntry = styled.div<OrderSummaryEntryProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${`${0.3125 * BODY_FONT_SIZE}px 0px`};
    width: 100%;
    ${({ divider, theme }) => divider && `
        padding-top: ${1.875 * BODY_FONT_SIZE}px;
        border-top: ${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple100}
    `};
`;

export const OrderSummaryText = styled.h3`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;
    margin: 0;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

export const OrderSummaryPrice = styled.div`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple400};
    text-transform: uppercase;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

interface CheckoutButtonProps {
    detectTouchDevice: boolean,
}
export const CheckoutButton = styled.button<CheckoutButtonProps>`
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${`${3.125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE + 0.1}em`};
    color: ${({ theme }) => theme.color.white};
    text-transform: uppercase;
    background: ${({ theme }) => theme.verascopeColor.orange200};
    margin-top: ${`${1.25 * BODY_FONT_SIZE}px`};

    &:hover {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            background: ${theme.verascopeColor.orange100};
        `)}
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.1}em`};
    }
`;

export const SpinnerContainer = styled.img`
    animation: rotate 2s linear infinite;
    margin: 0;
`;

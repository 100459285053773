// ===== Packages =====

import React                    from 'react';
import styled                   from 'styled-components';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
}                               from '../../../constants/generalConstants';

RecordingLoader.defaultProps = {
    small: undefined,
};
interface Props {
    small?: boolean,
}
function RecordingLoader({ small }: Props): JSX.Element {
    return (
        <Container
            small={small}
        >
            <div />
            <div />
            <div />
            <div />
            <div />
        </Container>
    );
}

// ============= Styled Components ==============

interface ContainerProps {
    small: boolean | undefined,
}
const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        width: ${({ small }) => `${small ? 0.375 * BODY_FONT_SIZE : 0.75 * BODY_FONT_SIZE}px`};
        height: ${({ small }) => `${small ? 0.375 * BODY_FONT_SIZE : 0.75 * BODY_FONT_SIZE}px`};
        border-radius: ${({ small }) => `${small ? 0.1875 * BODY_FONT_SIZE : 0.375 * BODY_FONT_SIZE}px`};
        margin: ${({ small }) => `${small ? 0.0625 * BODY_FONT_SIZE : 0.125 * BODY_FONT_SIZE}px`};
        animation-fill-mode: both;
        display: inline-block;
        background-color: ${({ theme }) => theme.verascopeColor.red200};
    }

    & > div:nth-child(1) {
        animation: scale 2500ms 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }

    & > div:nth-child(2) {
        animation: scale 2500ms 240ms infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }

    & > div:nth-child(3) {
        animation: scale 2500ms 480ms infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }

    & > div:nth-child(4) {
        animation: scale 2500ms 720ms infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }

    & > div:nth-child(5) {
        animation: scale 2500ms 960ms infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
`;

export default RecordingLoader;

// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                       from 'react';
import {
    useNavigate,
    useLocation,
}                                       from 'react-router-dom';
import { ReactSVG }                     from 'react-svg';
import {
    doc,
    getDoc,
    getFirestore,
}                                       from 'firebase/firestore';

// ===== Components =====

import Button                           from '../Editor/helpers/Button';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    PAGE_ROUTE,
    UNSUBSCRIBE_STATUS_TYPE,
    USER_ACTION_TYPE,
    BUTTON_TYPE,
    FIRESTORE_COLLECTION,
    UNSUBSCRIBE_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    recordUserAction,
    updatePageTitle,
    updateUserInDB,
    getUnsubscribeMessage,
    detectTouchDevice,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
    ISnackbarItem,
}                                       from '../../interfaces';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
    HOVER_TARGET_CLASSNAME,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';

// ===== Images =====

import CautionIcon                      from '../../images/caution.svg';
import MailboxIcon                      from '../../images/empty-mailbox.svg';
import BellIcon                         from '../../images/bell.svg';
import VerascopeLogo                    from '../../images/verascope-logo-silhouette.svg';
import VerascopeLogomark                from '../../images/verascope-logomark.svg';

// ===== Styles =====

import {
    Container,
    ContentContainer,
    LogomarkContainer,
    StatusHeader,
    StatusMessage,
    ResubscribeContainer,
}                                       from './styles';
import {
    PageLogo,
    PageSubtitle,
    PageTitle,
}                                       from '../../styles';
import { theme }                        from '../../themes/theme-context';

interface Props {
    user: IUserItem | null,
    currentSessionId: string | null,
    unsubscribeId: string | null,
    unsubscribeStatus: UNSUBSCRIBE_STATUS_TYPE,
    unsubscribeType: UNSUBSCRIBE_TYPE | null,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function UnsubscribeResultView({
    user,
    currentSessionId,
    unsubscribeId,
    unsubscribeStatus,
    unsubscribeType,
    setSnackbarData,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== General Constants =====
    const SUBSCRIBE_BUTTON_WIDTH = 12.5 * BODY_FONT_SIZE;
    const SUBSCRIBE_BUTTON_HEIGHT = 3.125 * BODY_FONT_SIZE;
    const SNACKBAR_MESSAGE_RESUBSCRIBE_MAILING_LIST_SUCCESS = 'Successfully resubscribed to mailing list!';
    const SNACKBAR_MESSAGE_RESUBSCRIBE_MAILING_LIST_ERROR = 'There was a problem subscribing to mailing list.';
    const SNACKBAR_MESSAGE_RESUBSCRIBE_COMMENT_UPDATES_SUCCESS = 'Successfully resubscribed to annotation comment updates!';
    const SNACKBAR_MESSAGE_RESUBSCRIBE_COMMENT_UPDATES_ERROR = 'There was a problem subscribing to annotation comment updates.';
    const SNACKBAR_MESSAGE_RESUBSCRIBE_ERROR = 'There was a problem subscribing.';

    // ===== State =====

    // Indicates what type of cursor is currently visible
    const [hideResubscribeButton, setHideResubscribeButton] = useState<boolean>(false);

    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();

    // ===== Methods =====

    const onLogoEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.logomark,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLogoLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onLogoClick = async (e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickHomeButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.unsubscribeResult,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.landing}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.resubscribeButton,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const successfullyUnsubscribed = (): boolean => {
        switch (unsubscribeStatus) {
        case UNSUBSCRIBE_STATUS_TYPE.success:
            return true;
        case UNSUBSCRIBE_STATUS_TYPE.uninitiated:
        case UNSUBSCRIBE_STATUS_TYPE.stillSubscribed:
        case UNSUBSCRIBE_STATUS_TYPE.missingProperty:
        case UNSUBSCRIBE_STATUS_TYPE.missingType:
        case UNSUBSCRIBE_STATUS_TYPE.userNotFound:
        case UNSUBSCRIBE_STATUS_TYPE.timeout:
        case UNSUBSCRIBE_STATUS_TYPE.uncaughtError:
        default:
            return false;
        }
    };

    const resubscribe = async (): Promise<void> => {
        // Subscribe user to mailing list
        if (unsubscribeId) {
            const db = getFirestore();
            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const userRef = doc(db, usersCollection, unsubscribeId);

            const userSnap = await getDoc(userRef);
            const userItem = userSnap.data() as IUserItem;
            if (userSnap.exists()) {
                switch (unsubscribeType) {
                case UNSUBSCRIBE_TYPE.mailingList:
                    await updateUserInDB({
                        userId: unsubscribeId,
                        mailingListSubscription: {
                            history: [
                                // We make assumption that it exists if they were able of unsubscribing
                                ...userItem.mailingListSubscription!.history,
                                {
                                    subscribed: true,
                                    timestamp: Date.now(),
                                },
                            ],
                        },
                    });

                    // Trigger Message
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_RESUBSCRIBE_MAILING_LIST_SUCCESS,
                        icon: MailboxIcon,
                        hasSuccess: true,
                    });

                    // Remove resubscribe button
                    setHideResubscribeButton(true);
                    break;
                case UNSUBSCRIBE_TYPE.commentUpdates:
                    await updateUserInDB({
                        userId: unsubscribeId,
                        commentSubscription: {
                            history: [
                                // We make assumption that it exists if they were able of unsubscribing
                                ...userItem.commentSubscription!.history,
                                {
                                    subscribed: true,
                                    timestamp: Date.now(),
                                },
                            ],
                        },
                    });

                    // Trigger Message
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_RESUBSCRIBE_COMMENT_UPDATES_SUCCESS,
                        icon: BellIcon,
                        hasSuccess: true,
                    });

                    // Remove resubscribe button
                    setHideResubscribeButton(true);
                    break;
                default:
                    // Trigger Message
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_RESUBSCRIBE_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                }
            } else {
                // Trigger Message
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: unsubscribeType === UNSUBSCRIBE_TYPE.commentUpdates
                        ? SNACKBAR_MESSAGE_RESUBSCRIBE_COMMENT_UPDATES_ERROR
                        : SNACKBAR_MESSAGE_RESUBSCRIBE_MAILING_LIST_ERROR,
                    icon: CautionIcon,
                    hasFailure: true,
                });
            }
        } else {
            // Trigger Message
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: unsubscribeType === UNSUBSCRIBE_TYPE.commentUpdates
                    ? SNACKBAR_MESSAGE_RESUBSCRIBE_COMMENT_UPDATES_ERROR
                    : SNACKBAR_MESSAGE_RESUBSCRIBE_MAILING_LIST_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
        }

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickResubscribeButton,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    };

    // ===== Side Effects =====

    /**
     * Set Page Title
     */
    useEffect(() => {
        updatePageTitle(
            'Unsubscribe',
        );
    }, []);

    /**
     * Record user entering page
     */
    useEffect(() => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewUnsubscribeResultPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    }, [
        user,
        currentSessionId,
    ]);

    return (
        <Container
            successfullyUnsubscribed={successfullyUnsubscribed()}
        >
            <PageLogo
                withEnterTransition
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e) => onLogoEnter(e),
                } : {
                    onMouseEnter: (e) => onLogoEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e) => onLogoLeave(e),
                } : {
                    onMouseLeave: (e) => onLogoLeave(e),
                })}
                onMouseDown={onLogoClick}
            >
                <ReactSVG
                    src={VerascopeLogo}
                />
            </PageLogo>
            <PageTitle
                withEnterTransition
            >
                UNSUBSCRIBE
            </PageTitle>
            <PageSubtitle
                withEnterTransition
                color={successfullyUnsubscribed()
                    ? theme.verascopeColor.green300
                    : theme.verascopeColor.red300}
            >
                {`No longer want to receive ${unsubscribeType === UNSUBSCRIBE_TYPE.commentUpdates
                    ? 'comment update'
                    : 'mailing list'
                } emails?`}
            </PageSubtitle>
            <ContentContainer>
                <LogomarkContainer>
                    <ReactSVG
                        src={VerascopeLogomark}
                    />
                </LogomarkContainer>
                <StatusHeader>
                    {`Unsubscribe ${successfullyUnsubscribed() ? 'successful' : 'unsuccessful'}`}
                </StatusHeader>
                <StatusMessage
                    successfullyUnsubscribed={successfullyUnsubscribed()}
                >
                    {getUnsubscribeMessage(unsubscribeStatus, unsubscribeType)}
                    {successfullyUnsubscribed() && !hideResubscribeButton
                        ? ' If this is an error, resubscribe below:'
                        : null}
                </StatusMessage>
                {successfullyUnsubscribed()
                && !hideResubscribeButton
                && (
                    <ResubscribeContainer>
                        <Button
                            className={HOVER_TARGET_CLASSNAME}
                            type={BUTTON_TYPE.solid}
                            background={theme.verascopeColor.green200}
                            height={SUBSCRIBE_BUTTON_HEIGHT}
                            width={SUBSCRIBE_BUTTON_WIDTH}
                            icon={unsubscribeType === UNSUBSCRIBE_TYPE.commentUpdates
                                ? BellIcon
                                : MailboxIcon}
                            text="Resubscribe"
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            onClick={resubscribe}
                        />
                    </ResubscribeContainer>
                )}
            </ContentContainer>
        </Container>
    );
}

export default UnsubscribeResultView;

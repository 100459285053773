export enum EDITOR_TOOL {
    bold = 'Bold',
    italic = 'Italic',
    headerOne = 'Header 1',
    headerTwo = 'Header 2',
    underline = 'Underline',
    strikethrough = 'Strikethrough',
    bulletedList = 'Bulleted List',
    numberedList = 'Numbered List',
    quote = 'Quote',
    table = 'Table',
    checklist = 'Checklist',
    code = 'Code',
    divider = 'Divider',
    zoom = 'Zoom',
    figure = 'Figure',
    link = 'Link',
    mention = 'Mention',
    audioNote = 'Audio Note',
    sketch = 'Sketch',
    emoji = 'Emoji',
    youtube = 'YouTube',
    twitter = 'Twitter',
    spotify = 'Spotify',
    vimeo = 'Vimeo',
}

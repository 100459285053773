/* eslint-disable no-nested-ternary */
// ===== Packages =====

import styled, {
    css,
    FlattenSimpleInterpolation,
}                                   from 'styled-components';

// ===== Services =====

import {
    hexToRgb,
    setColorLightness,
    fontColorDiscriminator,
}                                   from '../services';

// ===== Iterfaces =====

import {
    ICursorActionCornerPosition,
}                                   from '../interfaces';

// ===== Constants =====

import {
    TAG_TEXT_LIGHTNESS_VALUE,
    MOVING_BUTTON_LIGHTNESS_VALUE,
    PAGE_LOGO_TRANSITION_DURATION,
    TAG_SECONDARY_TEXT_LIGHTNESS_VALUE,
    PAGE_TITLE_FONT_MULTIPLIER,
    PAGE_SUBTITLE_FONT_MULTIPLIER,
    PAGE_TITLE_MARGIN,
    PAGE_SUBTITLE_MARGIN,
    CURSOR_Z_INDEX,
    PAGE_LOGO_Z_INDEX,
    PAGE_TITLE_Z_INDEX,
    TAG_TEXT_THRESHOLD,
    TAG_MARGIN,
    TAG_SIDE_PADDING,
    PAGE_TITLE_TRANSITION_DURATION,
    PAGE_SUBTITLE_TRANSITION_DURATION,
    BUTTON_TEXT_LIGHTNESS_VALUE,
    NAVBAR_MARGIN_TOP_MULTIPLIER,
    CART_BUTTON_HEIGHT_MULTIPLIER,
    CART_ICON_CONTAINER_PADDING_MULTIPLIER,
    CART_ICON_MARGIN_MULTIPLIER,
    USER_PROFILE_DIALOG_RIGHT_CONTRACTED_MULTIPLIER,
    USER_PROFILE_AVATAR_LENGTH_MULTIPLIER,
    BODY_FONT_SIZE,
    BELL_BUTTON_HEIGHT_MULTIPLIER,
    NOTIFICATION_DIALOG_MARGIN_MULTIPLIER,
}                                   from '../constants/generalConstants';
import FONT_TYPE                    from '../constants/fontType';

const AUTHOR_RING_WIDTH = 2;
const SOUND_BUTTON_CONTAINER_PADDING = 5;

// ===== Styled Components =====

interface RectProps {
    dataName?: string,
    opacity?: number,
    strokeMiterLimit?: string
}
export const Rect = styled.rect<RectProps>``;

interface PathProps {
    dataName?: string,
    opacity?: number,
    strokeMiterLimit?: string
}
export const Path = styled.path<PathProps>``;

interface PolygonProps {
    dataName?: string,
    opacity?: number,
    strokeMiterLimit?: string
}
export const Polygon = styled.polygon<PolygonProps>``;

interface CircleProps {
    dataName?: string,
    opacity?: number,
    strokeMiterLimit?: string
}
export const Circle = styled.circle<CircleProps>``;

interface GroupProps {
    dataName?: string,
    opacity?: number,
    strokeMiterLimit?: string
}
export const Group = styled.g<GroupProps>``;

export const Container = styled.div`
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ theme }) => theme.verascopeColor.purple200};
    overflow: hidden;
    cursor: none;
`;

interface CustomCursorProps {
    isTouchDevice: boolean,
    isLocked: boolean,
    isHidden: boolean,
    length: number,
    background: string,
    backgroundTranslucency: number,
    borderWidth: number,
    lengthTransitionDuration: number,
    transitionDuration: number,
}
export const CustomCursor = styled.div<CustomCursorProps>`
    position: absolute;
    top: 0;
    left: 0;
    display: ${({ isTouchDevice }) => (!isTouchDevice ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    background: ${({ background, backgroundTranslucency }) => {
        const rgb = hexToRgb(background);
        if (rgb) {
            return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${backgroundTranslucency})`;
        }
        return 'transparent';
    }};
    border-radius: 100%;
    border-style: solid;
    border-color: ${({ theme }) => theme.color.neutral300};
    border-width: ${({ borderWidth }) => `${borderWidth}px`};
    opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
    pointer-events: none;
    z-index: ${CURSOR_Z_INDEX};
    transition: ${({
        transitionDuration,
        lengthTransitionDuration,
        isLocked,
        theme,
    }) => `
        background ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-color ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-width ${transitionDuration}ms ${theme.motion.eagerEasing},
        width ${lengthTransitionDuration}ms ${theme.motion.eagerEasing},
        height ${lengthTransitionDuration}ms ${theme.motion.eagerEasing}
        ${isLocked ? `, transform ${lengthTransitionDuration}ms ${theme.motion.eagerEasing}` : ''}
    `};

    & svg {
        fill: ${({ theme }) => theme.color.white};
    }
`;

interface CustomCursorSignProps {
    isVisible: boolean,
    small?: boolean,
    transitionDuration: number,
    transitionDelayDuration: number,
}
export const CustomCursorSign = styled.img<CustomCursorSignProps>`
    position: ${({ isVisible }) => (isVisible ? 'relative' : 'absolute')};
    width: ${({ isVisible, small }) => (isVisible
        ? `${small ? 0.9375 * BODY_FONT_SIZE : 1.5625 * BODY_FONT_SIZE}px`
        : '0px'
    )};
    height: ${({ isVisible, small }) => (isVisible
        ? `${small ? 0.9375 * BODY_FONT_SIZE : 1.5625 * BODY_FONT_SIZE}px`
        : '0px'
    )};
    pointer-events: none;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: ${({
        isVisible,
        transitionDuration,
        transitionDelayDuration,
        theme,
    }) => `
        opacity ${isVisible ? transitionDuration : 0}ms ${isVisible ? transitionDelayDuration : 0}ms ${theme.motion.standardEasing},
        width ${isVisible ? transitionDuration : 0}ms ${isVisible ? transitionDelayDuration : 0}ms ${theme.motion.standardEasing},
        height ${isVisible ? transitionDuration : 0}ms ${isVisible ? transitionDelayDuration : 0}ms ${theme.motion.standardEasing}
    `};
`;

interface SmallCursorActionContainerProps {
    corner: ICursorActionCornerPosition,
    length: number,
    isVisible: boolean,
    isGrabbing: boolean,
    isLineCursor: boolean,
    background: string,
    backgroundTranslucency: number,
    transitionDuration: number,
}
export const SmallCursorActionContainer = styled.div<SmallCursorActionContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: ${({ corner }) => (corner.top
        ? '0px'
        : 'auto'
    )};
    bottom: ${({ corner }) => (!corner.top
        ? '0px'
        : 'auto'
    )};
    right: ${({ corner }) => (!corner.left
        ? '0px'
        : 'auto'
    )};
    left: ${({ corner }) => (corner.left
        ? '0px'
        : 'auto'
    )};
    transform: ${({ isLineCursor, isGrabbing, corner }) => {
        let xSign = 1;
        let ySign = 1;

        if (
            (corner.top && !corner.left) // top-right
            || (corner.top && corner.left) // top-left
        ) {
            ySign = -1;
        }

        if (
            (corner.top && corner.left) // top-left
            || (!corner.top && corner.left) // bottom-left
        ) {
            xSign = -1;
        }

        if (isLineCursor || isGrabbing) {
            return `translate(${xSign < 0 ? '-' : ''}100%, ${ySign < 0 ? '-' : ''}100%)`;
        }

        // When in left, we want to shift it even more than when on right
        return `translate(${xSign < 0 ? '-' : ''}${xSign < 0 ? 10 : 8}0%, ${ySign < 0 ? '-' : ''}80%)`;
    }};
    width: ${({ isVisible, length }) => `${isVisible ? length : 0}px`};
    height: ${({ isVisible, length }) => `${isVisible ? length : 0}px`};
    background: ${({ background, backgroundTranslucency }) => {
        const rgb = hexToRgb(background);
        if (rgb) {
            return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${backgroundTranslucency})`;
        }
        return 'transparent';
    }};
    border-radius: 100%;
    border-style: solid;
    border-color: ${({ theme }) => theme.color.neutral300};
    border-width: ${({ isVisible }) => `${isVisible ? 1 : 0}px`};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    overflow: hidden;
    transition: ${({ transitionDuration, theme }) => `
        width ${transitionDuration}ms ${theme.motion.overshoot},
        height ${transitionDuration}ms ${theme.motion.overshoot},
        border-width ${transitionDuration}ms ${theme.motion.overshoot}
    `};
`;

interface CursorSightProps {
    isVisible: boolean,
    isTouchDevice: boolean,
}
export const CursorSight = styled.div<CursorSightProps>`
    display: ${({ isVisible, isTouchDevice }) => (isVisible && !isTouchDevice ? 'flex' : 'none')};
    position: fixed;
    width: ${`${0.3125 * BODY_FONT_SIZE}px`};
    height: ${`${0.3125 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.15625 * BODY_FONT_SIZE}px`};
    transform: translate(-50%, -50%);
    background: ${({ theme }) => theme.color.white};
    z-index: ${CURSOR_Z_INDEX};
    pointer-events: none;
`;

interface IconButtonProps {
    height?: number,
    detectTouchDevice: boolean,
    expandDuration?: number,
    fillDuration?: number,
}
export const IconButton = styled.svg<IconButtonProps>`
    height: ${({ height }) => `${height}px`};
    margin-left: ${`${0.625 * BODY_FONT_SIZE}px`};
    overflow: visible;

    & > path, & > rect {
        fill: ${({ theme }) => theme.verascopeColor.white};
        transform: scale(1);
        transform-origin: 50% 50% 0;
        transition: ${({
        expandDuration,
        fillDuration,
        theme,
    }) => `transform ${expandDuration}ms ${theme.motion.bounceEasing}, fill ${fillDuration}ms ${theme.motion.bounceEasing}`};
    }

    &:hover > path, &:hover > rect {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            fill: ${theme.verascopeColor.yellow200};
            transform: scale(1.5);
        `)}
    }
`;

interface SoundButtonOuterContainerProps {
    lightBackground?: boolean,
    userProfileDialogVisible: boolean,
    isRecognizedUser: boolean,
    userProfileDialogExpanded: boolean,
    notificationDialogExpanded: boolean,
    transitionDuration: number,
}
export const SoundButtonOuterContainer = styled.div<SoundButtonOuterContainerProps>`
    position: fixed;
    top: ${`${NAVBAR_MARGIN_TOP_MULTIPLIER - 0.1}em`};
    right: ${({
        userProfileDialogVisible,
        userProfileDialogExpanded,
        notificationDialogExpanded,
        isRecognizedUser,
    }) => {
        if (userProfileDialogVisible && isRecognizedUser) {
            // Authenticated User
            return `${2 * NOTIFICATION_DIALOG_MARGIN_MULTIPLIER + BELL_BUTTON_HEIGHT_MULTIPLIER + 2 * USER_PROFILE_DIALOG_RIGHT_CONTRACTED_MULTIPLIER + USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + CART_BUTTON_HEIGHT_MULTIPLIER + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER + CART_ICON_MARGIN_MULTIPLIER}em`;
        }
        if (userProfileDialogVisible && (userProfileDialogExpanded || notificationDialogExpanded) && !isRecognizedUser) {
            // Anonymous User with Dialog Expanded
            return `${USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + 1.25}em`;
        }

        if (userProfileDialogVisible && !(userProfileDialogExpanded || notificationDialogExpanded) && !isRecognizedUser) {
            // Anonymous User without Dialog Expanded
            return `${USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + 1}em`;
        }

        if (!(userProfileDialogVisible || !notificationDialogExpanded) && isRecognizedUser) {
            // Impossible
            throw Error('Encountered unexpected authentication state.');
        }

        // No user and no cart button
        return '0.25em';
    }};
    z-index: ${CURSOR_Z_INDEX - 2};
    padding: ${`${0.15625 * BODY_FONT_SIZE}px`};
    padding-bottom: 0px;
    transition: ${({ transitionDuration, theme }) => `
        top ${transitionDuration}ms ${theme.motion.eagerEasing},
        right ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
    background: ${({ lightBackground, theme }) => (lightBackground
        ? theme.color.white
        : 'transparent'
    )};
    border-radius: ${`${1.25 * BODY_FONT_SIZE}px`};
`;

interface SoundButtonInnerContainerProps {
    lightBackground?: boolean,
    height: number,
}
export const SoundButtonInnerContainer = styled.div<SoundButtonInnerContainerProps>`
    height: ${({ height }) => `${height + 2 * SOUND_BUTTON_CONTAINER_PADDING}px`};
    border-radius: ${({ height }) => `${(height + 2 * SOUND_BUTTON_CONTAINER_PADDING) / 2}px`};
    background: ${({ lightBackground, theme }) => (lightBackground
        ? theme.color.white
        : 'transparent'
    )};
`;

interface SoundButtonProps {
    dark?: boolean,
    height: number,
}
export const SoundButton = styled(IconButton)<SoundButtonProps>`
    margin-left: 0px;
    padding: ${`${SOUND_BUTTON_CONTAINER_PADDING}px`};
    height: ${({ height }) => `${height + 2 * SOUND_BUTTON_CONTAINER_PADDING}px`};

    & > path, & > rect {
        fill: ${({ theme, dark }) => (dark
        ? theme.verascopeColor.purple200
        : theme.color.white
    )};
    }
`;

interface PlaceholderBoxProps {
    width: number | string,
    height: number | string,
    margin?: string,
    padding?: string
}
export const PlaceholderBox = styled.div<PlaceholderBoxProps>`
    width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
    height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
    margin: ${({ margin }) => margin ?? '0px'};
    padding: ${({ padding }) => padding ?? '0px'};
    animation: placeholderShimmer 1s linear 0s infinite normal forwards, placeholderGlow 2s linear 0s infinite normal forwards;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    border-radius: ${`${0.15625 * BODY_FONT_SIZE}px`};
`;

interface PageLogoProps {
    dark?: boolean,
    withEnterTransition: boolean,
}
export const PageLogo = styled.div<PageLogoProps>`
    position: fixed;
    top: 0.625em;
    left: 1em;
    z-index: ${PAGE_LOGO_Z_INDEX};
    width: 2.5em;
    animation: ${({
        theme,
        withEnterTransition,
    }) => (withEnterTransition
        ? `fadeInPageLogo ${2 * PAGE_LOGO_TRANSITION_DURATION}ms ${theme.motion.eagerEasing}` /* Half is hidden, half is active */
        : 'none'
    )};
    transform-origin: center;
    transition: ${({ theme }) => `transform ${PAGE_LOGO_TRANSITION_DURATION}ms ${theme.motion.overshoot}`};

    ${({ dark, theme }) => dark && `
        & path {
            fill: ${theme.verascopeColor.purple200};
        }
    `}

    &:hover {
        transform: scale(1.2) !important; /* We use important because of Transition which hijacks this property */
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: 0.5em;
        width: 2.1875em;
    }
`;

interface PageTitleProps {
    color?: string,
    withEnterTransition?: boolean,
}
export const PageTitle = styled.h1<PageTitleProps>`
    position: absolute;
    top: ${PAGE_TITLE_MARGIN}px;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${PAGE_TITLE_Z_INDEX};
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: 1em;
    color: ${({ color, theme }) => color || theme.verascopeColor.white};
    line-height: ${PAGE_TITLE_FONT_MULTIPLIER}em;
    white-space: nowrap;
    user-select: none;
    text-align: center;
    margin: 0;
    animation: ${({
        theme,
        withEnterTransition,
    }) => (withEnterTransition
        ? `fadeInPageTitle ${PAGE_TITLE_TRANSITION_DURATION}ms ${theme.motion.eagerEasing}` /* 66% is hidden, 33% is active */
        : 'none'
    )};

    ${({ theme }) => theme.mediaQuery.small} {
        display: none;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: none;
    }
`;

interface PageSubtitleProps {
    color: string,
    withEnterTransition?: boolean,
}
export const PageSubtitle = styled.h3<PageSubtitleProps>`
    position: absolute;
    top: ${PAGE_SUBTITLE_MARGIN}px;
    left: 50%;
    transform: translateX(-50%);
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: 0.8em;
    color: ${({ color, theme }) => color || theme.verascopeColor.white};
    line-height: ${PAGE_SUBTITLE_FONT_MULTIPLIER}em;
    user-select: none;
    text-align: center;
    width: 50ch;
    max-width: 80%;
    margin: 0;
    animation: ${({
        theme,
        withEnterTransition,
    }) => (withEnterTransition
        ? `fadeInPageTitle ${PAGE_SUBTITLE_TRANSITION_DURATION}ms ${theme.motion.eagerEasing}` /* 66% is hidden, 33% is active */
        : 'none'
    )};

    ${({ theme }) => theme.mediaQuery.small} {
        top: ${PAGE_SUBTITLE_MARGIN + 10}px;
        font-size: 0.7em;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: ${PAGE_SUBTITLE_MARGIN + 10}px;
        font-size: 0.7em;
    }
`;

interface TagProps {
    floating?: boolean,
    solid?: boolean,
    center?: boolean,
    hover?: boolean,
    index: number,
    height: number,
    color?: string,
    responsiveText?: boolean,
    inactive?: boolean,
    transitionDuration: number,
}
export const Tag = styled.div<TagProps>`
    position: relative;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: ${({ center }) => (center
        ? 'center'
        : 'space-between'
    )};
    flex-direction: row;
    width: auto;
    height: ${({ height }) => `${height}px`};
    margin-bottom: ${TAG_MARGIN}px;
    margin-right: ${TAG_MARGIN}px;
    padding: ${`0px ${TAG_SIDE_PADDING}px`};
    background-color: ${({
        solid,
        color,
        inactive,
        theme,
    }) => {
        if (inactive) {
            return theme.color.neutral100;
        }

        if (solid && color) {
            return color;
        }

        return 'transparent';
    }};
    border: ${({
        solid,
        color,
        inactive,
        theme,
    }) => (solid || inactive
        ? 'none'
        : `${0.0625 * BODY_FONT_SIZE}px solid ${color || theme.color.neutral400}`
    )};
    border-radius:  ${({ height }) => `${height / 2}px`};
    box-shadow: ${({ floating, inactive, theme }) => (floating && !inactive
        ? theme.color.boxShadow100
        : '0 0px 0px 0px rgba(0, 64, 128, 0.2)'
    )};
    color: ${({
        solid,
        color,
        inactive,
        theme,
    }) => {
        if (inactive) {
            return theme.color.neutral600;
        }

        if (solid && color) {
            return fontColorDiscriminator(color);
        }

        if (solid && !color) {
            return theme.color.neutral900;
        }

        if (!solid && color) {
            return setColorLightness(
                color,
                TAG_TEXT_LIGHTNESS_VALUE,
            );
        }

        return theme.color.neutral900;
    }};
    cursor: ${({ inactive }) => (inactive ? 'not-allowed' : 'none')};
    transition: ${({
        theme,
        transitionDuration,
    }) => `box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing}`};

    & h3 {
        margin-right: ${({ center }) => `${center ? 5 : 0}px`};
    ${({ height, responsiveText }) => (height <= TAG_TEXT_THRESHOLD && responsiveText
        ? 'font-size: 0.65em'
        : ''
    )};
    }

    & h4 {
        color: ${({ solid, color, theme }) => (solid
        ? `${color
            ? fontColorDiscriminator(color)
            : theme.color.neutral700
        }`
        : `${color
            ? setColorLightness(
                color,
                TAG_SECONDARY_TEXT_LIGHTNESS_VALUE,
            )
            : theme.color.neutral700
        }`
    )};
    }
    
    & a {
        background: ${({
        solid,
        color,
        inactive,
        theme,
    }) => {
        if (inactive) {
            return theme.color.neutral100;
        }

        if (solid && color) {
            return color;
        }

        return 'transparent';
    }};
    }

    &:hover {
        box-shadow: ${({ floating, hover, theme }) => (floating
        ? `${hover ? theme.color.boxShadow300 : 'none'}`
        : `${hover ? '0 2px 8px -2px rgba(0, 64, 128, 0.2)' : 'none'}`
    )};
    }
`;

export const TagText = styled.h3`
    overflow: hidden;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.75em;
    font-weight: 500;
    white-space: nowrap;
    vertical-align: middle;
    margin: 0;
    margin-left: ${`${0.3125 * BODY_FONT_SIZE}px`};
`;

export const TagItemCount = styled.h4`
    margin: 0;
    margin-left: ${`${0.625 * BODY_FONT_SIZE}px`};
    margin-right: ${`${0.3125 * BODY_FONT_SIZE}px`};
    overflow: hidden;
    white-space: nowrap;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.75em;
    font-weight: 500;
    color: ${({ theme }) => theme.color.neutral700};
    vertical-align: middle;
`;

interface TagButtonProps {
    transitionDuration: number,
}
export const TagButton = styled.button<TagButtonProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${`${1.25 * BODY_FONT_SIZE}px`};
    height: ${`${1.25 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.625 * BODY_FONT_SIZE}px`};
    background: inherit;
    border: none;
    outline: none;
    padding: 0;
    margin-left: ${`${0.3125 * BODY_FONT_SIZE}px`};
    cursor: none;
    transition: ${({
        theme,
        transitionDuration,
    }) => `background ${transitionDuration}ms ${theme.motion.eagerEasing}`};

    & svg, & div {
        height: 100%;
        width: 100%;
    }

    & path {
        fill: ${({ theme }) => theme.color.white};
        transition: ${({
        theme,
        transitionDuration,
    }) => `fill ${transitionDuration}ms ${theme.motion.standardEasing}`};
    }

    &:hover {
        background: ${({ theme }) => `rgba(
            ${hexToRgb(theme.color.neutral300)!.r},
            ${hexToRgb(theme.color.neutral300)!.g},
            ${hexToRgb(theme.color.neutral300)!.b},
            0.2
        )`};

        & path {
            fill: ${({ theme }) => theme.color.white};
        }
    }
`;

interface TagButtonIconProps {
    transitionDuration: number,
}
export const TagButtonIcon = styled.svg<TagButtonIconProps>`
    height: ${`${0.75 * BODY_FONT_SIZE}px`};
    width: ${`${0.75 * BODY_FONT_SIZE}px`};
    fill: ${({ theme }) => theme.color.white};
    transition: ${({
        theme,
        transitionDuration,
    }) => `fill ${transitionDuration}ms ${theme.motion.standardEasing}`};

    & path {
        transition: ${({
        theme,
        transitionDuration,
    }) => `fill ${transitionDuration}ms ${theme.motion.standardEasing}`};
    }
`;

interface MovingButtonBackgroundProps {
    index: number,
    visible: boolean,
    top: number | null,
    solid: boolean,
    buttonWidth: number,
    buttonHeight: number,
    xOffset?: number,
    spacing?: number,
    animation: FlattenSimpleInterpolation | null,
    color: string,
    zIndex?: number,
    invertOnSmallScreens?: boolean,
    disabled?: boolean,
    transitionDuration: number,
}
export const MovingButtonBackground = styled.div<MovingButtonBackgroundProps>`
    position: absolute;
    top: ${({ top }) => `${top || 0}px`};
    left: ${({
        index,
        buttonWidth,
        xOffset = 0,
        spacing = 0,
    }) => `${(index * (buttonWidth + spacing) + xOffset)}px`};
    height: ${({ buttonHeight }) => `${buttonHeight}px`};
    width: ${({ buttonWidth }) => `${buttonWidth}px`};
    border-radius: ${({ buttonHeight }) => `${buttonHeight}px`};
    background: none;
    margin: ${`0px ${0.15625 * BODY_FONT_SIZE}px`};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    z-index: ${({ zIndex }) => zIndex || 0};
    animation: ${({ animation }) => (animation || 'none')};
    ${({ color, solid }) => color && !solid && `
        border: ${`${0.0625 * BODY_FONT_SIZE}px solid ${setColorLightness(color, MOVING_BUTTON_LIGHTNESS_VALUE)}`};
    `};
    ${(({
        color,
        solid,
        disabled,
        theme,
    }) => {
        if (color && solid && !disabled) {
            return `
               background: ${color};
           `;
        }

        if (color && solid && disabled) {
            return `
               background: ${theme.color.neutral400};
           `;
        }
    })};
    transition: ${({
        theme,
        transitionDuration,
    }) => `
        background ${transitionDuration}ms ${theme.motion.standardEasing}
    `};

    ${({
        invertOnSmallScreens,
        theme,
        index,
        buttonWidth,
        xOffset = 0,
        spacing = 0,
    }) => invertOnSmallScreens && `
        ${theme.mediaQuery.small} {
        left: auto;
        right: ${(index * (buttonWidth + spacing) + xOffset)}px;
    }`}
`;

interface SlateLeafProps {
    bold: boolean,
    italics: boolean,
    underline: boolean,
    strikethrough: boolean,
    highlightColor?: string,
    highlightDuration: boolean,
}
export const SlateLeaf = styled.span<SlateLeafProps>`
    font-weight: ${({ bold }) => (bold ? 700 : 'inherit')};
    font-style: ${({ italics }) => (italics ? 'italic' : 'normal')};
    text-decoration-line: ${({ underline, strikethrough }) => {
        let decoration = '';
        if (underline) {
            decoration += 'underline';
        }

        if (strikethrough) {
            decoration = decoration.length > 0 ? `${decoration} line-through` : 'line-through';
        }

        return decoration.length > 0 ? decoration : 'none';
    }};
    color: ${({ highlightColor, theme }) => (!!highlightColor
        && fontColorDiscriminator(highlightColor) === theme.color.white
        ? theme.color.white
        : 'inherit'
    )};
    background: ${({ highlightColor }) => (highlightColor || 'none')};
    transition: ${({ highlightDuration, theme }) => `
        background ${highlightDuration}ms ${theme.motion.standardEasing}
    `};
`;

interface SlatePlaceholderProps {
    fontMultiplier: number,
}
export const SlatePlaceholder = styled.div<SlatePlaceholderProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 400;
    color: ${({ theme }) => theme.color.neutral800};
    margin-top: ${`${0.1875 * BODY_FONT_SIZE}px`};
`;

export const DropzoneInput = styled.input``;

export const basicEditorFontStyles = css`
    font-family: ${FONT_TYPE.IOWAN_OLD_STYLE};
    font-size: 1.4em;
    font-weight: 400;
    line-height: 1.7em;
    color: ${({ theme }) => theme.color.purple100};
`;

export const OptionsMenuContainer = styled.div`
    position: relative;
`;

interface GenericButtonProps {
    background: string | undefined,
    height: number | null,
    hasIcon: boolean,
    hasText: number,
    loading: boolean,
}
export const GenericButton = styled.button<GenericButtonProps>`
    display: inline-flex;
    flex-direction: column;
    height: ${`${2.5 * BODY_FONT_SIZE}px`};
    border-radius: ${`${1.25 * BODY_FONT_SIZE}px`};
    text-align: center;
    background-color:
    ${({ theme, background }) => background || theme.color.neutral800};
    border: none;
    padding: 0;
    position: relative;
    cursor: none;
    outline: none;
    text-decoration: none;
    user-select: none;
    transition: ${({ theme }) => `
        background-color 0.3s ${theme.motion.eagerEasing},
        border 0.3s,
        box-shadow 0.3s
    `};

  & > a {
      height: ${({ height }) => `${height}px`};
  }

  ${({
        hasIcon,
        hasText,
        height,
        loading,
    }) => hasIcon && hasText && `
        & section {
            padding: 0px ${height ? (loading ? height / 2 : height / 3) : 0}px;
            padding-right: ${height ? height / 2 : 0}px;

            & > div:first-of-type {
                margin-right: 5px;
            }

            & > img:first-of-type {
                margin-right: ${loading ? 0 : 5}px;
            }
        }
  `}

  ${({ hasIcon, hasText, height }) => hasIcon && !hasText && `
        width: ${height}px;
        justify-content: center;

        & section {
            width: ${height}px;
            padding: ${height ? height / 6 : 0}px;
            justify-content: center;
        }
  `}

  ${({ hasIcon, hasText, height }) => !hasIcon && hasText && `
        & section {
            padding: 0px ${height ? height / 2 : 0}px;
        }
  `}
`;

export const PlaceholderContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
`;

export const PlaceholderImageContainer = styled.div`
    width: ${`${3.125 * BODY_FONT_SIZE}px`};
    opacity: 0.8;

    & div {
        width: 100%;
        height: 100%;
    }

    & svg {
        height: 100%;
        width: 100%;
        fill: ${({ theme }) => theme.color.neutral600};
        transition: fill 0.3s;
    }
`;

export const PlaceholderHeading = styled.div`
    margin-top: ${`${0.625 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1.5em;
    font-weight: 700;
    color: ${({ theme }) => theme.color.neutral800};
    width: 30ch;
`;

export const PlaceholderSubtitle = styled.div`
    display: block;
    margin-top: ${`${1.25 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1em;
    font-weight: 400;
    color: ${({ theme }) => theme.color.neutral600};
    text-transform: none;
    pointer-events: none;
    max-width: 60ch;

    & > a {
        display: block;
        font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
        font-size: 1em;
        font-weight: 500;
        color: ${({ color, theme }) => color || theme.color.neutral600};
        text-transform: none;
        transition: color 0.3s;

        &:hover {
        color: ${({ color, theme }) => (color
        ? setColorLightness(color, BUTTON_TEXT_LIGHTNESS_VALUE)
        : setColorLightness(theme.color.neutral600, BUTTON_TEXT_LIGHTNESS_VALUE)
    )};
        }
    }
`;

interface DetailViewProps {
    fixed?: boolean,
    width: number,
    height?: number,
    zIndex: number,
    isVisible: boolean,
    isSteering: boolean,
    showWarning: boolean,
    background: string,
    transitionDuration: number,
}
export const DetailView = styled.div<DetailViewProps>`
    position: ${({ fixed }) => (fixed
        ? 'fixed'
        : 'relative'
    )};
    top: ${({ fixed }) => `${fixed ? '0px' : 'auto'}`};
    right: ${({ fixed }) => `${fixed ? '0px' : 'auto'}`};
    height: ${({ isVisible, height }) => (isVisible && height
        ? `${height}px`
        : '100vh'
    )};
    width: ${({ isVisible, width }) => (isVisible
        ? `${width}px`
        : '0px'
    )};
    background: ${({ showWarning, background, theme }) => (showWarning
        ? theme.verascopeColor.red200
        : background
    )};
    z-index: ${({ zIndex, fixed }) => (fixed ? zIndex : 'auto')};
    transition: ${({
        theme,
        isSteering,
        transitionDuration,
    }) => `background ${transitionDuration}ms ${theme.motion.eagerEasing}${isSteering
        ? ''
        : `, width ${transitionDuration}ms ${theme.motion.overshoot}`}
    `};
`;

export const AvatarsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    & > div {
        margin-right: ${`${0.3125 * BODY_FONT_SIZE}px`};
    }
`;

interface AvatarContainerProps {
    length: number,
    withRing?: boolean,
    hasAvatarImage: boolean,
}
export const AvatarContainer = styled.div<AvatarContainerProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ length, withRing }) => `${withRing ? length + AUTHOR_RING_WIDTH : length}px`};
    height: ${({ length, withRing }) => `${withRing ? length + AUTHOR_RING_WIDTH : length}px`};
    box-shadow: 0 0.5px 1.5px rgba(0,0,0,.12), 0 0.5px 1px rgba(0,0,0,.24);
    background: ${({ theme }) => theme.verascopeColor.purple400};
    border: ${({ withRing, theme }) => (withRing
        ? `${AUTHOR_RING_WIDTH}px solid ${theme.verascopeColor.orange200}`
        : 'none'
    )};
    border-radius: ${({ length, withRing }) => `${withRing ? (length + AUTHOR_RING_WIDTH) / 2 : length / 2}px`};

    & svg, & div {
        position: relative;
        width: ${({ hasAvatarImage, length }) => `${hasAvatarImage ? length : length - 5}px`};
        height: ${({ hasAvatarImage, length }) => `${hasAvatarImage ? length : length - 5}px`};
    }

    & path {
        fill: ${({ theme }) => theme.color.white};
    }
`;

interface UserAvatarProps {
    url: string,
    length: number,
}
export const UserAvatar = styled.div<UserAvatarProps>`
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    border-radius: ${({ length }) => `${length / 2}px`};
    background-image: ${({ url }) => (url ? `url(${url})` : 'none')};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

interface OverlayProps {
    zIndex?: number,
}
export const Overlay = styled.div<OverlayProps>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${({ zIndex }) => zIndex || 'auto'};
    cursor: none;
    ${({ theme }) => (() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const rgbColor = hexToRgb(theme.verascopeColor.purple100)!;
        if (CSS.supports('backdrop-filter: blur(5px)')) {
            return `
                backdrop-filter: blur(5px);
                background-color: rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.3);
            `;
        }

        return `background-color: rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.3);`;
    })()};
`;

interface HandlebarProps {
    width: number,
    height: number,
    isSteering: boolean,
    transitionDuration: number,
}
export const Handlebar = styled.div<HandlebarProps>`
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border-radius: ${({ width }) => `${width / 2}px`};
    background: #C5CDD1;
    transition: ${({ theme, transitionDuration }) => `background ${transitionDuration}ms ${theme.motion.eagerEasing}`};

    ${({ theme }) => theme.mediaQuery.small} {
        background: none;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        background: none;
    }
`;

interface HandlebarContainerProps {
    width: number,
    height?: string,
    isSteering: boolean,
    background: string,
    steeringBackground: string,
    hoverBackground: string,
    steeringHoverBackground: string,
    transitionDuration: number,
}
export const HandlebarContainer = styled.div<HandlebarContainerProps>`
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    justify-content: center;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => (height || '100vh')};
    background: ${({
        isSteering,
        background,
        steeringBackground,
    }) => (isSteering ? steeringBackground : background)};
    transition: ${({ theme, transitionDuration }) => `background ${transitionDuration}ms ${theme.motion.standardEasing}`};

    &:hover {
        background: ${({
        isSteering,
        steeringHoverBackground,
        hoverBackground,
    }) => (isSteering
        ? steeringHoverBackground
        : hoverBackground)};

        & > ${Handlebar} {
            background: ${({ theme, isSteering }) => (isSteering
        ? theme.color.neutral300
        : theme.verascopeColor.white)};
        }
    }

    ${({ theme }) => theme.mediaQuery.small} {
        background: none;

        &:hover {
            background: none;

            & > ${Handlebar} {
                background: none;
            }
        }
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        background: none;
        width: 0px;

        &:hover {
            background: none;

            & > ${Handlebar} {
                background: none;
            }
        }
    }
`;

// ===== Packages =====

import {
    doc,
    setDoc,
    getFirestore,
}                                       from 'firebase/firestore';

// ===== Interfaces =====

import {
    INotificationItem,
}                                       from '../interfaces';

// ===== Enums =====

import {
    FIRESTORE_COLLECTION,
}                                       from '../enums';

interface SetNotificationOptions {
    notification: INotificationItem,
}
const setNotificationInDB = async ({ notification }: SetNotificationOptions): Promise<void> => {
    const db = getFirestore();
    const collection = process.env.NODE_ENV === 'production'
        ? FIRESTORE_COLLECTION.notifications
        : FIRESTORE_COLLECTION.stagingNotifications;
    await setDoc(doc(db, collection, notification.id), notification);
};

export default setNotificationInDB;

export enum UNSUBSCRIBE_STATUS_TYPE {
    uninitiated = 'uninitiated', // Unsubscribe request has not been made
    stillSubscribed = 'still-subscribed', // User still subscribed after initiating unsubscribe request
    missingProperty = 'missing-property', // property not present in User Item
    missingType = 'missing-type', // type of unsubscribe request not specified
    success = 'success', // User successfully unsubscribed
    userNotFound = 'user-not-found', // User with User ID not in database
    timeout = 'timeout', // User ID not within expected timeframe
    uncaughtError = 'uncaught-error', // Unknown error encountered
}

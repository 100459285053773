// ===== Packages =====

import { createGlobalStyle }                from 'styled-components';

// ===== Constants =====

import {
    CURSOR_Z_INDEX,
    DEFAULT_FONT_SIZE,
}                                           from '../constants/generalConstants';

// ===== Fonts =====

// Importing: https://lewislbr.dev/blog/add-fonts-react-typescript-styled-components-webpack/
import PressStart2P                         from '../fonts/press-start-2p-regular.ttf';
import FuturaHandwritten                    from '../fonts/FuturaHandwritten.ttf';

// Weight Names: https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight

import PlusJakartaSansExtraBold             from '../fonts/plus-jakarta-sans/PlusJakartaSans-ExtraBold.ttf';
import PlusJakartaSansBold                  from '../fonts/plus-jakarta-sans/PlusJakartaSans-Bold.ttf';
import PlusJakartaSansSemiBold              from '../fonts/plus-jakarta-sans/PlusJakartaSans-SemiBold.ttf';
import PlusJakartaSansMedium                from '../fonts/plus-jakarta-sans/PlusJakartaSans-Medium.ttf';
import PlusJakartaSansRegular               from '../fonts/plus-jakarta-sans/PlusJakartaSans-Regular.ttf';
import PlusJakartaSansLight                 from '../fonts/plus-jakarta-sans/PlusJakartaSans-Light.ttf';
import PlusJakartaSansExtraLight            from '../fonts/plus-jakarta-sans/PlusJakartaSans-ExtraLight.ttf';
import PlusJakartaSansExtraBoldItalic       from '../fonts/plus-jakarta-sans/PlusJakartaSans-ExtraBoldItalic.ttf';
import PlusJakartaSansBoldItalic            from '../fonts/plus-jakarta-sans/PlusJakartaSans-BoldItalic.ttf';
import PlusJakartaSansSemiBoldItalic        from '../fonts/plus-jakarta-sans/PlusJakartaSans-SemiBoldItalic.ttf';
import PlusJakartaSansMediumItalic          from '../fonts/plus-jakarta-sans/PlusJakartaSans-MediumItalic.ttf';
import PlusJakartaSansItalic                from '../fonts/plus-jakarta-sans/PlusJakartaSans-Italic.ttf';
import PlusJakartaSansLightItalic           from '../fonts/plus-jakarta-sans/PlusJakartaSans-LightItalic.ttf';
import PlusJakartaSansExtraLightItalic      from '../fonts/plus-jakarta-sans/PlusJakartaSans-ExtraLightItalic.ttf';

import IowanOldStyleBlack                   from '../fonts/iowan-old-style/IowanOldStyle-Black.otf';
import IowanOldStyleBold                    from '../fonts/iowan-old-style/IowanOldStyle-Bold.otf';
import IowanOldStyleRoman                   from '../fonts/iowan-old-style/IowanOldStyle-Roman.otf';
import IowanOldStyleBlackItalic             from '../fonts/iowan-old-style/IowanOldStyle-BlackItalic.otf';
import IowanOldStyleBoldItalic              from '../fonts/iowan-old-style/IowanOldStyle-BoldItalic.otf';
import IowanOldStyleItalic                  from '../fonts/iowan-old-style/IowanOldStyle-Italic.otf';

export const GlobalStyle = createGlobalStyle`
    :root {
        --default_font_size: ${DEFAULT_FONT_SIZE}px;
    }

    @property --cartridgeFilterProgressPercentage {
        syntax: '<integer>';
        initial-value: 100;
        inherits: false;
    }  

    @property --cartItemCounter {
        syntax: '<integer>';
        initial-value: 0;
        inherits: false;
    } 

    * {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
    }

    // Reference: https://css-tricks.com/snippets/jquery/smooth-scrolling/
    html {
        scroll-behavior: smooth;
    }

    body {
        font-size: var(--default_font_size);
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        overflow: hidden;
    }


    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    
    .ReactVirtualized__Grid {
        padding-bottom: 100px;
        overflow: visible scroll !important;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
        overflow: visible !important;
    }

    @media (min-width: 0px) and (max-width: 991px) {
        .ReactVirtualized__Grid {
            overflow: hidden auto !important;
        }

        .ReactVirtualized__Grid__innerScrollContainer {
            overflow: hidden !important;
        }
    }

    .detail-sheet {
        pointer-events: auto !important;
        z-index: ${CURSOR_Z_INDEX - 1} !important;
        cursor: none;
    }

    /* https://stackoverflow.com/questions/35151887/how-to-create-placeholder-while-loading-like-facebook */
    /* https://cloudcannon.com/blog/how-the-facebook-content-placeholder-works */
    @keyframes placeholderShimmer {
        0% {
            background-position: -468px 0px;
        }
        100% {
            background-position: 468px 0px;
        }
    }

    @keyframes placeholderGlow {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.3;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        30% {
            transform: scale(1);
        }
        40% {
            transform: scale(1.16);
        }
        50% {
            transform: scale(1);
        }
        60% {
            transform: scale(1);
        }
        70% {
            transform: scale(1.1);
        }
        80% {
            transform: scale(1);
        }
        100% {
            transform: scale(1);
        }
    }
    
    @keyframes shake {
        10%, 90% {
            transform: translate3d(-0.5px, 0, 0);
        }

        20%, 80% {
            transform: translate3d(1px, 0, 0);
        }

        30%, 50%, 70% {
            transform: translate3d(-2px, 0, 0);
        }

        40%, 60% {
            transform: translate3d(2px, 0, 0);
        }
    }

    @keyframes bounce {
        0% {
            transform: translateX(0px);
        }

        25% {
            transform: translateX(3px);
        }

        50% {
            transform: translateX(0px);
        }

        75% {
            transform: translateX(3px);
        }

        100% {
            transform: translateX(0px);
        }
    }

    @keyframes fadeInPageLogo {
        0% {
            opacity: 0;
            transform: translateX(-20px);
        }

        50% {
            opacity: 0;
            transform: translateX(-20px);
        }

        100% {
            opacity: 1;
            transform: translateX(0px);
        }
    }

    @keyframes fadeInPageTitle {
        0% {
            opacity: 0;
            transform: translate(-50%, 20px);
        }

        66% {
            opacity: 0;
            transform: translate(-50%, 20px);
        }

        100% {
            opacity: 1;
            transform: translate(-50%, 0px);
        }
    }

    @keyframes scrollTextLeft {
        0% {
            transform: translateX(110%);
        }
        100% {
            transform: translateX(-110%);
        }
    }

    @keyframes scale {
        0% {
            transform: scale(1);
            opacity: 1;
        } 45% {
            transform: scale(0.1);
            opacity: 0.7;
        } 80% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes flicker {
        0% {
            opacity: 0;
        }
        44% {
            opacity: 0.8;
        }
        89% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @font-face {
        font-family: 'PressStart2P';
        font-weight: 400;
        src: url(${PressStart2P});
    }

    @font-face {
        font-family: 'FuturaHandwritten';
        font-weight: 400;
        src: url(${FuturaHandwritten});
    }

    @font-face {
        font-family: 'Iowan Old Style';
        font-weight: 1000;
        src: url(${IowanOldStyleBlack}) format('otf');
    }

    @font-face {
        font-family: 'Iowan Old Style';
        font-weight: 1000;
        font-style: italic;
        src: url(${IowanOldStyleBlackItalic}) format('otf');
    }

    @font-face {
        font-family: 'Iowan Old Style';
        font-weight: 700;
        src: url(${IowanOldStyleBold}) format('otf');
    }

    @font-face {
        font-family: 'Iowan Old Style';
        font-weight: 700;
        font-style: italic;
        src: url(${IowanOldStyleBoldItalic}) format('otf');
    }

    @font-face {
        font-family: 'Iowan Old Style';
        font-weight: 400;
        src: url(${IowanOldStyleRoman}) format('otf');
    }

    @font-face {
        font-family: 'Iowan Old Style';
        font-weight: 400;
        font-style: italic;
        src: url(${IowanOldStyleItalic}) format('otf');
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 800;
        src: url(${PlusJakartaSansExtraBold});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 800;
        font-style: italic;
        src: url(${PlusJakartaSansExtraBoldItalic});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 800;
        src: url(${PlusJakartaSansExtraBold});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 800;
        font-style: italic;
        src: url(${PlusJakartaSansExtraBoldItalic});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 700;
        src: url(${PlusJakartaSansBold});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 700;
        font-style: italic;
        src: url(${PlusJakartaSansBoldItalic});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        src: url(${PlusJakartaSansSemiBold});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-style: italic;
        src: url(${PlusJakartaSansSemiBoldItalic});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        src: url(${PlusJakartaSansMedium});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-style: italic;
        src: url(${PlusJakartaSansMediumItalic});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        src: url(${PlusJakartaSansRegular});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-style: italic;
        src: url(${PlusJakartaSansItalic});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 300;
        src: url(${PlusJakartaSansLight});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 300;
        font-style: italic;
        src: url(${PlusJakartaSansLightItalic});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 200;
        src: url(${PlusJakartaSansExtraLight});
    }

    @font-face {
        font-family: 'Plus Jakarta Sans';
        font-weight: 200;
        font-style: italic;
        src: url(${PlusJakartaSansExtraLightItalic});
    }
`;

// ===== Packages =====

import styled               from 'styled-components';

// ===== Services =====

import {
    hexToRgb,
    setColorLightness,
}                           from '../../services';

// ===== Enums =====

import {
    ZOOM_LEVEL,
}                           from '../Editor/elements/enums';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
    CURSOR_Z_INDEX,
    POST_BANNER_Z_INDEX,
    USER_PROFILE_DIALOG_Z_INDEX,
    POST_EDITOR_MAX_WIDTH,
    POST_TITLE_MARGIN_BOTTOM,
    POST_TITLE_MARGIN_TOP,
    POST_SUBTITLE_MARGIN,
    POST_AUTHOR_MARGIN_LEFT,
    POST_DETAIL_TEXT_MARGIN_LEFT,
    POST_CONTENT_LEFT_CONTAINER_PADDING_LEFT,
    POST_CONTENT_LEFT_CONTAINER_WIDTH_MEDIUM,
    POST_CONTENT_LEFT_CONTAINER_PADDING_RIGHT_MEDIUM,
    POST_CONTENT_LEFT_CONTAINER_WIDTH_SMALL,
    POST_CONTENT_LEFT_CONTAINER_PADDING_LEFT_SMALL,
    POST_CONTENT_LEFT_CONTAINER_PADDING_LEFT_590,
    POST_CONTENT_LEFT_BANNER_CONTAINER_WIDTH_590,
    POST_CONTENT_LEFT_CONTAINER_PADDING_RIGHT_590,
    POST_CONTENT_LEFT_CONTAINER_PADDING_RIGHT_SMALL,
    POST_CONTENT_RIGHT_BANNER_CONTAINER_WIDTH_590,
    POST_CONTENT_LEFT_CONTAINER_MARGIN_MEDIUM,
    POST_CONTENT_LEFT_CONTENT_CONTAINER_WIDTH_590,
    POST_THUMBNAIL_MAX_WIDTH_MEDIUM,
    POST_THUMBNAIL_MAX_WIDTH_SMALL,
    SUCCESS_BACKGROUND_LIGHTNESS_VALUE,
    ERROR_BACKGROUND_LIGHTNESS_VALUE,
    MODAL_SHEET_CLOSE_BUTTON_CONTAINER_TOP,
    MODAL_SHEET_CLOSE_BUTTON_LENGTH,
    POST_PAYWALL_Z_INDEX,
}                           from '../../constants/generalConstants';
import FONT_TYPE            from '../../constants/fontType';

interface ContainerProps {
    hasSuccess: boolean,
    hasError: boolean,
}
export const Container = styled.div<ContainerProps>`
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background-color: ${({
        hasSuccess,
        hasError,
        theme,
    }) => {
        if (hasError) {
            return setColorLightness(
                theme.color.error,
                ERROR_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        if (hasSuccess) {
            return setColorLightness(
                theme.verascopeColor.blue200,
                SUCCESS_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        return theme.color.white;
    }};
`;

interface PostContentContainerProps {
    isSteering: boolean,
    transitionDuration: number,
}
export const PostContentContainer = styled.div<PostContentContainerProps>`
    position: relative;
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background-color: inherit;
    overflow-y: scroll;
    transition: ${({
        theme,
        isSteering,
        transitionDuration,
    }) => `background ${transitionDuration}ms ${theme.motion.eagerEasing}${isSteering
        ? ''
        : `, width ${transitionDuration}ms ${theme.motion.overshoot}`}`};
`;

interface PostBannerProps {
    height: number,
    stickyPostBannerActive: boolean,
    transitionDuration: number,
}
export const PostBanner = styled.div<PostBannerProps>`
    position: relative; // Modified directly using ref in ReaderView
    top: auto; // Modified directly using ref in ReaderView
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    background: ${({ stickyPostBannerActive, theme }) => (stickyPostBannerActive
        ? `rgba(${
            hexToRgb(theme.verascopeColor.purple200)!.r
        }, ${
            hexToRgb(theme.verascopeColor.purple200)!.g
        }, ${
            hexToRgb(theme.verascopeColor.purple200)!.b
        }, 0.0)`
        : theme.verascopeColor.purple200
    )};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    width: 100%;
    height: ${({ height }) => `${height}px`};
    transform: none; // Modified directly using ref in ReaderView
    z-index: ${POST_BANNER_Z_INDEX};
    transition: ${({ theme, transitionDuration }) => `
        background ${transitionDuration}ms ${theme.motion.eagerEasing},
        top ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
    ${() => (() => {
        if (CSS.supports('backdrop-filter: blur(5px)')) {
            return 'backdrop-filter: blur(5px);';
        }
    })()};

    ${({ theme }) => theme.mediaQuery.medium} {
        justify-content: flex-start;
    }

    ${({ theme }) => theme.mediaQuery.small} {
        justify-content: flex-start;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        justify-content: flex-start;
    }
`;

interface PostBannerLeftContainerProps {
    width: number,
    marginRight: number,
    heightExpanded: number,
    paddingExpanded: number,
}
export const PostBannerLeftContainer = styled.div<PostBannerLeftContainerProps>`
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: ${({ width, marginRight }) => `${width + marginRight}px`};
    height: ${({ heightExpanded }) => `${heightExpanded}px`}; // Modified directly using ref in ReaderView
    padding: ${({ paddingExpanded }) => `${paddingExpanded}px`}; // Modified directly using ref in ReaderView
    padding-left: ${`${POST_CONTENT_LEFT_CONTAINER_PADDING_LEFT}px`};
    padding-right: ${({ paddingExpanded }) => `${paddingExpanded}px`};

    ${({ theme }) => theme.mediaQuery.medium} {
        width: ${`${POST_CONTENT_LEFT_CONTAINER_WIDTH_MEDIUM}px`};
        padding-right: ${`${POST_CONTENT_LEFT_CONTAINER_PADDING_RIGHT_MEDIUM}px`};
    }

    ${({ theme }) => theme.mediaQuery.small} {
        width: ${`${POST_CONTENT_LEFT_CONTAINER_WIDTH_SMALL}px`};
        padding-left: ${`${POST_CONTENT_LEFT_CONTAINER_PADDING_LEFT_SMALL}px`};
        padding-right: ${`${POST_CONTENT_LEFT_CONTAINER_PADDING_RIGHT_SMALL}px`};
        align-items: flex-end;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: ${`${POST_CONTENT_LEFT_CONTAINER_WIDTH_SMALL}px`};
        padding-left: ${`${POST_CONTENT_LEFT_CONTAINER_PADDING_LEFT_SMALL}px`};
        padding-right: ${`${POST_CONTENT_LEFT_CONTAINER_PADDING_RIGHT_SMALL}px`};
        align-items: flex-end;
    }

    @media (max-width: 590px) {
        width: ${`${POST_CONTENT_LEFT_BANNER_CONTAINER_WIDTH_590}px`};
        padding-right: ${`${POST_CONTENT_LEFT_CONTAINER_PADDING_RIGHT_590}px`};
        padding-left: ${`${POST_CONTENT_LEFT_CONTAINER_PADDING_LEFT_590}px`};
    }
`;

interface PostBannerCenterContainerProps {
    height: number;
    postBannerLeftContainerWidth: number,
    postBannerRightContainerWidth: number,
}
export const PostBannerCenterContainer = styled.div<PostBannerCenterContainerProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: ${({
        postBannerLeftContainerWidth,
        postBannerRightContainerWidth,
    }) => `calc(100vw - (${postBannerLeftContainerWidth}px + ${postBannerRightContainerWidth}px))`};
    /* max-width: ${`${POST_EDITOR_MAX_WIDTH}px`}; */
    height: ${({ height }) => `${height}px`};
    /* margin: 0 auto; */

    ${({ theme }) => theme.mediaQuery.medium} {
        width: ${({
        postBannerRightContainerWidth,
    }) => `calc(100vw - (${POST_CONTENT_LEFT_CONTAINER_WIDTH_MEDIUM}px + ${postBannerRightContainerWidth}px))`};
    }

    ${({ theme }) => theme.mediaQuery.small} {
        width: ${({
        postBannerRightContainerWidth,
    }) => `calc(100vw - (${POST_CONTENT_LEFT_CONTAINER_WIDTH_SMALL}px + ${postBannerRightContainerWidth}px))`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: ${({
        postBannerRightContainerWidth,
    }) => `calc(100vw - (${POST_CONTENT_LEFT_CONTAINER_WIDTH_SMALL}px + ${postBannerRightContainerWidth}px))`};
    }

    @media (max-width: 590px) {
        width: ${`calc(100vw - (${POST_CONTENT_LEFT_BANNER_CONTAINER_WIDTH_590}px + ${POST_CONTENT_RIGHT_BANNER_CONTAINER_WIDTH_590}px))`};
        margin: ${`0px ${1.5625 * BODY_FONT_SIZE}px`};
    }
`;

interface PostBannerRightContainerProps {
    annotationViewWidth: number,
}
export const PostBannerRightContainer = styled.div<PostBannerRightContainerProps>`
    position: relative;
    width: ${({ annotationViewWidth }) => `${annotationViewWidth}px`};

    @media (max-width: 590px) {
        width: ${`${POST_CONTENT_RIGHT_BANNER_CONTAINER_WIDTH_590}px`};
    }
`;

interface PostContentProps {
    adjustMarginTop: number,
}
export const PostContent = styled.div<PostContentProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: ${({ adjustMarginTop }) => `${adjustMarginTop}px`};
    background: inherit;
`;

interface PostContentLeftContainerProps {
    width: number,
    marginRight: number,
}
export const PostContentLeftContainer = styled.div<PostContentLeftContainerProps>`
    position: relative;
    width: ${({ width, marginRight }) => `${width + marginRight}px`};
    height: fit-content;
    padding-right: ${({ marginRight }) => `${marginRight}px`};

    ${({ theme }) => theme.mediaQuery.medium} {
        width: ${`${POST_CONTENT_LEFT_CONTAINER_WIDTH_MEDIUM}px`};
        padding-right: ${`${POST_CONTENT_LEFT_CONTAINER_MARGIN_MEDIUM}px`};
    }

    ${({ theme }) => theme.mediaQuery.small} {
        width: ${`${POST_CONTENT_LEFT_CONTAINER_WIDTH_SMALL}px`};
        padding-right: ${`${POST_CONTENT_LEFT_CONTAINER_MARGIN_MEDIUM}px`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: ${`${POST_CONTENT_LEFT_CONTAINER_WIDTH_SMALL}px`};
        padding-right: ${`${POST_CONTENT_LEFT_CONTAINER_MARGIN_MEDIUM}px`};
    }

    @media (max-width: 590px) {
        width: ${`${POST_CONTENT_LEFT_CONTENT_CONTAINER_WIDTH_590}px`};
        padding-right: ${`${POST_CONTENT_LEFT_CONTAINER_MARGIN_MEDIUM}px`};
    }
`;

interface PostContentCenterContainerProps {
    readOnly: boolean,
    postBannerLeftContainerWidth: number,
    postBannerRightContainerWidth: number,
}
export const PostContentCenterContainer = styled.div<PostContentCenterContainerProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: inherit;
    width: ${({
        postBannerLeftContainerWidth,
        postBannerRightContainerWidth,
    }) => `calc(100vw - (${postBannerLeftContainerWidth}px + ${postBannerRightContainerWidth}px))`};
    height: fit-content;
    // Commented out because it interferes with Zoom Hover Card presentation (gets truncated)
    /* overflow: ${({ readOnly }) => (readOnly ? 'hidden' : 'visible')}; */

    ${({ theme }) => theme.mediaQuery.medium} {
        width: ${({
        postBannerRightContainerWidth,
    }) => `calc(100vw - (${POST_CONTENT_LEFT_CONTAINER_WIDTH_MEDIUM}px + ${postBannerRightContainerWidth}px))`};
    }

    ${({ theme }) => theme.mediaQuery.small} {
        width: ${({
        postBannerRightContainerWidth,
    }) => `calc(100vw - (${POST_CONTENT_LEFT_CONTAINER_WIDTH_SMALL}px + ${postBannerRightContainerWidth}px))`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: ${({
        postBannerRightContainerWidth,
    }) => `calc(100vw - (${POST_CONTENT_LEFT_CONTAINER_WIDTH_SMALL}px + ${postBannerRightContainerWidth}px))`};
    }

    @media (max-width: 590px) {
        width: ${({
        postBannerRightContainerWidth,
    }) => `calc(100vw - (${POST_CONTENT_LEFT_CONTENT_CONTAINER_WIDTH_590}px + ${postBannerRightContainerWidth}px))`};
        padding: ${`0 ${1.5625 * BODY_FONT_SIZE}px`};
    }
`;

interface PostThumbnailProps {
    url?: string,
    bottomExpanded: number,
    widthExpanded: number,
    heightExpandedLarge: number,
    heightExpandedMedium: number,
    heightExpandedSmall: number,
    heightExpandedExtraSmall: number,
    borderRadiusExpanded: number,
}
export const PostThumbnail = styled.div<PostThumbnailProps>`
    position: relative;
    bottom: ${({ bottomExpanded }) => `${bottomExpanded}px`}; // Modified directly using ref in ReaderView
    width: ${({ widthExpanded }) => `${widthExpanded}px`}; // Modified directly using ref in ReaderView
    height: ${({ heightExpandedLarge }) => `${heightExpandedLarge}px`}; // Modified directly using ref in ReaderView
    background-color: ${({ theme }) => theme.verascopeColor.purple200};
    background-image: ${({ url }) => (url ? `url(${url})` : 'none')};
    background-repeat: no-repeat;
    background-size: cover; // can't use 'contain' because width and height switch as dominant aspect
    background-position: center;
    border-radius: ${({ borderRadiusExpanded }) => `${borderRadiusExpanded}px`}; // Modified directly using ref in ReaderView
    box-shadow: ${({ theme }) => theme.color.boxShadow500};
    overflow: hidden;

    ${({ theme }) => theme.mediaQuery.medium} {
        max-width: ${`${POST_THUMBNAIL_MAX_WIDTH_MEDIUM}px`};
        height: ${({ heightExpandedMedium }) => `${heightExpandedMedium}px`}; // Modified directly using ref in ReaderView
    }

    ${({ theme }) => theme.mediaQuery.small} {
        max-width: ${`${POST_THUMBNAIL_MAX_WIDTH_SMALL}px`};
        height: ${({ heightExpandedSmall }) => `${heightExpandedSmall}px`}; // Modified directly using ref in ReaderView
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        max-width: ${`${POST_THUMBNAIL_MAX_WIDTH_SMALL}px`};
        height: ${({ heightExpandedExtraSmall }) => `${heightExpandedExtraSmall}px`}; // Modified directly using ref in ReaderView
    }
`;

interface AddPostThumbnailContainerProps {
    isDragAccept: boolean,
    isDragReject: boolean,
}
export const AddPostThumbnailContainer = styled.div<AddPostThumbnailContainerProps>`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({
        isDragAccept,
        isDragReject,
        theme,
    }) => {
        if (isDragReject) {
            return theme.color.error;
        }

        if (isDragAccept) {
            return theme.verascopeColor.blue200;
        }

        return 'transparent';
    }};
`;

export const AddPostThumbnailInput = styled.input``;

interface AddPostThumbnailIconProps {
    stickyPostBannerActive: boolean,
    transitionDuration: number,
}
export const AddPostThumbnailIcon = styled.div<AddPostThumbnailIconProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: ${({ stickyPostBannerActive }) => `${stickyPostBannerActive ? 1.875 * BODY_FONT_SIZE : 3.125 * BODY_FONT_SIZE}px`};
    width: ${({ stickyPostBannerActive }) => `${stickyPostBannerActive ? 1.875 * BODY_FONT_SIZE : 3.125 * BODY_FONT_SIZE}px`};
    pointer-events: none;
    transition: ${({ transitionDuration, theme }) => `
        height ${transitionDuration}ms ${theme.motion.standardEasing},
        width ${transitionDuration}ms ${theme.motion.standardEasing}
    `};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        height: 100%;
        width: 100%;
        fill: ${({ theme }) => theme.color.white};
    }
`;

export const PostThumbnailUploadProgressBarContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${`${5 * BODY_FONT_SIZE}px`};
    height: ${`${0.625 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    overflow: hidden;
    border: ${({ theme }) => `${0.0625 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple300}`};
`;

interface PostThumbnailUploadProgressBarProps {
    progress: number,
}
export const PostThumbnailUploadProgressBar = styled.div<PostThumbnailUploadProgressBarProps>`
    width: ${({ progress }) => `${progress}%`};
    height: 100%;
    background: ${({ theme }) => theme.verascopeColor.purple500};
`;

export const PostTitlesContainer = styled.div`
    flex: 0; // Any extra space from PostBannerCenterContainer should go to PostMetadataContainer
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: ${`${POST_EDITOR_MAX_WIDTH}px`};
    
    @media (max-width: 590px) {
        flex: 1; // Any extra space from PostBannerCenterContainer should go to this styled component
        align-items: center;
        justify-content: center;
    }
`;

interface PostTitleProps {
    fontMultiplier: number,
}
export const PostTitle = styled.h1<PostTitleProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 700;
    color: ${({ theme }) => theme.color.white};
    max-width: 35ch;
    margin-top: ${`${POST_TITLE_MARGIN_TOP}px`};
    margin-bottom: ${`${POST_TITLE_MARGIN_BOTTOM}px`};

    ${({ theme }) => theme.mediaQuery.medium} {
        font-size: 1.6em;
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: 1.6em;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 1.4em;
        margin-bottom: 0px;
    }

    @media (max-width: 590px) {
        text-align: center;
    }
`;

interface PostSubtitleProps {
    fontMultiplier: number,
    fixedToViewport: boolean,
    transitionDuration: number,
}
export const PostSubtitle = styled.h2<PostSubtitleProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 500;
    color: ${({ theme }) => theme.verascopeColor.purple500};
    max-width: 50ch;
    margin: ${POST_SUBTITLE_MARGIN};

    ${({ theme }) => theme.mediaQuery.medium} {
        font-size: 1em;
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: 1em;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 0.9em;
    }

    @media (max-width: 590px) {
        text-align: center;
        opacity: ${({ fixedToViewport }) => `${(fixedToViewport ? 0 : 1)} !important`};
        transition: ${({ transitionDuration, theme }) => `
            opacity ${transitionDuration}ms ${theme.motion.eagerEasing}
        `};
    }
`;

interface PostInputProps {
    isInvalid?: boolean,
    isItalic?: boolean,
    color: string,
    fontSize: number,
    fontWeight: number,
    marginTop: number,
}
export const PostInput = styled.input<PostInputProps>`
    display: block;
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontSize }) => `${fontSize}em`};
    font-weight: ${({ fontWeight }) => fontWeight};
    font-style: ${({ isItalic }) => (isItalic ? 'italic' : 'normal')};
    line-height: 1.5em;
    color: ${({ isInvalid, theme, color }) => (isInvalid
        ? theme.verascopeColor.red200
        : color
    )};
    text-align: center;
    background-color: ${({
        isInvalid,
        theme,
    }) => {
        if (isInvalid) {
            return setColorLightness(
                theme.color.error,
                95,
            );
        }

        return setColorLightness(
            theme.verascopeColor.purple300,
            80,
        );
    }};
    border: ${({ theme, isInvalid }) => `${0.125 * BODY_FONT_SIZE}px solid ${isInvalid
        ? theme.verascopeColor.red200
        : theme.color.white}`}; 
    width: 100%;
    margin-top: ${({ marginTop }) => `${marginTop}px`};
    padding: ${`${0.15625 * BODY_FONT_SIZE}px ${0.9375 * BODY_FONT_SIZE}px`};
    text-transform: none;
    border: none;
    text-decoration: none;
    border-radius: ${`${1.875 * BODY_FONT_SIZE}px`};
    cursor: none;

    &:focus{
        outline: none;
    }

    &::placeholder {
        color: ${({ theme }) => theme.verascopeColor.purple500};
    }
`;

interface PostMetadataContainerProps {
    padding: number,
}
export const PostMetadataContainer = styled.div<PostMetadataContainerProps>`
    flex: 1; // Any extra space from PostBannerCenterContainer should go to this styled component
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: ${({ padding }) => `${padding}px`};

    @media (max-width: 590px) {
        flex: 0; // Any extra space from PostBannerCenterContainer should go to PostTitlesContainer
        padding-bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};
    }
`;

interface PostMetadataContentContainerProps {
    height: number,
    padding: number,
}
export const PostMetadataContentContainer = styled.div<PostMetadataContentContainerProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: ${({ height }) => `${height + 1}px`}; // 1px is for the border-top
    padding-top: ${({ padding }) => `${padding}px`};
    border-top: ${({ theme }) => `${0.0625 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple300}`};

    @media (max-width: 590px) {
        padding-top: ${`${0.3125 * BODY_FONT_SIZE}px`};
    }
`;

export const PostAuthorContainer = styled.div`
    flex: 0; // Any extra space from PostMetadataContainer should go to PostDetailsContainer
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

interface PostAuthorNameProps {
    avatarHeight: number,
    fontMultiplier: number,
}
export const PostAuthorName = styled.h4<PostAuthorNameProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 400;
    color: ${({ theme }) => theme.color.white};
    margin: auto 0;
    margin-top: ${({ avatarHeight }) => `${avatarHeight / 2}px`};
    margin-left: ${`${POST_AUTHOR_MARGIN_LEFT}px`};
    white-space: nowrap;
    transform: translateY(-50%);
`;

export const PostDetailsContainer = styled.div`
    flex: 1; // Any extra space from PostMetadataContainer should go to this styled component
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

    // When we remove author name
    @media (max-width: 470px) {
        justify-content: center;
    }
`;

interface PostDetailProps {
    center?: boolean,
}
export const PostDetail = styled.div<PostDetailProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
    margin: auto 0;
    margin-top: 0;
    margin-left: ${`${1.25 * BODY_FONT_SIZE}px`};

    // When we remove author name
    @media (max-width: 470px) {
        margin-left: 0px;
    }
`;

export const PostDetailIcon = styled.div`
    height: ${`${1.25 * BODY_FONT_SIZE}px`};
    width: ${`${1.25 * BODY_FONT_SIZE}px`};
    min-width: ${`${1.25 * BODY_FONT_SIZE}px`};
    
    & svg, & div {
        position: relative;
        height: 100%;
        width: 100%;
        margin: 0;
    }

    & path {
        fill: ${({ theme }) => theme.verascopeColor.purple500};
    }
`;

interface PostDetailTextProps {
    avatarHeight: number,
    fontMultiplier: number,
}
export const PostDetailText = styled.h4<PostDetailTextProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 400;
    color: ${({ theme }) => theme.color.white};
    margin: 0;
    margin-top: ${({ avatarHeight }) => `${avatarHeight / 2}px`};
    margin-left: ${`${POST_DETAIL_TEXT_MARGIN_LEFT}px`};
    transform: translateY(-50%);
`;

interface CollapsedPostTitle {
    height: number,
}
export const CollapsedPostTitle = styled.h4<CollapsedPostTitle>`
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1em;
    font-weight: 700;
    color: ${({ theme }) => theme.verascopeColor.purple200};
    max-width: 35ch;
    height: ${({ height }) => `${height}px`};
    text-overflow: ellipsis;
    margin: 0;

    @media (max-width: 590px) {
        justify-content: center;
        max-width: none;
        width: 100%;
    }
`;

export const AnnotationContainer = styled.div`
    position: relative;
    height: 100%;
    overflow-x: hidden; // This prevents the viewport from having extra width causing viewport scroll
`;

export const UploadProgressContainer = styled.div`
    position: fixed;
    display: block;
    width: ${`${10.3125 * BODY_FONT_SIZE}px`};
    bottom: ${`${3.75 * BODY_FONT_SIZE}px`};
    right: ${`${2.5 * BODY_FONT_SIZE}px`};
    z-index: ${CURSOR_Z_INDEX - 1};
`;

export const UpdateTextContainer = styled.div`
    position: fixed;
    bottom: ${`${2.1875 * BODY_FONT_SIZE}px`};
    right: ${`${2.5 * BODY_FONT_SIZE}px`};
    z-index: ${CURSOR_Z_INDEX - 1};

    & > div {
        margin-bottom: 0px;
    }
`;

export const EditorUpdateText = styled.div`
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.9em;
    font-weight: 400;
    color: ${({ theme }) => theme.color.neutral600};
    margin-bottom: ${`${1.25 * BODY_FONT_SIZE}px`};
    text-align: center;
    user-select: none;
`;

export const ModalSheetCloseButtonContainer = styled.div`
    position: absolute;
    top: ${`${MODAL_SHEET_CLOSE_BUTTON_CONTAINER_TOP}px`};
    right: ${`${0.9375 * BODY_FONT_SIZE}px`};
    z-index: 1;
    background: ${({ theme }) => theme.color.white};
    border-radius: ${`${MODAL_SHEET_CLOSE_BUTTON_LENGTH / 2}px`};
`;

interface ZoomLevelSelectorContainerProps {
    readOnly: boolean,
    selectorWidth: number,
}
export const ZoomLevelSelectorContainer = styled.div<ZoomLevelSelectorContainerProps>`
    position: fixed;
    bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};
    left: ${({ readOnly }) => (readOnly ? 'auto' : '30px')};
    right: ${({ readOnly }) => (readOnly ? `${1.875 * BODY_FONT_SIZE}px` : 'auto')};
    background: inherit;
    border-radius: ${({ selectorWidth }) => `${selectorWidth / 2}px`};
    z-index: ${CURSOR_Z_INDEX - 1};
`;

interface CurrentZoomLevelTextProps {
    currentZoomDigit: ZOOM_LEVEL,
}
export const CurrentZoomLevelText = styled.span<CurrentZoomLevelTextProps>`
    position: absolute;
    top: ${`${1.53125 * BODY_FONT_SIZE}px`};
    left: ${({ currentZoomDigit }) => {
        switch (currentZoomDigit) {
        case ZOOM_LEVEL.five:
            return `${1.73125 * BODY_FONT_SIZE}px`;
        case ZOOM_LEVEL.four:
            return `${1.69375 * BODY_FONT_SIZE}px`;
        case ZOOM_LEVEL.three:
            return `${1.71875 * BODY_FONT_SIZE}px`;
        case ZOOM_LEVEL.two:
            return `${1.71875 * BODY_FONT_SIZE}px`;
        case ZOOM_LEVEL.one:
            return `${1.78125 * BODY_FONT_SIZE}px`;
        default:
            return `${1.69375 * BODY_FONT_SIZE}px`;
        }
    }};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.9em;
    font-weight: 700;
    color: ${({ theme }) => theme.verascopeColor.purple300};
    text-align: center;
    line-height: 1em;
    z-index: ${CURSOR_Z_INDEX - 1};
    pointer-events: none;
`;

export const CurrentZoomLevelIcon = styled.img`
    position: absolute;
    top: ${`${1.5 * BODY_FONT_SIZE}px`};
    left: ${`${1.53125 * BODY_FONT_SIZE}px`};
    width: ${`${0.9375 * BODY_FONT_SIZE}px`};
    height: ${`${0.9375 * BODY_FONT_SIZE}px`};
    pointer-events: none;
`;

interface PostPlaceholderContainerProps {
    postBannerHeight: number,
    postContentMarginTop: number,
}
export const PostPlaceholderContainer = styled.div<PostPlaceholderContainerProps>`
    height: ${({ postBannerHeight, postContentMarginTop }) => `calc(100vh - ${postBannerHeight + postContentMarginTop}px)`};
    width: 100%;
`;

interface HandlebarProps {
    width: number,
    height: number,
    isSteering: boolean,
    transitionDuration: number,
}
export const Handlebar = styled.div<HandlebarProps>`
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border-radius: ${({ width }) => `${width / 2}px`};
    background: #C5CDD1;
    transition: ${({ theme, transitionDuration }) => `background ${transitionDuration}ms ${theme.motion.eagerEasing}`};

    ${({ theme }) => theme.mediaQuery.small} {
        background: none;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        background: none;
    }
`;

interface HandlebarContainerProps {
    width: number,
    height?: string,
    isSteering: boolean,
    transitionDuration: number,
}
export const HandlebarContainer = styled.div<HandlebarContainerProps>`
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    justify-content: center;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => (height || '100vh')};
    background: inherit;
    transition: ${({ theme, transitionDuration }) => `background ${transitionDuration}ms ${theme.motion.standardEasing}`};

    &:hover {
        & > ${Handlebar} {
            background: ${({ theme, isSteering }) => (isSteering
        ? theme.color.neutral900
        : theme.verascopeColor.purple400)};
        }
    }

    ${({ theme }) => theme.mediaQuery.small} {
        background: none;

        &:hover {
            background: none;

            & > ${Handlebar} {
                background: none;
            }
        }
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        background: none;
        width: 0px;

        &:hover {
            background: none;

            & > ${Handlebar} {
                background: none;
            }
        }
    }
`;

interface PurchaseBookButtonContainerProps {
    buttonHeight: number,
}
export const PurchaseBookButtonContainer = styled.div<PurchaseBookButtonContainerProps>`
    position: absolute;
    display: flex;
    right: ${`${11.5 * BODY_FONT_SIZE}px`};
    top: ${({ buttonHeight }) => `${-(buttonHeight + 0.5 * BODY_FONT_SIZE)}px`};
`;

export const ButtonContainer = styled.div`
    position: relative;
`;

export const ModalContentsContainer = styled.div``;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${`${1.25 * BODY_FONT_SIZE}px`};
`;

export const ModalTitle = styled.div`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1.8em;
    font-weight: 700;
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    margin-bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 1.5em;
    }  
`;

export const ModalSubtitle = styled.div`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1em;
    font-weight: 400;
    color: ${({ theme }) => theme.color.neutral600};
    line-height: 1.5em;
    text-align: center;
    max-width: 40ch;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 0.9em;
    }  
`;

export const ModalBody = styled.div`
    text-align: center;
`;

interface AdventureCharacterAvatarContainerProps {
    background: string,
    transitionDuration: number,
}
export const AdventureCharacterAvatarContainer = styled.div<AdventureCharacterAvatarContainerProps>`
    display: flex;
    justify-content: center;
    position: relative;
    width: ${`${7.5 * BODY_FONT_SIZE}px`};
    height: ${`${7.5 * BODY_FONT_SIZE}px`};
    border-radius: ${`${3.75 * BODY_FONT_SIZE}px`};
    background: ${({ theme }) => theme.color.white};
    overflow: hidden;
    box-shadow: ${({ theme }) => theme.color.insetBoxShadow200};
    transition: ${({
        transitionDuration,
        theme,
    }) => `
        background ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
`;

interface AdventureCharacterZoomLevelProps {
    color: string,
    transitionDuration: number,
}
export const AdventureCharacterZoomLevel = styled.h4<AdventureCharacterZoomLevelProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    font-weight: 700;
    color: ${({ color }) => color};
    white-space: nowrap;
    margin: 0px;
    transition: ${({
        transitionDuration,
        theme,
    }) => `
        color ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
`;

interface AdventureCharacterContainerProps {
    background: string,
    zoomHoverColor: string,
    transitionDuration: number,
}
export const AdventureCharacterContainer = styled.div<AdventureCharacterContainerProps>`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: ${`${13.125 * BODY_FONT_SIZE}px`};
    height: ${`${13.125 * BODY_FONT_SIZE}px`};
    background: ${({ background }) => background};
    transform: scale(1);
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    padding: ${`${0.9375 * BODY_FONT_SIZE}px`};
    margin: ${`${0.3125 * BODY_FONT_SIZE}px`};
    transition: ${({
        transitionDuration,
        theme,
    }) => `
        background ${transitionDuration}ms ${theme.motion.eagerEasing},
        height ${transitionDuration}ms ${theme.motion.eagerEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    &:hover {
        background: ${({ theme }) => theme.color.white};
        transform: scale(1.1);
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
        z-index: 1;

        & > ${AdventureCharacterAvatarContainer} {
            background: ${({ background }) => background};
        }

        & > ${AdventureCharacterZoomLevel} {
            color: ${({ zoomHoverColor }) => zoomHoverColor};
        }
    }

    ${({ theme }) => theme.mediaQuery.small} {
        
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
    }   
`;

export const AdventureCharacterAvatar = styled.img`
    position: absolute;
    top: ${`${1.25 * BODY_FONT_SIZE}px`};
    width: 80%;
    height: 80%;
    pointer-events: none;
`;

interface AdventureCharacterNameProps {
    color: string,
}
export const AdventureCharacterName = styled.h2<AdventureCharacterNameProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1em;
    font-weight: 700;
    color: ${({ color }) => color};
    white-space: nowrap;
    margin: 0px;
    margin-top: ${`${0.9375 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};
`;

export const CartAnimationItemsContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${USER_PROFILE_DIALOG_Z_INDEX};
`;

export const PaywallContainer = styled.div`
    position: relative;
    z-index: ${POST_PAYWALL_Z_INDEX};
    width: 100%;
    background: ${({ theme }) => theme.verascopeColor.purple100};
    box-shadow: ${({ theme }) => theme.color.boxShadow400};
`;

export const PaywallOverhang = styled.div`
    position: absolute;
    top: ${`-${1.5 * BODY_FONT_SIZE}px`};
    left: 0;
    width: 100%;
    height: ${`${3 * BODY_FONT_SIZE}px`};
    background: ${({ theme }) => theme.verascopeColor.purple100};
    border-radius: ${`${0.5 * BODY_FONT_SIZE}px`};
`;

export const PaywallHeader = styled.h2`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 2em;
    font-weight: 700;
    color: ${({ theme }) => theme.color.white};
    line-height: 1.5em;
    text-align: center;
    padding: ${`0px ${1.5 * BODY_FONT_SIZE}px`};
    margin: ${`${2.5 * BODY_FONT_SIZE}px 0px`};
    margin-bottom: ${`${0.5 * BODY_FONT_SIZE}px`};
    white-space: nowrap;
`;

export const PaywallSubheader = styled.h3`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1.1em;
    font-weight: 500;
    color: ${({ theme }) => theme.verascopeColor.purple500};
    line-height: 1.5em;
    max-width: 30ch;
    text-align: center;
    padding: ${`0px ${1.5 * BODY_FONT_SIZE}px`};
    margin: 0px auto;
    margin-bottom: ${`${2 * BODY_FONT_SIZE}px`};
`;

export const PaywallButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0px auto;
    margin-bottom: 55vh;
`;

// ===== Packages =====

import styled       from 'styled-components';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
}                   from '../../constants/generalConstants';
import FONT_TYPE    from '../../constants/fontType';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ theme }) => theme.verascopeColor.purple200};
`;

export const ImageContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${`${2.5 * BODY_FONT_SIZE}px`};
`;

export const VerascopeQuadrilateral = styled.svg`
    position: relative;
    left: ${`${0.625 * BODY_FONT_SIZE}px`};
    width: ${`${25 * BODY_FONT_SIZE}px`};

    & path {
        fill: ${({ theme }) => theme.verascopeColor.purple100};
    }
`;

export const ExclamationIcon = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${`translate(calc(-50% - ${1.875 * BODY_FONT_SIZE}px), calc(-50% - ${0.625 * BODY_FONT_SIZE}px))`};
    height: ${`${6.875 * BODY_FONT_SIZE}px`};
`;

export const ErrorTitle = styled.h2`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1.5em;
    font-weight: 700;
    color: ${({ theme }) => theme.color.white};
    margin: 0;
    margin-bottom: ${`${0.625 * BODY_FONT_SIZE}px`};
`;

export const ErrorMessage = styled.p`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1em;
    font-weight: 500;
    color: ${({ theme }) => theme.color.neutral400};
    width: 60ch;
    text-align: center;
    line-height: 1.5em;
    margin: 0;
    margin-bottom: ${`${2.5 * BODY_FONT_SIZE}px`};
`;

export const ResetButtonContainer = styled.div`
    
`;

interface ResetButtonProps {
    transitionDuration: number,
}
export const ResetButton = styled.button<ResetButtonProps>`
    position: relative;
    width: ${`${10 * BODY_FONT_SIZE}px`};
    height: ${`${3.125 * BODY_FONT_SIZE}px`};
    border-radius: ${`${1.5625 * BODY_FONT_SIZE}px`};
    background: ${({ theme }) => theme.verascopeColor.orange200};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    outline: none;
    border: none;
    cursor: none;
    user-select: none;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1.2em;
    font-weight: 600;
    color: ${({ theme }) => theme.color.white};
    transition: ${({
        theme,
        transitionDuration,
    }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing},
        transform ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
        transform: scale(1.2);
    }
`;

// ===== Packages =====

import styled                       from 'styled-components';

// ===== Services =====

import {
    setColorLightness,
}                                   from '../../services';

// ===== Constants =====

import {
    USER_PROFILE_DIALOG_Z_INDEX,
    CART_BUTTON_HEIGHT_MULTIPLIER,
    CART_ICON_CONTAINER_PADDING_MULTIPLIER,
    CART_ICON_MARGIN_MULTIPLIER,
    USER_PROFILE_AVATAR_LENGTH_MULTIPLIER,
    USER_PROFILE_DIALOG_AVATAR_BUFFER_MULTIPLIER,
    BODY_FONT_SIZE,
}                                   from '../../constants/generalConstants';
import FONT_TYPE                    from '../../constants/fontType';

const CONTENT_MARGIN_TOP = 0.75; // em

interface ContainerProps {
    isExpanded: boolean,
    isRecognizedUser: boolean,
    lightBackground: boolean,
    transitionDuration: number,
}
export const Container = styled.div<ContainerProps>`
    position: fixed;
    top: 0px;
    right: ${({ isRecognizedUser }) => `${isRecognizedUser
        ? `${CART_BUTTON_HEIGHT_MULTIPLIER + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER + CART_ICON_MARGIN_MULTIPLIER}em`
        : `${0}px`}`};
    z-index: ${USER_PROFILE_DIALOG_Z_INDEX};
    background: ${({
        isExpanded,
        lightBackground,
        theme,
    }) => (isExpanded || lightBackground
        // The pages who you want to have a white custom cursor should route here
        // Dark background invokes white cursor
        ? theme.color.white
        : theme.verascopeColor.purple200
    )};
    transition: ${({ transitionDuration, theme }) => `
        right ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        right: 0px;
    } 
`;

interface DialogBodyContainerProps {
    contractedTop: number,
    contractedRight: number,
    expandedTop: number,
    expandedRight: number,
    avatarLength: number,
    expandedWidth: number,
    expandedHeight: number,
    isExpanded: boolean,
    closeButtonContainerLength: number,
    isRecognizedUser: boolean,
    padding: number,
    transitionDuration: number,
}
export const DialogBodyContainer = styled.div<DialogBodyContainerProps>`
    position: absolute;
    top: ${({ isExpanded, contractedTop, expandedTop }) => `${isExpanded ? expandedTop : contractedTop}px`};
    right: ${({ isExpanded, contractedRight, expandedRight }) => `${isExpanded ? expandedRight : contractedRight}px`};
    background: ${({ theme }) => theme.color.white};
    box-shadow: ${({ isExpanded, theme }) => (isExpanded
        ? theme.color.boxShadow300
        : 'none'
    )};
    border-radius: ${({ closeButtonContainerLength }) => `${closeButtonContainerLength / 2}px`};
    border-top-right-radius: ${({ isExpanded, closeButtonContainerLength }) => `${isExpanded ? 0 : closeButtonContainerLength / 2}px`};
    width: ${({ isExpanded, avatarLength, expandedWidth }) => `${isExpanded ? expandedWidth : avatarLength}px`};
    height: ${({ isExpanded, avatarLength, expandedHeight }) => `${isExpanded ? expandedHeight : avatarLength}px`};
    padding: ${({ isExpanded, padding }) => `${isExpanded ? padding : 0}px`};
    pointer-events: ${({ isExpanded }) => (isExpanded ? 'auto' : 'none')};
    transition: ${({ transitionDuration, theme }) => `
        top ${transitionDuration}ms ${theme.motion.eagerEasing},
        right ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-top-right-radius ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-top-left-radius ${transitionDuration}ms ${theme.motion.eagerEasing},
        width ${transitionDuration}ms ${theme.motion.eagerEasing},
        height ${transitionDuration}ms ${theme.motion.eagerEasing},
        padding ${transitionDuration}ms ${theme.motion.eagerEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: ${({ isExpanded, contractedTop }) => `${isExpanded ? 0 : contractedTop}px`};
        right: ${({ isExpanded, contractedRight, isRecognizedUser }) => {
        if (isExpanded) {
            return '0px';
        }

        if (isRecognizedUser) {
            return `${contractedRight + (CART_BUTTON_HEIGHT_MULTIPLIER + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER + CART_ICON_MARGIN_MULTIPLIER) * BODY_FONT_SIZE}px`;
        }

        return `${contractedRight}px`;
    }};
        width: ${({ isExpanded, avatarLength }) => `${isExpanded ? '100vw' : `${avatarLength}px`}`};
        border-top-left-radius: ${({ isExpanded, closeButtonContainerLength }) => `${isExpanded ? 0 : closeButtonContainerLength / 2}px`};
    }   
`;

interface DialogBodyProps {
    isExpanded: boolean,
    opacityDuration: number,
    transitionDelayDuration: number,
}
export const DialogBody = styled.div<DialogBodyProps>`
    opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
    transition: ${({
        isExpanded,
        opacityDuration,
        transitionDelayDuration,
        theme,
    }) => `
        opacity ${opacityDuration}ms ${isExpanded ? transitionDelayDuration : 0}ms ${theme.motion.standardEasing}
    `};
`;

interface CornerCurveProps {
    isExpanded: boolean,
    closeButtonContainerLength: number,
    transitionDuration: number,
}
export const CornerCurve = styled.div<CornerCurveProps>`
    position: absolute;
    right: ${({ isExpanded, closeButtonContainerLength }) => `${isExpanded ? closeButtonContainerLength : 0}px`};
    top: 0px;
    width: ${({ isExpanded, closeButtonContainerLength }) => `${isExpanded ? (closeButtonContainerLength / 2) : 0}px`};
    height: ${({ isExpanded, closeButtonContainerLength }) => `${isExpanded ? (closeButtonContainerLength / 2) : 0}px`};
    transform: translateY(-100%) rotate(180deg);
    pointer-events: none;
    transition: ${({ transitionDuration, theme }) => `
        right ${transitionDuration}ms ${theme.motion.eagerEasing},
        width ${transitionDuration}ms ${theme.motion.eagerEasing},
        height ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
    }

    & path {
        fill: ${({ theme }) => theme.color.white};
    }
`;

interface CloseButtonContainerProps {
    contractedTop: number,
    contractedRight: number,
    expandedTop: number,
    expandedRight: number,
    avatarLength: number,
    length: number,
    isExpanded: boolean,
    isRecognizedUser: boolean,
    transitionDuration: number,
}
export const CloseButtonContainer = styled.div<CloseButtonContainerProps>`
    position: absolute;
    top: ${({ isExpanded, contractedTop, expandedTop }) => `${isExpanded ? `${expandedTop}px` : `${contractedTop}px`}`};
    right: ${({ isExpanded, contractedRight, expandedRight }) => `${isExpanded ? `${expandedRight}px` : `${contractedRight}px`}`};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.color.white};
    width: ${({ length, avatarLength, isExpanded }) => (isExpanded
        ? `${length}px`
        : `${avatarLength}px`
    )};
    height: ${({ length, avatarLength, isExpanded }) => (isExpanded
        ? `${length}px`
        : `${avatarLength}px`
    )};
    border-radius: ${({ length }) => `${(length) / 2}px`};
    border-bottom-right-radius: ${({ length, isExpanded }) => (isExpanded
        ? '0px'
        : `${length / 2}px`
    )};
    border-bottom-left-radius: ${({ length, isExpanded }) => (isExpanded
        ? '0px'
        : `${length / 2}px`
    )};
    transition: ${({ transitionDuration, theme }) => `
        top ${transitionDuration}ms ${theme.motion.eagerEasing},
        right ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-top-right-radius ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-top-left-radius ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        right: ${({
        isRecognizedUser,
        isExpanded,
        expandedTop,
        contractedTop,
    }) => {
        if (isExpanded) {
            if (isRecognizedUser) {
                return `${expandedTop + (CART_BUTTON_HEIGHT_MULTIPLIER + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER + CART_ICON_MARGIN_MULTIPLIER) * BODY_FONT_SIZE}px`;
            }

            return `${expandedTop}px`;
        }

        if (isRecognizedUser) {
            return `${contractedTop + (CART_BUTTON_HEIGHT_MULTIPLIER + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER + CART_ICON_MARGIN_MULTIPLIER) * BODY_FONT_SIZE}px`;
        }

        return `${contractedTop}px`;
    }};
    }
`;

interface AvatarOverlayProps {
    length: number,
    uploadingImage: boolean,
    transitionDuration: number,
}
export const AvatarOverlay = styled.div<AvatarOverlayProps>`
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    border-radius: ${({ length }) => `${length / 2}px`};
    background: ${({ theme }) => theme.verascopeColor.purple200};
    opacity: ${({ uploadingImage }) => (uploadingImage ? 1 : 0)};
    pointer-events: none;
    z-index: 1;
    transition: ${({ transitionDuration, theme }) => `
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & svg {
        width: ${`${0.9375 * BODY_FONT_SIZE}px`};
        height: ${`${0.9375 * BODY_FONT_SIZE}px`};
    }

    & fill {
        background: ${({ theme }) => theme.color.white};
    }
`;

interface AvatarContainerProps {
    contractedTop: number,
    contractedRight: number,
    expandedTop: number,
    expandedRight: number,
    length: number,
    isExpanded: boolean,
    anonIconId: string,
    avatarLength: number,
    isRecognizedUser: boolean,
    dialogBodyPadding: number,
    translateDuration: number,
}
export const AvatarContainer = styled.div<AvatarContainerProps>`
    position: absolute;
    top: ${({ isExpanded, contractedTop, expandedTop }) => `${isExpanded ? `${expandedTop}px` : `${contractedTop}px`}`};
    right: ${({ isExpanded, contractedRight, expandedRight }) => `${isExpanded ? `${expandedRight}px` : `${contractedRight}px`}`};
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    background: ${({ theme }) => theme.verascopeColor.orange200};
    border-radius: ${({ length }) => `${length / 2}px`};
    z-index: 1;
    transition: ${({
        translateDuration,
        theme,
    }) => `
        top ${translateDuration}ms ${theme.motion.eagerEasing},
        right ${translateDuration}ms ${theme.motion.eagerEasing},
        width ${translateDuration}ms ${theme.motion.eagerEasing},
        height ${translateDuration}ms ${theme.motion.eagerEasing}
    `};

    & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    & ${({ anonIconId }) => `#${anonIconId}`} svg {
        width: ${({ length }) => `${length - 0.3125 * BODY_FONT_SIZE}px`};
        height: ${({ length }) => `${length - 0.3125 * BODY_FONT_SIZE}px`};
    }

    & svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    & path {
        fill: ${({ theme }) => theme.color.white};
    }

    &:hover ${AvatarOverlay} {
        opacity: ${({ isExpanded }) => (isExpanded ? 0.6 : 0)};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: ${({
        isExpanded,
        contractedTop,
        dialogBodyPadding,
    }) => `${isExpanded ? `${dialogBodyPadding}px` : `${contractedTop}px`}`};
        right: ${({
        isExpanded,
        contractedRight,
        avatarLength,
        dialogBodyPadding,
        isRecognizedUser,
    }) => {
        if (isExpanded) {
            return `calc(100vw - ${avatarLength + dialogBodyPadding}px)`;
        }

        if (isRecognizedUser) {
            return `${contractedRight + (CART_BUTTON_HEIGHT_MULTIPLIER + 2 * CART_ICON_CONTAINER_PADDING_MULTIPLIER + CART_ICON_MARGIN_MULTIPLIER) * BODY_FONT_SIZE}px`;
        }

        return `${contractedRight}px`;
    }};
    }
`;

interface AvatarUploadProgressBarContainerProps {
    length: number,
}
export const AvatarUploadProgressBarContainer = styled.div<AvatarUploadProgressBarContainerProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({ length }) => `${length - 5}px`};
    height: 0.5em;
    border-radius: 0.25em;
    overflow: hidden;
    border: ${({ theme }) => `${0.0625 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple400}`};
`;

interface AvatarUploadProgressBarProps {
    progress: number,
}
export const AvatarUploadProgressBar = styled.div<AvatarUploadProgressBarProps>`
    width: ${({ progress }) => `${progress}%`};
    height: 100%;
    background: ${({ theme }) => theme.verascopeColor.blue200};
`;

interface NameContainerProps {
    padding: number,
}
export const NameContainer = styled.div<NameContainerProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: ${({ padding }) => `${padding}px`};
    margin-left: ${`${USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + USER_PROFILE_DIALOG_AVATAR_BUFFER_MULTIPLIER}em`};
`;

export const NameText = styled.h3`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 1em;
    color: ${({ theme }) => theme.color.neutral900};
    white-space: nowrap;
    margin: 0;
    margin-right: 0.5em;
`;

interface NameInputContainerProps {
    padding: number,
}
export const NameInputContainer = styled.div<NameInputContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: ${({ padding }) => `${padding}px`};
    margin-left: ${`${USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + USER_PROFILE_DIALOG_AVATAR_BUFFER_MULTIPLIER}em`};
`;

interface NameButtonContainerProps {
    width: number,
}
export const NameButtonContainer = styled.div<NameButtonContainerProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: ${({ width }) => `${width}px`};
    background: ${({ theme }) => theme.color.white};
`;

export const AuthenticationMessage = styled.p`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.75em;
    line-height: 1.5em;
    color: ${({ theme }) => theme.color.neutral700};
    max-width: 30ch;
    margin: 0;
    margin-top: ${`${CONTENT_MARGIN_TOP}em`};
    margin-left: ${`${(USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + USER_PROFILE_DIALOG_AVATAR_BUFFER_MULTIPLIER) * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: auto;
        max-width: 40ch;
    }
`;

interface AuthMethodsContainerProps {
    isExpanded: boolean,
}
export const AuthMethodsContainer = styled.div<AuthMethodsContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: ${({ isExpanded }) => `${isExpanded ? CONTENT_MARGIN_TOP : 0}em`};
    margin-left: ${`${(USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + USER_PROFILE_DIALOG_AVATAR_BUFFER_MULTIPLIER) * BODY_FONT_SIZE}px`};
    background: ${({ theme }) => theme.color.white};
`;

interface AuthMethodProps {
    transitionDuration: number,
}
export const AuthMethod = styled.div<AuthMethodProps>`
    display: inline-block;
    width: 2em;
    height: 2em;
    border-radius: 1em;
    padding: 0.3em;
    margin-right: 0.25em;
    background: ${({ theme }) => theme.color.neutral300};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transition: ${({ theme, transitionDuration }) => `
        background ${transitionDuration}ms ${theme.motion.eagerEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    &:hover {
        background: ${({ theme }) => theme.color.neutral500};
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }


    & svg, & > div:nth-child(2) {
        width: 1.4em;
        height: 1.4em;
    }
`;

interface EmailAuthFieldsContainerProps {
    isVisible: boolean,
}
export const EmailAuthFieldsContainer = styled.div<EmailAuthFieldsContainerProps>`
    position: relative;
    padding-left: ${({ isVisible }) => `${isVisible
        ? (USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + USER_PROFILE_DIALOG_AVATAR_BUFFER_MULTIPLIER) * BODY_FONT_SIZE
        : 0}px`};
    max-width: ${`${20 * BODY_FONT_SIZE}px`};
`;

export const EmailAuthInputContainer = styled.div`
    position: relative;
    display: inline-block;
    z-index: 1; // To put Tooltip in front of Back Button
`;

interface EmailAuthInputProps {
    isInvalid?: boolean,
    width: number,
    height: number,
}
export const EmailAuthInput = styled.input<EmailAuthInputProps>`
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.8em;
    line-height: 1.5em;
    color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.color.neutral800
    )};
    background-color: ${({
        isInvalid,
        theme,
    }) => {
        if (isInvalid) {
            return setColorLightness(
                theme.color.error,
                95,
            );
        }

        return theme.color.neutral200;
    }};
    border: ${({ theme, isInvalid }) => `${0.125 * BODY_FONT_SIZE}px solid ${isInvalid
        ? theme.verascopeColor.red200
        : theme.color.white}`}; 
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    margin: 0;
    margin-top: 0.5em;
    padding: 0.2em 1em;
    text-transform: none;
    border: none;
    text-decoration: none;
    border-radius: ${({ height }) => `${height / 2}px`};

    &:focus{
        outline: none;
    }

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral600};
    }
`;

interface NameInputProps {
    width: number,
    height: number,
}
export const NameInput = styled(EmailAuthInput)<NameInputProps>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    margin-top: 0px;
    margin-right: 0.25em;
    padding: 0px 1em;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: ${`calc((100% - ${5 * BODY_FONT_SIZE}px) / 2)`};
    }
`;

export const PasswordStrengthContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: ${`${0.4375 * BODY_FONT_SIZE}px`};
    width: 100%;
`;

interface PasswordStrengthBarProps {
    active: boolean,
    hasErrors: boolean,
}
export const PasswordStrengthBar = styled.div<PasswordStrengthBarProps>`
    display: inline-block;
    width: ${`${1.875 * BODY_FONT_SIZE}px`};
    height: ${`${0.375 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.1875 * BODY_FONT_SIZE}px`};
    background: ${({ active, hasErrors, theme }) => {
        if (hasErrors && active) return theme.verascopeColor.red200;
        if (active) return theme.verascopeColor.blue200;

        return theme.color.neutral300;
    }};
`;

export const EmailAuthBackButtonContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    background: ${({ theme }) => theme.color.white};
`;

interface EmailAuthTabsContainerProps {
    height: number,
}
export const EmailAuthTabsContainer = styled.div<EmailAuthTabsContainerProps>`
    position: relative;
    height: ${({ height }) => `${height}px`};
`;

interface EmailAuthTabButtonContainerProps {
    index: number,
    width: number,
    margin: number,
    background: string,
    movingButtonCurrentIndex: number,
}
export const EmailAuthTabButtonContainer = styled.div<EmailAuthTabButtonContainerProps>`
    position: absolute;
    top: 0px;
    left: ${({ index, width, margin }) => `${index * (width + margin)}px`};
    margin: ${({ margin }) => `0px ${margin / 2}px`};
    
    & h3 {
        color: ${({
        index,
        movingButtonCurrentIndex,
        theme,
    }) => (index === movingButtonCurrentIndex
        ? `${theme.color.white}`
        : 'auto'
    )};
    }

    &:hover button {
        background-color: ${({
        index,
        movingButtonCurrentIndex,
        background,
    }) => (index === movingButtonCurrentIndex
        ? background
        : 'auto'
    )};
    }
`;

interface ForgotPasswordTextProps {
    transitionDuration: number,
}
export const ForgotPasswordText = styled.p<ForgotPasswordTextProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.neutral800};
    white-space: nowrap;
    margin: 0;
    padding: ${`${0.3125 * BODY_FONT_SIZE}px 0px`};
    padding-left: ${`${0.9375 * BODY_FONT_SIZE}px`};
    transition: ${({ transitionDuration, theme }) => `
        color ${transitionDuration}ms ${theme.motion.standardEasing},
        font-weight ${transitionDuration}ms ${theme.motion.standardEasing}
    `};

    &:hover {
        color: ${({ theme }) => theme.verascopeColor.purple300};
        font-weight: 600;
    }
`;

interface SubmitEmailAuthButtonContainerProps {
    width: number,
}
export const SubmitEmailAuthButtonContainer = styled.div<SubmitEmailAuthButtonContainerProps>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: ${`${0.3125 * BODY_FONT_SIZE}px`};
    width: ${({ width }) => `${width}px`};
`;

interface AuthUserDetailsProps {
    isVisible: boolean,
    padding: number,
    paddingDuration: number,
}
export const AuthUserDetails = styled.div<AuthUserDetailsProps>`
    display: flex;
    flex-direction: column;
    padding-left: ${({ isVisible, padding }) => `${isVisible
        ? padding + (USER_PROFILE_AVATAR_LENGTH_MULTIPLIER + USER_PROFILE_DIALOG_AVATAR_BUFFER_MULTIPLIER) * BODY_FONT_SIZE
        : 0}px`};
    transition: ${({ paddingDuration, theme }) => `
        padding-left ${paddingDuration}ms ${theme.motion.eagerEasing}
    `};
`;

interface AuthUserContainerProps {
    margin?: string,
}
export const AuthUserContainer = styled.div<AuthUserContainerProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.3em;
    margin: ${({ margin }) => margin || '0px'};
`;

export const AuthUserIcon = styled.div`
    position: relative;
    height: 1em;
    width: 1em;
    margin-right: ${`${0.25 * BODY_FONT_SIZE}px`};

    & div {
        width: 100%;
        height: 100%;
    }

    & svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 1em;
        width: 1em;
    }

    & path {
        fill: ${({ theme }) => theme.color.neutral500};
    }
`;

export const AuthUserText = styled.p`
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.neutral700};
    white-space: nowrap;
    margin-left: ${`${0.3125 * BODY_FONT_SIZE}px`};
`;

export const AuthVerificationContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: ${`${0.4 * BODY_FONT_SIZE}px`};
`;

interface AuthEmailVerificationBadgeProps {
    height: number,
}
export const AuthEmailVerificationBadge = styled.p<AuthEmailVerificationBadgeProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${`0px ${0.4 * BODY_FONT_SIZE}px`};
    height: ${({ height }) => `${height}px`};
    margin: 0;
    border-radius: ${({ height }) => `${height / 2}px`};
    border: ${({ theme }) => `${0.1 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple400}`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 0.6em;
    color: ${({ theme }) => theme.verascopeColor.purple400};
    white-space: nowrap;
`;

export const AuthUserRow = styled.div`
    position: absolute;
    right: 0;
    margin: 0;
`;

export const AuthUserSignOutButtonContainer = styled.div`
    margin-top: ${`${0.625 * BODY_FONT_SIZE}px`};
`;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
    useCallback,
}                                               from 'react';
import styled                                   from 'styled-components';
import { ReactSVG }                             from 'react-svg';
import { Transition }                           from 'react-transition-group';
import { useDropzone }                          from 'react-dropzone';
import {
    Range,
    Selection,
}                                               from 'slate';
import {
    UploadTask,
    StorageError,
    getDownloadURL,
    UploadTaskSnapshot,
    StorageReference,
}                                               from 'firebase/storage';
import ShortUniqueId                            from 'short-unique-id';
import { v4 as uuidv4 }                         from 'uuid';
import mime                                     from 'mime-types';

// ===== Components =====

import Tooltip                                  from '../../Tooltip';
import { Button }                               from '.';
import { DropzoneInput }                        from '../../../styles';

// ===== Hooks =====

import {
    useTimeout,
    useEventListener,
}                                               from '../../../hooks';

// ===== Services =====

import {
    isMacOS,
    validateURL,
    setColorLightness,
    getSpotifyId,
    getTwitterId,
    getYouTubeParams,
    getVimeoId,
    deepCopy,
    playAudio,
    getMediaStorageBucket,
    uploadToCloudStorage,
    setMediaInDB,
    getStorageErrorMessage,
}                                               from '../../../services';

// ===== Enums =====

import {
    TOOLTIP_TYPE,
    BUTTON_TYPE,
    EDITOR_TOOL,
    EDITOR_TOOLBAR_TOOL_GROUP,
    STORAGE_ENTITY,
    MEDIA_TYPE,
    EDITOR_EMBEDDING_TYPE,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    STORAGE_ERROR_CODE,
}                                               from '../../../enums';

// ===== Interfaces =====

import {
    IToolGroup,
    IMediaItem,
    IEditorLinkEmbeddingLinkBundle,
    ITool,
    IUserItem,
}                                               from '../../../interfaces';

// ===== Images =====

// Group Icons
import TextIcon                                 from '../../../images/editor/text.svg';
import LayoutIcon                               from '../../../images/editor/paintbrush.svg';
import MediaIcon                                from '../../../images/editor/play-stroke.svg';
import IntegrationsIcon                         from '../../../images/editor/integrations.svg';

// Text
import BoldIcon                                 from '../../../images/editor/bold.svg';
import ItalicIcon                               from '../../../images/editor/italic.svg';
import UnderlineIcon                            from '../../../images/editor/underline.svg';
import StrikethroughIcon                        from '../../../images/editor/strikethrough.svg';

// Layout
import BulletListIcon                           from '../../../images/editor/unordered-list.svg';
import NumberedListIcon                         from '../../../images/editor/ordered-list.svg';
import HeaderOneIcon                            from '../../../images/editor/header-1.svg';
import HeaderTwoIcon                            from '../../../images/editor/header-2.svg';
import QuoteIcon                                from '../../../images/editor/quote.svg';
import TableIcon                                from '../../../images/editor/table.svg';
import ChecklistIcon                            from '../../../images/editor/checkbox.svg';
import CodeIcon                                 from '../../../images/editor/code.svg';
import DividerIcon                              from '../../../images/editor/divider.svg';

// Media
import ImageIcon                                from '../../../images/editor/image.svg';
import LinkIcon                                 from '../../../images/editor/link.svg';
import AtSignIcon                               from '../../../images/editor/at-sign.svg';
import MicrophoneIcon                           from '../../../images/editor/mic.svg';
import SketchIcon                               from '../../../images/editor/sketch.svg';

// Integrations
import YouTubeIcon                              from '../../../images/editor/youtube.svg';
import TwitterIcon                              from '../../../images/editor/twitter.svg';
import SpotifyIcon                              from '../../../images/editor/spotify.svg';
import VimeoIcon                                from '../../../images/editor/vimeo.svg';

// Other
import ChevronIcon                              from '../../../images/editor/chevron.svg';
import CrossIcon                                from '../../../images/editor/cross.svg';
import ArrowIcon                                from '../../../images/editor/arrow.svg';

// ===== Sounds =====

import InputClick                               from '../../../sounds/button_click.mp3';
import CreateClip                               from '../../../sounds/create.mp3';
import PivotExpand                              from '../../../sounds/swoosh_in.mp3';
import PivotContract                            from '../../../sounds/swoosh_out.mp3';

// ===== Constants =====

import CURSOR_SIGN                              from '../../../constants/cursorSigns';
import KEYCODE                                  from '../../../constants/keycodes';
import {
    DEFAULT_AUDIO_VOLUME,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    BUTTON_TEXT_LIGHTNESS_VALUE,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_OFFSET_DURATION,
    BUTTON_CONTAINER_LIGHTNESS_VALUE,
    HOVER_TARGET_CLASSNAME,
    BODY_FONT_SIZE,
}                                               from '../../../constants/generalConstants';
import FONT_TYPE                                from '../../../constants/fontType';
import {
    TOOL_MARGIN,
    TOOL_MARGIN_LEFT,
    MAX_TOOLS_PER_ROW,
    TOOL_GROUPS_COUNT,
    TOOL_GROUP_MARGIN,
    TOOL_GROUP_PADDING,
    GROUP_EXPAND_TRANSITION,
    TOOL_TRANSITION_DURATION,
    PIVOT_TRANSITION_DURATION,
    TOOLTIP_TRANSITION_DURATION,
    LINK_CARD_TRANSITION_DURATION,
    GROUP_TOOL_MARGIN_LEFT_ADDITION,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
}                                               from './constants';

// ===== Styles =====

import { theme as themeObj }                    from '../../../themes/theme-context';

StationaryToolbar.defaultProps = {
    onCursorEnter: undefined,
    onCursorLeave: undefined,
};
interface Props {
    show: boolean,
    hasSound: boolean,
    user: IUserItem | null,
    color: string,
    buttonLength: number,
    toggleBold: () => void,
    containsBold: boolean | undefined,
    toggleItalics: () => void,
    containsItalics: boolean | undefined,
    toggleHeaderOne: () => void,
    containsHeaderOne: boolean | undefined,
    toggleHeaderTwo: () => void,
    containsHeaderTwo: boolean | undefined,
    toggleUnderline: () => void,
    containsUnderline: boolean | undefined,
    toggleStrikethrough: () => void,
    containsStrikethrough: boolean | undefined,
    toggleBulletedList: () => void,
    containsBulletedList: boolean | undefined,
    toggleNumberedList: () => void,
    containsNumberedList: boolean | undefined,
    toggleQuote: () => void,
    containsQuote: boolean | undefined,
    toggleDivider: () => void,
    containsDivider: boolean | undefined,
    createFigure: (id: string, filePath: string, caption: string) => void,
    containsFigure: boolean | undefined,
    createLink: (id: string, href: string, selection: Selection) => void,
    createYouTubeEmbedding: (id: string, src: string) => void,
    containsYouTube: boolean | undefined,
    createTwitterEmbedding: (id: string, src: string) => void,
    containsTwitter: boolean | undefined,
    createSpotifyEmbedding: (id: string, src: string) => void,
    containsSpotify: boolean | undefined,
    createVimeoEmbedding: (id: string, src: string) => void,
    containsVimeo: boolean | undefined,
    insertAudioNote: (id: string) => void,
    containsAudioNote: boolean | undefined,
    defaultOpenGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, boolean>,
    selection: Selection,
    setSelection: (selection: Range) => void,
    selectEndDocument: () => void,
    uploadingMedia: Map<string, IMediaItem>,
    setUploadingMedia: (mediaItem: IMediaItem) => void,
    updateUploadingMedia: (mediaItem: IMediaItem) => void,
    onCursorEnter?: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave?: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
}
function StationaryToolbar({
    show = false,
    hasSound,
    user,
    color = '#4194D1',
    buttonLength,
    toggleBold,
    containsBold = false,
    toggleItalics,
    containsItalics = false,
    toggleHeaderOne,
    containsHeaderOne = false,
    toggleHeaderTwo,
    containsHeaderTwo = false,
    toggleUnderline,
    containsUnderline = false,
    toggleStrikethrough,
    containsStrikethrough = false,
    toggleBulletedList,
    containsBulletedList = false,
    toggleNumberedList,
    containsNumberedList = false,
    toggleQuote,
    containsQuote = false,
    toggleDivider,
    containsDivider = false,
    createFigure,
    containsFigure = false,
    createLink,
    createYouTubeEmbedding,
    containsYouTube = false,
    createTwitterEmbedding,
    containsTwitter = false,
    createSpotifyEmbedding,
    containsSpotify = false,
    createVimeoEmbedding,
    containsVimeo = false,
    insertAudioNote,
    containsAudioNote = false,
    defaultOpenGroups,
    selection,
    setSelection,
    selectEndDocument,
    uploadingMedia,
    setUploadingMedia,
    updateUploadingMedia,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
}: Props): JSX.Element {
    // ===== Refs =====

    const linkInputRef = useRef<HTMLInputElement>(null);

    // ----- Sound Clips

    const createAudioNoteClip = useRef<HTMLAudioElement>(new Audio());
    const inputClickClip = useRef<HTMLAudioElement>(new Audio());
    const pivotExpandClip = useRef<HTMLAudioElement>(new Audio());
    const pivotContractClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    const [allToolGroupsOpen, setAllToolGroupsOpen] = useState<boolean>(true);
    const [allToolGroupsClosed, setAllToolGroupsClosed] = useState<boolean>(true);
    const [linkURL, setLinkURL] = useState<string | null>(null);
    const [linkPlaceholderText, setLinkPlaceholderText] = useState<string>('');
    const [selectedTool, setSelectedTool] = useState<EDITOR_TOOL | null>(null);
    const [textSelection, setTextSelection] = useState<Range | null>(null);
    const [uploadingStateChangeEvent, setUploadingStateChangeEvent] = useState<{
        mediaItem: IMediaItem,
        snapshot: UploadTaskSnapshot,
        progress: number,
    } | null>(null);
    const [uploadingCompleteEvent, setUploadingCompleteEvent] = useState<{
        mediaItem: IMediaItem,
        storageRef: StorageReference,
    } | null>(null);
    // Upload dropzone files to storage
    // and create figure
    const onDropAccepted = useCallback((acceptedFiles: File[]) => {
        const uploadTasks: UploadTask[] = [];
        const mediaItems: IMediaItem[] = [];
        for (let i = 0; i < acceptedFiles.length; i += 1) {
            const file = acceptedFiles[i];
            const mediaId = uuidv4();
            const uniqueId = new ShortUniqueId({ length: 6 })(); // avoid file name collisions
            const mediaBucket = getMediaStorageBucket(MEDIA_TYPE.image);
            const fileName = file.name.toLowerCase().split('.')[0].replace(' ', '_');
            const fileExtension = mime.extension(file.type);
            const storageEntity = process.env.NODE_ENV === 'development'
                ? STORAGE_ENTITY.stagingAnnotations
                : STORAGE_ENTITY.annotations;
            const filePath = `${storageEntity}/${mediaBucket}/${mediaId}/${fileName}-${uniqueId}.${fileExtension}`;
            const mediaItem: IMediaItem = {
                id: mediaId,
                userId: user!.id,
                file,
                filePath,
                type: MEDIA_TYPE.image,
                uploadProgress: 0,
                caption: '',
            };
            mediaItems.push(mediaItem);
            setUploadingMedia(mediaItem);

            // make db entry of media
            // execute before file upload so upload cloud function has a place to write to
            setMediaInDB({
                mediaItem,
                filePath,
            });

            // upload file to cloud storage
            // url will be set by cloud function
            const uploadTask = uploadToCloudStorage(
                file,
                filePath,
            );
            uploadTasks.push(uploadTask);
        }

        uploadTasks.forEach((task: UploadTask, index: number) => {
            task.on(
                'state_changed',
                (snapshot: UploadTaskSnapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadingStateChangeEvent({
                        mediaItem: mediaItems[index],
                        snapshot,
                        progress,
                    });
                },
                (error: StorageError) => {
                    throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
                },
                () => {
                    setUploadingCompleteEvent({
                        mediaItem: mediaItems[index],
                        storageRef: task.snapshot.ref,
                    });
                },
            );
        });
    }, [user]);
    const {
        open: openFileSelector,
        getRootProps,
        getInputProps,
    } = useDropzone({
        noClick: true,
        noDrag: true,
        noKeyboard: true,
        accept: 'image/*',
        onDropAccepted,
    });

    const onBold = (tool: EDITOR_TOOL, sel: Selection): void => toggleBold();

    const onItalic = (tool: EDITOR_TOOL, sel: Selection): void => toggleItalics();

    const onUnderline = (tool: EDITOR_TOOL, sel: Selection): void => toggleUnderline();

    const onStrikethrough = (tool: EDITOR_TOOL, sel: Selection): void => toggleStrikethrough();

    const onBulletList = (tool: EDITOR_TOOL, sel: Selection): void => toggleBulletedList();

    const onNumberList = (tool: EDITOR_TOOL, sel: Selection): void => toggleNumberedList();

    const onHeaderOne = (tool: EDITOR_TOOL, sel: Selection): void => toggleHeaderOne();

    const onHeaderTwo = (tool: EDITOR_TOOL, sel: Selection): void => toggleHeaderTwo();

    const onQuote = (tool: EDITOR_TOOL, sel: Selection): void => toggleQuote();

    const onDivider = (tool: EDITOR_TOOL, sel: Selection): void => toggleDivider();

    const onFigure = (tool: EDITOR_TOOL, sel: Selection): void => {
        if (!sel) {
            // If we want to add a Figure to Slate, without prior selection
            // Select end of document first
            selectEndDocument();
        }

        openFileSelector();
    };

    const onAudioNote = (tool: EDITOR_TOOL, sel: Selection): void => {
        // Make sure selection is set
        if (sel) {
            setSelection(sel);
        } else {
            // If we want to add an audio note to Slate, without prior selection
            // Select end of document first
            selectEndDocument();
        }

        // Play Sound
        if (hasSound && createAudioNoteClip.current) {
            createAudioNoteClip.current.pause();
            createAudioNoteClip.current.currentTime = 0;
            playAudio(createAudioNoteClip.current);
        }

        const audioNoteId = uuidv4();
        insertAudioNote(audioNoteId);
    };

    const onLink = (tool: EDITOR_TOOL, sel: Selection): void => {
        if (sel) {
            setSelection(sel);
        }
        setSelectedTool(tool);
    };

    const onEmbedding = (tool: EDITOR_TOOL, sel: Selection): void => {
        if (sel) {
            setSelection(sel);
        }

        setSelectedTool(tool);
    };

    // TODO Tools still to be implemented

    const onTable = (tool: EDITOR_TOOL, sel: Selection): void => console.log('Handle Table', tool);

    const onChecklist = (tool: EDITOR_TOOL, sel: Selection): void => console.log('Handle Checklist', tool);

    const onCode = (tool: EDITOR_TOOL, sel: Selection): void => console.log('Handle Code', tool);

    const onMention = (tool: EDITOR_TOOL, sel: Selection): void => console.log('Handle Mention', tool);

    const onSketch = (tool: EDITOR_TOOL, sel: Selection): void => console.log('Handle Sketch', tool);

    // ===== State =====

    const [toolGroups, setToolGroups] = useState<Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup>>(new Map([
        [
            EDITOR_TOOLBAR_TOOL_GROUP.text,
            {
                id: EDITOR_TOOLBAR_TOOL_GROUP.text,
                currentIndex: 0,
                index: 0,
                active:
                    !!toggleBold
                    || !!toggleItalics
                    || !!toggleHeaderOne
                    || !!toggleHeaderTwo
                    || !!toggleUnderline
                    || !!toggleStrikethrough
                    || !!createLink,
                expanded: !!defaultOpenGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text),
                name: 'Text',
                icon: TextIcon,
                tools: new Map([
                    [
                        EDITOR_TOOL.bold,
                        {
                            id: EDITOR_TOOL.bold,
                            active: !!toggleBold,
                            applied: containsBold,
                            name: 'Bold',
                            icon: BoldIcon,
                            shortcut: useMemo(() => {
                                if (isMacOS()) {
                                    return '⌘ + B';
                                }

                                return 'Ctrl + B';
                            }, []),
                            handleClick: onBold,
                        },
                    ],
                    [
                        EDITOR_TOOL.italic,
                        {
                            id: EDITOR_TOOL.italic,
                            active: !!toggleItalics,
                            applied: containsItalics,
                            name: 'Italic',
                            icon: ItalicIcon,
                            shortcut: useMemo(() => {
                                if (isMacOS()) {
                                    return '⌘ + I';
                                }

                                return 'Ctrl + I';
                            }, []),
                            handleClick: onItalic,
                        },
                    ],
                    [
                        EDITOR_TOOL.headerOne,
                        {
                            id: EDITOR_TOOL.headerOne,
                            active: !!toggleHeaderOne,
                            applied: containsHeaderOne,
                            name: 'Header 1',
                            icon: HeaderOneIcon,
                            // shortcut: useMemo(() => '# + Space'),
                            shortcut: null,
                            handleClick: onHeaderOne,
                        },
                    ],
                    [
                        EDITOR_TOOL.headerTwo,
                        {
                            id: EDITOR_TOOL.headerTwo,
                            active: !!toggleHeaderTwo,
                            applied: containsHeaderTwo,
                            name: 'Header 2',
                            icon: HeaderTwoIcon,
                            // shortcut: useMemo(() => '## + Space'),
                            shortcut: null,
                            handleClick: onHeaderTwo,
                        },
                    ],
                    [
                        EDITOR_TOOL.underline,
                        {
                            id: EDITOR_TOOL.underline,
                            active: !!toggleUnderline,
                            applied: containsUnderline,
                            name: 'Underline',
                            icon: UnderlineIcon,
                            shortcut: useMemo(() => {
                                if (isMacOS()) {
                                    return '⌘ + U';
                                }

                                return 'Ctrl + U';
                            }, []),
                            handleClick: onUnderline,
                        },
                    ],
                    [
                        EDITOR_TOOL.strikethrough,
                        {
                            id: EDITOR_TOOL.strikethrough,
                            active: !!toggleStrikethrough,
                            applied: containsStrikethrough,
                            name: 'Strikethrough',
                            icon: StrikethroughIcon,
                            shortcut: useMemo(() => {
                                if (isMacOS()) {
                                    return '⌘ + Shift + S';
                                }

                                return 'Ctrl + Shift + S';
                            }, []),
                            handleClick: onStrikethrough,
                        },
                    ],
                    [
                        EDITOR_TOOL.link,
                        {
                            id: EDITOR_TOOL.link,
                            active: !!createLink,
                            applied: false,
                            name: 'Link',
                            icon: LinkIcon,
                            // shortcut: useMemo(() => {
                            //     if (isMacOS()) {
                            //         return '⌘ + K';
                            //     }
                            //
                            //     return 'Ctrl + K';
                            // }),
                            shortcut: null,
                            handleClick: onLink,
                        },
                    ],
                ]),
            },
        ],
        [
            EDITOR_TOOLBAR_TOOL_GROUP.layout,
            {
                id: EDITOR_TOOLBAR_TOOL_GROUP.layout,
                currentIndex: 1,
                index: 1,
                active:
                    !!toggleBulletedList
                    || !!toggleNumberedList
                    || !!toggleQuote
                    || !!toggleDivider,
                name: 'Layout',
                expanded: !!defaultOpenGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout),
                icon: LayoutIcon,
                tools: new Map([
                    [
                        EDITOR_TOOL.bulletedList,
                        {
                            id: EDITOR_TOOL.bulletedList,
                            active: !!toggleBulletedList,
                            applied: containsBulletedList,
                            name: 'Bulleted List',
                            icon: BulletListIcon,
                            // shortcut: useMemo(() => '* + Space'),
                            shortcut: null,
                            handleClick: onBulletList,
                        },
                    ],
                    [
                        EDITOR_TOOL.numberedList,
                        {
                            id: EDITOR_TOOL.numberedList,
                            active: !!toggleNumberedList,
                            applied: containsNumberedList,
                            name: 'Numbered List',
                            icon: NumberedListIcon,
                            // shortcut: useMemo(() => '1. + Space'),
                            shortcut: null,
                            handleClick: onNumberList,
                        },
                    ],
                    [
                        EDITOR_TOOL.quote,
                        {
                            id: EDITOR_TOOL.quote,
                            active: !!toggleQuote,
                            applied: containsQuote,
                            name: 'Quote',
                            icon: QuoteIcon,
                            // shortcut: useMemo(() => '> + Space'),
                            shortcut: null,
                            handleClick: onQuote,
                        },
                    ],
                    [
                        EDITOR_TOOL.table,
                        {
                            id: EDITOR_TOOL.table,
                            // TODO Set to false based on block or selection (and whether callback exists)
                            active: false,
                            // TODO Set to be true if props sent down
                            applied: false,
                            name: 'Table',
                            icon: TableIcon,
                            shortcut: null,
                            handleClick: onTable,
                        },
                    ],
                    [
                        EDITOR_TOOL.checklist,
                        {
                            id: EDITOR_TOOL.checklist,
                            // TODO Set to false based on block or selection (and whether callback exists)
                            active: false,
                            // TODO Set to be true if props sent down
                            applied: false,
                            name: 'Checklist',
                            icon: ChecklistIcon,
                            // shortcut: useMemo(() => '[] + Space'),
                            shortcut: null,
                            handleClick: onChecklist,
                        },
                    ],
                    [
                        EDITOR_TOOL.code,
                        {
                            id: EDITOR_TOOL.code,
                            // TODO Set to false based on block or selection (and whether callback exists)
                            active: false,
                            // TODO Set to be true if props sent down
                            applied: false,
                            name: 'Code',
                            icon: CodeIcon,
                            // shortcut: useMemo(() => '```'),
                            shortcut: null,
                            handleClick: onCode,
                        },
                    ],
                    [
                        EDITOR_TOOL.divider,
                        {
                            id: EDITOR_TOOL.divider,
                            active: !!toggleDivider,
                            applied: containsDivider,
                            name: 'Divider',
                            icon: DividerIcon,
                            // shortcut: useMemo(() => '---'),
                            shortcut: null,
                            handleClick: onDivider,
                        },
                    ],
                ]),
            },
        ],
        [
            EDITOR_TOOLBAR_TOOL_GROUP.media,
            {
                id: EDITOR_TOOLBAR_TOOL_GROUP.media,
                currentIndex: 2,
                index: 2,
                active:
                    !!createFigure
                    || !!insertAudioNote,
                name: 'Media',
                expanded: !!defaultOpenGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media),
                icon: MediaIcon,
                tools: new Map([
                    [
                        EDITOR_TOOL.figure,
                        {
                            id: EDITOR_TOOL.figure,
                            active: !!createFigure,
                            applied: containsFigure,
                            name: 'Image',
                            icon: ImageIcon,
                            shortcut: null,
                            handleClick: onFigure,
                        },
                    ],
                    [
                        EDITOR_TOOL.mention,
                        {
                            id: EDITOR_TOOL.mention,
                            // TODO Set to false based on block or selection (and whether callback exists)
                            active: false,
                            // TODO Set to be true if props sent down
                            applied: false,
                            name: 'Mention',
                            icon: AtSignIcon,
                            shortcut: useMemo(() => '@', []),
                            handleClick: onMention,
                        },
                    ],
                    [
                        EDITOR_TOOL.audioNote,
                        {
                            id: EDITOR_TOOL.audioNote,
                            active: !!insertAudioNote,
                            applied: containsAudioNote,
                            name: 'Audio Note',
                            icon: MicrophoneIcon,
                            shortcut: null,
                            handleClick: onAudioNote,
                        },
                    ],
                    [
                        EDITOR_TOOL.sketch,
                        {
                            id: EDITOR_TOOL.sketch,
                            // TODO Set to false based on block or selection (and whether callback exists)
                            active: false,
                            // TODO Set to be true if props sent down
                            applied: false,
                            name: 'Sketch',
                            icon: SketchIcon,
                            shortcut: null,
                            handleClick: onSketch,
                        },
                    ],
                ]),
            },
        ],
        [
            EDITOR_TOOLBAR_TOOL_GROUP.embeddings,
            {
                id: EDITOR_TOOLBAR_TOOL_GROUP.embeddings,
                currentIndex: 3,
                index: 3,
                active: !!createYouTubeEmbedding
                || !!createTwitterEmbedding
                || !!createSpotifyEmbedding,
                name: 'Embeddings',
                expanded: !!defaultOpenGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings),
                icon: IntegrationsIcon,
                tools: new Map([
                    [
                        EDITOR_TOOL.youtube,
                        {
                            id: EDITOR_TOOL.youtube,
                            active: !!createYouTubeEmbedding,
                            applied: containsYouTube,
                            name: 'YouTube',
                            icon: YouTubeIcon,
                            shortcut: null,
                            handleClick: onEmbedding,
                        },
                    ],
                    [
                        EDITOR_TOOL.twitter,
                        {
                            id: EDITOR_TOOL.twitter,
                            active: !!createTwitterEmbedding,
                            applied: containsTwitter,
                            name: 'Twitter',
                            icon: TwitterIcon,
                            shortcut: null,
                            handleClick: onEmbedding,
                        },
                    ],
                    [
                        EDITOR_TOOL.spotify,
                        {
                            id: EDITOR_TOOL.spotify,
                            active: !!createSpotifyEmbedding,
                            applied: containsSpotify,
                            name: 'Spotify',
                            icon: SpotifyIcon,
                            shortcut: null,
                            handleClick: onEmbedding,
                        },
                    ],
                    [
                        EDITOR_TOOL.vimeo,
                        {
                            id: EDITOR_TOOL.vimeo,
                            active: !!createVimeoEmbedding,
                            applied: containsVimeo,
                            name: 'Vimeo',
                            icon: VimeoIcon,
                            shortcut: null,
                            handleClick: onEmbedding,
                        },
                    ],
                ]),
            },
        ],
        [
            EDITOR_TOOLBAR_TOOL_GROUP.expandAll,
            {
                id: EDITOR_TOOLBAR_TOOL_GROUP.expandAll,
                currentIndex: 4,
                index: 4,
                active: true,
                name: 'Expand All',
                tools: null,
            },
        ],
    ]));

    // ===== Side Effects =====

    // Always expand defauly groups when show
    // User might have collapsed some in previous
    // toolbar session
    useEffect(() => {
        if (show && defaultOpenGroups) {
            const updatedToolGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup> = deepCopy(toolGroups);
            Array.from(defaultOpenGroups.keys()).forEach((groupName) => {
                updatedToolGroups.get(groupName)!.expanded = true;
            });
            setToolGroups(updatedToolGroups);
        }

        if (
            !textSelection
            && selectedTool
        ) {
            // Make sure toolbar has no selection when revealed
            setSelectedTool(null);
        }
    }, [
        show,
    ]);

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            createAudioNoteClip.current
            && inputClickClip.current
        ) {
            // Create Audio Note
            createAudioNoteClip.current.volume = DEFAULT_AUDIO_VOLUME;
            createAudioNoteClip.current.src = CreateClip;

            // Input Click
            inputClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            inputClickClip.current.src = InputClick;

            // Pivot Expand
            pivotExpandClip.current.volume = DEFAULT_AUDIO_VOLUME;
            pivotExpandClip.current.src = PivotExpand;

            // Pivot Contract
            pivotContractClip.current.volume = DEFAULT_AUDIO_VOLUME;
            pivotContractClip.current.src = PivotContract;
        }

        return function cleanup() {
            if (createAudioNoteClip.current) createAudioNoteClip.current.remove();
            if (inputClickClip.current) inputClickClip.current.remove();
            if (pivotExpandClip.current) pivotExpandClip.current.remove();
            if (pivotContractClip.current) pivotContractClip.current.remove();
        };
    }, []);

    useEffect(() => {
        if (uploadingStateChangeEvent) {
            handleUploadingMediaStateChange(
                uploadingStateChangeEvent.mediaItem,
                uploadingStateChangeEvent.snapshot,
                uploadingStateChangeEvent.progress,
            );
            // setUploadingStateChangeEvent(null);
        }
    }, [uploadingStateChangeEvent]);

    useEffect(() => {
        if (uploadingCompleteEvent) {
            // Handle successful uploads on complete
            getDownloadURL(uploadingCompleteEvent.storageRef).then((downloadURL: string) => {
                handleUploadingMediaComplete(uploadingCompleteEvent.mediaItem, downloadURL);
            });
        }
    }, [uploadingCompleteEvent]);

    // if creating a link and press enter,
    // initiate submit process
    useEventListener(
        'keydown',
        (e: Event) => {
            if ((
                (e as KeyboardEvent).key === KEYCODE.enter
                && selectedTool === EDITOR_TOOL.link
                && linkURL
                && validateURL(linkURL)
            ) || (
                (e as KeyboardEvent).key === KEYCODE.enter
                && selectedTool === EDITOR_TOOL.youtube
                && linkURL
                && getYouTubeParams(linkURL)
            )
            || (
                (e as KeyboardEvent).key === KEYCODE.enter
                && selectedTool === EDITOR_TOOL.twitter
                && linkURL
                && getTwitterId(linkURL)
            ) || (
                (e as KeyboardEvent).key === KEYCODE.enter
                && selectedTool === EDITOR_TOOL.spotify
                && linkURL
                && getSpotifyId(linkURL)
            ) || (
                (e as KeyboardEvent).key === KEYCODE.enter
                && selectedTool === EDITOR_TOOL.vimeo
                && linkURL
                && getVimeoId(linkURL)
            )) {
                if (selectedTool === EDITOR_TOOL.link) {
                    onLinkSubmit();
                } else {
                    if (!selection) {
                        // If we want to add a link to Slate, without prior selection
                        // Select end of document first
                        selectEndDocument();
                    }

                    onEmbeddingCreate(selectedTool, e);
                }
            }
        },
        linkInputRef.current,
    );

    // if creating a link
    // focus link input
    useEffect(() => {
        if (
            (
                selectedTool === EDITOR_TOOL.link
                || selectedTool === EDITOR_TOOL.youtube
                || selectedTool === EDITOR_TOOL.twitter
                || selectedTool === EDITOR_TOOL.spotify
                || selectedTool === EDITOR_TOOL.vimeo
            ) && linkInputRef.current
        ) {
            linkInputRef.current.focus();
            if (selectedTool === EDITOR_TOOL.link && linkURL) {
                // If editing link, place value in link
                linkInputRef.current.value = linkURL;
            } else {
                // If adding an embedding, clear link
                linkInputRef.current.value = '';
            }

            switch (selectedTool) {
            case EDITOR_TOOL.youtube:
                setLinkPlaceholderText('Enter YouTube URL');
                break;
            case EDITOR_TOOL.twitter:
                setLinkPlaceholderText('Enter Twitter URL');
                break;
            case EDITOR_TOOL.spotify:
                setLinkPlaceholderText('Enter Spotify URL');
                break;
            case EDITOR_TOOL.vimeo:
                setLinkPlaceholderText('Enter Vimeo URL');
                break;
            default:
                setLinkPlaceholderText('Enter Link URL');
                break;
            }
        }
    }, [selectedTool, linkInputRef.current]);

    // maintain editor text selection when typing out link
    useEffect(() => {
        if (
            !selection
            && document.activeElement === linkInputRef.current
            && textSelection) {
            // Place lost selection back on slate editor
            // create link process needs to read it
            setSelection(textSelection);
        }
    }, [document.activeElement, selection]);

    // maintain/keep track of selection while selecting emojis
    // and change valid selection while typing input
    useEffect(() => {
        if (
            (
                selectedTool === EDITOR_TOOL.link
                || selectedTool === EDITOR_TOOL.youtube
                || selectedTool === EDITOR_TOOL.twitter
                || selectedTool === EDITOR_TOOL.spotify
                || selectedTool === EDITOR_TOOL.vimeo
            ) && selection
            && selection !== textSelection
        ) {
            setTextSelection(selection);
        }
    }, [selection]);

    const allClosed = Array.from(toolGroups.values())
        .filter((group) => group.active)
        .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
        .filter((group) => !group.expanded);
    const toolCount = allClosed.map((group) => Array.from(group.tools!.values()).length);

    const {
        start: delayCloseAllToolGroups,
        clear: clearDelayCloseAllToolGroups,
    } = useTimeout(() => {
        const groupsClosed = allClosed.length
        === Array.from(toolGroups.values())
            .filter((group) => group.active)
            .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll).length;
        setAllToolGroupsClosed(groupsClosed);
    }, Math.max(...toolCount) * (TOOL_TRANSITION_DURATION + 25));

    // Determine if all groups closed
    useEffect(() => {
        const groupsClosed = allClosed.length
        === Array.from(toolGroups.values())
            .filter((group) => group.active)
            .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll).length;
        if (groupsClosed !== allToolGroupsClosed) {
            if (!groupsClosed) {
                setAllToolGroupsClosed(groupsClosed);
            } else {
                clearDelayCloseAllToolGroups();
                delayCloseAllToolGroups();
            }
        }
    }, [toolGroups]);

    // Determine if all groups open
    const {
        start: delayOpenAllToolGroups,
        clear: clearDelayOpenAllToolGroups,
    } = useTimeout(() => {
        const allOpen = Array.from(toolGroups.values())
            .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
            .reduce((result, group) => ((result && group.expanded) || false), true);
        setAllToolGroupsOpen(allOpen);
    }, Math.max(...toolCount) * (TOOL_TRANSITION_DURATION + 25));

    useEffect(() => {
        const allOpen = Array.from(toolGroups.values())
            .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
            .reduce((result, group) => ((result && group.expanded) || false), true);

        if (
            allOpen !== allToolGroupsOpen
            && allOpen
        ) {
            setAllToolGroupsOpen(allOpen);
        } else if (
            allOpen !== allToolGroupsOpen
            && !allOpen
        ) {
            clearDelayOpenAllToolGroups();
            delayOpenAllToolGroups();
        }

        // Play Sound
        if (
            hasSound
            && !allOpen
            && allToolGroupsOpen
            && pivotExpandClip.current
        ) {
            pivotExpandClip.current.pause();
            pivotExpandClip.current.currentTime = 0;
            playAudio(pivotExpandClip.current);
        } else if (
            hasSound
            && allOpen
            && !allToolGroupsOpen
            && pivotContractClip.current
        ) {
            pivotContractClip.current.pause();
            pivotContractClip.current.currentTime = 0;
            playAudio(pivotContractClip.current);
        }
    }, [show, toolGroups]);

    // Determines Applied Tool
    useEffect(() => {
        const updatedToolGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup> = deepCopy(toolGroups);

        // YouTube
        if (
            containsYouTube !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)?.tools?.get(EDITOR_TOOL.youtube)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)!.tools!.get(EDITOR_TOOL.youtube)!.applied = containsYouTube;
        }

        // Twitter
        if (
            containsTwitter !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)?.tools?.get(EDITOR_TOOL.twitter)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)!.tools!.get(EDITOR_TOOL.twitter)!.applied = containsTwitter;
        }

        // Spotify
        if (
            containsSpotify !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)?.tools?.get(EDITOR_TOOL.spotify)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)!.tools!.get(EDITOR_TOOL.spotify)!.applied = containsSpotify;
        }

        // Vimeo
        if (
            containsSpotify !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)?.tools?.get(EDITOR_TOOL.vimeo)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)!.tools!.get(EDITOR_TOOL.vimeo)!.applied = containsVimeo;
        }

        // Figure
        if (
            containsFigure !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media)?.tools?.get(EDITOR_TOOL.figure)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media)!.tools!.get(EDITOR_TOOL.figure)!.applied = containsFigure;
        }

        // Audio Note
        if (
            containsAudioNote !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media)?.tools?.get(EDITOR_TOOL.audioNote)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media)!.tools!.get(EDITOR_TOOL.audioNote)!.applied = containsAudioNote;
        }

        // Note: Link is missing because Stationary Toolbar will never have an applied Link

        // Divider
        if (
            containsDivider !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)?.tools?.get(EDITOR_TOOL.divider)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)!.tools!.get(EDITOR_TOOL.divider)!.applied = containsDivider;
        }

        // Bulleted List
        if (
            containsBulletedList !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)?.tools?.get(EDITOR_TOOL.bulletedList)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)!.tools!.get(EDITOR_TOOL.bulletedList)!.applied = containsBulletedList;
        }

        // Numbered List
        if (
            containsNumberedList !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)?.tools?.get(EDITOR_TOOL.numberedList)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)!.tools!.get(EDITOR_TOOL.numberedList)!.applied = containsNumberedList;
        }

        // Quote
        if (
            containsQuote !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)?.tools?.get(EDITOR_TOOL.quote)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)!.tools!.get(EDITOR_TOOL.quote)!.applied = containsQuote;
        }

        // Header One
        if (
            containsHeaderOne !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.headerOne)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.headerOne)!.applied = containsHeaderOne;
        }

        // Header Two
        if (
            containsHeaderTwo !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.headerTwo)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.headerTwo)!.applied = containsHeaderTwo;
        }

        // Bold
        if (
            containsBold !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.bold)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.bold)!.applied = containsBold;
        }

        // Underline
        if (
            containsUnderline !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.underline)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.underline)!.applied = containsUnderline;
        }

        // Strikethrough
        if (
            containsStrikethrough !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.strikethrough)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.strikethrough)!.applied = containsStrikethrough;
        }

        // Italics
        if (
            containsItalics !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.italic)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.italic)!.applied = containsItalics;
        }

        setToolGroups(updatedToolGroups);
    }, [
        containsBold,
        containsItalics,
        containsHeaderOne,
        containsHeaderTwo,
        containsUnderline,
        containsStrikethrough,
        containsBulletedList,
        containsNumberedList,
        containsQuote,
        containsDivider,
        containsFigure,
        containsYouTube,
        containsTwitter,
        containsSpotify,
        containsAudioNote,
    ]);

    // ===== Methods =====

    const handleGroupExpanded = (id: EDITOR_TOOLBAR_TOOL_GROUP, expanded: boolean): void => {
        const updatedToolGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup> = deepCopy(toolGroups);
        updatedToolGroups.get(id)!.expanded = expanded;
        setToolGroups(updatedToolGroups);

        // Play Sound
        if (
            hasSound
            && expanded
            && pivotExpandClip.current
        ) {
            pivotExpandClip.current.pause();
            pivotExpandClip.current.currentTime = 0;
            playAudio(pivotExpandClip.current);
        } else if (
            hasSound
            && !expanded
            && pivotContractClip.current
        ) {
            pivotContractClip.current.pause();
            pivotContractClip.current.currentTime = 0;
            playAudio(pivotContractClip.current);
        }
    };

    const handleExpandAll = (e: React.MouseEvent): void => {
        e.preventDefault();
        const expandAll = !Array.from(toolGroups.values())
            .filter((g) => g.active)
            .filter((g) => g.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
            .reduce((result, g) => ((result && g.expanded) || false), true);
        const updatedToolGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup> = deepCopy(toolGroups);
        Array.from(toolGroups.values())
            .filter((group) => group.active)
            .forEach((group) => {
                updatedToolGroups.get(group.id)!.expanded = expandAll;
            });
        setToolGroups(updatedToolGroups);

        // Play Sound
        if (
            hasSound
            && expandAll
            && pivotExpandClip.current
        ) {
            pivotExpandClip.current.pause();
            pivotExpandClip.current.currentTime = 0;
            playAudio(pivotExpandClip.current);
        } else if (
            hasSound
            && !expandAll
            && pivotContractClip.current
        ) {
            pivotContractClip.current.pause();
            pivotContractClip.current.currentTime = 0;
            playAudio(pivotContractClip.current);
        }
    };

    const handleBack = (e: React.MouseEvent): void => {
        e.preventDefault();
        if (
            selectedTool === EDITOR_TOOL.link
            || selectedTool === EDITOR_TOOL.youtube
            || selectedTool === EDITOR_TOOL.twitter
            || selectedTool === EDITOR_TOOL.spotify
            || selectedTool === EDITOR_TOOL.vimeo
        ) {
            // Reset Link URL
            setLinkURL(null);
            setSelectedTool(null);
        } else if (selectedTool === EDITOR_TOOL.emoji) {
            setSelectedTool(null);
        }
    };

    const onLinkChange = (): void => {
        if (linkInputRef.current) setLinkURL(linkInputRef.current.value);
    };

    const onLinkSubmit = (): void => {
        if (!user) throw Error('There was a problem uploading media. User data not found.');

        if (linkURL) {
            const linkId = uuidv4();
            const linkEmbeddingBundle: IEditorLinkEmbeddingLinkBundle = {
                id: linkId,
                userId: user.id,
                url: linkURL!,
                type: MEDIA_TYPE.link,
            };

            // make db entry of link
            setMediaInDB({
                linkEmbeddingBundle,
            });

            // Create link
            createLink(linkId, linkURL, textSelection);
        }

        // Toolbar has no link card, so we go back
        setSelectedTool(null);
        // Stationary toolbar clears link data
        setLinkURL(null);
        if (linkInputRef.current) {
            linkInputRef.current.value = '';
        }
    };

    const onEmbeddingCreate = (embeddingType: EDITOR_TOOL, e?: Event): void => {
        if (e) {
            e.preventDefault();
        }

        const linkItemId = uuidv4();
        let editorEmbeddingType: EDITOR_EMBEDDING_TYPE | undefined;
        switch (embeddingType) {
        case EDITOR_TOOL.youtube:
            if (linkURL) {
                createYouTubeEmbedding(linkItemId, linkURL);
                editorEmbeddingType = EDITOR_EMBEDDING_TYPE.youtube;
            }
            break;
        case EDITOR_TOOL.twitter:
            if (linkURL) {
                createTwitterEmbedding(linkItemId, linkURL);
                editorEmbeddingType = EDITOR_EMBEDDING_TYPE.twitter;
            }
            break;
        case EDITOR_TOOL.spotify:
            if (linkURL) {
                createSpotifyEmbedding(linkItemId, linkURL);
                editorEmbeddingType = EDITOR_EMBEDDING_TYPE.spotify;
            }
            break;
        case EDITOR_TOOL.vimeo:
            if (linkURL) {
                createVimeoEmbedding(linkItemId, linkURL);
                editorEmbeddingType = EDITOR_EMBEDDING_TYPE.vimeo;
            }
            break;
        default:
            break;
        }

        if (editorEmbeddingType) {
            if (!user) throw Error('There was a problem uploading media. User data not found.');

            const linkEmbeddingBundle: IEditorLinkEmbeddingLinkBundle = {
                id: linkItemId,
                userId: user.id,
                url: linkURL!,
                type: MEDIA_TYPE.embedding,
                embeddingType: editorEmbeddingType,
                caption: '',
            };

            // make db entry of link
            setMediaInDB({
                linkEmbeddingBundle,
            });
        }

        // Once we create embedding we return to tool selection screen
        setSelectedTool(null);
        // Clear Link Data
        setLinkURL(null);
        if (linkInputRef.current) {
            linkInputRef.current.value = '';
        }
    };

    const getIsValidLink = (): boolean => {
        const checkURL = linkURL || '';
        switch (selectedTool) {
        case EDITOR_TOOL.link:
            return !!validateURL(checkURL);
        case EDITOR_TOOL.youtube:
            return !!getYouTubeParams(checkURL);
        case EDITOR_TOOL.twitter:
            return !!getTwitterId(checkURL);
        case EDITOR_TOOL.spotify:
            return !!getSpotifyId(checkURL);
        case EDITOR_TOOL.vimeo:
            return !!getVimeoId(checkURL);
        default:
            return false;
        }
    };

    const handleLinkSubmit = (e: React.MouseEvent): void => {
        if (e) {
            // Makes sure that when we press the submit button on
            // the link input (instead of pressing enter) that we
            // don't lose focus (and thus trigger an effect that)
            // resets the tool
            e.preventDefault();
        }

        if (selectedTool === EDITOR_TOOL.link) {
            onLinkSubmit();
        } else if (selectedTool) {
            if (!selection) {
                // If we want to add a link to Slate, without prior selection
                // Select end of document first
                selectEndDocument();
            }
            onEmbeddingCreate(selectedTool);
        }
    };

    const onToolbarGroupMouseEnter = (group: IToolGroup, e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorToolbarToolGroup,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onToolbarGroupMouseLeave = (group: IToolGroup, e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onToolbarToolMouseEnter = (tool: ITool, e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorToolbarTool,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onToolbarToolMouseLeave = (tool: ITool, e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onToolbarGenericButtonMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorToolbarButton,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onToolbarGenericButtonMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onLinkInputMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.input,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onLinkInputMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onLinkInputFocus = (): void => {
        setInputFocused(true);

        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    const onLinkInputBlur = (): void => {
        setInputFocused(false);
    };

    const handleUploadingMediaComplete = (
        mediaItem: IMediaItem,
        imgURL: string,
    ): void => {
        // Make sure selection is set
        if (textSelection) {
            setSelection(textSelection);
        }

        // Update Uploading Media
        updateUploadingMedia({
            ...uploadingMedia.get(mediaItem.id)!,
            url: imgURL,
        });

        // Create Figure
        createFigure(mediaItem.id, mediaItem.filePath, mediaItem.file.name);
    };

    const handleUploadingMediaStateChange = (
        mediaItem: IMediaItem,
        _snapshot: UploadTaskSnapshot,
        progress: number,
    ): void => {
        if (uploadingMedia.has(mediaItem.id)) {
            updateUploadingMedia({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...uploadingMedia.get(mediaItem.id)!,
                uploadProgress: progress,
            });
        } else {
            setUploadingMedia(mediaItem);
        }
    };

    // ===== Rendering =====

    const numActiveGroups = useMemo(() => Array.from(toolGroups.values())
        .filter((g) => g.active)
        .filter((g) => g.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
        .length, [toolGroups]);

    const linkCardLinkTip = useMemo(() => {
        switch (selectedTool) {
        case EDITOR_TOOL.youtube:
            return 'Copy link from browser address bar.';
        case EDITOR_TOOL.twitter:
            return 'Copy link from browser address bar.';
        case EDITOR_TOOL.spotify:
            return 'Right Click \'⋯ > Share > Copy Link\' for useable link.';
        case EDITOR_TOOL.vimeo:
            return 'Copy link from browser address bar.';
        default:
            return '';
        }
    }, [selectedTool]);

    return (
        <>
            <ToolbarBody
                show={show}
                buttonLength={buttonLength}
                allToolGroupsClosed={allToolGroupsClosed}
            >
                {Array.from(toolGroups.values())
                    .filter((group) => group.active)
                    .sort((a, b) => {
                        if (a.currentIndex > b.currentIndex) {
                            return 1;
                        }
                        if (a.currentIndex === b.currentIndex) {
                            return 0;
                        }
                        return -1;
                    }).map((group, index, groupArr) => {
                        if (group.id === EDITOR_TOOLBAR_TOOL_GROUP.expandAll) {
                            return (
                                <Transition
                                    key={group.id}
                                    in={show}
                                    timeout={{
                                        enter: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + TOOL_TRANSITION_DURATION,
                                        ),
                                        exit: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            ((groupArr.length - Math.max(index - 1, 0))
                                            * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + TOOL_TRANSITION_DURATION,
                                        ),
                                    }}
                                    appear
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    {(state) => (
                                        <ExpandAllTool
                                            className={HOVER_TARGET_CLASSNAME}
                                            color={color}
                                            buttonLength={buttonLength}
                                            allToolGroupsOpen={allToolGroupsOpen}
                                            onMouseEnter={onToolbarGenericButtonMouseEnter}
                                            onMouseLeave={onToolbarGenericButtonMouseLeave}
                                            onMouseDown={handleExpandAll}
                                            style={{
                                                ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                    direction: 'right',
                                                    duration: TOOL_TRANSITION_DURATION,
                                                    offset: 5,
                                                }),
                                                ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                    direction: 'right',
                                                    offset: 5,
                                                    numItems: groupArr.length,
                                                    index,
                                                })[state],
                                            }}
                                        >
                                            <ReactSVG
                                                src={ChevronIcon}
                                            />
                                        </ExpandAllTool>
                                    )}
                                </Transition>
                            );
                        }

                        const hasAppliedTool = group.tools
                            ? Array
                                .from(group.tools!.values())
                                .filter((tool) => tool.applied)
                                .length > 0
                            : false;
                        return (
                            <Transition
                                key={group.id}
                                in={show}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + TOOL_TRANSITION_DURATION,
                                    ),
                                    exit: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ((groupArr.length - Math.max(index - 1, 0))
                                        * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + TOOL_TRANSITION_DURATION,
                                    ),
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <ToolGroup
                                        key={group.id}
                                        color={color}
                                        zIndex={index}
                                        buttonLength={buttonLength}
                                        expanded={group.expanded}
                                        allToolGroupsClosed={allToolGroupsClosed}
                                        hasAppliedTool={hasAppliedTool}
                                        numActiveGroups={numActiveGroups}
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'right',
                                                duration: TOOL_TRANSITION_DURATION,
                                                offset: 5,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'right',
                                                offset: 5,
                                                numItems: groupArr.length,
                                                index,
                                            })[state],
                                        }}
                                    >
                                        <GroupHeader
                                            className={HOVER_TARGET_CLASSNAME}
                                            color={color}
                                            buttonLength={buttonLength}
                                            expanded={!!group.expanded}
                                            hasAppliedTool={hasAppliedTool}
                                            onMouseEnter={(e) => onToolbarGroupMouseEnter(group, e)}
                                            onMouseLeave={(e) => onToolbarGroupMouseLeave(group, e)}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleGroupExpanded(
                                                    group.id,
                                                    !group.expanded,
                                                );
                                            }}
                                        >
                                            <Tooltip
                                                text={`${group.name}`}
                                                side={TOOLTIP_TYPE.bottom}
                                            />
                                            {group.icon && (
                                                <ReactSVG
                                                    src={group.icon}
                                                />
                                            )}
                                        </GroupHeader>
                                        {!!group.tools && (
                                            <GroupTools
                                                isOpen={!!group.expanded}
                                                buttonLength={buttonLength}
                                                numTools={
                                                    Array
                                                        .from(group.tools.values())
                                                        .filter((tool) => tool.active)
                                                        .length
                                                }
                                            >
                                                {Array
                                                    .from(group.tools.values())
                                                    .filter((tool) => tool.active)
                                                    // .filter((tool) => tool.id !== EDITOR_TOOL.link || (selection && !Range.isCollapsed(selection)))
                                                    .map((tool, i, toolArr) => (
                                                        <Transition
                                                            key={tool.id}
                                                            in={group.expanded}
                                                            timeout={{
                                                                enter: Math.min(
                                                                    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                                                    (i * FADE_IN_STAGGER_OFFSET_DURATION)
                                                                    + TOOL_TRANSITION_DURATION,
                                                                ),
                                                                exit: Math.min(
                                                                    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                                                    ((toolArr.length - Math.max(i - 1, 0))
                                                                    * FADE_IN_STAGGER_OFFSET_DURATION)
                                                                    + TOOL_TRANSITION_DURATION,
                                                                ),
                                                            }}
                                                            appear
                                                            mountOnEnter
                                                            unmountOnExit
                                                        >
                                                            {(transitionState) => (
                                                                <Tool
                                                                    {...getRootProps({
                                                                        key: tool.id,
                                                                        className: HOVER_TARGET_CLASSNAME,
                                                                        applied: tool.applied,
                                                                        color,
                                                                        buttonLength,
                                                                        onMouseEnter: (e) => onToolbarToolMouseEnter(tool, e),
                                                                        onMouseLeave: (e) => onToolbarToolMouseLeave(tool, e),
                                                                        onMouseDown: (e) => {
                                                                            e.preventDefault();
                                                                            if (
                                                                                tool.id === EDITOR_TOOL.link
                                                                                || tool.id === EDITOR_TOOL.figure
                                                                                || tool.id === EDITOR_TOOL.youtube
                                                                                || tool.id === EDITOR_TOOL.twitter
                                                                                || tool.id === EDITOR_TOOL.spotify
                                                                                || tool.id === EDITOR_TOOL.vimeo
                                                                                || tool.id === EDITOR_TOOL.emoji
                                                                            ) {
                                                                                // cache editor selection value
                                                                                setTextSelection(selection);
                                                                            }

                                                                            if (selectedTool !== tool.id) {
                                                                                tool.handleClick(tool.id, selection);
                                                                            } else {
                                                                                // Clicking the any of the mentioned tools above when
                                                                                // they are activated should hide the link input field
                                                                                //
                                                                                // For some odd reason, the functions do not read the
                                                                                // most up to date version of selectedTool, thus
                                                                                // it had to be placed out here.
                                                                                setSelectedTool(null);
                                                                            }
                                                                        },
                                                                        style: {
                                                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                                                direction: 'right',
                                                                                duration: TOOL_TRANSITION_DURATION,
                                                                                offset: 5,
                                                                            }),
                                                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                                                direction: 'right',
                                                                                offset: 5,
                                                                                numItems: toolArr.length,
                                                                                index: i,
                                                                            })[transitionState],
                                                                        },
                                                                    })}
                                                                >
                                                                    <Tooltip
                                                                        text={`${tool.name}`}
                                                                        side={TOOLTIP_TYPE.bottom}
                                                                    />
                                                                    {tool.id === EDITOR_TOOL.figure
                                                                    && <DropzoneInput {...getInputProps()} />}
                                                                    <ReactSVG
                                                                        src={tool.icon}
                                                                    />
                                                                </Tool>
                                                            )}
                                                        </Transition>
                                                    ))}
                                            </GroupTools>
                                        )}
                                    </ToolGroup>
                                )}
                            </Transition>
                        );
                    })}
            </ToolbarBody>
            <Transition
                in={show
                    && (
                        selectedTool === EDITOR_TOOL.link
                        || selectedTool === EDITOR_TOOL.youtube
                        || selectedTool === EDITOR_TOOL.twitter
                        || selectedTool === EDITOR_TOOL.spotify
                        || selectedTool === EDITOR_TOOL.vimeo
                    )}
                timeout={{
                    enter: LINK_CARD_TRANSITION_DURATION,
                    exit: LINK_CARD_TRANSITION_DURATION,
                }}
            >
                {(state) => (
                    <EditLinkContainer
                        visible={show
                            && (
                                selectedTool === EDITOR_TOOL.link
                                || selectedTool === EDITOR_TOOL.youtube
                                || selectedTool === EDITOR_TOOL.twitter
                                || selectedTool === EDITOR_TOOL.spotify
                                || selectedTool === EDITOR_TOOL.vimeo
                            )}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 20,
                                duration: LINK_CARD_TRANSITION_DURATION,
                                easing: themeObj.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 20,
                            })[state],
                        }}
                    >
                        <GhostButton
                            className={HOVER_TARGET_CLASSNAME}
                            buttonLength={buttonLength}
                            onMouseEnter={onToolbarGenericButtonMouseEnter}
                            onMouseLeave={onToolbarGenericButtonMouseLeave}
                            onMouseDown={handleBack}
                        >
                            <ReactSVG
                                src={CrossIcon}
                            />
                        </GhostButton>
                        <LinkInput
                            type="text"
                            ref={linkInputRef}
                            height={buttonLength}
                            className={HOVER_TARGET_CLASSNAME}
                            defaultValue={linkURL || undefined}
                            placeholder={linkPlaceholderText}
                            valid={getIsValidLink()}
                            onChange={onLinkChange}
                            onMouseEnter={onLinkInputMouseEnter}
                            onMouseLeave={onLinkInputMouseLeave}
                            onFocus={onLinkInputFocus}
                            onBlur={onLinkInputBlur}
                        />
                        <Button
                            {...(linkURL && validateURL(linkURL)
                                ? {
                                    boxShadow: true,
                                }
                                : {}
                            )}
                            className={HOVER_TARGET_CLASSNAME}
                            type={BUTTON_TYPE.solid}
                            background={color}
                            height={buttonLength}
                            icon={ArrowIcon}
                            disabled={!getIsValidLink()}
                            onMouseEnter={onToolbarGenericButtonMouseEnter}
                            onMouseLeave={onToolbarGenericButtonMouseLeave}
                            onMouseDown={handleLinkSubmit}
                        />
                        <Transition
                            in={
                                selectedTool === EDITOR_TOOL.youtube
                                || selectedTool === EDITOR_TOOL.twitter
                                || selectedTool === EDITOR_TOOL.spotify
                                || selectedTool === EDITOR_TOOL.vimeo
                            }
                            timeout={{
                                enter: TOOLTIP_TRANSITION_DURATION,
                                exit: TOOLTIP_TRANSITION_DURATION,
                            }}
                            appear
                            mountOnEnter
                        >
                            {(transitionState) => (
                                <ToolbarTooltip
                                    top={-35}
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'up',
                                            offset: 10,
                                            duration: TOOLTIP_TRANSITION_DURATION,
                                            easing: themeObj.motion.eagerEasing,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'up',
                                            offset: 10,
                                        })[transitionState],
                                    }}
                                >
                                    <ToolbarNameText>
                                        {'Tip: '}
                                    </ToolbarNameText>
                                    <ToolbarShortcutText>
                                        {linkCardLinkTip}
                                    </ToolbarShortcutText>
                                </ToolbarTooltip>
                            )}
                        </Transition>
                    </EditLinkContainer>
                )}
            </Transition>
        </>
    );
}

// ===== Styled Components =====

interface ToolbarBodyProps {
    allToolGroupsClosed: boolean,
    show: boolean,
    buttonLength: number,
}
const ToolbarBody = styled.div<ToolbarBodyProps>`
    position: relative;
    display: ${({ allToolGroupsClosed }) => {
        if (allToolGroupsClosed) {
            return 'inline-flex';
        }

        return 'block';
    }};
    border-radius: ${({ buttonLength }) => `${(buttonLength + 2 * (TOOL_GROUP_MARGIN + TOOL_GROUP_PADDING)) / 2}px`};
    pointer-events: ${({ show }) => (show
        ? 'auto'
        : 'none'
    )};
    transition: ${({ theme }) => `
        opacity ${PIVOT_TRANSITION_DURATION}ms ${theme.motion.standardEasing}
    `};
    background: inherit;
`;

interface ToolGroupProps {
    hasAppliedTool?: boolean,
    expanded?: boolean,
    color?: string,
    buttonLength: number,
    allToolGroupsClosed?: boolean,
    numActiveGroups?: number,
    zIndex?: number,
}
const ToolGroup = styled.div<ToolGroupProps>`
    position: relative;
    display: inline-flex;
    min-height: ${({ buttonLength }) => `${buttonLength}px`};
    margin: ${`${TOOL_GROUP_MARGIN / 2}px`}; // Half the value used in PortableToolbar
    margin-right: 0px;
    padding: ${`${TOOL_GROUP_PADDING}px`};
    border-radius: ${({ buttonLength }) => `${(buttonLength + 2 * TOOL_GROUP_MARGIN) / 2}px`};
    background-color: ${({
        hasAppliedTool,
        expanded,
        color,
        theme,
    }) => {
        if (
            hasAppliedTool
            && !expanded
            && color
        ) {
            return setColorLightness(
                color,
                BUTTON_CONTAINER_LIGHTNESS_VALUE,
            );
        }

        return theme.color.neutral200;
    }};
    max-width: ${({ buttonLength }) => `${(2 * TOOL_GROUP_MARGIN) + (2 * TOOL_GROUP_PADDING) + MAX_TOOLS_PER_ROW * ((2 * TOOL_MARGIN) + buttonLength + GROUP_TOOL_MARGIN_LEFT_ADDITION) + ((2 * TOOL_MARGIN) + buttonLength)}px`};
    z-index: ${({ zIndex, numActiveGroups }) => (typeof numActiveGroups === 'number' && typeof zIndex === 'number'
        ? numActiveGroups - zIndex
        : 'auto'
    )};
    transition: background-color 0.3s;

    ${({ allToolGroupsClosed }) => allToolGroupsClosed && `
        &:not(:last-child) {
            margin-right: 0px;
        }
    `};

    ${({ numActiveGroups, expanded }) => expanded
        && numActiveGroups !== TOOL_GROUPS_COUNT && `
        &:not(:last-child) {
            margin-bottom: 0px;
        }
    `};

    ${({ hasAppliedTool, expanded, theme }) => !expanded
        && !hasAppliedTool && `
        &:hover {
            background-color: ${theme.color.neutral300};
        }
    `};
`;

interface ExpandAllToolProps {
    allToolGroupsOpen: boolean,
    buttonLength: number,
    color: string,
}
const ExpandAllTool = styled(ToolGroup)<ExpandAllToolProps>`
    min-height: 0px;
    height: ${({ buttonLength }) => `${buttonLength + 2 * TOOL_GROUP_MARGIN}px`};
    width: ${({ buttonLength }) => `${buttonLength + 2 * TOOL_GROUP_MARGIN}px`};
    border-radius: ${({ buttonLength }) => `${(buttonLength + 2 * TOOL_GROUP_MARGIN) / 2}px`};
    background-color: ${({ theme }) => theme.color.neutral200};
    margin: ${`${TOOL_GROUP_MARGIN / 2}px`}; // Half the value used in PortableToolbar
    margin-right: 0px;
    padding: ${`${0.625 * BODY_FONT_SIZE}px`};
    cursor: none;

    & div {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({
        color,
        theme,
    }) => {
        if (color) {
            return color;
        }

        return theme.color.neutral700;
    }};
        transform: ${({ allToolGroupsOpen }) => `rotate(${
        allToolGroupsOpen
            ? 180
            : 0
    }deg)`};
        transition: ${({ theme }) => `
            fill 300ms ${theme.motion.eagerEasing},
            transform 300ms ${theme.motion.eagerEasing}
        `};
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.neutral300};

        & svg {
            fill: ${({
        color,
        theme,
    }) => {
        if (color) {
            return color;
        }

        return theme.color.neutral800;
    }};
        }
    }
`;

interface ToolProps {
    color: string,
    applied?: boolean,
    buttonLength: number,
}
const Tool = styled.div<ToolProps>`
    position: relative;
    display: inline-block;
    width: ${({ buttonLength }) => `${buttonLength}px`};
    height: ${({ buttonLength }) => `${buttonLength}px`};
    border-radius: ${`${0.9375 * BODY_FONT_SIZE}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
    background-color: ${({ applied, color, theme }) => (applied && color
        ? color
        : theme.color.white
    )};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transition: box-shadow 0.3s;
    cursor: none;


    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ applied, theme }) => (applied
        ? theme.color.white
        : theme.color.neutral700
    )};
    }

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }
`;

interface GroupHeaderProps {
    hasAppliedTool: boolean,
    expanded: boolean,
    color: string,
}
const GroupHeader = styled(Tool)<GroupHeaderProps>`
    position: relative;
    display: inline-block;
    background-color: transparent;
    box-shadow: none;
    transition: background-color 0.3s;

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({
        hasAppliedTool,
        expanded,
        color,
        theme,
    }) => {
        if (
            hasAppliedTool
            && !expanded
            && color
        ) {
            return setColorLightness(
                color,
                BUTTON_TEXT_LIGHTNESS_VALUE,
            );
        }

        if (expanded && color) {
            return color;
        }

        return theme.color.neutral700;
    }};
        transition: ${({ theme }) => `
            fill 300ms ${theme.motion.eagerEasing}
        `};
    }

    &:hover {
        box-shadow: none;
        background-color: ${({
        hasAppliedTool,
        expanded,
        color,
        theme,
    }) => {
        if (
            hasAppliedTool
            && !expanded
            && color
        ) {
            return setColorLightness(
                color,
                BUTTON_CONTAINER_LIGHTNESS_VALUE,
            );
        }

        return theme.color.neutral300;
    }};

        & svg {
            fill: ${({
        hasAppliedTool,
        expanded,
        color,
        theme,
    }) => {
        if (
            hasAppliedTool
            && !expanded
            && color
        ) {
            return setColorLightness(
                color,
                BUTTON_TEXT_LIGHTNESS_VALUE,
            );
        }

        if (expanded && color) {
            return color;
        }

        return theme.color.neutral800;
    }};
        }
    }
`;

interface GroupToolsGroupProps {
    isOpen: boolean,
    numTools: number,
    buttonLength: number,
}
const GroupTools = styled.div<GroupToolsGroupProps>`
    display: inline-block;
    width: ${({ isOpen, numTools, buttonLength }) => `${isOpen
        ? numTools * (buttonLength + TOOL_MARGIN_LEFT)
        : 0
    }px`};
    max-width: ${({ buttonLength }) => `${MAX_TOOLS_PER_ROW * ((2 * TOOL_MARGIN) + buttonLength + GROUP_TOOL_MARGIN_LEFT_ADDITION)}px`};
    ${({ isOpen, numTools, theme }) => !isOpen && `
        transition: width ${GROUP_EXPAND_TRANSITION}ms ${theme.motion.delayEasing} ${(numTools - 1) * TOOL_TRANSITION_DURATION}ms
    `};
    background: inherit;

    & > div {
        margin-left: ${`${TOOL_MARGIN_LEFT}px`};
    }
`;

interface EditLinkContainerProps {
    visible: boolean,
}
const EditLinkContainer = styled.div<EditLinkContainerProps>`
    position: absolute;
    top: ${`-${2.5 * BODY_FONT_SIZE}px`};
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: ${`${1.5625 * BODY_FONT_SIZE}px`};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
    ${({ visible }) => !visible && `
        pointer-events: none;
    `};

    & > button {
        transform: rotate(-90deg);
    }
`;

const GhostButton = styled(ToolGroup)`
    min-height: 0px;
    height: ${({ buttonLength }) => `${buttonLength}px`};
    width: ${({ buttonLength }) => `${buttonLength}px`};
    background-color: ${({ theme }) => theme.color.white};
    margin: 0px;
    padding: ${({ buttonLength }) => `${buttonLength ? (buttonLength / 4.7) : 0.46875 * BODY_FONT_SIZE}px`};
    cursor: none;

    & div {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral600};
        transition: ${({ theme }) => `
            fill 300ms ${theme.motion.eagerEasing}
        `};
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.neutral100};

        & svg {
            fill: ${({ theme }) => theme.color.neutral700};
        }
    }
`;

interface LinkInputProps {
    valid: boolean,
    height: number,
}
const LinkInput = styled.input<LinkInputProps>`
    margin: ${`0 ${0.3125 * BODY_FONT_SIZE}px`};
    height: ${({ height }) => `${height}px`};
    width: ${`${12.5 * BODY_FONT_SIZE}px`};
    margin-right: ${`${0.3125 * BODY_FONT_SIZE}px`};
    padding: ${`0px ${1.25 * BODY_FONT_SIZE}px`};
    border-radius: ${({ height }) => `${height / 2}px`};
    border: none;
    outline: none;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 1em;
    color: ${({ valid, theme }) => (!valid
        ? theme.color.error
        : theme.color.neutral900
    )};
    background-color: ${({ valid, theme }) => (!valid
        ? setColorLightness(
            theme.color.error,
            98,
        )
        : theme.color.neutral100
    )};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    cursor: none;
    transition: box-shadow 0.3s;

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral400};
    }
`;

interface ToolbarTooltipProps {
    top: number,
    left?: number,
    horizontalCenter?: boolean,
}
const ToolbarTooltip = styled.div<ToolbarTooltipProps>`
    position: absolute;
    top: ${({ top }) => `${top}px`};
    left: ${({ left, horizontalCenter }) => {
        if (horizontalCenter) {
            return '50%';
        }

        if (left) {
            return `${left}px`;
        }

        return '0px';
    }};
    ${({ horizontalCenter }) => horizontalCenter && `
        transform: translateX(-50%);
    `};
    border-radius: ${`${0.875 * BODY_FONT_SIZE}px`};
    background-color: ${({ theme }) => theme.color.neutral900};
    padding: ${`${0.25 * BODY_FONT_SIZE}px ${0.9375 * BODY_FONT_SIZE}px`};
    white-space: nowrap;
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
`;

const ToolbarNameText = styled.span`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.white};
    white-space: pre;
`;

const ToolbarShortcutText = styled(ToolbarNameText)`
    font-weight: 300;
    color: ${({ theme }) => theme.color.neutral600};
    white-space: pre;
`;

export default StationaryToolbar;

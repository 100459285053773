// ===== Packages =====

import {
    doc,
    setDoc,
    getFirestore,
}                                       from 'firebase/firestore';

// ===== Interfaces =====

import {
    ISaleNegotiationRequestItem,
}                                       from '../interfaces';

// ===== Enums =====

import {
    FIRESTORE_COLLECTION,
}                                       from '../enums';

interface SetNegotiationRequestOptions {
    request: ISaleNegotiationRequestItem,
}
const setSaleNegotiationRequestInDB = async ({ request }: SetNegotiationRequestOptions): Promise<void> => {
    const db = getFirestore();
    const collection = process.env.NODE_ENV === 'production'
        ? FIRESTORE_COLLECTION.saleNegotiationRequests
        : FIRESTORE_COLLECTION.stagingSaleNegotiationRequests;
    await setDoc(doc(db, collection, request.id), request);
};

export default setSaleNegotiationRequestInDB;

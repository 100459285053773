// ===== Enums =====

import {
    UNSUBSCRIBE_STATUS_TYPE,
    UNSUBSCRIBE_TYPE,
}                               from '../enums';

const getUnsubscribeMessage = (
    status: UNSUBSCRIBE_STATUS_TYPE,
    type: UNSUBSCRIBE_TYPE | null,
): string => {
    let message: string;
    switch (status) {
    case UNSUBSCRIBE_STATUS_TYPE.uninitiated:
        message = 'Unsubscribe request was not made. Please try unsubscribe again.';
        break;
    case UNSUBSCRIBE_STATUS_TYPE.stillSubscribed:
        message = `Your account is still subscribed to ${type === UNSUBSCRIBE_TYPE.mailingList
            ? 'the mailing list'
            : 'comment updates'
        }. Please try unsubscribe again.`;
        break;
    case UNSUBSCRIBE_STATUS_TYPE.missingProperty:
        message = 'Your account is missing critical information required to unsubscribe you from'
            + ` ${type === UNSUBSCRIBE_TYPE.mailingList
                ? 'the mailing list'
                : 'comment updates'
            }. Please contact verascope to resolve the issue.`;
        break;
    case UNSUBSCRIBE_STATUS_TYPE.missingType:
        message = 'Unsubscribe request is missing an unsubscribe type. Please specify whether you\'d'
            + 'like to unsubscribe from the mailing list or comment updates.';
        break;
    case UNSUBSCRIBE_STATUS_TYPE.success:
        message = `Your account has been successfully unsubscribed from ${type === UNSUBSCRIBE_TYPE.mailingList
            ? 'the mailing list'
            : 'comment updates'
        }.`;
        break;
    case UNSUBSCRIBE_STATUS_TYPE.userNotFound:
        message = 'No verascope account exists for the provided user. Please contact verascope if there are any unresolved matters.';
        break;
    case UNSUBSCRIBE_STATUS_TYPE.timeout:
        message = 'No verascope account was provided. Please try again.';
        break;
    case UNSUBSCRIBE_STATUS_TYPE.uncaughtError:
        message = 'Unrecognized error occurred. Please try again.';
        break;
    default:
        message = 'Unrecognized error occurred. Please try again.';
        break;
    }

    return message;
};

export default getUnsubscribeMessage;

// ===== Packages =====

import {
    Node,
    Element,
}                                   from 'slate';

// ===== Interfaces =====

import {
    EditorNode,
    ZoomLevelOptions,
    ZoomLevelNode,
}                                   from '../interfaces';
import LineInterface                from '../interfaces/lineInterface';

// ===== Enums =====

import {
    ZOOM_LEVEL,
    ZOOM_LEVEL_TYPE,
}                                   from './enums';

const BLOCK_ZOOM_LEVEL_TYPE = 'zoom-level';

const isValidZoomLevel = (level: ZOOM_LEVEL): boolean => Object.values(ZOOM_LEVEL).includes(level);

const isZoomLevelElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_ZOOM_LEVEL_TYPE;

const isInlineZoomLevel = (node: EditorNode | Node): boolean => isZoomLevelElement(node)
    && 'level' in node
    && 'levelType' in node
    && (node as ZoomLevelNode).levelType === ZOOM_LEVEL_TYPE.inline;

const isBlockZoomLevel = (node: EditorNode | Node): boolean => isZoomLevelElement(node)
    && 'level' in node
    && 'levelType' in node
    && (node as ZoomLevelNode).levelType === ZOOM_LEVEL_TYPE.block;

const newZoomLevelElement = (options: ZoomLevelOptions): ZoomLevelNode => ({
    type: BLOCK_ZOOM_LEVEL_TYPE,
    isLine: options.levelType === ZOOM_LEVEL_TYPE.block,
    level: options.level || ZOOM_LEVEL.five,
    levelType: options.levelType || ZOOM_LEVEL_TYPE.block,
    children: [{ text: '' }],
});

export default {
    ZOOM_LEVEL,
    BLOCK_ZOOM_LEVEL_TYPE,
    TYPE: BLOCK_ZOOM_LEVEL_TYPE,
    isZoomLevelElement,
    ...LineInterface,
    newZoomLevelElement,
    isValidZoomLevel,
    isInlineZoomLevel,
    isBlockZoomLevel,
};

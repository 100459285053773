// ===== Packages =====

import styled from 'styled-components';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
}                       from '../../constants/generalConstants';

export const Container = styled.div`
    margin: ${`${1.25 * BODY_FONT_SIZE}px 0`};
    box-shadow: ${({ theme }) => theme.color.boxShadow500};

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    & iframe {
        border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    }
`;

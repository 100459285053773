// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import FONT_TYPE                    from '../../constants/fontType';
import {
    CART_AND_CHECKOUT_FONT_SIZE_LARGE,
    CART_AND_CHECKOUT_FONT_SIZE_SMALL,
    BODY_FONT_SIZE,
}                                   from '../../constants/generalConstants';

const INPUT_WIDTH = 2.5 * BODY_FONT_SIZE;
const INPUT_WIDTH_WITH_SUBTITLE = 5 * BODY_FONT_SIZE;
const INPUT_CONTAINER_PADDING_LEFT = 0.625 * BODY_FONT_SIZE;
const INPUT_CONTAINER_MARGIN = 0.1875 * BODY_FONT_SIZE;

interface ContainerProps {
    itemCount: number,
    subtitleItemCount: number,
}
export const Container = styled.div<ContainerProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: ${({ itemCount, subtitleItemCount }) => `${
        (itemCount - subtitleItemCount) * (INPUT_WIDTH + INPUT_CONTAINER_MARGIN)
        + subtitleItemCount * (INPUT_WIDTH_WITH_SUBTITLE + INPUT_CONTAINER_MARGIN)
        + 0.1875 * BODY_FONT_SIZE
    }px`};
    border: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple100}`};
`;

export const CustomRadioButton = styled.span`
    position: relative;
    width: ${`${1.25 * BODY_FONT_SIZE}px`};
    min-width: ${`${1.25 * BODY_FONT_SIZE}px`};
    height: ${`${1.25 * BODY_FONT_SIZE}px`};
    min-height: ${`${1.25 * BODY_FONT_SIZE}px`};
    margin-right: ${`${0.9375 * BODY_FONT_SIZE}px`};
    border: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple100}`};
`;

export const Label = styled.div``;

export const Title = styled.label`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;
    margin-right: ${`${6.25 * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
        line-height: 1.8em;
    }
`;

export const Annotation = styled.span`
    color: ${({ theme }) => theme.verascopeColor.orange200};
`;

export const Subtitle = styled.p`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.2}em`};
    color: ${({ theme }) => theme.color.neutral700};
    text-transform: uppercase;
    margin-right: ${`${6.25 * BODY_FONT_SIZE}px`};
    line-height: 1.6em;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.2}em`};
        line-height: 1.8em;
    }
`;

export const Detail = styled.h3`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple400};
    text-transform: uppercase;
    margin: 0;
    margin-right: ${`${0.625 * BODY_FONT_SIZE}px`};

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

interface InputContainerProps {
    selected?: boolean,
    index: number,
    subtitlePriorItemCount: number,
    withSubtitle: boolean,
}
export const InputContainer = styled.div<InputContainerProps>`
    position: absolute;
    top: ${({ subtitlePriorItemCount, index }) => `${
        (index - subtitlePriorItemCount) * (INPUT_WIDTH + INPUT_CONTAINER_MARGIN)
        + subtitlePriorItemCount * (INPUT_WIDTH_WITH_SUBTITLE + INPUT_CONTAINER_MARGIN)
    }px`};
    left: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: ${({ withSubtitle }) => `${withSubtitle
        ? INPUT_WIDTH_WITH_SUBTITLE
        : INPUT_WIDTH}px`};
    padding-left: ${`${0.625 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${INPUT_CONTAINER_MARGIN}px`};

    ${({
        withSubtitle,
        selected,
        subtitlePriorItemCount,
        index,
        theme,
    }) => !selected && `
            &:hover {
                top: ${`${
        (index - subtitlePriorItemCount) * (INPUT_WIDTH + INPUT_CONTAINER_MARGIN)
        + subtitlePriorItemCount * (INPUT_WIDTH_WITH_SUBTITLE + INPUT_CONTAINER_MARGIN)
        - 0.1875 * BODY_FONT_SIZE}px`};
                left: -${0.1875 * BODY_FONT_SIZE}px;
                border: ${`${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple400}`};
                width: calc(100% + ${0.375 * BODY_FONT_SIZE}px);
                height: ${`${withSubtitle
        ? INPUT_WIDTH_WITH_SUBTITLE + 0.375 * BODY_FONT_SIZE
        : INPUT_WIDTH + 0.375 * BODY_FONT_SIZE}px`};
            }
    `}

    &:hover > ${Title} {
        color: ${({ theme }) => theme.verascopeColor.purple300};
    }

    &:hover > ${Subtitle} {
        color: ${({ theme }) => theme.verascopeColor.purple300};
    }

    &:hover > ${CustomRadioButton} {
        border: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple300}`};
    }

    & input:checked ~ ${CustomRadioButton} {
        background: ${({ theme }) => theme.verascopeColor.orange200};
        border: ${({ theme }) => `${0.1875 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.orange200}`};
    }

    & input:checked ~ ${CustomRadioButton}:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: ${`${0.46875 * BODY_FONT_SIZE}px`};
        width: ${`${0.46875 * BODY_FONT_SIZE}px`};
        background: ${({ theme }) => theme.color.white};
    }

    & input:checked ~ ${Title} {
        color: ${({ theme }) => theme.verascopeColor.orange100};
    }

    & input:checked ~ ${Subtitle} {
        color: ${({ theme }) => theme.verascopeColor.orange100};
    }

    & input:checked ~ ${Detail} {
        color: ${({ theme }) => theme.verascopeColor.orange100};
    }
`;

export const Input = styled.input`
    position: absolute;
    opacity: 0;
    height: 100%;
    width: ${`calc(100% - ${INPUT_CONTAINER_PADDING_LEFT}px)`};
    cursor: none;
    margin: 0;
`;

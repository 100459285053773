/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
    useCallback,
}                                               from 'react';
import styled                                   from 'styled-components';
import { ReactSVG }                             from 'react-svg';
import { Transition }                           from 'react-transition-group';
import { useDropzone }                          from 'react-dropzone';
import { useFocused }                           from 'slate-react';
import {
    Range,
    Selection,
    Descendant,
}                                               from 'slate';
import {
    HttpsCallableResult,
}                                               from 'firebase/functions';
import {
    UploadTask,
    StorageError,
    getDownloadURL,
    UploadTaskSnapshot,
    StorageReference,
}                                               from 'firebase/storage';
import ShortUniqueId                            from 'short-unique-id';
import { v4 as uuidv4 }                         from 'uuid';
import mime                                     from 'mime-types';

// ===== Components =====

import {
    Button,
    EmojiSelector,
}                                               from '.';
import { DropzoneInput }                        from '../../../styles';

// ===== Hooks =====

import {
    useTimeout,
    useEventListener,
}                                               from '../../../hooks';

// ===== Services =====

import {
    isMacOS,
    setColorLightness,
    applyCharacterLimit,
    getSpotifyId,
    getTwitterId,
    getYouTubeParams,
    getVimeoId,
    deepCopy,
    playAudio,
    fetchURLMetadata,
    getMediaStorageBucket,
    uploadToCloudStorage,
    setMediaInDB,
    updateAnnotationMediaInDB,
    validateURL,
    getStorageErrorMessage,
    detectTouchDevice,
}                                               from '../../../services';

// ===== Enums =====

import {
    BUTTON_TYPE,
    EDITOR_TOOLBAR_TYPE,
    EDITOR_CONTEXT_TYPE,
    EDITOR_TOOL,
    EDITOR_TOOLBAR_TOOL_GROUP,
    MEDIA_TYPE,
    STORAGE_ENTITY,
    EDITOR_EMBEDDING_TYPE,
    FIRESTORE_COLLECTION,
    INTERACTABLE_OBJECT,
    CURSOR_TARGET,
    STORAGE_ERROR_CODE,
}                                               from '../../../enums';

// ===== Interfaces =====

import {
    IAppliedEditorTool,
    IEmoji,
    ILinkMetadata,
    ITool,
    IToolGroup,
    IMediaItem,
    IEditorLinkEmbeddingLinkBundle,
    IUserItem,
}                                               from '../../../interfaces';

// ===== Images =====

// Group Icons
import TextIcon                                 from '../../../images/editor/text.svg';
import LayoutIcon                               from '../../../images/editor/paintbrush.svg';
import MediaIcon                                from '../../../images/editor/play-stroke.svg';
import IntegrationsIcon                         from '../../../images/editor/integrations.svg';

// Text
import BoldIcon                                 from '../../../images/editor/bold.svg';
import ItalicIcon                               from '../../../images/editor/italic.svg';
import UnderlineIcon                            from '../../../images/editor/underline.svg';
import StrikethroughIcon                        from '../../../images/editor/strikethrough.svg';

// Layout
import BulletListIcon                           from '../../../images/editor/unordered-list.svg';
import NumberedListIcon                         from '../../../images/editor/ordered-list.svg';
import HeaderOneIcon                            from '../../../images/editor/header-1.svg';
import HeaderTwoIcon                            from '../../../images/editor/header-2.svg';
import QuoteIcon                                from '../../../images/editor/quote.svg';
import TableIcon                                from '../../../images/editor/table.svg';
import ChecklistIcon                            from '../../../images/editor/checkbox.svg';
import CodeIcon                                 from '../../../images/editor/code.svg';
import DividerIcon                              from '../../../images/editor/divider.svg';
import MagnifyingGlassIcon                      from '../../../images/editor/magnifying-glass.svg';

// Media
import ImageIcon                                from '../../../images/editor/image.svg';
import LinkIcon                                 from '../../../images/editor/link.svg';
import AtSignIcon                               from '../../../images/editor/at-sign.svg';
import MicrophoneIcon                           from '../../../images/editor/mic.svg';
import SketchIcon                               from '../../../images/editor/sketch.svg';
import SmileyIcon                               from '../../../images/editor/smiley.svg';

// Integrations
import YouTubeIcon                              from '../../../images/editor/youtube.svg';
import TwitterIcon                              from '../../../images/editor/twitter.svg';
import SpotifyIcon                              from '../../../images/editor/spotify.svg';
import VimeoIcon                                from '../../../images/editor/vimeo.svg';

// Other
import PlusIcon                                 from '../../../images/editor/plus.svg';
import EditorToolbarCornerCurve                 from '../../../images/editor/editor-toolbar-corner-curve.svg';
import ChevronIcon                              from '../../../images/editor/chevron.svg';
import ArrowIcon                                from '../../../images/editor/arrow.svg';
import TrashIcon                                from '../../../images/editor/trash.svg';
import EditIcon                                 from '../../../images/editor/pencil.svg';

// ===== Sounds ======

import InputClick                               from '../../../sounds/button_click.mp3';
import CreateClip                               from '../../../sounds/create.mp3';
import PivotExpand                              from '../../../sounds/swoosh_in.mp3';
import PivotContract                            from '../../../sounds/swoosh_out.mp3';

// ===== Constants =====

import KEYCODE                                  from '../../../constants/keycodes';
import CURSOR_SIGN                              from '../../../constants/cursorSigns';
import {
    BODY_FONT_SIZE,
    DEFAULT_AUDIO_VOLUME,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    BLOCK_CHOOSER_BLOCK_WIDTH,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_OFFSET_DURATION,
    BUTTON_CONTAINER_LIGHTNESS_VALUE,
    BUTTON_TEXT_LIGHTNESS_VALUE,
    PORTABLE_TOOLBAR_BLOCK_Z_INDEX,
    PORTABLE_TOOLBAR_SELECTION_Z_INDEX,
    HOVER_TARGET_CLASSNAME,
}                                               from '../../../constants/generalConstants';
import FONT_TYPE                                from '../../../constants/fontType';
import {
    TOOL_MARGIN,
    TOOL_MARGIN_LEFT,
    MAX_TOOLS_PER_ROW,
    TOOL_GROUPS_COUNT,
    TOOL_GROUP_MARGIN,
    TOOL_GROUP_PADDING,
    GROUP_EXPAND_TRANSITION,
    TOOL_TRANSITION_DURATION,
    BODY_TRANSITION_DURATION,
    PIVOT_TRANSITION_DURATION,
    TOOLTIP_TRANSITION_DURATION,
    LINK_CARD_TRANSITION_DURATION,
    GROUP_TOOL_MARGIN_LEFT_ADDITION,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    LINK_CARD_MAX_TITLE_COUNT,
    LINK_CARD_IMAGE_PADDING,
    LINK_CARD_BUTTON_HEIGHT,
    LINK_CARD_IMAGE_WIDTH,
    LINK_CARD_IMAGE_HEIGHT,
    LINK_CARD_WITHOUT_METADATA_WIDTH,
    LINK_CARD_WITH_METADATA_HEIGHT,
    LINK_CARD_WITHOUT_METADATA_HEIGHT,
}                                               from './constants';

// ===== Styles =====

import { theme as themeObj }                    from '../../../themes/theme-context';

PortableToolbar.defaultProps = {
    hide: false,
    readOnly: false,
    setRef: null,
    selectedLinkURL: null,
    selectedLinkID: null,
    containsLink: undefined,
    updatePosition: undefined,
    onSelectionToolbarVisibilityChange: undefined,
    handleEmojiSelect: undefined,
    onCursorEnter: undefined,
    onCursorLeave: undefined,
    toggleZoom: undefined,
    containsZoom: undefined,
};
interface Props {
    type: EDITOR_TOOLBAR_TYPE,
    user: IUserItem | null,
    hasSound: boolean,
    editorType: EDITOR_CONTEXT_TYPE,
    editorId: string,
    buttonLength: number,
    top: number,
    left: number,
    hide?: boolean,
    readOnly?: boolean,
    setRef?: (ref: HTMLDivElement) => void,
    color: string,
    toggleBold: () => void,
    containsBold: boolean | undefined,
    toggleItalics: () => void,
    containsItalics: boolean | undefined,
    toggleHeaderOne: () => void,
    containsHeaderOne: boolean | undefined,
    toggleHeaderTwo: () => void,
    containsHeaderTwo: boolean | undefined,
    toggleUnderline: () => void,
    containsUnderline: boolean | undefined,
    toggleStrikethrough: () => void,
    containsStrikethrough: boolean | undefined,
    toggleBulletedList: () => void,
    containsBulletedList: boolean | undefined,
    toggleNumberedList: () => void,
    containsNumberedList: boolean | undefined,
    toggleQuote: () => void,
    containsQuote: boolean | undefined,
    toggleDivider: () => void,
    containsDivider: boolean | undefined,
    toggleZoom?: () => void,
    containsZoom?: boolean | undefined,
    createFigure: (id: string, filePath: string, caption: string) => void,
    containsFigure: boolean | undefined,
    createLink: (id: string, href: string, selection: Selection) => void,
    updateLink: (id: string, href: string, selection: Selection) => void,
    removeLink: () => void,
    selectedLinkURL?: string | null,
    selectedLinkID?: string | null,
    containsLink?: boolean | undefined,
    createYouTubeEmbedding: (id: string, src: string) => void,
    containsYouTube: boolean | undefined,
    createTwitterEmbedding: (id: string, src: string) => void,
    containsTwitter: boolean | undefined,
    createSpotifyEmbedding: (id: string, src: string) => void,
    containsSpotify: boolean | undefined,
    createVimeoEmbedding: (id: string, src: string) => void,
    containsVimeo: boolean | undefined,
    insertAudioNote: (id: string) => void,
    containsAudioNote: boolean | undefined,
    handleEmojiSelect?: (emojiObj: IEmoji) => void,
    updatePosition?: () => void,
    defaultOpenGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, boolean>,
    selection: Selection,
    setSelection: (selection: Range) => void,
    selectEndDocument: () => void,
    value: Descendant[],
    onSelectionToolbarVisibilityChange?: (visible: boolean) => void,
    uploadingMedia: Map<string, IMediaItem>,
    setUploadingMedia: (mediaItem: IMediaItem) => void,
    updateUploadingMedia: (mediaItem: IMediaItem) => void,
    onCursorEnter?: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave?: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
}
function PortableToolbar({
    type = EDITOR_TOOLBAR_TYPE.block,
    user,
    hasSound,
    buttonLength,
    editorType,
    editorId,
    top = 0,
    left = 0,
    hide = false,
    readOnly = false,
    setRef,
    color = '#4194D1',
    toggleBold,
    containsBold = false,
    toggleItalics,
    containsItalics = false,
    toggleHeaderOne,
    containsHeaderOne = false,
    toggleHeaderTwo,
    containsHeaderTwo = false,
    toggleUnderline,
    containsUnderline = false,
    toggleStrikethrough,
    containsStrikethrough = false,
    toggleBulletedList,
    containsBulletedList = false,
    toggleNumberedList,
    containsNumberedList = false,
    toggleQuote,
    containsQuote = false,
    toggleDivider,
    containsDivider = false,
    toggleZoom,
    containsZoom = false,
    createFigure,
    containsFigure = false,
    createLink,
    updateLink,
    removeLink,
    selectedLinkURL = '',
    selectedLinkID = '',
    containsLink = false,
    createYouTubeEmbedding,
    containsYouTube = false,
    createTwitterEmbedding,
    containsTwitter = false,
    createSpotifyEmbedding,
    containsSpotify = false,
    createVimeoEmbedding,
    containsVimeo = false,
    insertAudioNote,
    containsAudioNote = false,
    handleEmojiSelect,
    updatePosition,
    defaultOpenGroups,
    selection,
    setSelection,
    selectEndDocument,
    value,
    onSelectionToolbarVisibilityChange,
    uploadingMedia,
    setUploadingMedia,
    updateUploadingMedia,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
}: Props): JSX.Element {
    // TODO implement shortcuts

    // ===== General Constants =====

    const ALL_OPEN_TOOLS_PER_ROW = 4;
    const EMOJI_SELECTOR_WIDTH = 20.625 * BODY_FONT_SIZE;
    const EMOJI_SELECTOR_HEIGHT = 12.5 * BODY_FONT_SIZE;
    const EMOJI_SELECTOR_BUTTON_HEIGHT = 1.875 * BODY_FONT_SIZE;
    const LINK_CARD_FONT_MULTIPLIER = 0.8;

    // ===== Refs =====

    const toolbarRef = useRef<HTMLDivElement>(null);
    const linkInputRef = useRef<HTMLInputElement>(null);
    const emojiInputRef = useRef<HTMLElement | null>(null);

    // ----- Sound Clips

    const createAudioNoteClip = useRef<HTMLAudioElement>(new Audio());
    const pivotExpandClip = useRef<HTMLAudioElement>(new Audio());
    const pivotContractClip = useRef<HTMLAudioElement>(new Audio());
    const inputClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State ====

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [xPos, setXPos] = useState<number>(left);
    const [yPos, setYPos] = useState<number>(top);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [allToolGroupsOpen, setAllToolGroupsOpen] = useState<boolean>(false);
    const [allToolGroupsClosed, setAllToolGroupsClosed] = useState<boolean>(true);
    const [tooltipText, setTooltipText] = useState<ITool | string>('');
    const [linkURL, setLinkURL] = useState<string | null>(selectedLinkURL);
    const [linkPlaceholderText, setLinkPlaceholderText] = useState<string>('');
    const [isEditingLink, setIsEditingLink] = useState<boolean>(!(selectedLinkURL && validateURL(selectedLinkURL)));
    const [selectedTool, setSelectedTool] = useState<EDITOR_TOOL | null>(null);
    // When we have a selection, this indicates what tool is applied to it
    const [appliedTool, setAppliedTool] = useState<IAppliedEditorTool | null>(null);
    const [textSelection, setTextSelection] = useState<Range | null>(null);
    const [prevValue, setPrevValue] = useState<Descendant[] | null>(null);
    const [maxToolRowCount, setMaxToolRowCount] = useState<number>(MAX_TOOLS_PER_ROW);
    const [showSelectionToolbar, setShowSelectionToolbar] = useState<boolean>(false);
    // stores OG Metadata of cartridge with website
    const [linkMetadata, setLinkMetadata] = useState<ILinkMetadata | null>(null);
    const focused = useFocused();
    const [uploadingStateChangeEvent, setUploadingStateChangeEvent] = useState<{
        mediaItem: IMediaItem,
        snapshot: UploadTaskSnapshot,
        progress: number,
    } | null>(null);
    const [uploadingCompleteEvent, setUploadingCompleteEvent] = useState<{
        mediaItem: IMediaItem,
        storageRef: StorageReference,
    } | null>(null);
    // Upload dropzone files to storage
    // and create figure
    const onDropAccepted = useCallback((acceptedFiles: File[]) => {
        const uploadTasks: UploadTask[] = [];
        const mediaItems: IMediaItem[] = [];
        for (let i = 0; i < acceptedFiles.length; i += 1) {
            const file = acceptedFiles[i];
            if (!uploadingMedia.has(file.name)) {
                const mediaId = uuidv4();
                const uniqueId = new ShortUniqueId({ length: 6 })(); // avoid file name collisions
                const mediaBucket = getMediaStorageBucket(MEDIA_TYPE.image);
                const fileName = file.name.toLowerCase().split('.')[0].replace(' ', '_');
                const fileExtension = mime.extension(file.type);
                let storageEntity: STORAGE_ENTITY;
                if (editorType === EDITOR_CONTEXT_TYPE.post) {
                    storageEntity = process.env.NODE_ENV === 'production'
                        ? STORAGE_ENTITY.posts
                        : STORAGE_ENTITY.stagingPosts;
                } else {
                    storageEntity = process.env.NODE_ENV === 'development'
                        ? STORAGE_ENTITY.stagingAnnotations
                        : STORAGE_ENTITY.annotations;
                }
                const filePath = `${storageEntity}/${mediaBucket}/${mediaId}/${fileName}-${uniqueId}.${fileExtension}`;
                const mediaItem: IMediaItem = {
                    id: mediaId,
                    userId: user!.id,
                    file,
                    filePath,
                    type: MEDIA_TYPE.image,
                    uploadProgress: 0,
                    caption: '',
                };
                mediaItems.push(mediaItem);
                setUploadingMedia(mediaItem);

                // make db entry of media
                // execute before file upload so upload cloud function has a place to write to
                setMediaInDB({
                    mediaItem,
                    filePath,
                });

                // upload file to cloud storage
                // url will be set by cloud function
                const uploadTask = uploadToCloudStorage(
                    file,
                    filePath,
                );
                uploadTasks.push(uploadTask);
            }
        }

        uploadTasks.forEach((task: UploadTask, index: number) => {
            task.on(
                'state_changed',
                (snapshot: UploadTaskSnapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadingStateChangeEvent({
                        mediaItem: mediaItems[index],
                        snapshot,
                        progress,
                    });
                },
                (error: StorageError) => {
                    throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
                },
                () => {
                    setUploadingCompleteEvent({
                        mediaItem: mediaItems[index],
                        storageRef: task.snapshot.ref,
                    });
                },
            );
        });
    }, [user]);
    const {
        open: openFileSelector,
        getRootProps,
        getInputProps,
    } = useDropzone({
        noClick: true,
        noDrag: true,
        noKeyboard: true,
        accept: 'image/*',
        onDropAccepted,
    });

    // ===== Animation Constants =====

    const EMOJI_TRANSITION_DURATION = 150;
    const TOOLBAR_TRANSITION_DURATION = 150;
    const SELECTION_TOOLBAR_VISIBILITY_CHANGE_DELAY = 150;

    const onBold = (tool: EDITOR_TOOL, sel: Selection): void => toggleBold();

    const onItalic = (tool: EDITOR_TOOL, sel: Selection): void => toggleItalics();

    const onUnderline = (tool: EDITOR_TOOL, sel: Selection): void => toggleUnderline();

    const onStrikethrough = (tool: EDITOR_TOOL, sel: Selection): void => toggleStrikethrough();

    const onBulletList = (tool: EDITOR_TOOL, sel: Selection): void => toggleBulletedList();

    const onNumberList = (tool: EDITOR_TOOL, sel: Selection): void => toggleNumberedList();

    const onHeaderOne = (tool: EDITOR_TOOL, sel: Selection): void => toggleHeaderOne();

    const onHeaderTwo = (tool: EDITOR_TOOL, sel: Selection): void => toggleHeaderTwo();

    const onQuote = (tool: EDITOR_TOOL, sel: Selection): void => toggleQuote();

    const onDivider = (tool: EDITOR_TOOL, sel: Selection): void => toggleDivider();

    const onZoom = (tool: EDITOR_TOOL, sel: Selection): void => { if (toggleZoom) toggleZoom(); };

    const onFigure = (tool: EDITOR_TOOL, sel: Selection): void => {
        if (!sel) {
            // If we want to add a Figure to Slate, without prior selection
            // Select end of document first
            selectEndDocument();
        }

        openFileSelector();
    };

    const onAudioNote = (tool: EDITOR_TOOL, sel: Selection): void => {
        // Make sure selection is set
        if (sel) {
            setSelection(sel);
        } else {
            // If we want to add an Audio Note to Slate, without prior selection
            // Select end of document first
            selectEndDocument();
        }

        // Play Sound
        if (hasSound && createAudioNoteClip.current) {
            createAudioNoteClip.current.pause();
            createAudioNoteClip.current.currentTime = 0;
            playAudio(createAudioNoteClip.current);
        }

        const audioNoteId = uuidv4();
        insertAudioNote(audioNoteId);

        // Close Toolbar
        setIsOpen(false);
    };

    const onLink = (tool: EDITOR_TOOL, sel: Selection): void => {
        if (sel) {
            setSelection(sel);
        }

        setSelectedTool(tool);
    };

    const onEmoji = (tool: EDITOR_TOOL, sel: Selection): void => {
        setSelectedTool(tool);
    };

    const onEmbedding = (tool: EDITOR_TOOL, sel: Selection): void => {
        if (sel) {
            setSelection(sel);
        }

        setSelectedTool(tool);
    };

    // TODO Tools still to be implemented

    const onTable = (tool: EDITOR_TOOL, sel: Selection): void => console.log('Handle Table', tool);

    const onChecklist = (tool: EDITOR_TOOL, sel: Selection): void => console.log('Handle Checklist', tool);

    const onCode = (tool: EDITOR_TOOL, sel: Selection): void => console.log('Handle Code', tool);

    const onMention = (tool: EDITOR_TOOL, sel: Selection): void => console.log('Handle Mention', tool);

    const onSketch = (tool: EDITOR_TOOL, sel: Selection): void => console.log('Handle Sketch', tool);

    // ===== State =====

    const [toolGroups, setToolGroups] = useState<Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup>>(new Map([
        [
            EDITOR_TOOLBAR_TOOL_GROUP.text,
            {
                id: EDITOR_TOOLBAR_TOOL_GROUP.text,
                currentIndex: 0,
                index: 0,
                active:
                    !!toggleBold
                    || !!toggleItalics
                    || !!toggleHeaderOne
                    || !!toggleHeaderTwo
                    || !!toggleUnderline
                    || !!toggleStrikethrough
                    || (!!createLink && !!removeLink && !!updateLink),
                expanded: !!defaultOpenGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text),
                name: 'Text',
                icon: TextIcon,
                tools: new Map([
                    [
                        EDITOR_TOOL.bold,
                        {
                            id: EDITOR_TOOL.bold,
                            active: !!toggleBold,
                            applied: containsBold,
                            name: 'Bold',
                            icon: BoldIcon,
                            shortcut: useMemo(() => {
                                if (isMacOS()) {
                                    return '⌘ + B';
                                }

                                return 'Ctrl + B';
                            }, []),
                            handleClick: onBold,
                        },
                    ],
                    [
                        EDITOR_TOOL.italic,
                        {
                            id: EDITOR_TOOL.italic,
                            active: !!toggleItalics,
                            applied: containsItalics,
                            name: 'Italic',
                            icon: ItalicIcon,
                            shortcut: useMemo(() => {
                                if (isMacOS()) {
                                    return '⌘ + I';
                                }

                                return 'Ctrl + I';
                            }, []),
                            handleClick: onItalic,
                        },
                    ],
                    [
                        EDITOR_TOOL.headerOne,
                        {
                            id: EDITOR_TOOL.headerOne,
                            active: !!toggleHeaderOne,
                            applied: containsHeaderOne,
                            name: 'Header 1',
                            icon: HeaderOneIcon,
                            // shortcut: useMemo(() => '# + Space'),
                            shortcut: null,
                            handleClick: onHeaderOne,
                        },
                    ],
                    [
                        EDITOR_TOOL.headerTwo,
                        {
                            id: EDITOR_TOOL.headerTwo,
                            active: !!toggleHeaderTwo,
                            applied: containsHeaderTwo,
                            name: 'Header 2',
                            icon: HeaderTwoIcon,
                            // shortcut: useMemo(() => '## + Space'),
                            shortcut: null,
                            handleClick: onHeaderTwo,
                        },
                    ],
                    [
                        EDITOR_TOOL.underline,
                        {
                            id: EDITOR_TOOL.underline,
                            active: !!toggleUnderline,
                            applied: containsUnderline,
                            name: 'Underline',
                            icon: UnderlineIcon,
                            shortcut: useMemo(() => {
                                if (isMacOS()) {
                                    return '⌘ + U';
                                }

                                return 'Ctrl + U';
                            }, []),
                            handleClick: onUnderline,
                        },
                    ],
                    [
                        EDITOR_TOOL.strikethrough,
                        {
                            id: EDITOR_TOOL.strikethrough,
                            active: !!toggleStrikethrough,
                            applied: containsStrikethrough,
                            name: 'Strikethrough',
                            icon: StrikethroughIcon,
                            shortcut: useMemo(() => {
                                if (isMacOS()) {
                                    return '⌘ + Shift + S';
                                }

                                return 'Ctrl + Shift + S';
                            }, []),
                            handleClick: onStrikethrough,
                        },
                    ],
                    [
                        EDITOR_TOOL.link,
                        {
                            id: EDITOR_TOOL.link,
                            active: !!createLink && !!removeLink && !!updateLink,
                            applied: containsLink,
                            name: 'Link',
                            icon: LinkIcon,
                            // shortcut: useMemo(() => {
                            //     if (isMacOS()) {
                            //         return '⌘ + K';
                            //     }
                            //
                            //     return 'Ctrl + K';
                            // }),
                            shortcut: null,
                            handleClick: onLink,
                        },
                    ],
                ]),
            },
        ],
        [
            EDITOR_TOOLBAR_TOOL_GROUP.layout,
            {
                id: EDITOR_TOOLBAR_TOOL_GROUP.layout,
                currentIndex: 1,
                index: 1,
                active:
                    !!toggleBulletedList
                    || !!toggleNumberedList
                    || !!toggleQuote
                    || !!toggleDivider
                    || !!toggleZoom,
                name: 'Layout',
                expanded: !!defaultOpenGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout),
                icon: LayoutIcon,
                tools: new Map([
                    [
                        EDITOR_TOOL.bulletedList,
                        {
                            id: EDITOR_TOOL.bulletedList,
                            active: !!toggleBulletedList,
                            applied: containsBulletedList,
                            name: 'Bulleted List',
                            icon: BulletListIcon,
                            // shortcut: useMemo(() => '* + Space'),
                            shortcut: null,
                            handleClick: onBulletList,
                        },
                    ],
                    [
                        EDITOR_TOOL.numberedList,
                        {
                            id: EDITOR_TOOL.numberedList,
                            active: !!toggleNumberedList,
                            applied: containsNumberedList,
                            name: 'Numbered List',
                            icon: NumberedListIcon,
                            // shortcut: useMemo(() => '1. + Space'),
                            shortcut: null,
                            handleClick: onNumberList,
                        },
                    ],
                    [
                        EDITOR_TOOL.quote, {
                            id: EDITOR_TOOL.quote,
                            active: !!toggleQuote,
                            applied: containsQuote,
                            name: 'Quote',
                            icon: QuoteIcon,
                            // shortcut: useMemo(() => '> + Space'),
                            shortcut: null,
                            handleClick: onQuote,
                        },
                    ],
                    [
                        EDITOR_TOOL.table,
                        {
                            id: EDITOR_TOOL.table,
                            // TODO Set to false based on block or selection (and whether callback exists)
                            active: false,
                            // TODO Set to be true if props sent down
                            applied: false,
                            name: 'Table',
                            icon: TableIcon,
                            shortcut: null,
                            handleClick: onTable,
                        },
                    ],
                    [
                        EDITOR_TOOL.checklist,
                        {
                            id: EDITOR_TOOL.checklist,
                            // TODO Set to false based on block or selection (and whether callback exists)
                            active: false,
                            // TODO Set to be true if props sent down
                            applied: false,
                            name: 'Checklist',
                            icon: ChecklistIcon,
                            // shortcut: useMemo(() => '[] + Space'),
                            shortcut: null,
                            handleClick: onChecklist,
                        },
                    ],
                    [
                        EDITOR_TOOL.code,
                        {
                            id: EDITOR_TOOL.code,
                            // TODO Set to false based on block or selection (and whether callback exists)
                            active: false,
                            // TODO Set to be true if props sent down
                            applied: false,
                            name: 'Code',
                            icon: CodeIcon,
                            // shortcut: useMemo(() => '```'),
                            shortcut: null,
                            handleClick: onCode,
                        },
                    ],
                    [
                        EDITOR_TOOL.divider,
                        {
                            id: EDITOR_TOOL.divider,
                            active: !!toggleDivider,
                            applied: containsDivider,
                            name: 'Divider',
                            icon: DividerIcon,
                            // shortcut: useMemo(() => '---'),
                            shortcut: null,
                            handleClick: onDivider,
                        },
                    ],
                    [
                        EDITOR_TOOL.zoom,
                        {
                            id: EDITOR_TOOL.zoom,
                            active: !!toggleZoom,
                            applied: containsZoom,
                            name: 'Zoom',
                            icon: MagnifyingGlassIcon,
                            // shortcut: useMemo(() => '---'),
                            shortcut: null,
                            handleClick: onZoom,
                        },
                    ],
                ]),
            },
        ],
        [
            EDITOR_TOOLBAR_TOOL_GROUP.media,
            {
                id: EDITOR_TOOLBAR_TOOL_GROUP.media,
                currentIndex: 2,
                index: 2,
                active:
                    !!createFigure
                    || !!handleEmojiSelect
                    || !!insertAudioNote,
                name: 'Media',
                expanded: !!defaultOpenGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media),
                icon: MediaIcon,
                tools: new Map([
                    [
                        EDITOR_TOOL.figure,
                        {
                            id: EDITOR_TOOL.figure,
                            active: !!createFigure,
                            applied: containsFigure,
                            name: 'Image',
                            icon: ImageIcon,
                            shortcut: null,
                            handleClick: onFigure,
                        },
                    ],
                    [
                        EDITOR_TOOL.mention,
                        {
                            id: EDITOR_TOOL.mention,
                            // TODO Set to false based on block or selection (and whether callback exists)
                            active: false,
                            // TODO Set to be true if props sent down
                            applied: false,
                            name: 'Mention',
                            icon: AtSignIcon,
                            shortcut: useMemo(() => '@', []),
                            handleClick: onMention,
                        },
                    ],
                    [
                        EDITOR_TOOL.audioNote,
                        {
                            id: EDITOR_TOOL.audioNote,
                            active: !!insertAudioNote,
                            applied: containsAudioNote,
                            name: 'Audio Note',
                            icon: MicrophoneIcon,
                            shortcut: null,
                            handleClick: onAudioNote,
                        },
                    ],
                    [
                        EDITOR_TOOL.sketch,
                        {
                            id: EDITOR_TOOL.sketch,
                            // TODO Set to false based on block or selection (and whether callback exists)
                            active: false,
                            // TODO Set to be true if props sent down
                            applied: false,
                            name: 'Sketch',
                            icon: SketchIcon,
                            shortcut: null,
                            handleClick: onSketch,
                        },
                    ],
                    [
                        EDITOR_TOOL.emoji,
                        {
                            id: EDITOR_TOOL.emoji,
                            active: !!handleEmojiSelect,
                            applied: false,
                            name: 'Emojis',
                            icon: SmileyIcon,
                            shortcut: null,
                            handleClick: onEmoji,
                        },
                    ],
                ]),
            },
        ],
        [
            EDITOR_TOOLBAR_TOOL_GROUP.embeddings,
            {
                id: EDITOR_TOOLBAR_TOOL_GROUP.embeddings,
                currentIndex: 3,
                index: 3,
                active:
                    !!createYouTubeEmbedding
                    || !!createTwitterEmbedding
                    || !!createSpotifyEmbedding,
                name: 'Embeddings',
                expanded: !!defaultOpenGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings),
                icon: IntegrationsIcon,
                tools: new Map([
                    [
                        EDITOR_TOOL.youtube,
                        {
                            id: EDITOR_TOOL.youtube,
                            active: !!createYouTubeEmbedding,
                            applied: containsYouTube,
                            name: 'YouTube',
                            icon: YouTubeIcon,
                            shortcut: null,
                            handleClick: onEmbedding,
                        },
                    ],
                    [
                        EDITOR_TOOL.twitter,
                        {
                            id: EDITOR_TOOL.twitter,
                            active: !!createTwitterEmbedding,
                            applied: containsTwitter,
                            name: 'Twitter',
                            icon: TwitterIcon,
                            shortcut: null,
                            handleClick: onEmbedding,
                        },
                    ],
                    [
                        EDITOR_TOOL.spotify,
                        {
                            id: EDITOR_TOOL.spotify,
                            active: !!createSpotifyEmbedding,
                            applied: containsSpotify,
                            name: 'Spotify',
                            icon: SpotifyIcon,
                            shortcut: null,
                            handleClick: onEmbedding,
                        },
                    ],
                    [
                        EDITOR_TOOL.vimeo,
                        {
                            id: EDITOR_TOOL.vimeo,
                            active: !!createVimeoEmbedding,
                            applied: containsVimeo,
                            name: 'Vimeo',
                            icon: VimeoIcon,
                            shortcut: null,
                            handleClick: onEmbedding,
                        },
                    ],
                ]),
            },
        ],
        [
            EDITOR_TOOLBAR_TOOL_GROUP.expandAll,
            {
                id: EDITOR_TOOLBAR_TOOL_GROUP.expandAll,
                currentIndex: 4,
                index: 4,
                active: true,
                name: 'Expand All',
                tools: null,
            },
        ],
    ]));

    // ===== Side Effects =====

    useEffect(() => {
        if (toolbarRef.current && setRef) {
            setRef(toolbarRef.current);
        }
    }, [toolbarRef.current]);

    // Determine visibility of Selection Toolbar

    // Broadcast to parent that Selection Toolbar is Active
    const {
        start: delayBroadcastSelectionToolbarVisible,
        clear: clearDelayBroadcastSelectionToolbarVisible,
    } = useTimeout((visible: boolean) => {
        // We delay changing selection toolbar to true
        // in the parent editor because it will initiate
        // a premature rerender before Slate calls onChange.
        // This results the changes in that Slate onChange
        // not being factored in. Consequences of this can
        // be a "jumping" cursor.
        //
        // Further, we delay the state change in order to allow
        // a user to double click text to select the entire line
        setShowSelectionToolbar(visible);

        if (onSelectionToolbarVisibilityChange) {
            onSelectionToolbarVisibilityChange(visible);
        }

        // Update Position
        if (updatePosition) {
            updatePosition();
        }

        // Remove tool selection if not a link
        if (!containsLink) {
            setSelectedTool(null);
        }
    }, SELECTION_TOOLBAR_VISIBILITY_CHANGE_DELAY);

    // Inspect Mouseup events to confirm if user has
    // used mouse to select text.
    // If so, show selection toolbar.
    useEventListener(
        'mouseup',
        (e: Event) => {
            const targetEditor = e.composedPath().find((ele: EventTarget) => {
                const target = (ele as HTMLElement);
                if (target.dataset) {
                    return target.dataset.id === editorId;
                }

                return false;
            });
            const nativeSelection = window.getSelection();
            const showToolbar = (
                (!!selection
                    && !nativeSelection?.isCollapsed
                ) || (selectedLinkURL !== null)
            ) && !!targetEditor;

            // If Text Selection and Selection Toolbar not Visible Already
            // Show Selection Toolbar
            if (
                !readOnly
                && !nativeSelection?.isCollapsed
                && type === EDITOR_TOOLBAR_TYPE.selection
                && showToolbar !== showSelectionToolbar
            ) {
                clearDelayBroadcastSelectionToolbarVisible();
                delayBroadcastSelectionToolbarVisible(showToolbar);
            } else if (
                !readOnly
                && type === EDITOR_TOOLBAR_TYPE.selection
                && showSelectionToolbar
                && toolbarRef.current
                && e.composedPath
                && !e.composedPath().includes(toolbarRef.current)
            ) {
                setShowSelectionToolbar(false);
                // Only Toolbar of type SELECTION will get function
                if (onSelectionToolbarVisibilityChange) {
                    onSelectionToolbarVisibilityChange(false);
                }
            }
        },
    );

    // Inspect Keyup events to confirm if user has
    // used keys to select text.
    // If so, show selection toolbar.
    useEventListener(
        'keyup',
        (e) => {
            const targetEditor = e.composedPath().find((ele: EventTarget) => {
                const target = (ele as HTMLElement);
                if (target.dataset) {
                    return target.dataset.id === editorId;
                }

                return false;
            });
            const nativeSelection = window.getSelection();
            const showToolbar = (
                (!!selection
                    && !nativeSelection?.isCollapsed
                ) || (selectedLinkURL !== null)
            ) && !!targetEditor;

            // If Text Selection and Selection Toolbar not Visible Already
            // Show Selection Toolbar
            if (
                !readOnly
                && !nativeSelection?.isCollapsed
                && type === EDITOR_TOOLBAR_TYPE.selection
                && showToolbar
                && showToolbar !== showSelectionToolbar
            ) {
                clearDelayBroadcastSelectionToolbarVisible();
                delayBroadcastSelectionToolbarVisible();

                // Remove tool selection if not a link
                if (!containsLink) {
                    setSelectedTool(null);
                }
            }

            if (
                !readOnly
                && nativeSelection?.isCollapsed
                && type === EDITOR_TOOLBAR_TYPE.selection
                && document.activeElement !== toolbarRef.current
                && document.activeElement !== linkInputRef.current
                && document.activeElement !== emojiInputRef.current
                && !showToolbar
                && showToolbar !== showSelectionToolbar
            ) {
                setShowSelectionToolbar(false);
                // Only Toolbar of type SELECTION will get function
                if (onSelectionToolbarVisibilityChange) {
                    onSelectionToolbarVisibilityChange(false);
                }
                setSelectedTool(null);
            }
        },
    );

    // If user places collapsed cursor text that is wrapped by
    // a link (containsLink), make the Selection Toolbar
    // visible and show link card.
    //
    // Having the 'focused' flag ensures we don't show selection
    // if we weren't focused on the Slate Editor
    useEffect(() => {
        // If we make the link in block toolbar
        // We show link card in selection toolbar
        if (
            containsLink
            && !!selection
            && window.getSelection()?.isCollapsed
            && focused
            && type === EDITOR_TOOLBAR_TYPE.selection
        ) {
            setSelectedTool(EDITOR_TOOL.link);
            setIsEditingLink(false);

            clearDelayBroadcastSelectionToolbarVisible();
            delayBroadcastSelectionToolbarVisible();

            if (
                (selectedLinkURL && !linkURL)
                || selectedLinkURL !== linkURL
            ) {
                // When we create a link from the block toolbar
                // that link is not duplicated in the selection toolbar always
                // because it might be in isEditingLink mode
                setLinkURL(selectedLinkURL);
            }
        }
    }, [containsLink, selection]);

    // update position of toolbar
    // Only update if editor is focused
    useEffect(() => {
        if (
            focused
            && top !== yPos
        ) {
            setYPos(top);
        }

        if (
            focused
            && left !== xPos
        ) {
            setXPos(left);
        }
    }, [top, left]);

    // Always expand default groups when show
    // User might have collapsed some in previous
    // toolbar session
    useEffect(() => {
        if ((showSelectionToolbar || isOpen) && defaultOpenGroups) {
            const updatedToolGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup> = deepCopy(toolGroups);
            defaultOpenGroups.forEach((_value: boolean, groupName: EDITOR_TOOLBAR_TOOL_GROUP) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if (updatedToolGroups && updatedToolGroups.has(groupName)) updatedToolGroups!.get(groupName)!.expanded = true;
            });
            setToolGroups(updatedToolGroups);
        }
    }, [
        showSelectionToolbar,
        isOpen,
    ]);

    // collapse block toolbar if it is instructed to hide
    useEffect(() => {
        if (hide && isOpen) {
            setIsOpen(false);
        }
    }, [hide]);

    // Hide selection toolbar if we switch from write to readonly (such as when editing annotations)
    useEffect(() => {
        if (readOnly && showSelectionToolbar) {
            setShowSelectionToolbar(false);
        }
    }, [readOnly]);

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            createAudioNoteClip.current
            && pivotExpandClip.current
            && pivotContractClip.current
            && inputClickClip.current
        ) {
            // Create Audio Note
            createAudioNoteClip.current.volume = DEFAULT_AUDIO_VOLUME;
            createAudioNoteClip.current.src = CreateClip;

            // Pivot Expand
            pivotExpandClip.current.volume = DEFAULT_AUDIO_VOLUME;
            pivotExpandClip.current.src = PivotExpand;

            // Pivot Contract
            pivotContractClip.current.volume = DEFAULT_AUDIO_VOLUME;
            pivotContractClip.current.src = PivotContract;

            // Input Click
            inputClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            inputClickClip.current.src = InputClick;
        }

        return function cleanup() {
            if (createAudioNoteClip.current) createAudioNoteClip.current.remove();
            if (pivotExpandClip.current) pivotExpandClip.current.remove();
            if (pivotContractClip.current) pivotContractClip.current.remove();
            if (inputClickClip.current) inputClickClip.current.remove();
        };
    }, []);

    useEffect(() => {
        if (uploadingStateChangeEvent) {
            handleUploadingMediaStateChange(
                uploadingStateChangeEvent.mediaItem,
                uploadingStateChangeEvent.snapshot,
                uploadingStateChangeEvent.progress,
            );
            // setUploadingStateChangeEvent(null);
        }
    }, [uploadingStateChangeEvent]);

    useEffect(() => {
        if (uploadingCompleteEvent) {
            // Handle successful uploads on complete
            getDownloadURL(uploadingCompleteEvent.storageRef).then((downloadURL: string) => {
                handleUploadingMediaComplete(uploadingCompleteEvent.mediaItem, downloadURL);
            });
        }
    }, [uploadingCompleteEvent]);

    // if creating a link and press enter,
    // initiate submit process
    useEventListener(
        'keydown',
        (e: Event) => {
            if ((
                (e as KeyboardEvent).key === KEYCODE.enter
                && isEditingLink
                && selectedTool === EDITOR_TOOL.link
                && linkURL
                && validateURL(linkURL)
            ) || (
                (e as KeyboardEvent).key === KEYCODE.enter
                && selectedTool === EDITOR_TOOL.youtube
                && linkURL
                && getYouTubeParams(linkURL)
            )
            || (
                (e as KeyboardEvent).key === KEYCODE.enter
                && selectedTool === EDITOR_TOOL.twitter
                && linkURL
                && getTwitterId(linkURL)
            ) || (
                (e as KeyboardEvent).key === KEYCODE.enter
                && selectedTool === EDITOR_TOOL.spotify
                && linkURL
                && getSpotifyId(linkURL)
            ) || (
                (e as KeyboardEvent).key === KEYCODE.enter
                && selectedTool === EDITOR_TOOL.vimeo
                && linkURL
                && getVimeoId(linkURL)
            )) {
                if (selectedTool === EDITOR_TOOL.link) {
                    onLinkSubmit();
                } else {
                    if (!selection) {
                        // If we want to add a link to Slate, without prior selection
                        // Select end of document first
                        selectEndDocument();
                    }

                    onEmbeddingCreate(selectedTool, e);
                }
            }
        },
        linkInputRef.current,
    );

    // if creating a link
    // focus link input and prepare appropriate link input value
    // and placeholder
    useEffect(() => {
        if (
            (
                (
                    isEditingLink
                    && !selectedLinkURL
                    && selectedTool === EDITOR_TOOL.link
                ) || selectedTool === EDITOR_TOOL.youtube
                || selectedTool === EDITOR_TOOL.twitter
                || selectedTool === EDITOR_TOOL.spotify
                || selectedTool === EDITOR_TOOL.vimeo
            ) && linkInputRef.current
        ) {
            linkInputRef.current.focus();
            if (selectedTool === EDITOR_TOOL.link && linkURL) {
                // If editing link, place value in link
                linkInputRef.current.value = linkURL;
            } else {
                // If adding an embedding, clear link
                linkInputRef.current.value = '';
            }

            switch (selectedTool) {
            case EDITOR_TOOL.youtube:
                setLinkPlaceholderText('Enter YouTube URL');
                break;
            case EDITOR_TOOL.twitter:
                setLinkPlaceholderText('Enter Twitter URL');
                break;
            case EDITOR_TOOL.spotify:
                setLinkPlaceholderText('Enter Spotify URL');
                break;
            case EDITOR_TOOL.vimeo:
                setLinkPlaceholderText('Enter Vimeo URL');
                break;
            default:
                setLinkPlaceholderText('Enter Link URL');
                break;
            }
        }
    }, [selectedTool, isEditingLink, linkInputRef.current]);

    // Determine Link Editing Mode
    // when Selection Toolbar made visible
    useEffect(() => {
        if (
            selectedTool === EDITOR_TOOL.link
            && type === EDITOR_TOOLBAR_TYPE.selection
            && !linkURL
        ) {
            setIsEditingLink(true);
        } else if (
            selectedTool === EDITOR_TOOL.link
            && type === EDITOR_TOOLBAR_TYPE.selection
            && linkURL
        ) {
            setIsEditingLink(false);
        }
    }, [selectedTool]);

    // Set Link Input Value
    useEffect(() => {
        if (
            linkURL
            && linkInputRef.current
        ) {
            // Set link input value if we have a url
            linkInputRef.current.value = linkURL;

            // Get Link Metadata
            fetchURLMetadata(
                linkURL,
                (result: HttpsCallableResult<unknown>) => {
                    const metadata: ILinkMetadata = result.data as ILinkMetadata;
                    setLinkMetadata(metadata);
                },
            );
        } else if (
            !linkURL
            && linkInputRef.current
        ) {
            // Empty link value if we have no linkURL
            linkInputRef.current.value = '';
        }
    }, [isEditingLink, linkInputRef.current]);

    // maintain editor text selection when typing out link
    useEffect(() => {
        if (
            !selection
            && document.activeElement === linkInputRef.current
            && textSelection) {
            // Place lost selection back on slate editor
            // create link process needs to read it
            setSelection(textSelection);
        }
    }, [document.activeElement, selection]);

    // maintain/keep track of selection while selecting emojis
    // and change valid selection while typing input
    useEffect(() => {
        if (selectedTool === EDITOR_TOOL.emoji) {
            if (!selection && textSelection) {
                setSelection(textSelection);
            } else if (
                selection
                && selection !== textSelection
            ) {
                setTextSelection(selection);
            }
        } else if (
            (
                selectedTool === EDITOR_TOOL.link
                || selectedTool === EDITOR_TOOL.youtube
                || selectedTool === EDITOR_TOOL.twitter
                || selectedTool === EDITOR_TOOL.spotify
                || selectedTool === EDITOR_TOOL.vimeo
            ) && selection
            && selection !== textSelection
        ) {
            setTextSelection(selection);
        }
    }, [selection]);

    // Close and open toolbar when not focused
    // or when start typing (indicated by a changing slate value)
    useEffect(() => {
        if (
            (
                (
                    document.activeElement !== toolbarRef.current
                    && document.activeElement !== linkInputRef.current
                    && document.activeElement !== emojiInputRef.current
                    && !focused
                ) || (
                    JSON.stringify(value, null, 2)
                    !== JSON.stringify(prevValue, null, 2)
                    && !selectedTool
                    && !containsBold
                    && !containsItalics
                    && !containsHeaderOne
                    && !containsHeaderTwo
                    && !containsUnderline
                    && !containsStrikethrough
                )
            )
            && (
                isOpen
                || showSelectionToolbar
            )
        ) {
            setIsOpen(false);
            setShowSelectionToolbar(false);
            // Only Toolbar of type SELECTION will get function
            if (onSelectionToolbarVisibilityChange) {
                onSelectionToolbarVisibilityChange(false);
            }
            setSelectedTool(null);
        }

        // Cache previous slate value
        setPrevValue(value);
    }, [document.activeElement, value]);

    // if link url modified
    // update in toolbar
    useEffect(() => {
        if (
            selectedLinkURL !== linkURL
            && !isEditingLink
        ) {
            setLinkURL(selectedLinkURL);
        }

        // Get Link Metadata to show if we don't have it already
        if (
            !isEditingLink
            && selectedLinkURL
            && validateURL(selectedLinkURL)
            && linkURL
        ) {
            // Get Link Metadata
            fetchURLMetadata(
                selectedLinkURL,
                (result: HttpsCallableResult<unknown>) => {
                    const metadata: ILinkMetadata = result.data as ILinkMetadata;
                    setLinkMetadata(metadata);
                },
            );
        }
    }, [selectedLinkURL, isEditingLink]);

    const allClosed = Array.from(toolGroups.values())
        .filter((group) => group.active)
        .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
        .filter((group) => !group.expanded);
    const toolCount = allClosed.map((group) => Array.from(group.tools!.values()).length);

    const {
        start: delayCloseAllToolGroups,
        clear: clearDelayCloseAllToolGroups,
    } = useTimeout(() => {
        const groupsClosed = allClosed.length
        === Array.from(toolGroups.values())
            .filter((group) => group.active)
            .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll).length;
        setAllToolGroupsClosed(groupsClosed);
        const groups = reorderTools(toolGroups);
        setToolGroups(groups);
    }, Math.max(...toolCount) * (TOOL_TRANSITION_DURATION + 25));

    // Determines Applied Tool
    useEffect(() => {
        const updatedToolGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup> = deepCopy(toolGroups);
        let updatedAppliedTool: IAppliedEditorTool | null = null;

        // Updating Applied Tools
        // YouTube
        if (
            containsYouTube !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)?.tools?.get(EDITOR_TOOL.youtube)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)!.tools!.get(EDITOR_TOOL.youtube)!.applied = containsYouTube;
        }

        if (!updatedAppliedTool && containsYouTube) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.embeddings,
                tool: EDITOR_TOOL.youtube,
            };
        }

        // Twitter
        if (
            containsTwitter !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)?.tools?.get(EDITOR_TOOL.twitter)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)!.tools!.get(EDITOR_TOOL.twitter)!.applied = containsTwitter;
        }

        if (!updatedAppliedTool && containsTwitter) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.embeddings,
                tool: EDITOR_TOOL.twitter,
            };
        }

        // Spotify
        if (
            containsSpotify !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)?.tools?.get(EDITOR_TOOL.spotify)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)!.tools!.get(EDITOR_TOOL.spotify)!.applied = containsSpotify;
        }

        if (!updatedAppliedTool && containsSpotify) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.embeddings,
                tool: EDITOR_TOOL.spotify,
            };
        }

        // Vimeo
        if (
            containsSpotify !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)?.tools?.get(EDITOR_TOOL.vimeo)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)!.tools!.get(EDITOR_TOOL.vimeo)!.applied = containsVimeo;
        }

        if (!updatedAppliedTool && containsVimeo) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.embeddings,
                tool: EDITOR_TOOL.vimeo,
            };
        }

        // Figure
        if (
            containsFigure !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media)?.tools?.get(EDITOR_TOOL.figure)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media)!.tools!.get(EDITOR_TOOL.figure)!.applied = containsFigure;
        }

        if (!updatedAppliedTool && containsFigure) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.media,
                tool: EDITOR_TOOL.figure,
            };
        }

        // Audio Note
        if (
            containsAudioNote !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media)?.tools?.get(EDITOR_TOOL.audioNote)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media)!.tools!.get(EDITOR_TOOL.audioNote)!.applied = containsAudioNote;
        }

        if (!updatedAppliedTool && containsAudioNote) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.media,
                tool: EDITOR_TOOL.audioNote,
            };
        }

        // Link
        if (
            containsLink !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.link)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.link)!.applied = containsLink;
        }

        if (!updatedAppliedTool && containsLink) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.text,
                tool: EDITOR_TOOL.link,
            };
        }

        // Divider
        if (
            containsDivider !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)?.tools?.get(EDITOR_TOOL.divider)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)!.tools!.get(EDITOR_TOOL.divider)!.applied = containsDivider;
        }

        if (!updatedAppliedTool && containsDivider) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.layout,
                tool: EDITOR_TOOL.divider,
            };
        }

        // Zoom
        if (
            containsZoom !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)?.tools?.get(EDITOR_TOOL.zoom)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)!.tools!.get(EDITOR_TOOL.zoom)!.applied = containsZoom;
        }

        if (!updatedAppliedTool && containsZoom) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.layout,
                tool: EDITOR_TOOL.zoom,
            };
        }

        // Bulleted List
        if (
            containsBulletedList !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)?.tools?.get(EDITOR_TOOL.bulletedList)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)!.tools!.get(EDITOR_TOOL.bulletedList)!.applied = containsBulletedList;
        }

        if (!updatedAppliedTool && containsBulletedList) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.layout,
                tool: EDITOR_TOOL.bulletedList,
            };
        }

        // Numbered List
        if (
            containsNumberedList !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)?.tools?.get(EDITOR_TOOL.numberedList)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)!.tools!.get(EDITOR_TOOL.numberedList)!.applied = containsNumberedList;
        }

        if (!updatedAppliedTool && containsNumberedList) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.layout,
                tool: EDITOR_TOOL.numberedList,
            };
        }

        // Quote
        if (
            containsQuote !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)?.tools?.get(EDITOR_TOOL.quote)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)!.tools!.get(EDITOR_TOOL.quote)!.applied = containsQuote;
        }

        if (!updatedAppliedTool && containsQuote) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.layout,
                tool: EDITOR_TOOL.quote,
            };
        }

        // Header One
        if (
            containsHeaderOne !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.headerOne)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.headerOne)!.applied = containsHeaderOne;
        }

        if (!updatedAppliedTool && containsHeaderOne) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.text,
                tool: EDITOR_TOOL.headerOne,
            };
        }

        // Header Two
        if (
            containsHeaderTwo !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.headerTwo)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.headerTwo)!.applied = containsHeaderTwo;
        }

        if (!updatedAppliedTool && containsHeaderTwo) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.text,
                tool: EDITOR_TOOL.headerTwo,
            };
        }

        // Bold
        if (
            containsBold !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.bold)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.bold)!.applied = containsBold;
        }

        if (!updatedAppliedTool && containsBold) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.text,
                tool: EDITOR_TOOL.bold,
            };
        }

        // Underline
        if (
            containsUnderline !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.underline)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.underline)!.applied = containsUnderline;
        }

        if (!updatedAppliedTool && containsUnderline) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.text,
                tool: EDITOR_TOOL.underline,
            };
        }

        // Strikethrough
        if (
            containsStrikethrough !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.strikethrough)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.strikethrough)!.applied = containsStrikethrough;
        }

        if (!updatedAppliedTool && containsStrikethrough) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.text,
                tool: EDITOR_TOOL.strikethrough,
            };
        }

        // Italics
        if (
            containsItalics !== toolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)?.tools?.get(EDITOR_TOOL.italic)?.applied
        ) {
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.tools!.get(EDITOR_TOOL.italic)!.applied = containsItalics;
        }

        if (!updatedAppliedTool && containsItalics) {
            updatedAppliedTool = {
                group: EDITOR_TOOLBAR_TOOL_GROUP.text,
                tool: EDITOR_TOOL.italic,
            };
        }

        setToolGroups(updatedToolGroups);
        setAppliedTool(updatedAppliedTool);
    }, [
        containsBold,
        containsItalics,
        containsHeaderOne,
        containsHeaderTwo,
        containsUnderline,
        containsStrikethrough,
        containsBulletedList,
        containsNumberedList,
        containsQuote,
        containsDivider,
        containsZoom,
        containsFigure,
        containsLink,
        containsYouTube,
        containsTwitter,
        containsSpotify,
        containsAudioNote,
    ]);

    // Determine if all groups closed
    // ** IMPORTANT **: Must be below determines Applied Tool
    useEffect(() => {
        const groupsClosed = allClosed.length
        === Array.from(toolGroups.values())
            .filter((group) => group.active)
            .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll).length;
        if (groupsClosed !== allToolGroupsClosed) {
            if (!groupsClosed) {
                setAllToolGroupsClosed(groupsClosed);
                const groups = reorderTools(toolGroups);
                setToolGroups(groups);
            } else {
                clearDelayCloseAllToolGroups();
                delayCloseAllToolGroups();
            }
        }
    }, [toolGroups]);

    // Determine if all groups open
    const {
        start: delayOpenAllToolGroups,
        clear: clearDelayOpenAllToolGroups,
    } = useTimeout(() => {
        const allOpen = Array.from(toolGroups.values())
            .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
            .reduce((result, group) => ((result && group.expanded) || false), true);
        setAllToolGroupsOpen(allOpen);
        setMaxToolRowCount(allOpen ? ALL_OPEN_TOOLS_PER_ROW : MAX_TOOLS_PER_ROW);
    }, Math.max(...toolCount) * (TOOL_TRANSITION_DURATION + 25));

    useEffect(() => {
        const allOpen = Array.from(toolGroups.values())
            .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
            .reduce((result, group) => ((result && group.expanded) || false), true);
        if (
            allOpen !== allToolGroupsOpen
            && allOpen
        ) {
            setAllToolGroupsOpen(allOpen);
            setMaxToolRowCount(allOpen ? ALL_OPEN_TOOLS_PER_ROW : MAX_TOOLS_PER_ROW);
        } else if (
            allOpen !== allToolGroupsOpen
            && !allOpen
        ) {
            clearDelayOpenAllToolGroups();
            delayOpenAllToolGroups();
        }
    }, [isOpen, showSelectionToolbar, toolGroups]);

    // ===== Methods =====

    const getPivotIcon = (): string => {
        if (
            appliedTool
            && toolGroups.get(appliedTool.group)?.tools?.get(appliedTool.tool)?.icon
        ) {
            return toolGroups.get(appliedTool.group)!.tools!.get(appliedTool.tool)!.icon;
        }

        if (selectedTool === EDITOR_TOOL.link) {
            return LinkIcon;
        }

        if (selectedTool === EDITOR_TOOL.emoji) {
            return SmileyIcon;
        }

        if (selectedTool === EDITOR_TOOL.youtube) {
            return YouTubeIcon;
        }

        if (selectedTool === EDITOR_TOOL.twitter) {
            return TwitterIcon;
        }

        if (selectedTool === EDITOR_TOOL.spotify) {
            return SpotifyIcon;
        }

        if (selectedTool === EDITOR_TOOL.vimeo) {
            return VimeoIcon;
        }

        return PlusIcon;
    };

    const toggleToolbar = (e: React.MouseEvent): void => {
        e.preventDefault();
        setIsOpen(!isOpen);

        // Play Sound
        if (
            hasSound
            && !isOpen
            && pivotExpandClip.current
        ) {
            pivotExpandClip.current.pause();
            pivotExpandClip.current.currentTime = 0;
            playAudio(pivotExpandClip.current);
        } else if (
            hasSound
            && isOpen
            && pivotContractClip.current
        ) {
            pivotContractClip.current.pause();
            pivotContractClip.current.currentTime = 0;
            playAudio(pivotContractClip.current);
        }
    };

    const handleTooltip = (visible: boolean, text: ITool | string): void => {
        setShowTooltip(visible);
        setTooltipText(text);
    };

    const reorderTools = (tools: Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup>): Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup> => {
        const updatedToolGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup> = deepCopy(tools);

        const expandedGroups = Array.from(updatedToolGroups.values())
            .filter((group) => group.active)
            .filter((group) => group.expanded)
            .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
            .sort((a, b) => a.index - b.index);
        const allGroupsClosed = expandedGroups.length === 0;
        if (
            expandedGroups.length < Array.from(updatedToolGroups.values())
                .filter((group) => group.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
                .length
        ) {
            const RESET_FLAG = -1;
            // Indicate that tool group index be reset
            updatedToolGroups.forEach((group, i) => {
                updatedToolGroups.get(group.id)!.currentIndex = RESET_FLAG;
            });
            // If all groups closed, put expand all tool at the end
            // Otherwise put at the front, which has effect of placing
            // at top of toolbar
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.expandAll)!.currentIndex = allGroupsClosed ? 4 : 0;

            // The index from which we count down while resetting indices of
            // expanded groups
            const countdownIndex = allGroupsClosed
                ? Array.from(updatedToolGroups.values()).length - 2
                : Array.from(updatedToolGroups.values()).length - 1;
            expandedGroups.forEach((group, i) => {
                updatedToolGroups.get(group.id)!.currentIndex = countdownIndex - i;
            });

            // If all groups closed, we put expand all tool at the end
            // This has the effect of shifting the starting position
            // of index resetter
            let currentToolIndex = allGroupsClosed ? 0 : 1;

            Array.from(updatedToolGroups.values())
                .forEach((group) => {
                    if (
                        updatedToolGroups.get(group.id)?.currentIndex === RESET_FLAG
                    ) {
                        updatedToolGroups.get(group.id)!.currentIndex = currentToolIndex;
                        currentToolIndex += 1;
                    }
                });
        } else {
            // All Tool Groups Open
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.expandAll)!.currentIndex = 0;
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.media)!.currentIndex = 1;
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.embeddings)!.currentIndex = 2;
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.text)!.currentIndex = 3;
            updatedToolGroups.get(EDITOR_TOOLBAR_TOOL_GROUP.layout)!.currentIndex = 4;
        }

        return updatedToolGroups;
    };

    const {
        start: recomputeToolGroupOrder,
        clear: clearRecomputeToolGroupOrder,
    } = useTimeout(() => {
        const groups = reorderTools(toolGroups);
        setToolGroups(groups);
    }, Math.max(...toolCount) * (TOOL_TRANSITION_DURATION + 50));

    const {
        start: delayUpdatePosition,
        clear: clearDelayUpdatePosition,
    } = useTimeout(() => {
        if (updatePosition) {
            updatePosition();
        }
    }, Math.max(...toolCount) * (TOOL_TRANSITION_DURATION + 50));

    const handleGroupExpanded = (id: EDITOR_TOOLBAR_TOOL_GROUP, expanded: boolean): void => {
        const updatedToolGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup> = deepCopy(toolGroups);
        updatedToolGroups.get(id)!.expanded = expanded;
        setToolGroups(updatedToolGroups);

        const numExpanded = Array.from(toolGroups.values())
            .filter((group) => group.active)
            .filter((group) => group.expanded).length;
        if (expanded) {
            const groups = reorderTools(updatedToolGroups);
            setToolGroups(groups);
        } else if (
            numExpanded > 0
            && updatedToolGroups.get(id)!.index <= numExpanded
        ) {
            // Recompute Tool Group Order
            clearRecomputeToolGroupOrder();
            recomputeToolGroupOrder();
        }

        clearDelayUpdatePosition();
        delayUpdatePosition();

        // Play Sound
        if (
            hasSound
            && expanded
            && pivotExpandClip.current
        ) {
            pivotExpandClip.current.pause();
            pivotExpandClip.current.currentTime = 0;
            playAudio(pivotExpandClip.current);
        } else if (
            hasSound
            && !expanded
            && pivotContractClip.current
        ) {
            pivotContractClip.current.pause();
            pivotContractClip.current.currentTime = 0;
            playAudio(pivotContractClip.current);
        }
    };

    const handleExpandAll = (e: React.MouseEvent): void => {
        e.preventDefault();
        const expandAll = !Array.from(toolGroups.values())
            .filter((g) => g.active)
            .filter((g) => g.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
            .reduce((result, g) => ((result && g.expanded) || false), true);

        const updatedToolGroups: Map<EDITOR_TOOLBAR_TOOL_GROUP, IToolGroup> = deepCopy(toolGroups);
        Array.from(toolGroups.values())
            .filter((group) => group.active)
            .forEach((group) => {
                updatedToolGroups.get(group.id)!.expanded = expandAll;
            });
        setToolGroups(updatedToolGroups);

        if (expandAll) {
            // Only reorder immediately if will be expanding
            // Otherwise, wait for groups to close
            const groups = reorderTools(updatedToolGroups);
            setToolGroups(groups);
        }

        clearDelayUpdatePosition();
        delayUpdatePosition();

        // Play Sound
        if (
            hasSound
            && expandAll
            && pivotExpandClip.current
        ) {
            pivotExpandClip.current.pause();
            pivotExpandClip.current.currentTime = 0;
            playAudio(pivotExpandClip.current);
        } else if (
            hasSound
            && !expandAll
            && pivotContractClip.current
        ) {
            pivotContractClip.current.pause();
            pivotContractClip.current.currentTime = 0;
            playAudio(pivotContractClip.current);
        }
    };

    const handleBack = (e: React.MouseEvent): void => {
        e.preventDefault();
        if (
            selectedTool === EDITOR_TOOL.link
            || selectedTool === EDITOR_TOOL.youtube
            || selectedTool === EDITOR_TOOL.twitter
            || selectedTool === EDITOR_TOOL.spotify
            || selectedTool === EDITOR_TOOL.vimeo
        ) {
            // Reset Link URL
            if (selectedLinkURL !== linkURL) {
                setLinkURL(selectedLinkURL);
            }
            setSelectedTool(null);
        } else if (selectedTool === EDITOR_TOOL.emoji) {
            setSelectedTool(null);
        }

        // Focus Toolbar to not close toolbar
        toolbarRef.current?.focus();

        // Play Sound
        if (
            hasSound
            && pivotContractClip.current
        ) {
            pivotContractClip.current.pause();
            pivotContractClip.current.currentTime = 0;
            playAudio(pivotContractClip.current);
        }
    };

    const onLinkChange = (): void => {
        if (linkInputRef.current) setLinkURL(linkInputRef.current.value);
    };

    const onLinkSubmit = (): void => {
        if (!user) throw Error('There was a problem uploading media. User data not found.');

        if (!selectedLinkURL && linkURL) {
            const linkId = uuidv4();
            const linkEmbeddingBundle: IEditorLinkEmbeddingLinkBundle = {
                id: linkId,
                userId: user.id,
                url: linkURL!,
                type: MEDIA_TYPE.link,
            };

            // make db entry of link
            setMediaInDB({
                linkEmbeddingBundle,
            });

            // Create link
            createLink(linkId, linkURL, textSelection);
        } else if (selectedLinkID && linkURL) {
            // Update DB
            const collection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.media
                : FIRESTORE_COLLECTION.stagingMedia;
            updateAnnotationMediaInDB({
                collection,
                id: selectedLinkID,
                annotationMediaType: MEDIA_TYPE.link,
                url: linkURL,
            });

            // update link url
            updateLink(selectedLinkID, linkURL, textSelection);
        }

        // Set to False to show Link Card
        if (type === EDITOR_TOOLBAR_TYPE.block) {
            // Block Toolbar has no link card, so we go back
            setSelectedTool(null);
            // Block toolbar clears link data
            setLinkURL(null);
            if (linkInputRef.current) {
                linkInputRef.current.value = '';
            }
        } else if (type === EDITOR_TOOLBAR_TYPE.selection) {
            setIsEditingLink(false);
        }

        // Remove focus from link input so we don't erroneously change the
        // slate selection (through a side effect) when we're generating the link
        (document.activeElement as HTMLElement).blur();
    };

    const onLinkEdit = (e: React.MouseEvent): void => {
        e.preventDefault();
        // cache editor selection value
        setTextSelection(selection);
        // To prevent toolbar from hiding, set isOpen to true
        setIsOpen(true);
        setIsEditingLink(true);
        setSelectedTool(EDITOR_TOOL.link);
    };

    const onLinkRemove = (e: React.MouseEvent): void => {
        e.preventDefault();
        setSelectedTool(null);
        setIsEditingLink(true);
        removeLink();
        setLinkURL(null);
        // Remove focus from link input so we don't erroneously change the
        // slate selection (through a side effect) when we're recomputing it's value
        (document.activeElement as HTMLElement).blur();
    };

    const onEmbeddingCreate = (embeddingType: EDITOR_TOOL, e?: Event):  void => {
        if (e) {
            e.preventDefault();
        }

        const linkItemId = uuidv4();
        let editorEmbeddingType: EDITOR_EMBEDDING_TYPE | undefined;
        switch (embeddingType) {
        case EDITOR_TOOL.youtube:
            if (linkURL) {
                createYouTubeEmbedding(linkItemId, linkURL);
                editorEmbeddingType = EDITOR_EMBEDDING_TYPE.youtube;
            }
            break;
        case EDITOR_TOOL.twitter:
            if (linkURL) {
                createTwitterEmbedding(linkItemId, linkURL);
                editorEmbeddingType = EDITOR_EMBEDDING_TYPE.twitter;
            }
            break;
        case EDITOR_TOOL.spotify:
            if (linkURL) {
                createSpotifyEmbedding(linkItemId, linkURL);
                editorEmbeddingType = EDITOR_EMBEDDING_TYPE.spotify;
            }
            break;
        case EDITOR_TOOL.vimeo:
            if (linkURL) {
                createVimeoEmbedding(linkItemId, linkURL);
                editorEmbeddingType = EDITOR_EMBEDDING_TYPE.vimeo;
            }
            break;
        default:
            break;
        }

        if (editorEmbeddingType) {
            if (!user) throw Error('There was a problem uploading media. User data not found.');

            const linkEmbeddingBundle: IEditorLinkEmbeddingLinkBundle = {
                id: linkItemId,
                userId: user.id,
                url: linkURL!,
                type: MEDIA_TYPE.embedding,
                embeddingType: editorEmbeddingType,
                caption: '',
            };

            // make db entry of link
            setMediaInDB({
                linkEmbeddingBundle,
            });
        }

        if (
            type === EDITOR_TOOLBAR_TYPE.selection
            && isOpen
        ) {
            // To prevent toolbar from hiding, set isOpen to true
            // this turns it to false afterwards
            setIsOpen(false);
        }

        // Once we create embedding we return to tool selection screen
        setSelectedTool(null);
        // Clear link data
        setLinkURL(null);
        if (linkInputRef.current) {
            linkInputRef.current.value = '';
        }
    };

    const getIsValidLink = (): boolean => {
        const checkURL = linkURL || '';
        if (checkURL.length > 0) {
            switch (selectedTool) {
            case EDITOR_TOOL.link:
                return validateURL(checkURL);
            case EDITOR_TOOL.youtube:
                return !!getYouTubeParams(checkURL);
            case EDITOR_TOOL.twitter:
                return !!getTwitterId(checkURL);
            case EDITOR_TOOL.spotify:
                return !!getSpotifyId(checkURL);
            case EDITOR_TOOL.vimeo:
                return !!getVimeoId(checkURL);
            default:
                return false;
            }
        }

        return false;
    };

    const handleLinkSubmit = (e: React.MouseEvent): void => {
        if (e) {
            // Makes sure that when we press the submit button on
            // the link input (instead of pressing enter) that we
            // don't lose focus (and thus trigger an effect that)
            // resets the tool
            e.preventDefault();
        }

        if (selectedTool === EDITOR_TOOL.link) {
            onLinkSubmit();
        } else if (selectedTool) {
            if (!selection) {
                // If we want to add a link to Slate, without prior selection
                // Select end of document first
                selectEndDocument();
            }

            onEmbeddingCreate(selectedTool);
        }
    };

    const onPivotEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorPivot,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onPivotLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onToolbarGroupMouseEnter = (group: IToolGroup, e: React.MouseEvent): void => {
        handleTooltip(true, group.name);
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorToolbarToolGroup,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onToolbarGroupMouseLeave = (group: IToolGroup, e: React.MouseEvent): void => {
        handleTooltip(false, group.name);
        if (onCursorLeave) onCursorLeave(e);
    };

    const onToolbarToolMouseEnter = (tool: ITool, e: React.MouseEvent): void => {
        handleTooltip(true, tool);
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorToolbarTool,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onToolbarToolMouseLeave = (tool: ITool, e: React.MouseEvent): void => {
        handleTooltip(false, tool);
        if (onCursorLeave) onCursorLeave(e);
    };

    const onToolbarGenericButtonMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorToolbarButton,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onToolbarGenericButtonMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onLinkInputMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.input,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onLinkInputMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onLinkInputFocus = (): void => {
        setInputFocused(true);

        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    const onLinkInputBlur = (): void => {
        setInputFocused(false);
    };

    const handleUploadingMediaComplete = (
        mediaItem: IMediaItem,
        imgURL: string,
    ): void => {
        // Make sure selection is set
        if (textSelection) {
            setSelection(textSelection);
        }

        // Update Uploading Media
        updateUploadingMedia({
            ...uploadingMedia.get(mediaItem.id)!,
            url: imgURL,
        });

        // Create Figure
        createFigure(mediaItem.id, mediaItem.filePath, mediaItem.file.name);
    };

    const handleUploadingMediaStateChange = (
        mediaItem: IMediaItem,
        _snapshot: UploadTaskSnapshot,
        progress: number,
    ): void => {
        if (uploadingMedia.has(mediaItem.id)) {
            updateUploadingMedia({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...uploadingMedia.get(mediaItem.id)!,
                uploadProgress: progress,
            });
        } else {
            setUploadingMedia(mediaItem);
        }
    };

    // ===== Rendering =====

    const numActiveGroups = useMemo(() => Array.from(toolGroups.values())
        .filter((g) => g.active)
        .filter((g) => g.id !== EDITOR_TOOLBAR_TOOL_GROUP.expandAll)
        .length, [toolGroups]);

    const showLinkCard = useMemo(() => (isOpen || showSelectionToolbar)
    && selectedTool === EDITOR_TOOL.link
    && !!linkURL
    && !isEditingLink, [
        isOpen,
        showSelectionToolbar,
        selectedTool,
        linkURL,
        isEditingLink,
    ]);

    const linkCardLinkTip = useMemo(() => {
        switch (selectedTool) {
        case EDITOR_TOOL.youtube:
            return 'Copy link from browser address bar.';
        case EDITOR_TOOL.twitter:
            return 'Copy link from browser address bar.';
        case EDITOR_TOOL.spotify:
            return 'Right Click \'⋯ > Share > Copy Link\' for useable link.';
        case EDITOR_TOOL.vimeo:
            return 'Copy link from browser address bar.';
        default:
            return '';
        }
    }, [selectedTool]);

    return (
        <Container
            ref={toolbarRef}
            type={type}
            top={yPos}
            left={xPos}
            isOpen={isOpen}
            withBackground={type === EDITOR_TOOLBAR_TYPE.block}
            transitionDuration={TOOLBAR_TRANSITION_DURATION}
            {...(hide || (type === EDITOR_TOOLBAR_TYPE.selection && !showSelectionToolbar && !isOpen)
                ? {
                    hide: hide || !showSelectionToolbar,
                }
                : {}
            )}
        >
            {type === EDITOR_TOOLBAR_TYPE.block && (
                <Pivot
                    className={HOVER_TARGET_CLASSNAME}
                    isOpen={isOpen}
                    hoverRotate={!selectedTool && !appliedTool}
                    onMouseDown={toggleToolbar}
                    onMouseEnter={onPivotEnter}
                    onMouseLeave={onPivotLeave}
                >
                    <PivotIcon>
                        <ReactSVG
                            src={getPivotIcon()}
                        />
                    </PivotIcon>
                    <CornerCurve
                        isOpen={isOpen}
                    >
                        <ReactSVG
                            src={EditorToolbarCornerCurve}
                        />
                    </CornerCurve>
                </Pivot>
            )}
            <Transition
                in={!selectedTool && (isOpen || showSelectionToolbar)}
                timeout={{
                    enter: BODY_TRANSITION_DURATION,
                    exit: BODY_TRANSITION_DURATION,
                }}
            >
                {(state) => (
                    <ToolbarBody
                        show={isOpen || showSelectionToolbar}
                        toolbarType={type}
                        buttonLength={buttonLength}
                        allToolGroupsClosed={allToolGroupsClosed}
                        allToolGroupsOpen={allToolGroupsOpen}
                        numActiveGroups={numActiveGroups}
                        maxToolRowCount={maxToolRowCount}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 20,
                                duration: BODY_TRANSITION_DURATION,
                                easing: themeObj.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 20,
                            })[state],
                        }}
                    >
                        <Transition
                            in={showTooltip && !!tooltipText}
                            timeout={{
                                enter: TOOLTIP_TRANSITION_DURATION,
                                exit: TOOLTIP_TRANSITION_DURATION,
                            }}
                        >
                            {(transitionState) => (
                                <ToolbarTooltip
                                    horizontalCenter
                                    top={-35}
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'up',
                                            offset: 10,
                                            duration: TOOLTIP_TRANSITION_DURATION,
                                            easing: themeObj.motion.eagerEasing,
                                            horizontalCenter: true,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'up',
                                            offset: 10,
                                            horizontalCenter: true,
                                        })[transitionState],
                                    }}
                                >
                                    <ToolbarNameText>
                                        {typeof tooltipText === 'string' ? tooltipText : tooltipText!.name}
                                    </ToolbarNameText>
                                    {typeof tooltipText !== 'string'
                                    && tooltipText?.shortcut
                                    && (
                                        <ToolbarShortcutText>
                                            {`  ${tooltipText.shortcut}`}
                                        </ToolbarShortcutText>
                                    )}
                                </ToolbarTooltip>
                            )}
                        </Transition>
                        {Array.from(toolGroups.values())
                            .filter((group) => group.active)
                            .sort((a, b) => {
                                if (a.currentIndex > b.currentIndex) {
                                    return 1;
                                }
                                if (a.currentIndex === b.currentIndex) {
                                    return 0;
                                }
                                return -1;
                            }).map((group, groupIndex) => {
                                if (group.id === EDITOR_TOOLBAR_TOOL_GROUP.expandAll) {
                                    return (
                                        <ExpandAllTool
                                            key={group.id}
                                            zIndex={groupIndex}
                                            className={HOVER_TARGET_CLASSNAME}
                                            buttonLength={buttonLength}
                                            color={color}
                                            allToolGroupsOpen={allToolGroupsOpen}
                                            onMouseEnter={onToolbarGenericButtonMouseEnter}
                                            onMouseLeave={onToolbarGenericButtonMouseLeave}
                                            onMouseDown={handleExpandAll}
                                        >
                                            <ReactSVG
                                                src={ChevronIcon}
                                            />
                                        </ExpandAllTool>
                                    );
                                }

                                const hasAppliedTool = group.tools && group.tools?.values
                                    ? Array.from(group.tools.values())
                                        .filter((tool) => tool.applied)
                                        .length > 0
                                    : false;
                                return (
                                    <ToolGroup
                                        key={group.id}
                                        color={color}
                                        zIndex={groupIndex}
                                        expanded={group.expanded}
                                        allToolGroupsOpen={allToolGroupsOpen}
                                        allToolGroupsClosed={allToolGroupsClosed}
                                        hasAppliedTool={hasAppliedTool}
                                        numActiveGroups={numActiveGroups}
                                        maxToolRowCount={maxToolRowCount}
                                        buttonLength={buttonLength}
                                    >
                                        <GroupHeader
                                            className={HOVER_TARGET_CLASSNAME}
                                            color={color}
                                            buttonLength={buttonLength}
                                            expanded={!!group.expanded}
                                            hasAppliedTool={hasAppliedTool}
                                            onMouseEnter={(e) => onToolbarGroupMouseEnter(group, e)}
                                            onMouseLeave={(e) => onToolbarGroupMouseLeave(group, e)}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleTooltip(false, group.name);
                                                handleGroupExpanded(group.id, !group.expanded);
                                            }}
                                        >
                                            {group.icon && (
                                                <ReactSVG
                                                    src={group.icon}
                                                />
                                            )}
                                        </GroupHeader>
                                        {!!group.tools && (
                                            <GroupTools
                                                isOpen={!!group.expanded}
                                                maxToolRowCount={maxToolRowCount}
                                                buttonLength={buttonLength}
                                                numTools={group.tools && group.tools.values
                                                    ? Array
                                                        .from(group.tools.values())
                                                        .filter((tool) => tool.active)
                                                        .length
                                                    : 0}
                                            >
                                                {group.tools && group.tools.values
                                                    ? Array
                                                        .from(group.tools.values())
                                                        .filter((tool) => tool.active)
                                                        // .filter((tool) => tool.id !== EDITOR_TOOL.link || (selection && !Range.isCollapsed(selection)))
                                                        .map((tool, index, toolArr) => (
                                                            <Transition
                                                                key={tool.id}
                                                                in={group.expanded}
                                                                timeout={{
                                                                    enter: Math.min(
                                                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                                                        (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                                                        + TOOL_TRANSITION_DURATION,
                                                                    ),
                                                                    exit: Math.min(
                                                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                                                        ((toolArr.length - Math.max(index - 1, 0))
                                                                        * FADE_IN_STAGGER_OFFSET_DURATION)
                                                                        + TOOL_TRANSITION_DURATION,
                                                                    ),
                                                                }}
                                                                appear
                                                                mountOnEnter
                                                                unmountOnExit
                                                            >
                                                                {(transitionState) => (
                                                                    <Tool
                                                                        {...getRootProps({
                                                                            key: tool.id,
                                                                            className: HOVER_TARGET_CLASSNAME,
                                                                            applied: tool.applied,
                                                                            color,
                                                                            buttonLength,
                                                                            onMouseEnter: (e) => onToolbarToolMouseEnter(tool, e),
                                                                            onMouseLeave: (e) => onToolbarToolMouseLeave(tool, e),
                                                                            onMouseDown: (e) => {
                                                                                e.preventDefault();
                                                                                if (
                                                                                    tool.id === EDITOR_TOOL.link
                                                                                    || tool.id === EDITOR_TOOL.figure
                                                                                    || tool.id === EDITOR_TOOL.youtube
                                                                                    || tool.id === EDITOR_TOOL.twitter
                                                                                    || tool.id === EDITOR_TOOL.spotify
                                                                                    || tool.id === EDITOR_TOOL.vimeo
                                                                                    || tool.id === EDITOR_TOOL.emoji
                                                                                ) {
                                                                                    // cache editor selection value
                                                                                    setTextSelection(selection);
                                                                                }
                                                                                handleTooltip(false, tool);
                                                                                tool.handleClick(tool.id, selection);
                                                                            },
                                                                            style: {
                                                                                ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                                                    direction: 'right',
                                                                                    duration: TOOL_TRANSITION_DURATION,
                                                                                    offset: 5,
                                                                                }),
                                                                                ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                                                    direction: 'right',
                                                                                    offset: 5,
                                                                                    numItems: toolArr.length,
                                                                                    index,
                                                                                })[transitionState],
                                                                            },
                                                                        })}
                                                                    >
                                                                        {tool.id === EDITOR_TOOL.figure
                                                                        && <DropzoneInput {...getInputProps()} />}
                                                                        <ReactSVG
                                                                            src={tool.icon}
                                                                        />
                                                                    </Tool>
                                                                )}
                                                            </Transition>
                                                        ))
                                                    : null}
                                            </GroupTools>
                                        )}
                                    </ToolGroup>
                                );
                            })}
                    </ToolbarBody>
                )}
            </Transition>
            <Transition
                in={(isOpen || showSelectionToolbar)
                    && (
                        (
                            selectedTool === EDITOR_TOOL.link
                            && isEditingLink
                        ) || selectedTool === EDITOR_TOOL.youtube
                        || selectedTool === EDITOR_TOOL.twitter
                        || selectedTool === EDITOR_TOOL.spotify
                        || selectedTool === EDITOR_TOOL.vimeo
                    )}
                timeout={{
                    enter: LINK_CARD_TRANSITION_DURATION,
                    exit: LINK_CARD_TRANSITION_DURATION,
                }}
            >
                {(state) => (
                    <EditLinkContainer
                        toolbarType={type}
                        visible={(isOpen || showSelectionToolbar)
                            && (
                                (
                                    selectedTool === EDITOR_TOOL.link
                                    && isEditingLink
                                ) || selectedTool === EDITOR_TOOL.youtube
                                || selectedTool === EDITOR_TOOL.twitter
                                || selectedTool === EDITOR_TOOL.spotify
                                || selectedTool === EDITOR_TOOL.vimeo
                            )}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 20,
                                duration: LINK_CARD_TRANSITION_DURATION,
                                easing: themeObj.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 20,
                            })[state],
                        }}
                    >
                        <BackButton
                            className={HOVER_TARGET_CLASSNAME}
                            buttonLength={buttonLength}
                            onMouseEnter={onToolbarGenericButtonMouseEnter}
                            onMouseLeave={onToolbarGenericButtonMouseLeave}
                            onMouseDown={handleBack}
                        >
                            <ReactSVG
                                src={ArrowIcon}
                            />
                        </BackButton>
                        <LinkInput
                            type="text"
                            ref={linkInputRef}
                            className={HOVER_TARGET_CLASSNAME}
                            defaultValue={linkURL || undefined}
                            placeholder={linkPlaceholderText}
                            valid={getIsValidLink()}
                            onChange={onLinkChange}
                            onMouseEnter={onLinkInputMouseEnter}
                            onMouseLeave={onLinkInputMouseLeave}
                            onFocus={onLinkInputFocus}
                            onBlur={onLinkInputBlur}
                        />
                        <Button
                            {...(linkURL && validateURL(linkURL)
                                ? {
                                    boxShadow: true,
                                }
                                : {}
                            )}
                            className={HOVER_TARGET_CLASSNAME}
                            type={BUTTON_TYPE.solid}
                            background={color}
                            height={35}
                            icon={ArrowIcon}
                            disabled={!getIsValidLink()}
                            onMouseEnter={onToolbarGenericButtonMouseEnter}
                            onMouseLeave={onToolbarGenericButtonMouseLeave}
                            onMouseDown={handleLinkSubmit}
                        />
                        <Transition
                            in={
                                selectedTool === EDITOR_TOOL.youtube
                                || selectedTool === EDITOR_TOOL.twitter
                                || selectedTool === EDITOR_TOOL.spotify
                                || selectedTool === EDITOR_TOOL.vimeo
                            }
                            timeout={{
                                enter: TOOLTIP_TRANSITION_DURATION,
                                exit: TOOLTIP_TRANSITION_DURATION,
                            }}
                            appear
                            mountOnEnter
                        >
                            {(transitionState) => (
                                <ToolbarTooltip
                                    top={-35}
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'up',
                                            offset: 10,
                                            duration: TOOLTIP_TRANSITION_DURATION,
                                            easing: themeObj.motion.eagerEasing,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'up',
                                            offset: 10,
                                        })[transitionState],
                                    }}
                                >
                                    <ToolbarNameText>
                                        {'Tip: '}
                                    </ToolbarNameText>
                                    <ToolbarShortcutText>
                                        {linkCardLinkTip}
                                    </ToolbarShortcutText>
                                </ToolbarTooltip>
                            )}
                        </Transition>
                    </EditLinkContainer>
                )}
            </Transition>
            <Transition
                in={showLinkCard}
                timeout={{
                    enter: LINK_CARD_TRANSITION_DURATION,
                    exit: LINK_CARD_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <LinkCardContainer
                        toolbarType={type}
                        readOnly={readOnly}
                        hasLinkMetadata={!!linkMetadata}
                        width={LINK_CARD_WITHOUT_METADATA_WIDTH}
                        height={linkMetadata
                            ? LINK_CARD_WITH_METADATA_HEIGHT
                            : LINK_CARD_WITHOUT_METADATA_HEIGHT}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 20,
                                duration: LINK_CARD_TRANSITION_DURATION,
                                easing: themeObj.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 20,
                            })[state],
                        }}
                    >
                        <LinkCardTopContent
                            hasLinkMetadata={!!linkMetadata}
                            width={LINK_CARD_WITHOUT_METADATA_WIDTH}
                            height={LINK_CARD_WITHOUT_METADATA_HEIGHT}
                        >
                            {linkMetadata
                                ? (
                                    <>
                                        <LinkCardImageContainer>
                                            <LinkCardImage
                                                width={LINK_CARD_IMAGE_WIDTH}
                                                height={LINK_CARD_IMAGE_HEIGHT}
                                                {...(linkMetadata?.image
                                                    ? {
                                                        url: linkMetadata.image,
                                                    } : {}
                                                )}
                                            >
                                                {!linkMetadata?.image
                                                && (
                                                    <ReactSVG
                                                        src={LinkIcon}
                                                    />
                                                )}
                                            </LinkCardImage>
                                        </LinkCardImageContainer>
                                        <LinkCardInfoContainer>
                                            <LinkCardTitle
                                                className={HOVER_TARGET_CLASSNAME}
                                                href={selectedLinkURL || 'https://verasco.pe'}
                                                target="_blank"
                                                color={color}
                                                fontMultiplier={LINK_CARD_FONT_MULTIPLIER}
                                                onMouseEnter={onToolbarGenericButtonMouseEnter}
                                                onMouseLeave={onToolbarGenericButtonMouseLeave}
                                                {...(detectTouchDevice(document) ? {
                                                    onTouchStart: (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    },
                                                } : {
                                                    onMouseDown: (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    },
                                                })}
                                            >
                                                {applyCharacterLimit(
                                                    linkMetadata?.title || 'Untitled',
                                                    LINK_CARD_MAX_TITLE_COUNT,
                                                )}
                                            </LinkCardTitle>
                                            {linkMetadata?.url
                                            && (
                                                <LinkCardAddress>
                                                    {/* https://coderwall.com/p/utgplg/regex-full-url-base-url */}
                                                    {linkMetadata.url.replace(/(http(s)?:\/\/)|(\/.*){1}/g, '')}
                                                </LinkCardAddress>
                                            )}
                                        </LinkCardInfoContainer>
                                    </>
                                ) : (
                                    <TemporaryLinkText
                                        href={linkURL || ''}
                                        target="_blank"
                                        color={color}
                                        fontMultiplier={LINK_CARD_FONT_MULTIPLIER}
                                    >
                                        {linkURL
                                        && applyCharacterLimit(
                                            linkURL || 'Untitled',
                                            28,
                                        )}
                                    </TemporaryLinkText>
                                )}
                        </LinkCardTopContent>
                        {!readOnly
                        && (
                            <>
                                <LinkCardBottomContent
                                    side="left"
                                    bottom={LINK_CARD_IMAGE_PADDING + 5}
                                >
                                    <BackButton
                                        className={HOVER_TARGET_CLASSNAME}
                                        buttonLength={LINK_CARD_BUTTON_HEIGHT}
                                        onMouseEnter={onToolbarGenericButtonMouseEnter}
                                        onMouseLeave={onToolbarGenericButtonMouseLeave}
                                        onMouseDown={handleBack}
                                    >
                                        <ReactSVG
                                            src={ArrowIcon}
                                        />
                                    </BackButton>
                                </LinkCardBottomContent>
                                <LinkCardBottomContent
                                    side="right"
                                    bottom={LINK_CARD_IMAGE_PADDING + 2.5}
                                >
                                    <LinkCardRightGroup>
                                        <GhostButton
                                            className={HOVER_TARGET_CLASSNAME}
                                            buttonLength={LINK_CARD_BUTTON_HEIGHT}
                                            onMouseEnter={onToolbarGenericButtonMouseEnter}
                                            onMouseLeave={onToolbarGenericButtonMouseLeave}
                                            onMouseDown={onLinkEdit}
                                        >
                                            <ReactSVG
                                                src={EditIcon}
                                            />
                                        </GhostButton>
                                        <GhostButton
                                            className={HOVER_TARGET_CLASSNAME}
                                            buttonLength={LINK_CARD_BUTTON_HEIGHT}
                                            onMouseEnter={onToolbarGenericButtonMouseEnter}
                                            onMouseLeave={onToolbarGenericButtonMouseLeave}
                                            onMouseDown={onLinkRemove}
                                        >
                                            <ReactSVG
                                                src={TrashIcon}
                                            />
                                        </GhostButton>
                                    </LinkCardRightGroup>
                                </LinkCardBottomContent>
                            </>
                        )}
                    </LinkCardContainer>
                )}
            </Transition>
            <Transition
                in={(isOpen || showSelectionToolbar)
                    && selectedTool === EDITOR_TOOL.emoji}
                timeout={{
                    enter: EMOJI_TRANSITION_DURATION,
                    exit: EMOJI_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <EmojiSelect
                        toolbarType={type}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 20,
                                duration: EMOJI_TRANSITION_DURATION,
                                easing: themeObj.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 20,
                            })[state],
                        }}
                    >
                        <EmojiSelectorContainer
                            width={EMOJI_SELECTOR_WIDTH}
                            height={EMOJI_SELECTOR_HEIGHT}
                            toolbarType={type}
                        >
                            <EmojiSelector
                                compact
                                hasSound={hasSound}
                                color={color}
                                width={EMOJI_SELECTOR_WIDTH}
                                responsiveLayout={false}
                                setInputRef={(ref: HTMLElement) => { emojiInputRef.current = ref; }}
                                handleEmojiSelect={(emojiObj) => {
                                    // Make sure selection is set
                                    if (textSelection) setSelection(textSelection);
                                    // Handle Emoji
                                    if (handleEmojiSelect && emojiObj) handleEmojiSelect(emojiObj);
                                }}
                                onCursorEnter={onCursorEnter}
                                onCursorLeave={onCursorLeave}
                                setInputFocused={setInputFocused}
                            />
                        </EmojiSelectorContainer>
                        <EmojiSelectorBackButtonContainer>
                            <BackButton
                                className={HOVER_TARGET_CLASSNAME}
                                buttonLength={EMOJI_SELECTOR_BUTTON_HEIGHT}
                                onMouseEnter={onToolbarGenericButtonMouseEnter}
                                onMouseLeave={onToolbarGenericButtonMouseLeave}
                                onMouseDown={handleBack}
                            >
                                <ReactSVG
                                    src={ArrowIcon}
                                />
                            </BackButton>
                        </EmojiSelectorBackButtonContainer>
                    </EmojiSelect>
                )}
            </Transition>
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    top: number,
    left: number,
    hide?: boolean,
    type: EDITOR_TOOLBAR_TYPE,
    isOpen: boolean,
    withBackground: boolean,
    transitionDuration: number,
}
const Container = styled.div<ContainerProps>`
    position: absolute;
    top: ${({ top }) => `${top}px`};
    left: ${({ left }) => `${left}px`};
    background: ${({ withBackground, theme }) => (withBackground
        ? theme.color.white
        : 'none'
    )};
    border-radius: ${`${BLOCK_CHOOSER_BLOCK_WIDTH / 2}px`};
    ${({ isOpen }) => isOpen && `
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    `};
    z-index: ${({ type }) => (type === EDITOR_TOOLBAR_TYPE.block
        ? PORTABLE_TOOLBAR_BLOCK_Z_INDEX
        : PORTABLE_TOOLBAR_SELECTION_Z_INDEX
    )};
    ${({ hide }) => hide && `
        opacity: 0;
        pointer-events: none;
    `};
    transition: ${({ transitionDuration, theme }) => `
        top ${transitionDuration}ms ${theme.motion.delayEasing},
        left ${transitionDuration}ms ${theme.motion.delayEasing},
        opacity ${transitionDuration}ms ${theme.motion.delayEasing}
    `};
`;

interface ToolbarTooltipProps {
    top: number,
    left?: number,
    horizontalCenter?: boolean,
}
const ToolbarTooltip = styled.div<ToolbarTooltipProps>`
    position: absolute;
    top: ${({ top }) => `${top}px`};
    left: ${({ left, horizontalCenter }) => {
        if (horizontalCenter) {
            return '50%';
        }

        if (left) {
            return `${left}px`;
        }

        return '0px';
    }};
    ${({ horizontalCenter }) => horizontalCenter && `
        transform: translateX(-50%);
    `};
    border-radius: ${`${0.875 * BODY_FONT_SIZE}px`};
    background-color: ${({ theme }) => theme.color.neutral900};
    padding: ${`${0.25 * BODY_FONT_SIZE}px ${0.9375 * BODY_FONT_SIZE}px`};
    white-space: nowrap;
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
`;

const ToolbarNameText = styled.span`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.white};
    white-space: pre;
`;

const ToolbarShortcutText = styled(ToolbarNameText)`
    font-weight: 300;
    color: ${({ theme }) => theme.color.neutral600};
    white-space: pre;
`;

interface ToolbarBodyProps {
    show: boolean,
    toolbarType: EDITOR_TOOLBAR_TYPE,
    allToolGroupsOpen: boolean,
    allToolGroupsClosed: boolean,
    maxToolRowCount: number,
    numActiveGroups: number,
    buttonLength: number,
}
const ToolbarBody = styled.div<ToolbarBodyProps>`
    position: ${({ toolbarType }) => (toolbarType === EDITOR_TOOLBAR_TYPE.selection
        ? 'relative'
        : 'absolute'
    )};
    bottom: ${({ toolbarType }) => (toolbarType === EDITOR_TOOLBAR_TYPE.selection
        ? 'auto'
        : `${BLOCK_CHOOSER_BLOCK_WIDTH}px`
    )};
    display: ${({ allToolGroupsClosed, allToolGroupsOpen }) => {
        if (allToolGroupsClosed) {
            return 'inline-flex';
        }

        if (allToolGroupsOpen) {
            return 'grid';
        }

        return 'block';
    }};
    min-height: ${`${3.125 * BODY_FONT_SIZE}px`};
    min-width: ${`${4.375 * BODY_FONT_SIZE}px`};
    max-width: ${({
        toolbarType,
        maxToolRowCount,
        allToolGroupsOpen,
        buttonLength,
    }) => {
        if (
            toolbarType === EDITOR_TOOLBAR_TYPE.selection
            && allToolGroupsOpen
        ) {
            return `${2 * ((2 * TOOL_GROUP_MARGIN) + (2 * TOOL_GROUP_PADDING) + maxToolRowCount * ((2 * TOOL_MARGIN) + buttonLength + GROUP_TOOL_MARGIN_LEFT_ADDITION) + ((2 * TOOL_MARGIN) + buttonLength))}px`;
        }

        if (
            toolbarType === EDITOR_TOOLBAR_TYPE.selection
            && !allToolGroupsOpen
        ) {
            return `${(2 * TOOL_GROUP_MARGIN) + (2 * TOOL_GROUP_PADDING) + maxToolRowCount * ((2 * TOOL_MARGIN) + buttonLength + GROUP_TOOL_MARGIN_LEFT_ADDITION) + ((2 * TOOL_MARGIN) + buttonLength)}px`;
        }

        return 'none';
    }};
    border-radius: ${`${1.5625 * BODY_FONT_SIZE}px`};
    border-bottom-left-radius: ${({ toolbarType }) => `${toolbarType === EDITOR_TOOLBAR_TYPE.selection
        ? 1.5625 * BODY_FONT_SIZE
        : 0
    }px`};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    pointer-events: ${({ show }) => (show
        ? 'auto'
        : 'none'
    )};
    transition: ${({ theme }) => `
        opacity ${PIVOT_TRANSITION_DURATION}ms ${theme.motion.standardEasing}
    `};

    ${({ allToolGroupsOpen, numActiveGroups, theme }) => allToolGroupsOpen
        && numActiveGroups === TOOL_GROUPS_COUNT
        && `${theme.mediaQuery.large} {
            grid-template-columns: 50% 50%;

            &::before {
                /* Occupies first block of grid to move expand all button to the far right */
                content: "";
                grid-column: 1;
                grid-row: 1;
            }

            & > *:nth-child(2) {
                /* Places the Expand All button to the far right */
                justify-self: end;
                padding: ${0.3125 * BODY_FONT_SIZE}px;
                height: ${1.875 * BODY_FONT_SIZE}px;
                width: ${1.875 * BODY_FONT_SIZE}px;
                margin-right: ${0.625 * BODY_FONT_SIZE}px;
                margin-bottom: 0px;
            }
        }`
};

    ${({ allToolGroupsOpen, numActiveGroups, theme }) => allToolGroupsOpen
        && numActiveGroups === TOOL_GROUPS_COUNT
        && `${theme.mediaQuery.medium} {
            grid-template-columns: 50% 50%;

            &::before {
                /* Occupies first block of grid to move expand all button to the far right */
                content: "";
                grid-column: 1;
                grid-row: 1;
            }

            & > *:nth-child(2) {
                /* Places the Expand All button to the far right */
                justify-self: end;
                padding: ${0.3125 * BODY_FONT_SIZE}px;
                height: ${1.875 * BODY_FONT_SIZE}px;
                width: ${1.875 * BODY_FONT_SIZE}px;
                margin-right: ${0.625 * BODY_FONT_SIZE}px;
                margin-bottom: 0px;
            }
        }`
};

    ${({ allToolGroupsOpen, numActiveGroups, theme }) => allToolGroupsOpen
        && numActiveGroups === TOOL_GROUPS_COUNT
        && `${theme.mediaQuery.small} {
            grid-template-columns: 50% 50%;

            &::before {
                /* Occupies first block of grid to move expand all button to the far right */
                content: "";
                grid-column: 1;
                grid-row: 1;
            }

            & > *:nth-child(2) {
                /* Places the Expand All button to the far right */
                justify-self: end;
                padding: ${0.3125 * BODY_FONT_SIZE}px;
                height: ${1.875 * BODY_FONT_SIZE}px;
                width: ${1.875 * BODY_FONT_SIZE}px;
                margin-right: ${0.625 * BODY_FONT_SIZE}px;
                margin-bottom: 0px;
            }
        }`
};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        /* In extra small screens, we don't enable grid layout  */
        ${({
        toolbarType,
        maxToolRowCount,
        buttonLength,
        allToolGroupsClosed,
    }) => `
            display: ${allToolGroupsClosed ? 'inline-flex' : 'block'};
            max-width: ${toolbarType === EDITOR_TOOLBAR_TYPE.selection
        ? `${(2 * TOOL_GROUP_MARGIN) + (2 * TOOL_GROUP_PADDING) + maxToolRowCount * ((2 * TOOL_MARGIN) + buttonLength + GROUP_TOOL_MARGIN_LEFT_ADDITION) + ((2 * TOOL_MARGIN) + buttonLength)}px`
        : 'none'
};`
}}
`;

interface ToolGroupProps {
    hasAppliedTool?: boolean,
    expanded?: boolean,
    color?: string,
    buttonLength: number,
    maxToolRowCount?: number,
    allToolGroupsOpen?: boolean,
    numActiveGroups?: number,
    allToolGroupsClosed?: boolean,
    zIndex?: number,
}
const ToolGroup = styled.div<ToolGroupProps>`
    position: relative;
    display: inline-flex;
    min-height: ${({ buttonLength }) => `${buttonLength}px`};
    margin: ${`${TOOL_GROUP_MARGIN}px`};
    padding: ${`${TOOL_GROUP_PADDING}px`};
    border-radius: ${({ buttonLength }) => `${(buttonLength + 2 * TOOL_GROUP_MARGIN) / 2}px`};
    background-color: ${({
        hasAppliedTool,
        expanded,
        color,
        theme,
    }) => {
        if (
            hasAppliedTool
            && !expanded
            && color
        ) {
            return setColorLightness(
                color,
                BUTTON_CONTAINER_LIGHTNESS_VALUE,
            );
        }

        return theme.color.neutral100;
    }};
    max-width: ${({ maxToolRowCount, buttonLength }) => (maxToolRowCount
        ? `${(2 * TOOL_GROUP_MARGIN) + (2 * TOOL_GROUP_PADDING) + maxToolRowCount * ((2 * TOOL_MARGIN) + buttonLength + GROUP_TOOL_MARGIN_LEFT_ADDITION) + ((2 * TOOL_MARGIN) + buttonLength)}px`
        : 'none'
    )};
    z-index: ${({ zIndex, numActiveGroups }) => (typeof numActiveGroups === 'number' && typeof zIndex === 'number'
        ? numActiveGroups - zIndex
        : 'auto'
    )};
    transition: background-color 0.3s;

    ${({ allToolGroupsClosed }) => allToolGroupsClosed && `
        &:not(:last-child) {
            margin-right: 0px;
        }
    `};
    ${({ expanded, allToolGroupsOpen, numActiveGroups }) => expanded
        && !allToolGroupsOpen
        && numActiveGroups === TOOL_GROUPS_COUNT && `
        &:not(:nth-child(2)) {
            margin-top: 0px;
        }
    `};

    ${({ numActiveGroups, expanded }) => expanded
        && numActiveGroups !== TOOL_GROUPS_COUNT && `
        &:not(:last-child) {
            margin-bottom: 0px;
        }
    `};

    ${({ expanded, allToolGroupsOpen, theme }) => expanded
        && allToolGroupsOpen
        && `${theme.mediaQuery.large} {
            /* removes margin bottom on top row of grid when all groups expanded */
            &:not(:nth-last-child(1)):not(:nth-last-child(2)) {
                margin-bottom: 0;
            }

            /* reduce spacing between tool groups when all groups expanded */
            &:nth-child(odd) {
                margin-right: ${0.15625 * BODY_FONT_SIZE}px;
            }

            &:nth-child(even) {
                margin-left: ${0.15625 * BODY_FONT_SIZE}px;
            }
        }`
};

    ${({ expanded, allToolGroupsOpen, theme }) => expanded
        && allToolGroupsOpen
        && `${theme.mediaQuery.medium} {
            /* removes margin bottom on top row of grid when all groups expanded */
            &:not(:nth-last-child(1)):not(:nth-last-child(2)) {
                margin-bottom: 0;
            }

            /* reduce spacing between tool groups when all groups expanded */
            &:nth-child(odd) {
                margin-right: ${0.15625 * BODY_FONT_SIZE}px;
            }

            &:nth-child(even) {
                margin-left: ${0.15625 * BODY_FONT_SIZE}px;
            }
        }`
};

    ${({ expanded, allToolGroupsOpen, theme }) => expanded
        && allToolGroupsOpen
        && `${theme.mediaQuery.small} {
            /* removes margin bottom on top row of grid when all groups expanded */
            &:not(:nth-last-child(1)):not(:nth-last-child(2)) {
                margin-bottom: 0;
            }

            /* reduce spacing between tool groups when all groups expanded */
            &:nth-child(odd) {
                margin-right: ${0.15625 * BODY_FONT_SIZE}px;
            }

            &:nth-child(even) {
                margin-left: ${0.15625 * BODY_FONT_SIZE}px;
            }
        }`
};

    ${({ hasAppliedTool, expanded, theme }) => !expanded
        && !hasAppliedTool && `
        &:hover {
            background-color: ${theme.color.neutral200};
        }
    `};
`;

interface ExpandAllToolProps {
    allToolGroupsOpen: boolean,
    buttonLength: number,
    color: string,
}
const ExpandAllTool = styled(ToolGroup)<ExpandAllToolProps>`
    float: right;
    min-height: 0px;
    height: ${({ buttonLength }) => `${buttonLength}px`};
    width: ${({ buttonLength }) => `${buttonLength}px`};
    border-radius: ${({ buttonLength }) => `${buttonLength / 2}px`};
    background-color: ${({ theme }) => theme.color.neutral200};
    margin-bottom: 0px;
    padding: ${`${0.625 * BODY_FONT_SIZE}px`};
    cursor: none;

    & div {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({
        color,
        theme,
    }) => {
        if (color) {
            return color;
        }

        return theme.color.neutral700;
    }};
        transform: ${({ allToolGroupsOpen }) => `rotate(${
        allToolGroupsOpen
            ? 5.625 * BODY_FONT_SIZE
            : -5.625 * BODY_FONT_SIZE
    }deg)`};
        transition: ${({ theme }) => `
            fill 300ms ${theme.motion.eagerEasing},
            transform 300ms ${theme.motion.eagerEasing}
        `};
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.neutral300};

        & svg {
            fill: ${({
        color,
        theme,
    }) => {
        if (color) {
            return color;
        }

        return theme.color.neutral800;
    }};
        }
    }
`;

interface ToolProps {
    applied?: boolean,
    color: string,
    buttonLength: number,
}
const Tool = styled.div<ToolProps>`
    display: inline-block;
    width: ${({ buttonLength }) => `${buttonLength}px`};
    height: ${({ buttonLength }) => `${buttonLength}px`};
    border-radius: ${`${0.9375 * BODY_FONT_SIZE}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};
    margin: ${`${TOOL_MARGIN}px`};
    background-color: ${({ applied, color, theme }) => (applied && color
        ? color
        : theme.color.white
    )};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transition: box-shadow 0.3s;
    cursor: none;


    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ applied, theme }) => (applied
        ? theme.color.white
        : theme.color.neutral700
    )};
    }

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }
`;

interface GroupHeaderProps {
    hasAppliedTool: boolean,
    expanded: boolean,
    color: string,
}
const GroupHeader = styled(Tool)<GroupHeaderProps>`
    display: inline-block;
    background-color: transparent;
    box-shadow: none;
    transition: background-color 0.3s;

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({
        hasAppliedTool,
        expanded,
        color,
        theme,
    }) => {
        if (
            hasAppliedTool
            && !expanded
            && color) {
            return setColorLightness(
                color,
                BUTTON_TEXT_LIGHTNESS_VALUE,
            );
        }

        if (expanded && color) {
            return color;
        }

        return theme.color.neutral700;
    }};
        transition: ${({ theme }) => `
            fill 300ms ${theme.motion.eagerEasing}
        `};
    }

    &:hover {
        box-shadow: none;
        background-color: ${({
        hasAppliedTool,
        expanded,
        color,
        theme,
    }) => {
        if (
            hasAppliedTool
            && !expanded
            && color
        ) {
            return setColorLightness(
                color,
                BUTTON_CONTAINER_LIGHTNESS_VALUE,
            );
        }

        return theme.color.neutral200;
    }};

        & svg {
            fill: ${({
        hasAppliedTool,
        expanded,
        color,
        theme,
    }) => {
        if (hasAppliedTool
            && !expanded
            && color) {
            return setColorLightness(
                color,
                BUTTON_TEXT_LIGHTNESS_VALUE,
            );
        }

        if (expanded && color) {
            return color;
        }

        return theme.color.neutral800;
    }};
        }
    }
`;

interface GroupToolsProps {
    isOpen: boolean,
    numTools: number,
    buttonLength: number,
    maxToolRowCount: number,
}
const GroupTools = styled.div<GroupToolsProps>`
    display: inline-block;
    width: ${({ isOpen, numTools, buttonLength }) => `${isOpen
        ? numTools * (buttonLength + TOOL_MARGIN_LEFT + TOOL_MARGIN)
        : 0
    }px`};
    max-width: ${({ maxToolRowCount, buttonLength }) => `${maxToolRowCount * ((2 * TOOL_MARGIN) + buttonLength + GROUP_TOOL_MARGIN_LEFT_ADDITION)}px`};
    background: inherit;
    ${({ isOpen, numTools, theme }) => !isOpen && `
        transition: width ${GROUP_EXPAND_TRANSITION}ms ${theme.motion.delayEasing} ${(numTools - 1) * TOOL_TRANSITION_DURATION}ms
    `};

    & > div {
        margin-left: ${`${TOOL_MARGIN_LEFT}px`};
    }
`;

interface CornerCurveProps {
    isOpen: boolean,
}
const CornerCurve = styled.div<CornerCurveProps>`
    position: absolute;
    right: ${`-${1.0625 * BODY_FONT_SIZE}px`};
    top: 0;
    width: ${`${1.0625 * BODY_FONT_SIZE}px`};
    height: ${`${1.0625 * BODY_FONT_SIZE}px`};
    opacity: ${({ isOpen }) => (isOpen
        ? 1
        : 0
    )};
    pointer-events: none;
    transition: ${({ theme }) => `
        opacity ${PIVOT_TRANSITION_DURATION}ms ${theme.motion.standardEasing}
    `};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
    }
`;

interface PivotProps {
    isOpen: boolean,
    hoverRotate: boolean,
}
const Pivot = styled.div<PivotProps>`
    position: relative;
    width: ${`${BLOCK_CHOOSER_BLOCK_WIDTH}px`};
    height: ${`${BLOCK_CHOOSER_BLOCK_WIDTH}px`};
    border-radius: ${`${BLOCK_CHOOSER_BLOCK_WIDTH / 2}px`};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ isOpen, theme }) => (isOpen
        ? `0 ${0.5 * BODY_FONT_SIZE}px ${0.5 * BODY_FONT_SIZE}px rgba(0,0,0,.1)`
        : theme.color.boxShadow100
    )};
    z-index: 1;
    cursor: none;
    ${({ isOpen }) => isOpen && `
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    `};
    transition: ${({ theme }) => `
        border-top-left-radius ${PIVOT_TRANSITION_DURATION}ms ${theme.motion.standardEasing},
        border-top-right-radius ${PIVOT_TRANSITION_DURATION}ms ${theme.motion.standardEasing},
        box-shadow ${PIVOT_TRANSITION_DURATION}ms ${theme.motion.standardEasing}
    `};

    // Struggled to put in PivotIcon
    & > div:hover {
        transform: ${({ hoverRotate }) => (hoverRotate
        ? 'translate(-50%, -50%) rotate(180deg)'
        : 'translate(-50%, -50%)'
    )};
    }
`;

const PivotIcon = styled.div`
    width: ${`${1.375 * BODY_FONT_SIZE}px`};
    height: ${`${1.375 * BODY_FONT_SIZE}px`};
    left: 50%;
    top: 50%;
    position: relative;
    transform: translate(-50%, -50%);
    will-change: transform;
    display: inline-block;
    vertical-align: top;
    transition: ${({ theme }) => `transform 300ms ${theme.motion.standardEasing}`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        fill: ${({ theme }) => theme.color.neutral700};
        width: 100%;
        height: 100%;
    }
`;

interface EditLInkContainerProps {
    visible: boolean,
    toolbarType: EDITOR_TOOLBAR_TYPE,
}
const EditLinkContainer = styled.div<EditLInkContainerProps>`
    position: absolute;
    bottom: ${({ toolbarType }) => (toolbarType === EDITOR_TOOLBAR_TYPE.selection
        ? 0
        : `${BLOCK_CHOOSER_BLOCK_WIDTH}px`
    )};
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${`${3.125 * BODY_FONT_SIZE}px`};
    border-radius: ${`${1.5625 * BODY_FONT_SIZE}px`};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    border-bottom-left-radius: ${({ toolbarType }) => `${toolbarType === EDITOR_TOOLBAR_TYPE.selection
        ? 1.5625 * BODY_FONT_SIZE
        : 0
    }px`};
    padding: ${`${0.46875 * BODY_FONT_SIZE}px`};
    ${({ visible }) => !visible && `
        pointer-events: none;
    `};

    & > button {
        transform: rotate(-90deg);
    }
`;

export const GhostButton = styled(ToolGroup)`
    min-height: 0px;
    height: ${({ buttonLength }) => `${buttonLength || 2.1875 * BODY_FONT_SIZE}px`};
    width: ${({ buttonLength }) => `${buttonLength || 2.1875 * BODY_FONT_SIZE}px`};
    background-color: ${({ theme }) => theme.color.neutral300};
    margin: 0px;
    padding: ${({ buttonLength }) => `${buttonLength ? (buttonLength / 4.7) : 0.46875 * BODY_FONT_SIZE}px`};
    cursor: none;

    & div {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral800};
        transition: ${({ theme }) => `
            fill 300ms ${theme.motion.eagerEasing}
        `};
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.neutral600};

        & svg {
            fill: ${({ theme }) => theme.color.neutral1000};
        }
    }
`;

export const BackButton = styled(GhostButton)`
    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral800};
        transform: rotate(180deg);
        transition: ${({ theme }) => `
            fill 300ms ${theme.motion.eagerEasing}
        `};
    }
`;

interface LinkInputProps {
    valid: boolean,
}
const LinkInput = styled.input<LinkInputProps>`
    margin: ${`0 ${0.3125 * BODY_FONT_SIZE}px`};
    height: ${`${2.1875 * BODY_FONT_SIZE}px`};
    width: ${`${12.5 * BODY_FONT_SIZE}px`};
    margin-right: ${`${0.3125 * BODY_FONT_SIZE}px`};
    padding: ${`0px ${1.25 * BODY_FONT_SIZE}px`};
    border-radius: ${`${1.25 * BODY_FONT_SIZE}px`};
    border: none;
    outline: none;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 1em;
    color: ${({ valid, theme }) => (!valid
        ? theme.color.error
        : theme.color.neutral900
    )};
    background-color: ${({ valid, theme }) => (!valid
        ? setColorLightness(
            theme.color.error,
            98,
        )
        : theme.color.neutral100
    )};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    cursor: none;
    transition: box-shadow 0.3s;

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral400};
    }
`;

interface LinkCardContainerProps {
    toolbarType: EDITOR_TOOLBAR_TYPE,
    readOnly: boolean,
    hasLinkMetadata: boolean,
    width?: number | null,
    height?: number,
}
export const LinkCardContainer = styled.div<LinkCardContainerProps>`
    position: absolute;
    bottom: ${({ toolbarType }) => (toolbarType === EDITOR_TOOLBAR_TYPE.selection
        ? 0
        : `${BLOCK_CHOOSER_BLOCK_WIDTH}px`
    )};
    left: 0;
    display: flex;
    flex-direction: column;
    width: ${({ readOnly, hasLinkMetadata, width }) => (readOnly && !hasLinkMetadata && width
        ? `${width}px`
        : 'auto')};
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    border-radius: ${`${1.5625 * BODY_FONT_SIZE}px`};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    border-bottom-left-radius: ${({ toolbarType }) => `${toolbarType === EDITOR_TOOLBAR_TYPE.selection
        ? 1.5625 * BODY_FONT_SIZE
        : 0
    }px`};
    ${({ readOnly }) => readOnly && `
        align-items: center;
    `};
`;

interface LinkCardTopContentProps {
    hasLinkMetadata: boolean,
    width?: number,
    height?: number,
}
export const LinkCardTopContent = styled.div<LinkCardTopContentProps>`
    display: flex;
    flex-direction: row;
    justify-content: ${({ hasLinkMetadata }) => (!hasLinkMetadata
        ? 'center'
        : 'flex-start'
    )};
    ${({ hasLinkMetadata, width, height }) => !hasLinkMetadata && `
        position: relative;
        align-items: center;
        width: ${width ? `${width}px` : 'auto'};
        height: ${height ? `${height}px` : 'auto'};
    `};
`;

interface LinkCardBottomContentProps {
    side: string,
    bottom: number,
}
const LinkCardBottomContent = styled.div<LinkCardBottomContentProps>`
    position: absolute;
    left: ${({ side }) => (side === 'left'
        ? '0px'
        : 'auto'
    )};
    right: ${({ side }) => (side === 'right'
        ? '0px'
        : 'auto'
    )};
    bottom: ${({ bottom }) => `${bottom}px`};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: ${`${LINK_CARD_IMAGE_PADDING + 0.3125 * BODY_FONT_SIZE}px`};
    margin-right: ${`${0.625 * BODY_FONT_SIZE}px`};
    border-radius: 50%;
    background: inherit;
`;

export const LinkCardImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${`${LINK_CARD_IMAGE_PADDING}px`};
`;

interface LinkCardImageProps {
    url?: string,
    width?: number,
    height?: number,
}
export const LinkCardImage = styled.div<LinkCardImageProps>`
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    border-radius: ${`${0.9375 * BODY_FONT_SIZE}px`};
    border: ${({ theme }) => `${0.0625 * BODY_FONT_SIZE}px solid ${theme.color.neutral200}`};
    background-color: ${({ theme }) => theme.color.neutral200};
    background-image: ${({ url }) => (url ? `url(${url})` : 'none')};
    background-repeat: no-repeat;
    background-size: cover;
    padding: ${`${1.25 * BODY_FONT_SIZE}px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: ${`${2.1875 * BODY_FONT_SIZE}px`};
        height: ${`${2.1875 * BODY_FONT_SIZE}px`};
        fill: ${({ theme }) => theme.color.white};
    }
`;

export const LinkCardInfoContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${`${0.625 * BODY_FONT_SIZE}px ${0.3125 * BODY_FONT_SIZE}px`};
    padding-right: ${`${0.625 * BODY_FONT_SIZE}px`};
    width: ${`${9.375 * BODY_FONT_SIZE}px`};
`;

interface LinkCardTitleProps {
    color: string,
    fontMultiplier: number,
}
export const LinkCardTitle = styled.a<LinkCardTitleProps>`
    margin: 0;
    padding-top: ${`${0.3125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    line-height: 1.5em;
    color: ${({ theme }) => theme.color.neutral1000};
    text-decoration: none;
    cursor: none;
    transition: color 0.3s;

    &:hover {
        color: ${({ color, theme }) => color || theme.color.neutral700};
    }
`;

export const LinkCardAddress = styled.p`
    margin: 0;
    padding-top: ${`${0.3125 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.75em;
    line-height: 1em;
    color: ${({ theme }) => theme.color.neutral700};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const LinkCardRightGroup = styled.div`
    height: ${`${LINK_CARD_BUTTON_HEIGHT}px`};
    background: ${({ theme }) => theme.color.white};
    & > *:not(:last-child) {
        margin-right: ${`${0.3125 * BODY_FONT_SIZE}px`};
    }
`;

interface TemporaryLinkTextProps {
    color: string,
    fontMultiplier: number,
}
export const TemporaryLinkText = styled.a<TemporaryLinkTextProps>`
    display: block;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    line-height: 1.5em;
    color: ${({ theme }) => theme.color.neutral1000};
    text-decoration: none;
    margin: 0;
    padding: ${`${0.625 * BODY_FONT_SIZE}px ${0.9375 * BODY_FONT_SIZE}px`};
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    cursor: none;
    transition: color 0.3s;

    &:hover {
        color: ${({ color, theme }) => color || theme.color.neutral700};
    }
`;

interface EmojiSelectProps {
    toolbarType: EDITOR_TOOLBAR_TYPE
}
const EmojiSelect = styled.div<EmojiSelectProps>`
    position: absolute;
    bottom: ${({ toolbarType }) => `${toolbarType === EDITOR_TOOLBAR_TYPE.selection
        ? 0
        : 2.125 * BODY_FONT_SIZE
    }px`};
    background: inherit;
`;

interface EmojiSelectorContainerProps {
    height: number,
    width: number,
    toolbarType: EDITOR_TOOLBAR_TYPE,
}
const EmojiSelectorContainer = styled.div<EmojiSelectorContainerProps>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border-radius: ${`${0.625 * BODY_FONT_SIZE}px`};
    border-bottom-left-radius: ${({ toolbarType }) => `${toolbarType === EDITOR_TOOLBAR_TYPE.selection
        ? `${0.625 * BODY_FONT_SIZE}px`
        : 0
    }px`};
    overflow: hidden;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
`;

const EmojiSelectorBackButtonContainer = styled.div`
    position: absolute;
    top: 0;
    left: ${`-${2.1875 * BODY_FONT_SIZE}px`};
    background: inherit;
    z-index: 1;

    & > * {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }

    & > * {
        background-color: ${({ theme }) => theme.color.neutral600};
        transition: background-color 0.3s;

        & svg {
            fill: ${({ theme }) => theme.color.white};
        }

        &:hover {
            background-color: ${({ theme }) => theme.color.neutral700};

            & svg {
                fill: ${({ theme }) => theme.color.white};
            }
        }
    }
`;

export default PortableToolbar;

// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                               from 'react';
import styled                                   from 'styled-components';
import {
    useFocused,
    useSelected,
}                                               from 'slate-react';

// ===== Services =====

import {
    getVimeoId,
    setColorLightness,
    getEmbeddingDimensions,
    recordUserAction,
}                                               from '../../../services';

// ===== Interfaces =====

import {
    IUserItem,
}                                               from '../../../interfaces';

// ===== Enums =====

import {
    USER_ACTION_TYPE,
    EDITOR_CONTEXT_TYPE,
}                                               from '../../../enums';

// ===== Hooks =====

import { useEventListener }                     from '../../../hooks';

// ===== Constants =====

import EMBEDDING_DIMENSION                      from '../../../constants/embeddingDimensions';
import {
    BODY_FONT_SIZE,
    EDITOR_SELECTION_LIGHTNESS_VALUE,
    EDITOR_SELECTION_BORDER_THICKNESS,
}                                               from '../../../constants/generalConstants';

// Example Vimeo: https://vimeo.com/115154289

VimeoEmbed.defaultProps = {
    onLoad: undefined,
};
interface Props {
    id: string,
    url: string,
    color: string,
    readOnly: boolean,
    user: IUserItem | null,
    currentSessionId: string | null,
    postId: string | undefined,
    editorType: EDITOR_CONTEXT_TYPE,
    children: React.ReactElement,
    attributes: any,
    onLoad?: () => void,
}
function VimeoEmbed({
    id,
    url,
    color,
    readOnly,
    user,
    currentSessionId,
    postId,
    editorType,
    children,
    attributes,
    onLoad,
}: Props): JSX.Element {
    // ===== Refs =====

    const embedRef = useRef<HTMLDivElement | null>(null);

    // ===== State =====

    const [vimeoId, setVimeoId] = useState<string>('');
    const [dimensions, setDimensions] = useState(EMBEDDING_DIMENSION[480]);
    const focused = useFocused();
    const selected = useSelected();

    // ===== Side Effects =====

    // Get ID
    useEffect(() => {
        const vmoId = getVimeoId(url);
        if (vmoId) {
            setVimeoId(vmoId);
        }
    }, [url]);

    // On Mount, determine dimensions
    useEffect(() => {
        determineDimensions();
    }, [embedRef.current]);

    // On resize, determine dimensions
    useEventListener(
        'resize',
        () => {
            determineDimensions();
        },
    );

    // ===== Methods =====

    const determineDimensions = (): void => {
        if (embedRef.current) {
            const parentWidth = embedRef.current.parentElement!.clientWidth;
            const currentDimension = getEmbeddingDimensions(parentWidth);
            setDimensions(EMBEDDING_DIMENSION[currentDimension]);
        }
    };

    const clickVimeo = async (): Promise<void> => {
        if (
            readOnly
            && user
            && postId
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickVimeoVideo,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    postId,
                    editorType,
                },
            });
        }
    };

    return (
        <Container
            {...attributes}
            ref={embedRef}
            onClick={clickVimeo}
        >
            {vimeoId.length > 0
            && (
                <EmbedContainer
                    // Necessary to avoid: Cannot resolve a Slate point from DOM point
                    // Reference: https://github.com/ianstormtaylor/slate/issues/3930
                    contentEditable={false}
                >
                    <Frame
                        title={`vimeo-embed=${url}`}
                        src={`https://player.vimeo.com/video/${vimeoId}`}
                        frameBorder="0"
                        width={dimensions.width}
                        height={dimensions.height}
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        color={color}
                        focused={focused}
                        selected={selected}
                        onLoad={onLoad}
                    />
                </EmbedContainer>
            )}
            {children}
        </Container>
    );
}

// ===== Styled Components =====

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${`${1.25 * BODY_FONT_SIZE}px 0`};
`;

interface FrameProps {
    selected: boolean,
    focused: boolean,
}
const Frame = styled.iframe<FrameProps>`
    border: ${({
        selected,
        focused,
        color,
        theme,
    }) => (selected && focused
        ? `${EDITOR_SELECTION_BORDER_THICKNESS}px solid ${color
            ? setColorLightness(
                color,
                EDITOR_SELECTION_LIGHTNESS_VALUE,
            )
            : theme.verascopeColor.purple200
        }`
        : `${0.0625 * BODY_FONT_SIZE}px solid ${theme.color.neutral500}`
    )};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
`;

const EmbedContainer = styled.div``;

export default VimeoEmbed;

import styled from 'styled-components';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
}                       from '../../constants/generalConstants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${`${1.25 * BODY_FONT_SIZE}px 0px`};
    box-shadow: ${({ theme }) => theme.color.boxShadow500};
`;

export const Frame = styled.iframe`
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
`;

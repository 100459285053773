// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';

// ===== Interfaces =====

import {
    IStripePromotionValidItem,
}                               from '../interfaces';

const stripePromotionIsValid = async ({
    test,
    promotionCode,
    paymentIntentId,
}: {
    test: boolean,
    promotionCode: string,
    paymentIntentId: string,
}): Promise<IStripePromotionValidItem | undefined> => {
    const functions = getFunctions();
    const isValid = httpsCallable<
        {
            test: boolean,
            promotionCode: string,
            paymentIntentId: string,
        },
        IStripePromotionValidItem | undefined
    >(
        functions,
        'stripePromotionIsValid',
    );

    return isValid({
        test,
        promotionCode,
        paymentIntentId,
    }).then((result) => {
        const promotionId = result.data as IStripePromotionValidItem | undefined;
        return promotionId;
    }).catch((err) => err);
};

export default stripePromotionIsValid;

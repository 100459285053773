// ===== Packages =====

import {
    Selection,
}                           from 'slate';

// ===== Enums =====

import {
    ZOOM_LEVEL,
}                           from '../components/Editor/elements/enums';

// ===== Interfaces =====

import {
    IPostChapterItem,
    IZoomLevelItem,
}                           from '../interfaces';

const normalizePostSelectionPath = (
    annotationId: string,
    selection: Selection,
    chapters: IPostChapterItem[] | undefined,
    currentZoomLevel: IZoomLevelItem | null,
): Selection => {
    let annotationSelection = selection;
    if (
        chapters
        && currentZoomLevel
        && currentZoomLevel.level !== ZOOM_LEVEL.five
    ) {
        // Find which chapter annotation is part of
        const chapterIndex = chapters.findIndex((chapter) => chapter.annotations.includes(annotationId));
        // Find which section annotation is part of
        const priorNodes = chapters
            .slice(0, chapterIndex + 1)
            .reduce((chapterNodeTotal, chapter) => {
                if (chapter.sections) {
                    const sectionIndex = chapter.sections.findIndex((section) => section.annotations.includes(annotationId));
                    return chapterNodeTotal + chapter.sections.slice(0, sectionIndex).reduce((sectionNodeTotal, section) => {
                        const sectionValue = JSON.parse(section.value);
                        return sectionNodeTotal + sectionValue.length;
                    }, 0);
                }

                if (
                    currentZoomLevel.level < ZOOM_LEVEL.four
                    && chapter.value
                ) {
                    const chapterValue = JSON.parse(chapter.value);
                    return chapterNodeTotal + chapterValue.length;
                }

                return chapterNodeTotal;
            }, 0);
        annotationSelection = {
            anchor: {
                path: [
                    priorNodes + annotationSelection!.anchor.path[0],
                    ...annotationSelection!.anchor.path.slice(1),
                ],
                offset: annotationSelection!.anchor.offset,
            },
            focus: {
                path: [
                    priorNodes + annotationSelection!.focus.path[0],
                    ...annotationSelection!.focus.path.slice(1),
                ],
                offset: annotationSelection!.focus.offset,
            },
        };
    }

    return annotationSelection;
};

export default normalizePostSelectionPath;

// ===== Packages =====

import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
}                               from 'react';
import styled                   from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment                   from 'moment-timezone';
import { ReactSVG }             from 'react-svg';
import {
    useParams,
    useMatch,
    useNavigate,
    useLocation,
    useSearchParams,
}                               from 'react-router-dom';
import {
    doc,
    DocumentData,
    DocumentSnapshot,
    getDoc,
    getFirestore,
    collection,
    getDocs,
    addDoc,
    where,
    query,
    onSnapshot,
    setDoc,
}                               from 'firebase/firestore';
import {
    Editor,
}                               from 'slate';
import {
    ref,
    getStorage,
    getDownloadURL,
}                               from 'firebase/storage';
import { v4 as uuidv4 }         from 'uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import VisibilitySensor         from 'react-visibility-sensor';

// ===== Components =====

import {
    AnnotationEditor,
}                               from '../editors';
import {
    Button,
    OptionsMenu,
}                               from '.';
import Spinner                  from '../../Spinner';

// ===== Services =====

import {
    copyToClipboard,
    fontColorDiscriminator,
    generateTextSpeech,
    getStorageErrorMessage,
    recordUserAction,
    updateAnnotationInDB,
    updatePostInDB,
    detectTouchDevice,
    formatNumber,
    setAnnotationInDB,
    updateUserInDB,
    playAudio,
    updateNotificationInDB,
    applyCharacterLimit,
    setNotificationInDB,
}                               from '../../../services';

// ===== Hooks =====

import {
    useEventListener,
    useInterval,
    useTimeout,
}                               from '../../../hooks';

// ===== Interfaces =====

import {
    IAnnotationItem,
    IAnnotationValue,
    IDimension,
    IPostItem,
    ISnackbarItem,
    IUserItem,
    IZoomLevelItem,
    IEmail,
    INotificationItem,
    IMailTrackingItem,
}                               from '../../../interfaces';

// ===== Enums =====

import {
    BUTTON_TYPE,
    CURSOR_TARGET,
    EDITOR_CONTEXT_TYPE,
    FIRESTORE_COLLECTION,
    INTERACTABLE_OBJECT,
    NOTIFICATION_TYPE,
    PAGE_ROUTE,
    STORAGE_ERROR_CODE,
    TOOLTIP_TYPE,
    USER_ACTION_TYPE,
    READER_PARAMS_TYPE,
    UNSUBSCRIBE_TYPE,
    MAIL_TRACKING_TYPE,
}                               from '../../../enums';
import {
    ZOOM_LEVEL,
}                               from '../elements/enums';

// ===== Sounds =====

import AnnotationCreate         from '../../../sounds/create.mp3';
import AnnotationDelete         from '../../../sounds/delete.mp3';

// ===== Images =====

import SmileyIcon               from '../../../images/editor/smiley.svg';
import MoreIcon                 from '../../../images/editor/more.svg';
import CautionIcon              from '../../../images/caution.svg';
import LinkIcon                 from '../../../images/editor/link.svg';
import TrashIcon                from '../../../images/editor/trash.svg';
import PlayIcon                 from '../../../images/editor/play-fill.svg';
import PauseIcon                from '../../../images/editor/pause-fill.svg';
import CommentIcon              from '../../../images/comment.svg';

// ===== Constants =====

import {
    DEFAULT_CSS_TRANSITION_DURATION,
    HOVER_TARGET_CLASSNAME,
    ANNOTATION_EDITOR_Z_INDEX,
    BODY_FONT_SIZE,
    DEFAULT_EDITOR_COLOR,
    USER_PROFILE_AVATAR_LENGTH_MULTIPLIER,
    DEFAULT_AUDIO_VOLUME,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    MILLISECONDS_IN_A_SECOND,
    ANNOTATION_FOCUSED_CLOSE_BUTTON_HEIGHT,
    ANNOTATION_EDITOR_FONT_MULTIPLIER_REGULAR,
    ANNOTATION_COMMENT_EDITOR_CONTAINER_PADDING,
    ANNOTATION_COMMENTS_CONTAINER_VERTICAL_MARGIN,
    ANNOTATION_DECORATION_PREFIX,
    MODAL_SHEET_CLOSE_BUTTON_CONTAINER_TOP,
    MODAL_SHEET_CLOSE_BUTTON_COLOR,
    ANNOTATION_MORE_BUTTON_LENGTH,
    EMAIL_SENDER_ADDRESS,
    EMAIL_REPLY_ADDRESS,
    DEFAULT_POST_TITLE,
    EMAIL_TEMPLATE_COMMENT_NOTICE,
}                               from '../../../constants/generalConstants';
import CURSOR_SIGN              from '../../../constants/cursorSigns';
import MEDIA_QUERY_SIZE         from '../../../constants/mediaQuerySizes';
import FONT_TYPE                from '../../../constants/fontType';

// ===== Styles =====

import { theme as themeObj }    from '../../../themes/theme-context';
import {
    UserAvatar,
    PlaceholderBox,
    AvatarContainer,
}                               from '../../../styles';

Annotation.defaultProps = {
    toggleAnnotationStack: undefined,
    setRemeasureBucketHeight: undefined,
    showAnnotationDetail: undefined,
    hideAnnotationDetail: undefined,
    hideUserSignature: false,
    isAnnotationAuthorComment: false,
    zIndex: undefined,
    handleEditComment: undefined,
    setIsUserProfileDialogExpanded: undefined,
    setNotifyUserToSignUpAfterAnnotationCreationAttempt: undefined,
};
interface Props {
    annotation: IAnnotationItem,
    user: IUserItem | null,
    post: IPostItem | undefined,
    hasSound: boolean,
    bucketWidth: number,
    bucketHeight: number | null,
    currentSessionId: string | null,
    index: number,
    zIndex?: number,
    horizontalMargin: number,
    annotationStackActive: boolean,
    annotationStackExpanded: boolean,
    annotationCount: number,
    hide: boolean,
    viewportDimensions: IDimension,
    toggleAnnotationStack?: () => void,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    setTargetAnnotationID: React.Dispatch<React.SetStateAction<string | null>>,
    setCursorSigns: React.Dispatch<React.SetStateAction<string[]>>,
    showAnnotationDetail?: (annotation: IAnnotationItem) => void,
    hideAnnotationDetail?: () => void,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    setRemeasureBucketHeight?: React.Dispatch<React.SetStateAction<boolean>>,
    clearTimeoutAnnotationNudge: () => void,
    selectedPostValuePath: number[],
    currentZoomLevel: IZoomLevelItem | null,
    hideUserSignature?: boolean,
    isAnnotationAuthorComment?: boolean,
    handleEditComment?: (id: string | null) => void,
    setRefreshNotifications: React.Dispatch<React.SetStateAction<boolean>>,
    setIsUserProfileDialogExpanded?: (isExpanded: boolean) => void,
    setNotifyUserToSignUpAfterAnnotationCreationAttempt?: React.Dispatch<React.SetStateAction<boolean>>,
}
function Annotation({
    annotation,
    user,
    post,
    hasSound,
    bucketWidth,
    bucketHeight,
    currentSessionId,
    index,
    zIndex,
    horizontalMargin,
    annotationStackActive,
    annotationStackExpanded,
    annotationCount,
    hide,
    viewportDimensions,
    toggleAnnotationStack,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
    setTargetAnnotationID,
    setCursorSigns,
    showAnnotationDetail,
    hideAnnotationDetail,
    setSnackbarData,
    setRemeasureBucketHeight,
    clearTimeoutAnnotationNudge,
    selectedPostValuePath,
    currentZoomLevel,
    hideUserSignature,
    isAnnotationAuthorComment,
    handleEditComment,
    setRefreshNotifications,
    setIsUserProfileDialogExpanded,
    setNotifyUserToSignUpAfterAnnotationCreationAttempt,
}: Props): JSX.Element {
    // ===== General Information =====
    //
    // 1. We specifically choose not to increment an annotation if a user loads the web app with an
    // annotation focused, and rather require that they click on the annotation to increment it.
    // This is to prevent the annotation from being incremented when the app rerenders when the
    // web app with an annotation focused.

    // ===== General Constants =====

    const AVATAR_LENGTH = 1.75 * BODY_FONT_SIZE;
    const ANNOTATION_STACK_OFFSET_INCREMENT_LENGTH = 0.4375 * BODY_FONT_SIZE;
    const MAX_ANNOTATION_HEIGHT = 5 * BODY_FONT_SIZE;
    const OPTION_BUTTON_WIDTH = 5 * BODY_FONT_SIZE;
    const OPTION_BUTTON_HEIGHT = 1.875 * BODY_FONT_SIZE;
    const ANNOTATION_AUTHOR_PLACEHOLDER_WIDTH = 6.25 * BODY_FONT_SIZE;
    const ANNOTATION_AUTHOR_FONT_MULTIPLIER = 0.8;
    const QUOTE_CONTAINER_MARGIN_TOP = ANNOTATION_MORE_BUTTON_LENGTH + 0.3125 * BODY_FONT_SIZE;
    const DICTATION_BUTTON_LENGTH = ANNOTATION_MORE_BUTTON_LENGTH;
    const USER_SIGNATURE_PADDING = 0.5 * BODY_FONT_SIZE;
    const ANNOTATION_EDITOR_CONTAINER_PADDING_LEFT = 2.1875 * BODY_FONT_SIZE;
    // Minus two so that there's space between button and annotation content
    const DICTATION_BUTTON_X_OFFSET = USER_SIGNATURE_PADDING - 0.125 * BODY_FONT_SIZE;
    const MORE_BUTTON_X_OFFSET = DICTATION_BUTTON_X_OFFSET;
    const SNACKBAR_MESSAGE_COPY_LINK_SUCCESS = 'Copied annotation link to clipboard!';
    const SNACKBAR_MESSAGE_DELETE_ANNOTATION_SUCCESS = 'Annotation successfully deleted.';
    const SNACKBAR_MESSAGE_DELETE_COMMENT_SUCCESS = 'Comment successfully deleted.';
    const SNACKBAR_MESSAGE_COPY_LINK_ERROR = 'There was a problem copying annotation link to your clipboard';
    const MAX_COMMENT_NOTIFICATION_PREVIEW_COUNT = 50;

    // ===== Refs =====

    const containerRef = useRef<HTMLDivElement | null>(null);
    const audioNodeRef = useRef<HTMLAudioElement>(null);

    // ----- Sound Clips
    const createClip = useRef<HTMLAudioElement>(new Audio());
    const deleteClip = useRef<HTMLAudioElement>(new Audio());

    // ===== React Router =====

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const annotationIsFocused = useMatch(`${PAGE_ROUTE.book}/:annotationId`);
    const [searchParams] = useSearchParams();

    // ===== State =====

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
    const [annotationHeight, setAnnotationHeight] = useState<number | null>(null);
    const [annotationEditor, setAnnotationEditor] = useState<Editor | null>(null);
    const [annotationCommentEditor, setAnnotationCommentEditor] = useState<Editor | null>(null);
    const [remeasureAnnotationHeight, setRemeasureAnnotationHeight] = useState<boolean>(true);
    const [annotationAuthorAvatarURL, setAnnotationAuthorAvatarURL] = useState<string | null>(null);
    const [annotationAuthorAvatarPath, setAnnotationAuthorAvatarPath] = useState<string | undefined>(undefined);
    const [annotationAuthor, setAnnotationAuthor] = useState<IUserItem | null>(null);
    const [postAuthorAvatarURLs, setPostAuthorAvatarURLs] = useState<string[]>([]);
    const [postAuthors, setPostAuthors] = useState<IUserItem[]>([]);
    const [priorAnnotationsHeight, setPriorAnnotationsHeight] = useState<number>(0);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [audioURL, setAudioURL] = useState<string | null>(null);
    const [dictationPlaying, setDictationPlaying] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<number | null>(null);
    const [savedTime, setSavedTime] = useState<number | null>(null);
    const [audioLength, setAudioLength] = useState<number>(0);
    const [timeElapsed, setTimeElapsed] = useState<number>(0);
    const [annotationComments, setAnnotationComments] = useState<Map<string, IAnnotationItem>>(new Map());
    const [commentAuthors, setCommentAuthors] = useState<Map<string, IUserItem>>(new Map());
    // Used to determine whether to update audioURL when edited annotation
    const [dictationFilePathCount, setDictationFilePathCount] = useState<number>(0);
    const [unreadNotifications, setUnreadNotifications] = useState<INotificationItem[] | null>(null);
    const [fetchAnnotationComments, setFetchAnnotationComments] = useState<boolean>(false);
    const [subscribedToCommentUpdates, setSubscribedToCommentUpdates] = useState<boolean>(false);

    // ===== Animation Constants ======

    const ANNOTATION_ENTER_DURATION = 200;
    const ANNOTATION_TRANSITION_DURATION = DEFAULT_CSS_TRANSITION_DURATION / 2;

    // ===== Methods =====

    const handleVisibility = (isVisible: boolean): void => {
        if (
            !annotation.comment
            && !isFocused
            && isVisible
            && !unreadNotifications
        ) {
            setFetchAnnotationComments(true);
            fetchUnreadNotifications();
        }
    };

    const onMouseEnter = (e: React.MouseEvent): void => {
        let cursorSign = CURSOR_SIGN.click;
        if (
            annotationStackActive
            && !annotationStackExpanded
            && annotationHeight
            && annotationHeight > MAX_ANNOTATION_HEIGHT
        ) {
            cursorSign = CURSOR_SIGN.more;
        } else if (annotationStackActive && !annotationStackExpanded) {
            cursorSign = CURSOR_SIGN.expandStack;
        }

        if (!isFocused) {
            onCursorEnter(
                CURSOR_TARGET.annotation,
                [cursorSign],
                e.target as HTMLElement,
            );
        }
        setTargetAnnotationID(annotation.id);
    };

    const onMouseLeave = (e: React.MouseEvent): void => {
        setTargetAnnotationID(null);
        onCursorLeave(e);
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.annotationElement,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const focusAnnotation = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            const actionId = await recordUserAction({
                type: USER_ACTION_TYPE.openAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
            const annotationCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.annotations
                : FIRESTORE_COLLECTION.stagingAnnotations;
            updateAnnotationInDB({
                collection: annotationCollection,
                id: annotation.id,
                views: [
                    ...annotation.views,
                    actionId,
                ],
            });
        }

        navigate(
            `/${PAGE_ROUTE.book}/${annotation.id}?${searchParams.toString()}`,
            {
                state: location.state,
            },
        );
    };

    const unfocusAnnotation = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.closeAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }

        // Close button automatically disappears before unlocking cursor
        onCursorLeave();

        navigate(
            `/${PAGE_ROUTE.book}?${searchParams.toString()}`,
            {
                state: location.state,
            },
        );
    };

    const onClick = (): void => {
        if (
            toggleAnnotationStack
            && !annotationStackExpanded
            && !annotationIsFocused
        ) {
            toggleAnnotationStack();
            // We want the sign to change immediately
            setCursorSigns([CURSOR_SIGN.click]);
        } else if (
            !annotationIsFocused
            || (
                !!annotationIsFocused
                && params.annotationId
                && params.annotationId !== annotation.id
            )
        ) {
            focusAnnotation();
            onCursorLeave(); // reset cursor once you open focus annotation
        }
    };

    const onEditAnnotation = (): void => {
        setIsEditing(true);

        // we tell parent annotation editing has begun
        if (handleEditComment && annotation.comment) handleEditComment(annotation.id);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.editInitiateAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }
    };

    /* We intentionally do not remove associations of deleted annotations
    to users or posts */
    const onDeleteAnnotation = async (): Promise<void> => {
        // Update post annotation references
        // We place before deletion so rerender doesn't happen first
        if (post) {
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;
            const updatedAnnotations = [...post.annotations];
            const annotationIndex = updatedAnnotations.findIndex((id) => id === annotation.id);
            if (annotationIndex !== -1) updatedAnnotations.splice(annotationIndex, 1);
            updatePostInDB({
                collection: postsCollection,
                id: post.id,
                annotations: [...updatedAnnotations],
            });

            // Remove from chapters and sections as well
            if (post.chapters) {
                const indicesOfChaptersToRemove: number[] = [];
                post.chapters.forEach((chapter, chapterIndex) => {
                    if (chapter.annotations.includes(annotation.id)) {
                        indicesOfChaptersToRemove.push(chapterIndex);
                    }
                });
                const updatedChapters = [...post.chapters];
                indicesOfChaptersToRemove.forEach((chapterIndex) => {
                    const chapterAnnotations = [...updatedChapters[chapterIndex].annotations];
                    const annotationChapterIndex = chapterAnnotations.findIndex((id) => id === annotation.id);
                    if (annotationChapterIndex !== -1) chapterAnnotations.splice(annotationChapterIndex, 1);
                    updatedChapters[chapterIndex].annotations = [...chapterAnnotations];
                    updatedChapters[chapterIndex].updated = [
                        ...updatedChapters[chapterIndex].updated,
                        Date.now(),
                    ];

                    if (updatedChapters[chapterIndex].sections) {
                        const indicesOfSectionsToRemove: number[] = [];
                        updatedChapters[chapterIndex].sections!.forEach((section, sectionIndex) => {
                            if (section.annotations.includes(annotation.id)) {
                                indicesOfSectionsToRemove.push(sectionIndex);
                            }
                        });
                        indicesOfSectionsToRemove.forEach((sectionIndex) => {
                            const sectionAnnotations = [...updatedChapters[chapterIndex].sections![sectionIndex].annotations];
                            const annotationSectionIndex = sectionAnnotations.findIndex((id) => id === annotation.id);
                            if (annotationSectionIndex !== -1) sectionAnnotations.splice(annotationSectionIndex, 1);
                            updatedChapters[chapterIndex].sections![sectionIndex].annotations = [...sectionAnnotations];
                            updatedChapters[chapterIndex].sections![sectionIndex].updated = [
                                ...updatedChapters[chapterIndex].sections![sectionIndex].updated,
                                Date.now(),
                            ];
                        });
                    }
                });
                updatePostInDB({
                    collection: postsCollection,
                    id: post.id,
                    chapters: updatedChapters,
                });
            }
        }

        // Delete annotation
        const annotationCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.annotations
            : FIRESTORE_COLLECTION.stagingAnnotations;
        updateAnnotationInDB({
            collection: annotationCollection,
            id: annotation.id,
            deleted: {
                deleted: true,
                timestamp: Date.now(),
            },
        }).then(() => {
            // Play Sound
            if (hasSound && deleteClip.current) {
                deleteClip.current.pause();
                deleteClip.current.currentTime = 0;
                playAudio(deleteClip.current);
            }

            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: annotation.comment
                    ? SNACKBAR_MESSAGE_DELETE_COMMENT_SUCCESS
                    : SNACKBAR_MESSAGE_DELETE_ANNOTATION_SUCCESS,
                icon: TrashIcon,
            });
        });

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.deleteAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }
    };

    const onGetLink = async (): Promise<void> => {
        // Copy link to clipboard
        const link = `${window.location.origin}/${PAGE_ROUTE.book}/${annotation.id}`;
        copyToClipboard(
            link,
            () => {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_COPY_LINK_SUCCESS,
                    icon: LinkIcon,
                });
            },
            () => {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_COPY_LINK_ERROR,
                    icon: CautionIcon,
                    hasFailure: true,
                });
            },
        );

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.getLinkAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }
    };

    const remeasureParentHeight = (): void => {
        setRemeasureAnnotationHeight(true);
        if (setRemeasureBucketHeight) setRemeasureBucketHeight(true);
    };

    const toggleDictation = async (e: React.MouseEvent): Promise<void> => {
        e.stopPropagation();
        // Updates timestamps on a regular interval
        if (!dictationPlaying && audioNodeRef.current) {
            // if (timeElapsed === 0) {
            //     audioNodeRef.current.currentTime = 0;
            // }
            audioNodeRef.current.play()
                .then(async () => {
                    setDictationPlaying(true);
                    // Cache timestamp started recording
                    setStartTime(Date.now());

                    if (
                        user
                        && currentSessionId
                    ) {
                        // Record user action
                        await recordUserAction({
                            type: USER_ACTION_TYPE.startAnnotationDictation,
                            userId: user.id,
                            sessionId: currentSessionId,
                            payload: {
                                id: annotation.id,
                            },
                        });
                    }
                })
                .catch(() => {
                    //
                });
        } else if (dictationPlaying && audioNodeRef.current) {
            audioNodeRef.current.pause();
            setDictationPlaying(false);
            if (
                user
                && currentSessionId
            ) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.stopAnnotationDictation,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        id: annotation.id,
                    },
                });
            }
        }
    };

    const computeTimeElapsed = (): number => {
        // Collect and save elapsed time
        // We are likely recording audio, so need to time it the elapsed time
        let currentTime = Date.now();
        if (
            dictationPlaying
            && audioLength > 0
            && audioNodeRef.current
            && startTime
        ) {
            // We have track data, so compute normally
            currentTime = (audioNodeRef.current.currentTime * MILLISECONDS_IN_A_SECOND) + startTime;
        }

        let difference;
        if (
            !dictationPlaying
            && timeElapsed > 0
            && startTime
        ) {
            difference = currentTime - startTime;
        } else if (savedTime && startTime) {
            // If we've paused, factor in previous time
            difference = (currentTime - startTime) + savedTime;
        } else {
            // Ternary makes sure that we don't begin with a large
            // timestamp
            difference = startTime ? currentTime - startTime : 0;
        }
        return difference;
    };

    const recordFinishDictationAction = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            const actionId = await recordUserAction({
                type: USER_ACTION_TYPE.finishAnnotationDictation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id: annotation.id,
                },
            });
            const annotationCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.annotations
                : FIRESTORE_COLLECTION.stagingAnnotations;
            updateAnnotationInDB({
                collection: annotationCollection,
                id: annotation.id,
                plays: [
                    ...annotation.plays,
                    actionId,
                ],
            });
        }
    };

    const scrollQuoteIntoView = (): void => {
        if (isFocused) {
            const highlightElement: HTMLElement | null = document
                .querySelector(`[data-${ANNOTATION_DECORATION_PREFIX}${annotation.id}='${annotation.id}']`);
            if (highlightElement) {
                highlightElement.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'nearest',
                    block: 'center',
                });
            }
        }
    };

    const handleEditAnnotation = async (
        value: string,
        text: string,
        media: string[],
    ): Promise<void> => {
        if (!annotationEditor) throw Error('There was a problem submitting annotation. Editor reference not found.');
        if (!user) throw Error('There was a problem submitting annotation. User data not found.');
        // Button automatically disappears before unlocking cursor
        onCursorLeave();
        // we tell parent annotation editing is complete
        if (handleEditComment && annotation.comment) handleEditComment(null);

        const timestamp = Date.now();
        const annotationValue: IAnnotationValue = {
            id: uuidv4(),
            timestamp,
            value,
            text,
        };
        // We capture text sychronously because it is cleared by the annotation editor
        // after this call.
        // Placing it in the updateAnnotationInDB callback will result in an empty string.
        const annotationCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.annotations
            : FIRESTORE_COLLECTION.stagingAnnotations;
        updateAnnotationInDB({
            collection: annotationCollection,
            id: annotation.id,
            history: [
                annotationValue,
                ...annotation.history,
            ],
            media,
        }).then(() => {
            // Clear annotation audio url so we replace it with a new one
            setAudioURL(null);

            // Generate Text Dictation
            // This should in turn generate a transcript with timestamps
            // by triggering the speech-to-text cloud function
            generateTextSpeech(
                annotation.id,
                text,
                'masculine',
            );
            generateTextSpeech(
                annotation.id,
                text,
                'feminine',
                (result) => {
                    // We use feminine voice by default
                    const { filePath } = result.data;
                    updateAnnotationInDB({
                        collection: annotationCollection,
                        id: annotation.id,
                        dictationFilePaths: annotation.dictationFilePaths
                            ? [
                                filePath,
                                ...annotation.dictationFilePaths,
                            ] : [filePath],
                    });
                },
            );
            setIsEditing(false);
        });

        if (user && currentSessionId) {
            // Record user action
            await recordUserAction({
                type: USER_ACTION_TYPE.editSaveAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
            updateAnnotationInDB({
                collection: annotationCollection,
                id: annotation.id,
                updated: [
                    ...annotation.updated,
                    Date.now(),
                ],
            });
        }
    };

    const handleCancelEditAnnotation = (): void => {
        setIsEditing(false);
        // we tell parent annotation editing is complete
        if (handleEditComment && annotation.comment) handleEditComment(null);
        // Button automatically disappears before unlocking cursor
        onCursorLeave();

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.editCancelAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }
    };

    /**
     * Submits an annotation to Firestore
     * @param value serialized annotation
     * @param text annotation text
     * @param media optional media associated with annotation
     */
    const handleSubmitAnnotationComment = async (
        value: string,
        text: string,
        media: string[],
    ): Promise<void> => {
        if (!annotationCommentEditor) throw Error('There was a problem submitting annotation. Editor reference not found.');
        if (!user) throw Error('There was a problem submitting annotation. User data not found.');
        if (!annotation.postId) throw Error('There was a problem submitting annotation. Post ID not found.');

        // Clear annotation nudge timeout if active
        if (user.annotations.length === 0) clearTimeoutAnnotationNudge();

        const id = uuidv4();
        const timestamp = Date.now();
        const annotationValue: IAnnotationValue = {
            id: uuidv4(),
            timestamp,
            value,
            text,
        };
        const annotationComment: IAnnotationItem = {
            id,
            comment: {
                parentId: annotation.id,
                index: annotation.comments.length,
            },
            postId: annotation.postId,
            history: [annotationValue],
            userId: user.id,
            media,
            quoteHistory: [],
            views: [],
            plays: [],
            published: timestamp,
            updated: [],
            comments: [],
            deleted: {
                deleted: false,
                timestamp: null,
            },
        };
        // We capture text sychronously because it is cleared by the annotation editor
        // after this call.
        // Placing it in the setAnnotationInDB callback will result in an empty string.
        setAnnotationInDB({ annotation: annotationComment }).then(async () => {
            // Play Sound
            if (hasSound && createClip.current) {
                createClip.current.pause();
                createClip.current.currentTime = 0;
                playAudio(createClip.current);
            }

            // Update parent post comment references
            const annotationCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.annotations
                : FIRESTORE_COLLECTION.stagingAnnotations;
            updateAnnotationInDB({
                collection: annotationCollection,
                id: annotation.id,
                comments: [
                    ...annotation.comments,
                    id,
                ],
            });

            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;

            // Update post annotation references
            if (post && annotationComment.postId) {
                updatePostInDB({
                    collection: postsCollection,
                    id: annotationComment.postId,
                    annotations: [
                        ...post.annotations,
                        id,
                    ],
                });
            }

            // Update post chapter and sections annotation references
            if (
                annotationComment.postId
                && post
                && 'chapters' in post
                && (
                    post.chapters![selectedPostValuePath[0]]
                    || (
                        currentZoomLevel
                        && currentZoomLevel.level < ZOOM_LEVEL.five
                    )
                )
            ) {
                const updatedChapters = [...post.chapters!];
                if (post.chapters![selectedPostValuePath[0]]) {
                    // Zoom 4+
                    updatedChapters[selectedPostValuePath[0]].annotations = [
                        ...updatedChapters[selectedPostValuePath[0]].annotations,
                        id,
                    ];
                } else {
                    // Zoom 1-3
                    updatedChapters[selectedPostValuePath[0]].annotations = [
                        ...updatedChapters[selectedPostValuePath[0]].annotations,
                        id,
                    ];
                }

                if (
                    selectedPostValuePath.length === 2
                    && post.chapters![selectedPostValuePath[0]]!.sections
                    && post.chapters![selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]]
                ) {
                    updatedChapters[selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]].annotations = [
                        ...updatedChapters[selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]].annotations,
                        id,
                    ];
                } else if (
                    currentZoomLevel
                    && currentZoomLevel.level < ZOOM_LEVEL.five
                    && 'sections' in post.chapters![selectedPostValuePath[0]]
                ) {
                    updatedChapters[selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]].annotations = [
                        ...updatedChapters[selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]].annotations,
                        id,
                    ];
                }

                updatePostInDB({
                    collection: postsCollection,
                    id: annotationComment.postId,
                    chapters: updatedChapters,
                });
            }

            // Update author annotation references
            if (user) {
                updateUserInDB({
                    userId: user.id,
                    annotations: [
                        ...user.annotations,
                        id,
                    ],
                });
            }

            // Generate Text Dictation
            // This should in turn generate a transcript with timestamps
            // by triggering the text-to-speech cloud function
            // We want to create both male and female dictation files for the annotation
            // But we use the female one by default
            generateTextSpeech(
                id,
                text,
                'masculine',
            );
            generateTextSpeech(
                id,
                text,
                'feminine',
                (result) => {
                    // We use feminine voice by default
                    const { filePath } = result.data;
                    updateAnnotationInDB({
                        collection: annotationCollection,
                        id,
                        dictationFilePaths: [filePath],
                    });
                },
            );
        });

        // Create annotation comment notifications to comment authors
        const createAnnotationCommentNotification = (userId: string, isAnnotationAuthor: boolean): void => {
            if (post) {
                let chapterIndex: number | undefined;
                let sectionIndex: number | undefined;
                if (searchParams.has(READER_PARAMS_TYPE.chapter.toString())) {
                    chapterIndex = parseInt(searchParams.get(READER_PARAMS_TYPE.chapter)!, 10) - 1;
                }
                if (searchParams.has(READER_PARAMS_TYPE.section.toString())) {
                    sectionIndex = parseInt(searchParams.get(READER_PARAMS_TYPE.section)!, 10) - 1;
                }
                const notification: INotificationItem = {
                    id: uuidv4(),
                    userId,
                    type: NOTIFICATION_TYPE.annotationComment,
                    timestamp: Date.now(),
                    title: isAnnotationAuthor
                        ? `${user.firstName} ${user.lastName} commented on your annotation`
                        : `${user.firstName} ${user.lastName} also commented on ${annotationAuthor?.firstName} ${annotationAuthor?.lastName}'s annotation`,
                    message: applyCharacterLimit(
                        text,
                        MAX_COMMENT_NOTIFICATION_PREVIEW_COUNT,
                    ),
                    read: false,
                    payload: {
                        postId: post.id,
                        postName: post?.title,
                        chapterIndex,
                        sectionIndex,
                        annotationId: annotation.id,
                        commentId: id,
                    },
                };
                setNotificationInDB({ notification });
            } else {
                throw Error('Unable to generate comment notifications. Missing post info.');
            }
        };
        if (annotation.userId !== user.id) {
            createAnnotationCommentNotification(annotation.userId, true);
        }
        commentAuthors.forEach((author) => {
            if (
                author.id !== user.id
                && author.id !== annotation.userId
            ) {
                createAnnotationCommentNotification(author.id, false);
            }
        });

        // Send annotation notice email
        // Send confirmation email if hasn't received one in the last 48 hours
        const storage = getStorage();
        const db = getFirestore();
        const emailRequestCollection = FIRESTORE_COLLECTION.mail;

        const generateUserName = (userItem: IUserItem): string => {
            if (userItem.firstName && userItem.lastName) {
                return `${userItem.firstName} ${userItem.lastName}`;
            }

            if (userItem.firstName) {
                return userItem.firstName;
            }

            return 'A person';
        };

        const createAnnotationCommentEmail = async (
            userId: string,
            userEmail: string,
        ): Promise<void> => {
            const mailCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.mailTracking
                : FIRESTORE_COLLECTION.stagingMailTracking;
            const dayBeforeYesterday = new Date();
            dayBeforeYesterday.setDate(new Date().getDate() - 2);
            const mailQuery = query(
                collection(db, mailCollection),
                where('userId', '==', userId),
                where('timestamp', '>=', dayBeforeYesterday.setHours(0, 0, 0, 0)),
            );
            const querySnapshot = await getDocs(mailQuery);

            // Don't send email if user has received one in the last 48 hours
            if (querySnapshot.size > 0) {
                return;
            }

            // eslint-disable-next-line max-len
            let annotationAuthorURL = 'https://firebasestorage.googleapis.com/v0/b/verascope-website.appspot.com/o/mail-images%2Fuser-avatar-placeholder.png?alt=media&token=ee00bee1-136c-4b3c-bade-ee2c37e2aa1d';
            if (annotationAuthor && annotationAuthor.avatarFilePath) {
                const postAuthorPathParts = annotationAuthor.avatarFilePath.split('.');
                const mediumPath = `${postAuthorPathParts[0]}_medium.${postAuthorPathParts[1]}`;
                annotationAuthorURL = await getDownloadURL(ref(storage, mediumPath));
            }
            // eslint-disable-next-line max-len
            let commentAuthorURL = 'https://firebasestorage.googleapis.com/v0/b/verascope-website.appspot.com/o/mail-images%2Fuser-avatar-placeholder.png?alt=media&token=ee00bee1-136c-4b3c-bade-ee2c37e2aa1d';
            if (user.avatarFilePath) {
                const annotationAuthorPathParts = user.avatarFilePath.split('.');
                const mediumPath = `${annotationAuthorPathParts[0]}_medium.${annotationAuthorPathParts[1]}`;
                commentAuthorURL = await getDownloadURL(ref(storage, mediumPath));
            }
            let chapterIndex: number | undefined;
            let sectionIndex: number | undefined;
            if (searchParams.has(READER_PARAMS_TYPE.chapter.toString())) {
                chapterIndex = parseInt(searchParams.get(READER_PARAMS_TYPE.chapter)!, 10) - 1;
            }
            if (searchParams.has(READER_PARAMS_TYPE.section.toString())) {
                sectionIndex = parseInt(searchParams.get(READER_PARAMS_TYPE.section)!, 10) - 1;
            }

            if (
                annotationAuthor
                && chapterIndex !== undefined
                && sectionIndex !== undefined
            ) {
                let newSearchParams = searchParams;
                newSearchParams = new URLSearchParams([
                    // must always be zoom level five (because no annotations in other levels)
                    [READER_PARAMS_TYPE.zoom.toString(), ZOOM_LEVEL.five.toString()],
                    [READER_PARAMS_TYPE.chapter.toString(), (chapterIndex + 1).toString()],
                    [READER_PARAMS_TYPE.section.toString(), (sectionIndex + 1).toString()],
                ]);
                const annotationLink = `${window.location.origin}/${PAGE_ROUTE.book}/${annotation.id}?${newSearchParams.toString()}`;
                const commentNoticeMailTrackingId = uuidv4();
                const pixelURL = `https://us-central1-verascope-website.cloudfunctions.net/mailTracking?id=${commentNoticeMailTrackingId}&test=${(process.env.NODE_ENV === 'development').toString()}`;
                const emailMessage = EMAIL_TEMPLATE_COMMENT_NOTICE({
                    commentDescription: isAnnotationAuthor
                        ? `${generateUserName(user)} commented on your annotation`
                        : `${generateUserName(user)} also commented on ${generateUserName(annotationAuthor)}'s annotation`,
                    annotationAuthor: generateUserName(annotationAuthor),
                    annotationLink,
                    annotationText: annotation.history[0].text,
                    postTitle: post?.title || DEFAULT_POST_TITLE,
                    annotationAuthorURL,
                    commentText: annotationComment.history[0].text,
                    commentAuthorURL,
                    commentAuthor: generateUserName(user),
                    commentDate: moment.utc(annotationComment.published).format('ll'),
                    email: userEmail,
                    unsubscribeLink: `${window.location.origin}/${PAGE_ROUTE.unsubscribe}/${userId}?type=${UNSUBSCRIBE_TYPE.commentUpdates}`,
                    pixelURL,
                });
                const emailRequest: IEmail = {
                    from: EMAIL_SENDER_ADDRESS,
                    to: userEmail,
                    replyTo: EMAIL_REPLY_ADDRESS,
                    message: emailMessage,
                };
                await addDoc(collection(db, emailRequestCollection), emailRequest);
                const commentNoticeMailTrackingItem: IMailTrackingItem = {
                    id: commentNoticeMailTrackingId,
                    type: MAIL_TRACKING_TYPE.annotationCommentNotice,
                    timestamp: Date.now(),
                    email: userEmail,
                    userId,
                    opens: [],
                    pixelURL,
                    html: emailMessage.html,
                    test: process.env.NODE_ENV === 'development',
                };
                const mailTrackingCollection = process.env.NODE_ENV === 'production'
                    ? FIRESTORE_COLLECTION.mailTracking
                    : FIRESTORE_COLLECTION.stagingMailTracking;
                const mailTrackingItemRef = doc(db, mailTrackingCollection, commentNoticeMailTrackingId);
                await setDoc(mailTrackingItemRef, commentNoticeMailTrackingItem);
            } else {
                throw Error('Unable to create annotation comment email. Cannot locate required data.');
            }
        };

        if (
            annotation.userId !== user.id
            && annotationAuthor
            && annotationAuthor.email
            && annotationAuthor.commentSubscription
            && annotationAuthor.commentSubscription.history[annotationAuthor.commentSubscription.history.length - 1].subscribed
        ) {
            createAnnotationCommentEmail(annotation.userId, annotationAuthor.email);
        }
        commentAuthors.forEach((author) => {
            if (
                author.id !== user.id
                && author.id !== annotation.userId
                && author.email
                && author.commentSubscription
                && author.commentSubscription.history[author.commentSubscription.history.length - 1].subscribed
            ) {
                createAnnotationCommentEmail(author.id, author.email);
            }
        });

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.createAnnotationComment,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotationComment.id,
                },
            });
        }
    };

    const fetchUnreadNotifications = async (): Promise<void> => {
        if (user) {
            const notifications: INotificationItem[] = [];
            const db = getFirestore();
            const notificationCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.notifications
                : FIRESTORE_COLLECTION.stagingNotifications;
            const notificationQuery = query(
                collection(db, notificationCollection),
                where('type', '==', NOTIFICATION_TYPE.annotationComment),
                where('userId', '==', user.id),
                where('payload.annotationId', '==', annotation.id),
                where('read', '==', false),
            );
            const querySnapshot = await getDocs(notificationQuery);

            querySnapshot.forEach((document) => {
                if (document.exists()) {
                    const notification = document.data() as INotificationItem;
                    notifications.push(notification);
                }
            });

            setUnreadNotifications(notifications);
        }
    };

    const markAnnotationCommentNotifsAsRead = async (): Promise<void> => {
        // get annotation comment notifications
        if (
            user
            && unreadNotifications
            && unreadNotifications.length === 0
        ) await fetchUnreadNotifications();

        // mark notifications as read
        if (unreadNotifications) {
            const notificationCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.notifications
                : FIRESTORE_COLLECTION.stagingNotifications;
            const updateNotificationPromises: Promise<void>[] = [];
            unreadNotifications.forEach((notif) => {
                updateNotificationPromises.push(
                    updateNotificationInDB({
                        collection: notificationCollection,
                        id: notif.id,
                        read: true,
                    }),
                );
            });

            Promise.all(updateNotificationPromises).then(() => {
                setRefreshNotifications(true);
                setUnreadNotifications([]);
            });
        }
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            createClip.current
            && deleteClip.current
        ) {
            // Annotation Create
            createClip.current.volume = DEFAULT_AUDIO_VOLUME;
            createClip.current.src = AnnotationCreate;

            // Annotation Delete
            deleteClip.current.volume = DEFAULT_AUDIO_VOLUME;
            deleteClip.current.src = AnnotationDelete;
        }

        return function cleanup() {
            if (createClip.current) createClip.current.remove();
            if (deleteClip.current) deleteClip.current.remove();
        };
    }, []);

    /**
     * Loads the annotation author's data
     */
    useEffect(() => {
        if (!annotationAuthor) {
            const db = getFirestore();
            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const userRef = doc(db, usersCollection, annotation.userId);
            getDoc(userRef).then((userSnap) => {
                if (userSnap.exists()) {
                    const userItem = userSnap.data() as IUserItem;
                    setAnnotationAuthor(userItem);
                }
            });
        }
    }, [annotation.userId]);

    /**
     * Listens for new comments in Firestore
     */
    useEffect(() => {
        if (
            fetchAnnotationComments
            && !subscribedToCommentUpdates
        ) {
            // Initialize DB
            const db = getFirestore();

            const annotationCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.annotations
                : FIRESTORE_COLLECTION.stagingAnnotations;
            const annotationCommentsQuery = query(
                collection(db, annotationCollection),
                where('postId', '==', annotation.postId),
                where('comment.parentId', '==', annotation.id),
            );
            const unsubscribe = onSnapshot(annotationCommentsQuery, (querySnapshot) => {
                const map: Map<string, IAnnotationItem> = new Map();
                querySnapshot.forEach((document) => {
                    if (document.exists()) {
                        const comment = document.data() as IAnnotationItem;
                        // Add annotation to comment map
                        map.set(document.id, comment);
                    }
                });

                setAnnotationComments(map);
                setSubscribedToCommentUpdates(true);
            });

            return function cleanup() {
                unsubscribe();
            };
        }
    }, [
        fetchAnnotationComments,
    ]);

    /**
     * Remeasures bucket height so annotation stacks
     * adjust
     */
    useEffect(() => {
        if (remeasureAnnotationHeight && containerRef.current) {
            const { height } = containerRef.current.getBoundingClientRect();
            setAnnotationHeight(height);
            setRemeasureAnnotationHeight(false);
        }
    }, [
        remeasureAnnotationHeight,
    ]);

    /**
     * Computes the height of all prior annotations when new annotations
     */
    useEffect(() => {
        clearTimeoutComputePriorAnnotationsHeight();
        timeoutComputePriorAnnotationsHeight();
    }, [
        bucketHeight,
        annotationCount,
        params.annotationId,
    ]);

    /**
     * Focuses the annotation when the annotation id is in the url
     */
    useEffect(() => {
        if (
            !!annotationIsFocused
            && params.annotationId
            && params.annotationId === annotation.id
            && !isFocused
            && showAnnotationDetail
        ) {
            setIsFocused(true);
            // useful for when annotation detail banner on smaller viewports
            showAnnotationDetail(annotation);
            // mark any annotation comments as read
            markAnnotationCommentNotifsAsRead();
            // fetch comment annotations
            setFetchAnnotationComments(true);
        } else if (
            (
                !annotationIsFocused
                || (
                    !!annotationIsFocused
                    && params.annotationId
                    && params.annotationId !== annotation.id
                )
            )
            && isFocused
            && hideAnnotationDetail
        ) {
            setIsFocused(false);
            hideAnnotationDetail();
        }
    }, [
        params,
        annotationIsFocused,
    ]);

    useEffect(() => {
        if (
            annotationAuthor
            && annotationAuthor.avatarFilePath !== annotationAuthorAvatarPath
            // multiple calls to getDownloadURL can be made
            // before the first one returns
            && !annotationAuthorAvatarURL
        ) {
            const storage = getStorage();
            if (annotationAuthor.avatarFilePath) {
                const pathParts = annotationAuthor.avatarFilePath.split('.');
                const mediumPath = `${pathParts[0]}_medium.${pathParts[1]}`;
                getDownloadURL(ref(storage, mediumPath)).then((imageURL) => {
                    setAnnotationAuthorAvatarURL(imageURL);
                    setAnnotationAuthorAvatarPath(annotationAuthor.avatarFilePath);
                }).catch((error) => {
                    // We assume cloud function has not yet generated medium image yet
                    if (error.code === STORAGE_ERROR_CODE.objectNotFound) {
                        getDownloadURL(ref(storage, annotationAuthor.avatarFilePath)).then((imageURL) => {
                            setAnnotationAuthorAvatarURL(imageURL);
                        }).catch((err) => {
                            // This is not an error we need to present to the user
                            throw Error(getStorageErrorMessage(err.code as STORAGE_ERROR_CODE));
                        });
                    } else {
                        // This is not an error we need to present to the user
                        throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
                    }
                });
            } else {
                setAnnotationAuthorAvatarURL(null);
                setAnnotationAuthorAvatarPath(undefined);
            }
        }
    }, [
        annotationAuthorAvatarURL,
        annotationAuthor?.avatarFilePath,
    ]);

    useEffect(() => {
        if (
            postAuthors.length > 0
            && postAuthorAvatarURLs.length === 0
        ) {
            const postAuthorsPromises = [];
            for (let i = 0; i < postAuthors.length; i += 1) {
                const postAuthor = postAuthors[i];
                if (postAuthor.avatarFilePath) {
                    const storage = getStorage();
                    const pathParts = postAuthor.avatarFilePath!.split('.');
                    const mediumPath = `${pathParts[0]}_medium.${pathParts[1]}`;
                    postAuthorsPromises.push(getDownloadURL(ref(storage, mediumPath)));
                }
            }

            Promise.all(postAuthorsPromises).then((imageURLs) => {
                setPostAuthorAvatarURLs(imageURLs);
            });
        }
    }, [postAuthors]);

    // Get annotation dictation audio
    useEffect(() => {
        if (
            audioNodeRef.current
            && annotation.dictationFilePaths
            && annotation.dictationFilePaths.length > 0
            && (
                !audioURL
                || dictationFilePathCount < annotation.dictationFilePaths.length
            )
        ) {
            const storage = getStorage();
            const pathParts = annotation.dictationFilePaths[0].split('.');
            const flacPath = `${pathParts[0]}.flac`;
            getDownloadURL(ref(storage, flacPath))
                .then((url) => {
                    setAudioURL(url);
                    if (audioNodeRef.current) {
                        audioNodeRef.current.volume = DEFAULT_AUDIO_VOLUME;
                        (audioNodeRef.current.childNodes[0] as HTMLSourceElement).src = url;
                        audioNodeRef.current.load();
                        if (dictationFilePathCount < annotation.dictationFilePaths!.length) {
                            setDictationFilePathCount(annotation.dictationFilePaths!.length);
                        }
                    }
                })
                .catch((error) => {
                    if (error.code === STORAGE_ERROR_CODE.objectNotFound) {
                        getDownloadURL(ref(storage, annotation.dictationFilePaths![0]))
                            .then((url) => {
                                setAudioURL(url);
                                if (audioNodeRef.current) {
                                    audioNodeRef.current.volume = DEFAULT_AUDIO_VOLUME;
                                    (audioNodeRef.current.childNodes[0] as HTMLSourceElement).src = url;
                                    audioNodeRef.current.load();
                                    if (dictationFilePathCount < annotation.dictationFilePaths!.length) {
                                        setDictationFilePathCount(annotation.dictationFilePaths!.length);
                                    }
                                }
                            });
                    }
                });
        }
    }, [
        annotation.dictationFilePaths,
    ]);

    // Update elapsed time
    // Stop audio when reached the end
    useInterval(() => {
        const newElapsed = computeTimeElapsed();
        setTimeElapsed(newElapsed);

        const roundedTimeElapsed = Math.round(timeElapsed * 1000) / 1000;
        const roundedAudioLength = Math.round(audioLength * 1000) / 1000;
        // Stop Playback when reach end of recording
        if (
            audioNodeRef.current
            && dictationPlaying
            && !Number.isNaN(audioLength)
            && roundedTimeElapsed >= roundedAudioLength
        ) {
            // Update Recording timestamp
            setDictationPlaying(false);
            audioNodeRef.current.pause();
            audioNodeRef.current.currentTime = 0;
            setTimeElapsed(0);
            setStartTime(null);
            setSavedTime(null);
            recordFinishDictationAction();
        }
        // The alternative occurs when the metadata is not fetched.
        // This results in immediately ending the song.
        // This is an edge case that happens a handful of times.
    }, dictationPlaying ? MILLISECONDS_IN_A_SECOND / 10 : null);

    // Update audio lenth when metadata updated
    useEventListener(
        'loadedmetadata',
        useCallback((): void => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { duration } = audioNodeRef.current!;
            // Chromium bug condition
            if (audioNodeRef.current && duration === Infinity) {
                // ...set audioNodeRef.current.currentTime to a very large number
                // ...listen to "timeupdate" event
                // ...on "timeupdate", retrieve now-updated duration
                audioNodeRef.current.currentTime = 10000000 * Math.random();
            } else if (!Number.isNaN(duration)) {
                setAudioLength(duration * MILLISECONDS_IN_A_SECOND);
            }
        }, [audioNodeRef.current]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        audioNodeRef.current!,
    );

    /**
     * Loads the post authors' data
     */
    useEffect(() => {
        if (
            post
            && isFocused
            && postAuthors.length === 0
        ) {
            const db = getFirestore();
            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const authorPromises: Promise<DocumentSnapshot<DocumentData>>[] = [];
            for (let i = 0; i < post.authors.length; i += 1) {
                const userId = post.authors[i];
                authorPromises.push(getDoc(doc(db, usersCollection, userId)));
            }
            Promise.all(authorPromises).then((authors) => {
                setPostAuthors([
                    ...authors
                        .map((authorSnap) => authorSnap.data() as IUserItem)
                        .filter((author) => !!author),
                ]);
            });
        }
    }, [
        post?.authors,
        isFocused,
    ]);

    /**
     * Fetches user items of all comment authors
     */
    useEffect(() => {
        if (
            annotation
            && annotationComments.size > 0
            && isFocused
            && commentAuthors.size === 0
        ) {
            const db = getFirestore();
            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const authorPromises: Promise<DocumentSnapshot<DocumentData>>[] = [];
            const checkedAuthors: Map<string, boolean> = new Map();
            const commentArr = Array.from(annotationComments.values());
            for (let i = 0; i < commentArr.length; i += 1) {
                const comment = commentArr[i];
                if (!checkedAuthors.get(comment.userId)) {
                    authorPromises.push(getDoc(doc(db, usersCollection, comment.userId)));
                    checkedAuthors.set(comment.userId, true);
                }
            }
            Promise.all(authorPromises).then((authors) => {
                const commentAuthorItems: Map<string, IUserItem> = new Map();
                authors.forEach((authorSnap) => {
                    const authorItem = authorSnap.data() as IUserItem;
                    commentAuthorItems.set(authorItem.id, authorItem);
                });
                if (commentAuthorItems.size !== commentAuthors.size) {
                    setCommentAuthors(commentAuthorItems);
                }
            });
        }
    }, [
        annotation,
        isFocused,
        annotationComments,
    ]);

    const {
        start: timeoutComputePriorAnnotationsHeight,
        clear: clearTimeoutComputePriorAnnotationsHeight,
    } = useTimeout(() => {
        if (containerRef.current) {
            let priorAccumulatedHeights = 0;
            const siblings = containerRef.current.parentElement!.children;
            for (let i = 0; i < siblings.length; i += 1) {
                const sibling = siblings[i];
                const siblingId = sibling.id;
                if (siblingId === annotation.id) break;
                priorAccumulatedHeights += siblings[i].clientHeight;
                setPriorAnnotationsHeight(priorAccumulatedHeights);
            }
        }
    }, ANNOTATION_TRANSITION_DURATION);

    // ===== Memoization =====

    const isAnnotationAuthor = useMemo(() => !!user && annotation.userId === user.id, [
        user?.id,
        annotation.userId,
    ]);

    const isPostAuthor = useMemo(() => !!post?.authors && post.authors.includes(annotation.userId), [
        post?.authors,
        annotation.userId,
    ]);

    const hasAuthorAvatarImage = useMemo(() => (!!postAuthorAvatarURLs[0] || (
        postAuthors.length > 0
        && !!postAuthors[0] && !!postAuthors[0].photoURL
    )), [
        postAuthorAvatarURLs,
        postAuthors,
    ]);

    const hasUserAvatarImage = useMemo(() => (!!annotationAuthorAvatarURL || !!annotationAuthor?.photoURL), [
        annotationAuthorAvatarURL,
        annotationAuthor?.photoURL,
    ]);

    const DictationIcon = useMemo(() => (dictationPlaying ? PauseIcon : PlayIcon), [dictationPlaying]);

    const sortedComments = useMemo(() => Array.from(annotationComments.values())
        .sort((a, b) => a.comment!.index - b.comment!.index), [
        annotationComments,
    ]);

    // ===== Renderers =====

    /**
     * Renderer for the options menu buttons
     */
    const OptionsMenuButtons = useMemo(() => {
        const activeMenuButtons: {
            text: string;
            action: () => void;
        }[] = [];
        const menuButtons = [
            {
                text: isFocused ? 'Close' : 'Open',
                action: isFocused ? unfocusAnnotation : focusAnnotation,
            },
            {
                text: 'Edit',
                action: onEditAnnotation,
            },
            {
                text: 'Get Link',
                action: onGetLink,
            },
            {
                text: 'Delete',
                action: onDeleteAnnotation,
            },
        ];

        // Select Annotation
        if (!annotation.comment) activeMenuButtons.push(menuButtons[0]);

        // Edit Annotation
        if (isAnnotationAuthor) activeMenuButtons.push(menuButtons[1]);

        // Get Link of Annotation
        if (!annotation.comment) activeMenuButtons.push(menuButtons[2]);

        // Delete Annotation
        if (isAnnotationAuthor) activeMenuButtons.push(menuButtons[3]);

        return activeMenuButtons.map((button) => (
            <Button
                key={button.text}
                center
                className={HOVER_TARGET_CLASSNAME}
                type={BUTTON_TYPE.secret}
                height={OPTION_BUTTON_HEIGHT}
                width={OPTION_BUTTON_WIDTH}
                text={button.text}
                onMouseEnter={onButtonMouseEnter}
                onMouseLeave={onButtonMouseLeave}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: button.action,
                } : {
                    onMouseDown: button.action,
                })}
            />
        ));
    }, [
        isFocused,
        viewportDimensions.width,
        isAnnotationAuthor,
    ]);

    const AnnotationComponent = useMemo(() => (
        <Container
            ref={containerRef}
            id={annotation.id}
            className={HOVER_TARGET_CLASSNAME}
            index={index}
            zIndex={zIndex}
            isFocused={isFocused}
            isComment={!!annotation.comment}
            hide={hide}
            horizontalMargin={horizontalMargin}
            annotationStackActive={annotationStackActive}
            annotationStackExpanded={annotationStackExpanded}
            stackOffsetIncrementLength={ANNOTATION_STACK_OFFSET_INCREMENT_LENGTH}
            priorAnnotationsHeight={priorAnnotationsHeight}
            transitionDuration={ANNOTATION_TRANSITION_DURATION}
            enterDuration={ANNOTATION_ENTER_DURATION}
            {...(!annotation.comment && (
                viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                || !isFocused
            ) ? {
                    onMouseEnter,
                    onMouseLeave,
                } : {}
            )}
            {...(isEditing || annotation.comment
                ? {}
                : {
                    onClick,
                }
            )}
        >
            {!isEditing
            && (
                !annotation.comment
                || (
                    annotation.comment
                    && isAnnotationAuthor
                )
            )
            && (
                <OptionsMenuContainer
                    isComment={!!annotation.comment}
                    isAuthor={isAnnotationAuthor}
                    length={OPTION_BUTTON_HEIGHT}
                    xOffset={MORE_BUTTON_X_OFFSET}
                >
                    <OptionsMenu
                        center
                        className={HOVER_TARGET_CLASSNAME}
                        revealAbove={false}
                        buttonHeight={ANNOTATION_MORE_BUTTON_LENGTH}
                        childItemHeight={OPTION_BUTTON_HEIGHT}
                        childItemWidth={OPTION_BUTTON_WIDTH}
                        parentRef={containerRef.current
                            ? containerRef.current.parentElement
                            : null}
                        tooltipText="More"
                        tooltipSideType={annotation.comment && isAnnotationAuthor
                            ? TOOLTIP_TYPE.top
                            : TOOLTIP_TYPE.left}
                        onMouseEnter={onButtonMouseEnter}
                        onMouseLeave={onButtonMouseLeave}
                    >
                        {OptionsMenuButtons}
                    </OptionsMenu>
                </OptionsMenuContainer>
            )}
            {isFocused
            && !annotation.comment
            && viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
            && (
                <CloseButtonContainer
                    buttonHeight={ANNOTATION_FOCUSED_CLOSE_BUTTON_HEIGHT}
                >
                    <Button
                        boxShadow
                        className={HOVER_TARGET_CLASSNAME}
                        type={BUTTON_TYPE.solid}
                        height={ANNOTATION_FOCUSED_CLOSE_BUTTON_HEIGHT}
                        background={themeObj.verascopeColor.purple400}
                        text="Close"
                        onMouseEnter={onButtonMouseEnter}
                        onMouseLeave={onButtonMouseLeave}
                        onClick={unfocusAnnotation}
                    />
                </CloseButtonContainer>
            )}
            <ScrollContainer
                isEditing={isEditing}
            >
                {isFocused
                && !annotation.comment && (
                    <QuoteContainer
                        marginTop={QUOTE_CONTAINER_MARGIN_TOP}
                        transitionDuration={ANNOTATION_TRANSITION_DURATION}
                        onMouseEnter={onButtonMouseEnter}
                        onMouseLeave={onButtonMouseLeave}
                        onClick={scrollQuoteIntoView}
                    >
                        <QuoteText
                            transitionDuration={ANNOTATION_TRANSITION_DURATION}
                        >
                            {annotation.quoteHistory[0].texts.reduce((acc, text) => {
                                if (acc.length === 0) {
                                    return text;
                                }

                                return `${acc}, ${text}`;
                            }, '')}
                        </QuoteText>
                        <PostAuthorSignature>
                            <AvatarContainer
                                hasAvatarImage={hasAuthorAvatarImage}
                                length={AVATAR_LENGTH}
                            >
                                {hasAuthorAvatarImage
                                    ? (
                                        <UserAvatar
                                            url={
                                                postAuthorAvatarURLs[0]
                                                || postAuthors[0].photoURL!
                                            } // Has to be present if avatarURL is false
                                            length={USER_PROFILE_AVATAR_LENGTH_MULTIPLIER * BODY_FONT_SIZE}
                                        />
                                    ) : (
                                        <ReactSVG
                                            src={SmileyIcon}
                                        />
                                    )}
                            </AvatarContainer>
                            <PostAuthorDetails>
                                {postAuthors.length > 0
                                && (
                                    <PostAuthorName>
                                        {postAuthors.reduce((acc, author, authorIndex) => {
                                            if (authorIndex === 0) {
                                                return `${author.firstName} ${author.lastName}`;
                                            }

                                            return `${acc}, ${author.firstName} ${author.lastName}`;
                                        }, '')}
                                    </PostAuthorName>
                                )}
                                {post
                                && (
                                    <PostAuthorPostName>
                                        {post?.title}
                                    </PostAuthorPostName>
                                )}
                            </PostAuthorDetails>
                        </PostAuthorSignature>
                    </QuoteContainer>
                )}
                <AnnotationBody
                    isFocused={isFocused}
                    isComment={!!annotation.comment}
                    isAuthor={isAnnotationAuthor}
                    isEditing={isEditing}
                    maxHeight={MAX_ANNOTATION_HEIGHT}
                    annotationStackActive={annotationStackActive}
                    annotationStackExpanded={annotationStackExpanded}
                >
                    <UserSignature
                        isFocused={isFocused}
                        isComment={!!annotation.comment}
                        isAuthor={isAnnotationAuthor}
                        hideUserSignature={hideUserSignature}
                        padding={USER_SIGNATURE_PADDING}
                        marginLength={MORE_BUTTON_X_OFFSET + ANNOTATION_MORE_BUTTON_LENGTH}
                        transitionDuration={0}
                    >
                        {!hideUserSignature && (
                            <AvatarContainer
                                hasAvatarImage={hasUserAvatarImage}
                                length={AVATAR_LENGTH}
                                withRing={isPostAuthor
                                    && viewportDimensions.width < MEDIA_QUERY_SIZE.small.min}
                            >
                                {hasUserAvatarImage
                                    ? (
                                        <UserAvatar
                                            url={annotationAuthorAvatarURL || annotationAuthor!.photoURL!} // Has to be present if avatarURL is false
                                            length={USER_PROFILE_AVATAR_LENGTH_MULTIPLIER * BODY_FONT_SIZE}
                                        />
                                    ) : (
                                        <ReactSVG
                                            src={SmileyIcon}
                                        />
                                    )}
                            </AvatarContainer>
                        )}
                        {(viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min || isFocused || annotation.comment)
                        && (
                            <AnnotationDetails
                                isComment={!!annotation.comment}
                                isAuthor={isAnnotationAuthor}
                            >
                                {annotationAuthor && !hideUserSignature && (
                                    <AnnotationAuthorName
                                        fontMultiplier={ANNOTATION_AUTHOR_FONT_MULTIPLIER}
                                    >
                                        {`${annotationAuthor?.firstName} ${annotationAuthor?.lastName}`}
                                        {isPostAuthor && !isAnnotationAuthor && (
                                            <AuthorTag>
                                                Author
                                            </AuthorTag>
                                        )}
                                        {!!annotation.comment && isAnnotationAuthorComment && !isAnnotationAuthor && (
                                            <AnnotatorTag
                                                secondTag={isPostAuthor}
                                            >
                                                Annotator
                                            </AnnotatorTag>
                                        )}
                                    </AnnotationAuthorName>
                                )}
                                {!annotationAuthor && !hideUserSignature && (
                                    <PlaceholderBox
                                        width={ANNOTATION_AUTHOR_PLACEHOLDER_WIDTH}
                                        height={ANNOTATION_AUTHOR_FONT_MULTIPLIER * BODY_FONT_SIZE}
                                    />
                                )}
                                <AnnotationMetadata
                                    isComment={!!annotation.comment}
                                    isAuthor={isAnnotationAuthor}
                                >
                                    <Timestamp>
                                        {`${moment.utc(annotation.published).fromNow()}${annotation.history.length > 1 ? ' (edited)' : ''}`}
                                    </Timestamp>
                                    {!annotation.comment && (
                                        <AnnotationDetailSeparator>
                                        ·
                                        </AnnotationDetailSeparator>
                                    )}
                                    {!isFocused && !annotation.comment && (
                                        <CommentCountContainer>
                                            <CommentCountIcon>
                                                <ReactSVG
                                                    src={CommentIcon}
                                                />
                                            </CommentCountIcon>
                                            <CommentCountText>
                                                {`${formatNumber(annotationComments.size > 0
                                                    ? sortedComments.filter((comment) => !comment.deleted.deleted).length
                                                    : annotation.comments.length)} ${unreadNotifications && unreadNotifications.length > 0
                                                    ? `(${unreadNotifications.length} new)`
                                                    : ''
                                                }`}
                                            </CommentCountText>
                                        </CommentCountContainer>
                                    )}
                                </AnnotationMetadata>
                            </AnnotationDetails>
                        )}
                    </UserSignature>
                    {(viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min || isFocused || annotation.comment)
                    && (
                        <AnnotationEditorContainer
                            isFocused={isFocused}
                            isEditing={isEditing}
                            isComment={!!annotation.comment}
                            isAuthor={isAnnotationAuthor}
                            marginLength={MORE_BUTTON_X_OFFSET + ANNOTATION_MORE_BUTTON_LENGTH}
                            paddingLeft={isEditing ? 0 : ANNOTATION_EDITOR_CONTAINER_PADDING_LEFT}
                            transitionDuration={0}
                        >
                            <AnnotationEditor
                                shouldFocus={isEditing}
                                readOnly={!isEditing}
                                isEditing={isEditing}
                                width={isEditing
                                    ? bucketWidth
                                    : bucketWidth - ANNOTATION_EDITOR_CONTAINER_PADDING_LEFT}
                                user={user}
                                currentSessionId={currentSessionId}
                                {...(isAnnotationAuthor && !!annotation.comment
                                    ? {
                                        backgroundColor: isEditing
                                            ? themeObj.verascopeColor.purple500
                                            : themeObj.verascopeColor.purple400,
                                        highlightColor: themeObj.verascopeColor.orange200,
                                    }
                                    : {}
                                )}
                                {...(!isAnnotationAuthor && !!annotation.comment
                                    ? { backgroundColor: themeObj.color.neutral300 }
                                    : {}
                                )}
                                isComment={!!annotation.comment}
                                boxShadow={false}
                                isAuthor={isAnnotationAuthor}
                                hasSound={hasSound}
                                postId={annotation.postId}
                                color={DEFAULT_EDITOR_COLOR}
                                fontMultiplier={ANNOTATION_EDITOR_FONT_MULTIPLIER_REGULAR}
                                id={annotation.id}
                                type={EDITOR_CONTEXT_TYPE.annotation} // Used to know which storage bucket to save media
                                message={annotation.history[0]}
                                transcript={annotation.transcripts ? annotation.transcripts[0] : undefined}
                                dictationPlaying={dictationPlaying}
                                timeElapsed={timeElapsed}
                                onCursorEnter={onCursorEnter}
                                onCursorLeave={onCursorLeave}
                                setInputFocused={setInputFocused}
                                setEditor={setAnnotationEditor}
                                setSnackbarData={setSnackbarData}
                                remeasureParentHeight={remeasureParentHeight}
                                editAnnotation={handleEditAnnotation}
                                cancelEditAnnotation={handleCancelEditAnnotation}
                                {...(!annotation.comment ? {
                                    onMouseEnter,
                                    onMouseLeave,
                                } : {}
                                )}
                            />
                            {annotation.dictationFilePaths
                            && annotation.transcripts
                            && audioURL
                            && !isEditing
                            && (
                                <DictationButtonContainer
                                    left={DICTATION_BUTTON_X_OFFSET}
                                    length={DICTATION_BUTTON_LENGTH}
                                    isComment={!!annotation.comment}
                                >
                                    <Button
                                        className={HOVER_TARGET_CLASSNAME}
                                        type={BUTTON_TYPE.secret}
                                        height={DICTATION_BUTTON_LENGTH}
                                        width={DICTATION_BUTTON_LENGTH}
                                        icon={DictationIcon}
                                        onMouseEnter={onButtonMouseEnter}
                                        onMouseLeave={onButtonMouseLeave}
                                        onClick={toggleDictation}
                                    />
                                </DictationButtonContainer>
                            )}
                        </AnnotationEditorContainer>
                    )}
                    {annotationHeight
                    && annotationHeight > MAX_ANNOTATION_HEIGHT // Will never be possible on small viewport
                    && annotationStackActive
                    && (
                        <AnnotationGradient />
                    )}
                    {annotationHeight
                    && annotationHeight > MAX_ANNOTATION_HEIGHT // Will never be possible on small viewport
                    && annotationStackActive
                    && (
                        <TruncatedAnnotationIndicator>
                            <ReactSVG
                                src={MoreIcon}
                            />
                        </TruncatedAnnotationIndicator>
                    )}
                    {annotation.dictationFilePaths
                    && (
                        <AudioElement
                            ref={audioNodeRef}
                            preload="none"
                        >
                            {audioURL
                            && (
                                <AudioSource
                                    src={audioURL}
                                />
                            )}
                            <AudioTrack kind="captions" />
                            Your browser does not support audio.
                        </AudioElement>
                    )}
                </AnnotationBody>
                {isFocused && (
                    annotation.comments.length > 0
                    || sortedComments.length > 0
                ) && (
                    <AnnotationCommentsContainer>
                        {sortedComments.length > 0
                            ? (
                                <>
                                    {sortedComments.map((comment, i) => {
                                        const commentAuthor = commentAuthors.get(comment.userId);
                                        return (
                                            <AnnotationCommentContainer
                                                isAuthor={!!isAnnotationAuthor}
                                                isEditing={editingCommentId === comment.id}
                                            >
                                                {!comment.deleted.deleted
                                                    ? (
                                                        <Annotation
                                                            key={comment.id}
                                                            annotation={comment}
                                                            isAnnotationAuthorComment={comment.userId === annotation.userId}
                                                            hasSound={hasSound}
                                                            user={user || null}
                                                            post={post}
                                                            bucketWidth={bucketWidth - 2 * horizontalMargin - 2 * ANNOTATION_COMMENTS_CONTAINER_VERTICAL_MARGIN}
                                                            currentSessionId={currentSessionId}
                                                            annotationStackActive={annotationStackActive}
                                                            index={index}
                                                            horizontalMargin={0}
                                                            annotationCount={sortedComments.length}
                                                            hide={false}
                                                            hideUserSignature={i + 1 < sortedComments.length
                                                                && sortedComments[i + 1].userId === comment.userId}
                                                            annotationStackExpanded={false}
                                                            bucketHeight={bucketHeight}
                                                            viewportDimensions={viewportDimensions}
                                                            onCursorEnter={onCursorEnter}
                                                            onCursorLeave={onCursorLeave}
                                                            setInputFocused={setInputFocused}
                                                            setTargetAnnotationID={setTargetAnnotationID}
                                                            setCursorSigns={setCursorSigns}
                                                            setSnackbarData={setSnackbarData}
                                                            setRemeasureBucketHeight={setRemeasureBucketHeight}
                                                            clearTimeoutAnnotationNudge={clearTimeoutAnnotationNudge}
                                                            selectedPostValuePath={selectedPostValuePath}
                                                            currentZoomLevel={currentZoomLevel}
                                                            handleEditComment={(id: string | null) => setEditingCommentId(id)}
                                                            setRefreshNotifications={setRefreshNotifications}
                                                        />
                                                    ) : (
                                                        <DeletedCommentContainer
                                                            isAuthor={isAnnotationAuthor}
                                                            marginLength={MORE_BUTTON_X_OFFSET + ANNOTATION_MORE_BUTTON_LENGTH}
                                                        >
                                                            {commentAuthor
                                                                ? `${isAnnotationAuthor
                                                                    ? 'You'
                                                                    : `${commentAuthor.firstName}`
                                                                } deleted a comment`
                                                                : 'User deleted a comment'}
                                                        </DeletedCommentContainer>
                                                    )}
                                            </AnnotationCommentContainer>
                                        );
                                    })}
                                </>
                            ) : (
                                <SpinnerContainer>
                                    <Spinner
                                        lightBackground
                                    />
                                </SpinnerContainer>
                            )}
                    </AnnotationCommentsContainer>
                )}
            </ScrollContainer>
            {isFocused && (
                <AnnotationCommentEditorContainer>
                    <AnnotationEditor
                        id={`annotation-comment-editor-id=${annotation.id}`}
                        user={user}
                        shouldFocus
                        isCommentEditor
                        currentSessionId={currentSessionId}
                        readOnly={false}
                        hasSound={hasSound}
                        postId={annotation.postId}
                        width={bucketWidth - 2 * horizontalMargin - 2 * ANNOTATION_COMMENT_EDITOR_CONTAINER_PADDING}
                        type={EDITOR_CONTEXT_TYPE.annotation} // Used to know which storage bucket to save media
                        color={DEFAULT_EDITOR_COLOR}
                        fontMultiplier={ANNOTATION_EDITOR_FONT_MULTIPLIER_REGULAR}
                        submitAnnotationComment={handleSubmitAnnotationComment}
                        placeholder="Comment..."
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        setInputFocused={setInputFocused}
                        viewportDimensions={viewportDimensions}
                        setEditor={setAnnotationCommentEditor}
                        setSnackbarData={setSnackbarData}
                        setIsUserProfileDialogExpanded={setIsUserProfileDialogExpanded}
                        setNotifyUserToSignUpAfterAnnotationCreationAttempt={setNotifyUserToSignUpAfterAnnotationCreationAttempt}
                    />
                </AnnotationCommentEditorContainer>
            )}
        </Container>
    ), [
        // ===== Props =====
        annotation.history,
        annotation.dictationFilePaths,
        annotation.transcripts,
        annotation.quoteHistory,
        annotation.deleted,
        annotation.comments,
        sortedComments,
        post?.title,
        hasSound,
        bucketWidth,
        zIndex,
        annotationStackActive,
        annotationStackExpanded,
        hide,
        viewportDimensions,
        annotationEditor,
        annotationCommentEditor,
        // ===== State =====
        isEditing,
        editingCommentId,
        annotationHeight,
        annotationAuthor,
        annotationAuthorAvatarURL,
        // postAuthors,
        postAuthorAvatarURLs,
        // priorAnnotationsHeight, // causes rerender whenever new annotation rendered
        isFocused,
        // audioURL,
        dictationPlaying,
        timeElapsed,
        annotationComments,
        commentAuthors,
        // ===== Memoizations =====
        // isAnnotationAuthor,
        // isPostAuthor,
        // hasAuthorAvatarImage,
        DictationIcon,
        // OptionsMenuButtons,
    ]);

    return (
        <VisibilitySensor onChange={handleVisibility}>
            {AnnotationComponent}
        </VisibilitySensor>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    index: number,
    zIndex?: number,
    isFocused: boolean,
    isComment: boolean,
    hide: boolean,
    priorAnnotationsHeight: number,
    annotationStackActive: boolean,
    annotationStackExpanded: boolean,
    stackOffsetIncrementLength: number,
    horizontalMargin: number,
    transitionDuration: number,
    enterDuration: number,
}
const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    top: ${({
        index,
        priorAnnotationsHeight,
        annotationStackActive,
        annotationStackExpanded,
        stackOffsetIncrementLength,
    }) => {
        if (annotationStackActive && !annotationStackExpanded) {
            return `${-priorAnnotationsHeight + index * stackOffsetIncrementLength}px`;
        }

        return '0px';
    }};
    left: 0px;
    width: ${({ horizontalMargin }) => `calc(100% - ${2 * horizontalMargin}px)`};
    height: ${({ isFocused, isComment }) => (isFocused && !isComment
        ? '100%'
        : 'auto'
    )};
    max-width: ${({ isFocused, isComment }) => (isFocused || isComment
        ? 'none'
        : `${31.25 * BODY_FONT_SIZE}px`
    )};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    margin: ${({ horizontalMargin }) => `0px ${horizontalMargin}px`};
    margin-top: ${({
        index,
        isFocused,
        isComment,
        annotationStackActive,
        annotationStackExpanded,
    }) => `${index > 0
        && (
            (
                !annotationStackActive
                && !annotationStackExpanded
                // When focused, the annotation is absolutely positioned to be top of annotation bucket
                && !isFocused
            ) || isComment
        )
        ? 0.625 * BODY_FONT_SIZE
        : 0}px`};
    background: ${({ theme }) => theme.color.white};
    box-shadow: ${({ isComment, theme }) => (isComment
        ? 'none'
        : theme.color.boxShadow100
    )};
    z-index: ${({ zIndex }) => (zIndex || 'auto')};
    pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};
    visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
    transition: ${({
        theme,
        enterDuration,
        transitionDuration,
    }) => `
        top ${transitionDuration}ms ${theme.motion.eagerEasing},
        transform ${enterDuration}ms ${theme.motion.eagerEasing},
        opacity ${enterDuration}ms ${theme.motion.eagerEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing} !important
    `};

    ${({ isComment, theme }) => !isComment && `
        &:hover {
            box-shadow: ${theme.color.boxShadow300};
        }
    `}

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        ${({ isFocused }) => isFocused && `
            box-shadow: none;

            &:hover {
                box-shadow: none;
            }
        `}
    }
`;

interface ScrollContainerProps {
    isEditing: boolean,
}
const ScrollContainer = styled.div<ScrollContainerProps>`
    width: 100%;
    overflow-y: ${({ isEditing }) => (isEditing ? 'visible' : 'scroll')};
`;

interface AnnotationBodyProps {
    isFocused: boolean,
    isComment: boolean,
    isAuthor: boolean,
    isEditing: boolean,
    maxHeight: number,
    annotationStackActive: boolean,
    annotationStackExpanded: boolean,
}
const AnnotationBody = styled.div<AnnotationBodyProps>`
    display: flex;
    flex-direction: ${({ isFocused, isComment }) => (isFocused || isComment
        ? 'column-reverse'
        : 'column'
    )};
    align-items: ${({ isComment, isAuthor }) => {
        if (isComment && isAuthor) {
            return 'flex-end';
        }
        if (isComment) {
            return 'flex-start';
        }
        return 'unset';
    }};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    max-height: ${({
        maxHeight,
        annotationStackActive,
        annotationStackExpanded,
    }) => (annotationStackActive && !annotationStackExpanded ? `${maxHeight + 1.25 * BODY_FONT_SIZE}px` : 'none')};
    overflow-x: ${({ isEditing }) => {
        if (isEditing) return 'visible';
        return 'hidden';
    }};
    overflow-y: ${({ isFocused, isEditing }) => {
        if (isEditing) return 'visible';
        if (isFocused) return 'scroll';
        return 'hidden';
    }};
`;

interface UserSignatureProps {
    isFocused: boolean,
    isComment: boolean,
    isAuthor: boolean,
    hideUserSignature?: boolean,
    padding: number,
    marginLength?: number,
    transitionDuration: number,
}
const UserSignature = styled.div<UserSignatureProps>`
    position: relative;
    display: flex;
    flex-direction: ${({ isComment, isAuthor }) => (isComment && isAuthor
        ? 'row-reverse'
        : 'row'
    )};
    align-items: center;
    margin-left: ${({
        isComment,
        marginLength,
        isAuthor,
        hideUserSignature,
    }) => `${isComment
    && marginLength !== undefined && !isAuthor && hideUserSignature
        ? marginLength
        : 0}px`};
    padding: ${({ padding }) => `${padding}px`};
    padding-bottom: ${`${0.0625 * BODY_FONT_SIZE}px`};
    padding-top: ${({
        isFocused,
        isComment,
        hideUserSignature,
        padding,
    }) => {
        if (isFocused) {
            return '0px';
        }
        if (isComment && hideUserSignature) {
            return `${0.0625 * BODY_FONT_SIZE}px`;
        }

        return `${padding}px`;
    }};
    padding-left: ${({ isComment, isAuthor, padding }) => `${isComment && !isAuthor ? 0 : padding}px`};
    padding-right: ${({ isComment, isAuthor, padding }) => `${isComment && isAuthor ? 0 : padding}px`};
    transition: ${({ transitionDuration, theme }) => `
        padding-top ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding-bottom: ${({ padding }) => `${padding}px`};
    }
`;

interface AnnotationDetailsProps {
    isComment: boolean,
    isAuthor: boolean,
}
const AnnotationDetails = styled.div<AnnotationDetailsProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: ${({ isComment, isAuthor }) => `${isComment && isAuthor ? 'auto' : `${0.5 * BODY_FONT_SIZE}px`}`};
    margin-right: ${({ isComment, isAuthor }) => `${isComment && isAuthor ? `${0.5 * BODY_FONT_SIZE}px` : 'auto'}`};
`;

interface AnnotationMetadataProps {
    isComment: boolean,
    isAuthor: boolean,
}
const AnnotationMetadata = styled.div<AnnotationMetadataProps>`
    display: flex;
    flex-direction: row;
    justify-content: ${({ isComment, isAuthor }) => (isComment && isAuthor ? 'flex-end' : 'flex-start')};
`;

interface AnnotationAuthorNameProps {
    fontMultiplier: number,
}
const AnnotationAuthorName = styled.h4<AnnotationAuthorNameProps>`
    position: relative;
    display: inline-flex;
    flex-direction: row;
    opacity: 0.87;
    margin: 0;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 600;
    color: ${({ theme }) => theme.color.neutral1000};
    line-height: 1.2em;
    text-overflow: ellipsis;
`;

const Timestamp = styled.span`
    display: inline-block;
    font-size: 0.7em;
    line-height: 1.5em;
    color: ${({ theme }) => theme.color.neutral700};
    white-space: nowrap;
`;

const AnnotationDetailSeparator = styled.span`
    margin: ${`0px ${0.15625 * BODY_FONT_SIZE}px`};
    font-size: 0.7em;
    line-height: 1.5em;
    color: ${({ theme }) => theme.color.neutral700};
`;

const CommentCountContainer = styled.span`
    display: flex;
    align-items: center;
    z-index: ${ANNOTATION_EDITOR_Z_INDEX};
`;

const CommentCountIcon = styled.div`
    position: relative;
    top: ${`${0.03125 * BODY_FONT_SIZE}px`};
    height: ${`${1.125 * BODY_FONT_SIZE}px`};
    width: ${`${1.125 * BODY_FONT_SIZE}px`};

    & path {
        fill: ${({ theme }) => theme.color.neutral600};
    }
`;

const CommentCountText = styled.h3`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.7em;
    line-height: 1em;
    color: ${({ theme }) => theme.color.neutral800};
    margin: 0;
    margin-left: ${`${0.15625 * BODY_FONT_SIZE}px`};
`;

interface OptionsMenuContainerProps {
    length: number,
    isComment: boolean,
    isAuthor: boolean,
    xOffset: number,
}
const OptionsMenuContainer = styled.div<OptionsMenuContainerProps>`
    position: absolute;
    top: ${`${0.15625 * BODY_FONT_SIZE}px`};
    right: ${({ isComment, isAuthor, xOffset }) => {
        if (isComment && isAuthor) {
            return 'auto';
        }
        return `${xOffset}px`;
    }};
    left: ${({ isComment, isAuthor, xOffset }) => {
        if (isComment && isAuthor) {
            return `${xOffset}px`;
        }
        return 'auto';
    }};
    background: ${({ theme }) => theme.color.white};
    border-radius: ${({ length }) => `${length / 2}px`};
    z-index: ${({ isComment }) => (!isComment ? ANNOTATION_EDITOR_Z_INDEX + 1 : 'auto')};

    &:hover {
        z-index: ${ANNOTATION_EDITOR_Z_INDEX + 1};
    }

    ${({ isComment, theme }) => !isComment && `
        ${theme.mediaQuery.extraSmall} {
        top: ${`${MODAL_SHEET_CLOSE_BUTTON_CONTAINER_TOP}px`};
        right: ${3.125 * BODY_FONT_SIZE}px;
        background: ${MODAL_SHEET_CLOSE_BUTTON_COLOR};
    }
    `}
`;

const AuthorTag = styled.span`
    background: ${({ theme }) => theme.verascopeColor.purple400};
    border-radius: ${`${0.46875 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.7em;
    color: ${({ theme }) => fontColorDiscriminator(theme.verascopeColor.purple400)};
    line-height: 1em;
    padding: ${`${0.15625 * BODY_FONT_SIZE}px ${0.46875 * BODY_FONT_SIZE}px`};
    margin-left: ${`${0.46875 * BODY_FONT_SIZE}px`};
`;

interface AnnotatorTagProps {
    secondTag: boolean,
}
const AnnotatorTag = styled.span<AnnotatorTagProps>`
    background: ${({ theme }) => theme.verascopeColor.orange200};
    border-radius: ${`${0.46875 * BODY_FONT_SIZE}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.7em;
    color: ${({ theme }) => fontColorDiscriminator(theme.verascopeColor.orange200)};
    line-height: 1em;
    padding: ${`${0.15625 * BODY_FONT_SIZE}px ${0.46875 * BODY_FONT_SIZE}px`};
    margin-left: ${({ secondTag }) => `${secondTag ? 0.3125 * BODY_FONT_SIZE : 0.46875 * BODY_FONT_SIZE}px`};
`;

interface CloseButtonContainerProps {
    buttonHeight: number,
}
const CloseButtonContainer = styled.div<CloseButtonContainerProps>`
    position: absolute;
    top: ${({ buttonHeight }) => `-${buttonHeight + 0.3125 * BODY_FONT_SIZE}px`};
    left: 0px;
    background: ${({ theme }) => theme.color.white};
    border-radius: ${({ buttonHeight }) => `${buttonHeight / 2}px`};
`;

interface QuoteTextProps {
    transitionDuration: number,
}
const QuoteText = styled.p<QuoteTextProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 0.9em;
    line-height: 1em;
    color: ${({ theme }) => theme.color.neutral1000};
    margin: 0;
    margin-bottom: ${`${0.9375 * BODY_FONT_SIZE}px`};
    padding-left: ${`${0.3125 * BODY_FONT_SIZE}px`};
    border-left: ${({ theme }) => `${0.25 * BODY_FONT_SIZE}px solid ${theme.color.neutral700}`};
    transition: ${({ transitionDuration, theme }) => `
        border ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
`;

interface QuoteContainerProps {
    marginTop: number,
    transitionDuration: number,
}
const QuoteContainer = styled.div<QuoteContainerProps>`
    margin: ${`${0.9375 * BODY_FONT_SIZE}px`};
    margin-top: ${({ marginTop }) => `${marginTop}px`};
    margin-bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};
    margin-left: ${`${2.5 * BODY_FONT_SIZE}px`};
    padding: ${`${0.625 * BODY_FONT_SIZE}px`};
    border: ${({ theme }) => `${0.125 * BODY_FONT_SIZE}px solid ${theme.color.neutral300}`};
    border-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    transition: ${({ transitionDuration, theme }) => `
        border ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    &:hover {
        border: ${({ theme }) => `${0.125 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple400}`};

        & > ${QuoteText} {
            border-left: ${({ theme }) => `${0.25 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.purple400}`};
        }
    }
`;

const PostAuthorSignature = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
`;

const PostAuthorDetails = styled.div`
    margin-right: ${`${0.46875 * BODY_FONT_SIZE}px`};
`;

const PostAuthorName = styled.p`
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.8em;
    line-height: 1em;
    color: ${({ theme }) => theme.color.neutral1000};
    margin: 0;
    margin-bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};
    text-align: right;
`;

const PostAuthorPostName = styled.p`
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.7em;
    line-height: 1em;
    color: ${({ theme }) => theme.color.neutral600};
    text-align: right;
    margin: 0;
`;

interface AnnotationEditorContainerProps {
    isFocused: boolean,
    transitionDuration: number,
    paddingLeft: number,
    isComment: boolean,
    isAuthor: boolean,
    isEditing: boolean,
    marginLength?: number,
}
const AnnotationEditorContainer = styled.div<AnnotationEditorContainerProps>`
    position: relative;
    padding-left: ${({ paddingLeft }) => `${paddingLeft}px`};
    margin-left: ${({
        isComment,
        marginLength,
        isAuthor,
        isEditing,
    }) => `${isComment
        && marginLength !== undefined && isAuthor && !isEditing
        ? marginLength
        : 0}px`};
    margin-bottom: ${({ isFocused }) => `${isFocused ? 0.3125 * BODY_FONT_SIZE : 0}px`};
    transition: ${({ transitionDuration, theme }) => `
        padding-top ${transitionDuration}ms ${theme.motion.eagerEasing},
        margin-bottom ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
`;

const TruncatedAnnotationIndicator = styled.div`
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: ${`${0.1875 * BODY_FONT_SIZE}px`};
    height: ${`${1.25 * BODY_FONT_SIZE}px`};
    width: ${`${1.25 * BODY_FONT_SIZE}px`};
    z-index: ${ANNOTATION_EDITOR_Z_INDEX};

    & svg, & div {
        position: relative;
        height: ${`${1.25 * BODY_FONT_SIZE}px`};
        width: ${`${1.25 * BODY_FONT_SIZE}px`};
        margin: 0;
    }

    & path {
        fill: ${({ theme }) => theme.color.neutral800};
    }
`;

const AnnotationGradient = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: ${`${3.125 * BODY_FONT_SIZE}px`};
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0px, rgba(255, 255, 255, 1));
    z-index: ${ANNOTATION_EDITOR_Z_INDEX};
`;

interface DictationButtonContainerProps {
    left: number,
    length: number,
    isComment: boolean,
}
const DictationButtonContainer = styled.div<DictationButtonContainerProps>`
    position: absolute;
    bottom: ${({ isComment }) => `${isComment ? 0.125 * BODY_FONT_SIZE : 0.125 * BODY_FONT_SIZE}px`};
    left: ${({ left }) => `${left}px`};
    background: ${({ theme }) => theme.color.white};
    border-radius: ${({ length }) => `${length / 2}px`};
    z-index: ${ANNOTATION_EDITOR_Z_INDEX};

    & svg {
        fill: ${({ theme }) => theme.color.neutral700};
    }
`;

const AnnotationCommentEditorContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: ${`${ANNOTATION_COMMENT_EDITOR_CONTAINER_PADDING}px`};
    width: 100%;
    background: ${({ theme }) => theme.color.neutral500};
    border-bottom-left-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    border-bottom-right-radius: ${`${0.3125 * BODY_FONT_SIZE}px`};
    z-index: ${ANNOTATION_EDITOR_Z_INDEX};
`;

interface AnnotationCommentContainerProps {
    isAuthor: boolean,
    isEditing: boolean,
}
const AnnotationCommentContainer = styled.div<AnnotationCommentContainerProps>`
    position: relative;
    display: flex;
    justify-content: ${({ isAuthor }) => (isAuthor ? 'flex-end' : 'flex-start')};
    margin-bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};
    z-index: ${({ isEditing }) => (isEditing ? ANNOTATION_EDITOR_Z_INDEX + 1 : 'auto')};
`;

const AnnotationCommentsContainer = styled.div`
    margin: ${`0 ${ANNOTATION_COMMENTS_CONTAINER_VERTICAL_MARGIN}px`};
    margin-top: ${`${0.625 * BODY_FONT_SIZE}px`};
    margin-bottom: ${`${7.5 * BODY_FONT_SIZE}px`};
    border-top: ${({ theme }) => `${0.0625 * BODY_FONT_SIZE}px solid ${theme.color.neutral300}`};
    padding-top: ${`${0.625 * BODY_FONT_SIZE}px`};
`;

const SpinnerContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

interface DeletedCommentContainerProps {
    isAuthor: boolean,
    marginLength: number,
}
const DeletedCommentContainer = styled.div<DeletedCommentContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    font-weight: 400;
    color: ${({ theme }) => theme.color.neutral500};
    line-height: 1.2em;
    margin-left: ${({ isAuthor, marginLength }) => `${!isAuthor ? marginLength : 0}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px ${0.625 * BODY_FONT_SIZE}px`};
    border: ${({ theme }) => `${0.0625 * BODY_FONT_SIZE}px solid ${theme.color.neutral300}`};
    width: max-content;
    height: ${`${1.875 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.9375 * BODY_FONT_SIZE}px`};
`;

const AudioElement = styled.audio``;
const AudioSource = styled.source``;
const AudioTrack = styled.track``;

export default Annotation;

// ===== Packages =====

import styled           from 'styled-components';

// ===== Interfaces =====

import { ICoord }       from '../../interfaces';

// ===== Constants =====

import {
    CARTRIDGE_WIDTH,
    CARTRIDGE_HEIGHT,
    CARTRIDGE_IMAGE_HEIGHT,
    CARTRIDGE_IMAGE_WIDTH,
    CARTRIDGE_TEXT_ANIMATION_CONSTANT,
    CARTRIDGE_DETAIL_CONTAINER_WIDTH,
    CARTRIDGE_DETAIL_CONTAINER_SIDE_PADDING,
    CARTRIDGE_DETAIL_CONTAINER_HEIGHT_SMALL_VIEWPORT,
    TAG_MARGIN,
    BODY_FONT_SIZE,
}                       from '../../constants/generalConstants';
import FONT_TYPE        from '../../constants/fontType';

interface ContainerProps {
    isVisible: boolean,
    isShaking: boolean,
    shakeDuration: number,
    transformDuration: number,
}
export const Container = styled.div<ContainerProps>`
    position: relative;
    display: inline-block;
    width: ${`${CARTRIDGE_WIDTH}px`};
    height: ${`${CARTRIDGE_HEIGHT}px`};
    animation: ${({ isShaking, shakeDuration, theme }) => (isShaking
        ? `shake ${shakeDuration}ms ${theme.motion.eagerEasing} infinite`
        : 'none'
    )};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    cursor: none;

    ${({ theme }) => theme.mediaQuery.small} {
        height: ${CARTRIDGE_HEIGHT + CARTRIDGE_DETAIL_CONTAINER_HEIGHT_SMALL_VIEWPORT}px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        height: ${CARTRIDGE_HEIGHT + CARTRIDGE_DETAIL_CONTAINER_HEIGHT_SMALL_VIEWPORT}px;
    }
`;

export const CartridgeImageClippingMask = styled.div`
    position: absolute;
    top: ${`${3.75 * BODY_FONT_SIZE}px`};
    left: ${`${1.125 * BODY_FONT_SIZE}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${`${CARTRIDGE_IMAGE_WIDTH}px`};
    height: ${`${CARTRIDGE_IMAGE_HEIGHT}px`};
    overflow: hidden;
`;

interface CartridgeImageProps {
    url?: string,
    color?: string,
    isVisible: boolean,
    transitionDuration: number,
}
export const CartridgeImage = styled.img<CartridgeImageProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    user-select: none;
    user-drag: none;
    transition: ${({
        theme,
        transitionDuration,
    }) => `
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
`;

export const CartridgeFace = styled.img`
    position: relative;
    width: ${`${CARTRIDGE_WIDTH}px`};
    height: ${`${CARTRIDGE_HEIGHT}px`};
    z-index: 1;
    user-select: none;
    user-drag: none;
`;

interface CartridgeDetailContainerProps {
    isVisible: boolean,
    positionOffset: ICoord,
    transitionDuration: number,
}
export const CartridgeDetailContainer = styled.div<CartridgeDetailContainerProps>`
    position: absolute;
    left: 50%;
    transform: ${({ isVisible, positionOffset }) => (isVisible
        ? `translate(calc(-50% + ${positionOffset.x}px), calc(0px + ${positionOffset.y}px))`
        : `translate(calc(-50% + ${positionOffset.x}px), calc(-10% + ${positionOffset.y}px))`
    )};
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    width: ${`${CARTRIDGE_DETAIL_CONTAINER_WIDTH}px`};
    padding: ${`15px ${CARTRIDGE_DETAIL_CONTAINER_SIDE_PADDING}px`};
    padding-bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};
    border-radius: ${`${0.625 * BODY_FONT_SIZE}px`};
    background: ${({ theme }) => theme.verascopeColor.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    margin-top: 0px;
    overflow: hidden;
    transition: ${({
        theme,
        transitionDuration,
    }) => `
        transform ${transitionDuration}ms ${theme.motion.eagerEasing},
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    cursor: none;

    ${({ theme }) => theme.mediaQuery.small} {
        width: 100%;
        height: ${CARTRIDGE_DETAIL_CONTAINER_HEIGHT_SMALL_VIEWPORT}px;
        padding: ${`${0.625 * BODY_FONT_SIZE}px ${CARTRIDGE_DETAIL_CONTAINER_SIDE_PADDING}px`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: 100%;
        height: ${CARTRIDGE_DETAIL_CONTAINER_HEIGHT_SMALL_VIEWPORT}px;
        padding: ${`${0.625 * BODY_FONT_SIZE}px ${CARTRIDGE_DETAIL_CONTAINER_SIDE_PADDING}px`};
    }
`;

interface CartridgeTypeProps {
    fontMultiplier: number,
}
export const CartridgeType = styled.p<CartridgeTypeProps>`
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    color: #454F57;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    margin-bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};
`;

interface CartridgeTitleProps {
    scroll: boolean,
    width: number | null,
    fontMultiplier: number,
}
export const CartridgeTitle = styled.h3<CartridgeTitleProps>`
    position: relative;
    display: inline-block;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 1.1em;
    text-align: center;
    color: ${({ theme }) => theme.color.neutral900};
    white-space: nowrap;
    margin: 0 auto;
    margin-bottom: ${`${0.3125 * BODY_FONT_SIZE}px`};
    animation: ${({ scroll, width }) => (scroll && width
        ? `scrollTextLeft ${Math.max(CARTRIDGE_TEXT_ANIMATION_CONSTANT * width, 4)}s linear infinite`
        : 'none'
    )};

    ${({ theme }) => theme.mediaQuery.small} {
        font-weight: 700;
        font-size: 1em;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-weight: 700;
        font-size: 1em;
    }
`;

interface CartridgeAuthorsProps {
    fontMultiplier: number,
}
export const CartridgeAuthors = styled.h4<CartridgeAuthorsProps>`
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 0.875em;
    color: ${({ theme }) => theme.color.neutral700};
    text-align: center;
    margin: 0;
    margin-bottom: ${`${0.625 * BODY_FONT_SIZE}px`};
`;

export const TagsContainer = styled.div`
    position: relative;
    text-align: center;
    background: inherit;

    & a {
        background: inherit;
    }

    ${({ theme }) => theme.mediaQuery.small} {
        text-align: initial;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        text-align: initial;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`;

export const TitleGradient = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: ${`${CARTRIDGE_DETAIL_CONTAINER_WIDTH}px`};
    height: 1.5em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 60px, rgba(255, 255, 255, 1));
    z-index: 1;
`;

export const CartridgeTitleContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: ${`${CARTRIDGE_DETAIL_CONTAINER_WIDTH}px`};
    padding: ${`0px ${CARTRIDGE_DETAIL_CONTAINER_SIDE_PADDING}px`};
`;

export const MoreTagsText = styled.span`
    display: inline-block;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.65em;
    color: ${({ theme }) => theme.color.neutral700};
    white-space: nowrap;
    margin-top: ${TAG_MARGIN / 2}px;
`;

// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                               from 'react';
import styled                                   from 'styled-components';
import { useSelected }                          from 'slate-react';

// ===== Enums =====

import {
    ZOOM_LEVEL,
}                                               from '../elements/enums';
import {
    EDITOR_CONTEXT_TYPE,
}                                               from '../../../enums';

// ===== Interfaces =====

import {
    IZoomLevelItem,
}                                               from '../../../interfaces';

// ====- General Constants =====

import {
    BODY_FONT_SIZE,
}                                               from '../../../constants/generalConstants';

BottomBuffer.defaultProps = {
    currentZoomLevel: null,
};
interface Props {
    readOnly: boolean,
    height: number,
    editorType: EDITOR_CONTEXT_TYPE,
    adjustSelectionAroundBottomBuffer: () => void,
    currentZoomLevel?: IZoomLevelItem | null,
    children: React.ReactElement,
    attributes: any,
}
function BottomBuffer({
    readOnly,
    height,
    editorType,
    children,
    attributes,
    currentZoomLevel,
    adjustSelectionAroundBottomBuffer,
}: Props): JSX.Element {
    // ===== State =====

    const selected = useSelected();
    const [currentBufferHeight, setCurrentBufferHeight] = useState<number>(height);

    // ===== Side Effects =====

    useEffect(() => {
        if (selected) {
            adjustSelectionAroundBottomBuffer();
        }
    }, [selected]);

    useEffect(() => {
        if (
            currentZoomLevel
            && currentZoomLevel.level !== ZOOM_LEVEL.five
            && currentBufferHeight !== 0
        ) {
            setCurrentBufferHeight(0);
        } else if (
            currentZoomLevel
            && currentZoomLevel.level === ZOOM_LEVEL.five
            && currentBufferHeight !== height
        ) {
            setCurrentBufferHeight(height);
        }
    }, [currentZoomLevel]);

    return (
        <Container
            {...attributes}
            height={currentBufferHeight}
            // Helps to tell two different post values apart
            // Aids not wrapping zoom levels across values
            withBorder={
                !readOnly
                && editorType === EDITOR_CONTEXT_TYPE.post
                && currentZoomLevel
                && currentZoomLevel.level !== ZOOM_LEVEL.five
            }
            contentEditable={false}
        >
            {children}
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    height: number,
    withBorder: boolean,
}
const Container = styled.div<ContainerProps>`
    width: 100%;
    height: ${({ height }) => `${height}px`};
    margin: 0;
    padding: 0;
    border-top: ${({ withBorder, theme }) => (withBorder
        ? `${0.09375 * BODY_FONT_SIZE}px solid ${theme.verascopeColor.red200}`
        : 'none')};
`;

export default BottomBuffer;

const ID_TO_AUTHOR = new Map<string, string>([
    ['0076eb63-cf33-4653-8afe-a3ca74332727', 'tyler-the-creator'],
    ['00e18727-bff2-42d3-906a-37fa4aff67e5', 'ray-eames'],
    ['0115b018-ac4f-4702-a875-072c8cd1ab05', 'pete-hunt'],
    ['0134f71a-0ea5-4794-ae28-48c0b2588506', 'tom-occhino'],
    ['015c8eb3-3e7e-4a39-bee9-4e84c67dc4ef', 'gdc'],
    ['02726d0b-544c-4ca4-ad75-97556f981f1f', 'h-b-griffiths'],
    ['02935117-0a52-4428-84a2-cfa3d92377af', 'tame-impala'],
    ['0619c97f-ad71-46b4-bd6f-49fc50ae3b00', 'david-kirsh'],
    ['063188cc-2244-4027-a434-acf5f8d6ac73', 'steven-a-wasserman'],
    ['0643e049-d612-4b58-a63b-63d4634361cb', 'michael-chan'],
    ['069b6c75-1b34-4ef9-9e40-bd5520988216', 'matthew-oldridge'],
    ['0744326f-9e21-4dd7-94d5-9abfb485e28e', 'james-blake'],
    ['082b4def-dbe5-4005-a397-844829c0bf1d', 'david-wolpert'],
    ['08f1629a-78e1-470c-b013-5945f7400e18', 'playful-corp'],
    ['08fdbfd1-0a38-45d8-a6c9-f41ea22be271', 'mc-escher'],
    ['09162c9e-a3db-427c-8d7f-5afa01419a8b', 'john-palmer'],
    ['09943c0c-1ea0-41ea-a163-6cfcc4900751', 'kevin-hartnett'],
    ['09a786f2-9681-4ac2-b806-1b0903f353b7', 'scarlett-johansson'],
    ['0a0ec700-ee45-4d64-ac1d-c4913bb4b8c9', 'ryan-lucas'],
    ['0ac70aeb-e70f-4a81-9b8f-365a0c76b458', 'c-thi-nguyen'],
    ['0ad18c8a-6e8e-4383-9e5d-2a4a3a3c29a5', 'yann-lecun'],
    ['0c7e8924-f688-49fc-8095-ecbec64236d6', 'graham-farmelo'],
    ['0d575dde-c839-450c-9e83-f60032067394', 'paul-lockhart'],
    ['0dbc8c29-7b52-4473-b52d-070b427c0bfa', 'long-now'],
    ['10c1ed00-4bcb-4efd-b8e4-1249ede9ca67', 'joseph-j-laviola-jr'],
    ['11eaf139-b376-41ff-bd9c-7dcd37e2d5a0', 'david-nolen'],
    ['14045c6a-828c-453d-9c23-7afa929cd1f5', 'qri'],
    ['14864877-69af-4b18-ac7d-364d6d4deb01', 'grant-sanderson'],
    ['1512d673-730a-477a-9dc3-f4206b3dcfbe', 'recording-academy'],
    ['16c969bf-7e68-49c7-80a5-62c93f5187eb', 'eric-starr'],
    ['16f06f94-a829-40c9-ab30-2a3497170f49', 'balaji-srinivasan'],
    ['17082027-1e12-4c3a-b111-87b5cb409c6c', 'albert-einstein'],
    ['17e609c6-79f0-41e6-97d3-e6763333758b', 'kritina-holden'],
    ['1df8f728-4557-43be-a3a7-e4011a08d680', 'matt-nava'],
    ['1f80ae8f-e5ea-446c-a8ca-997a9004a375', 'accelerating-change'],
    ['1ff1f84c-c481-464f-b5e3-b82f3a549642', 'flatlife'],
    ['208153c7-1192-4d8c-801f-926b2a9746cc', 'noam-wasserman'],
    ['216e03b5-8cc9-4b4d-ba78-6f22c6909f05', 'bret-victor'],
    ['22900070-b1d5-4b12-91cb-64e8b7de5b54', 'kirill-grouchnikov'],
    ['2396a609-3ba9-4804-9ecb-29f7041bf9f2', 'elon-musk'],
    ['23ea5471-825a-461a-92cb-94f487ca654e', 'kendrick-lamar'],
    ['250458a2-8596-465e-8e1f-dc84d67b4957', 'jonathan-blow'],
    ['2702e1ca-b970-4cba-bf1d-f76365deb5a1', 'chris-fields'],
    ['273e69cd-2536-4931-be37-97f175ba8c11', 'seymour -papert'],
    ['27c9f7b4-8efe-4f53-86fa-13484c5584b8', 'isaac-chuang'],
    ['2965b47e-1b5b-44a3-8f6d-58a6e312b994', 'valve'],
    ['2984f10a-1e84-43de-a458-9b86f5f43973', 'retool'],
    ['2a1dda04-844d-49cd-b3cd-99d058895f41', 'geoff-mcfetridge'],
    ['2a4aa42e-003d-4a53-a3e2-1388e37197de', 'dave-marvit'],
    ['2a8b738b-b4bf-469b-8c50-d9b2dcd3a403', 'beat-suter'],
    ['2cf5a5eb-080d-49f8-b7ba-ea12cd2c1f06', 'techcrunch'],
    ['2ddf920a-8526-49a5-ae1c-125a5915e734', 'jane-reece'],
    ['2e74dbe7-42c0-45e1-83eb-b8c8ce01b92e', 'rene-bauer'],
    ['2e9fc715-4bae-4657-b24e-b8ecee3868b7', 'pharrell'],
    ['2ed398b2-fd62-4471-a6f4-127b8aeba7cc', 'helios-aliaga'],
    ['308e67fd-58ad-4deb-8b3e-a47daa15de3b', 'rick-rubin'],
    ['3098b8a1-5253-4e3f-a323-f0c02304d65f', 'ian-hubert'],
    ['309df1c8-5f16-4d74-b9ca-ee98f47dba8d', 'doug-a-bowman'],
    ['31915e21-f242-4a89-bbd3-2e430de2c41d', 'welcome-to-the-jungle'],
    ['320be395-804d-47c1-8ee8-113e47c4401a', 'baukunst'],
    ['333be2ea-e5dc-476e-8207-7e04c61f2cb7', 'gq'],
    ['3451263a-2773-417f-9078-4f2e5bd9fc3d', 'richard-a-bartle'],
    ['34bb9892-47be-43b8-a5d9-c3960b5b92c8', 'dieter-rams'],
    ['34c3b08f-1381-47ec-9b55-e2bd48847e54', 'andrew-hinton'],
    ['351c2ded-60e1-4f32-9aa1-a8fd12fd7dc3', 'kurt-jaimungal'],
    ['377a2f1d-e35c-4ed8-b354-c7ad747c0389', 'michael-artin'],
    ['37f892f5-5855-4e4c-b408-8cdaa0ddf82f', 'nomada-studio'],
    ['3887d103-ca03-4818-b7c3-3bc2cd14749c', 'bruno-munari'],
    ['38dd8f27-2d20-43b3-ba26-f669798c9711', 'gameswithgabe'],
    ['3b4e4734-d57f-4bbd-861b-bd4094e87a72', 'bliss-foster'],
    ['3bd4464d-c6ef-48d6-8606-6907b6ce77a2', 'machine-learning-street-talk'],
    ['3c09ec3e-dc63-4a01-b38e-1cb939408329', 'adrian-cuevas'],
    ['3c3e8579-ed4e-4175-b4ca-cc50be2fb40f', 'hot-97'],
    ['3c8655a2-72a9-4e02-9314-76b8b42de820', 'tony-fadell'],
    ['3c992773-ae47-4c98-9a6d-6dc34435832f', 'eric-smith'],
    ['3d7a5b36-9d9b-44ea-abd0-ad4d34018db7', 'ernst-kruijff'],
    ['3dc3b501-cc15-4fd5-8ee4-2e5e6c5a7b53', 'ben-felderstein'],
    ['3ee88bc2-3933-47cf-a017-a97d3fb104b9', 'burnie-burns'],
    ['41b1d58c-fbb8-4464-bfdd-27b3dc7cf5a4', 'michael-levin'],
    ['41c3b897-42af-49fc-8def-27ea00c721b8', 'coca-cola'],
    ['41f53fa6-6b6e-447a-a40b-6b175239dd59', 'jill-butler'],
    ['42805056-7ad5-4c75-a94f-ff11a990a96a', 'dmitri-tymoczko'],
    ['42fc9939-7d75-4352-8e19-5f6a756efa00', 'Donald Glover'],
    ['45d82171-5835-4152-b04e-f788e0577fd4', 'james-r-munkres'],
    ['46ac52d4-1293-43de-9ac1-894df19d09be', 'christoph-niemann'],
    ['4724cb1d-f6a4-40c6-b1d1-224b0f0d190e', 'mary-kalantzis'],
    ['4940de22-346d-4c4a-8862-c772c2295607', 'todd-howard'],
    ['4975ecd5-c5f6-44c4-bb94-3dbc027ea0f6', 'mobius-digital'],
    ['4a7c7c1b-d97f-4f6b-a1b4-15b87bed8b93', 'tony-casparro'],
    ['4bce1ede-cf44-4ec8-b9fe-95e6f03b44c9', 'h-graham-flegg'],
    ['4bfdfe5a-d4b8-4d1a-bc04-a281d408552b', 'paul-dirac'],
    ['4da45779-003d-4112-a48a-a02ff2d978af', 'ieee'],
    ['4eecd852-a624-4b4a-9215-c738c1ca61b1', 'extremely-ok-games-ltd'],
    ['4f5cf196-4df5-4711-9790-e66879742349', 'the-mathematical-association-of-america'],
    ['50ae8b30-e79c-44ff-9f16-a7f7b0913d0f', 'stewart-brand'],
    ['559dba2a-c4e2-4c66-a07f-bd3dbdbe610a', 'shane-osullivan'],
    ['56322710-da48-4f8f-847e-3b8f60b19284', 'daniel-kwan'],
    ['563c6fbc-2c2f-458a-94c8-af82284e8f3f', 'asher-isbrucker'],
    ['56e4279c-8310-4784-b7b4-5efe058302d5', 'scientific-american'],
    ['57711043-41e0-403e-a8c0-d1a31e26a595', 'edward-tufte'],
    ['585052ca-912e-4d40-bf78-21d1f175611a', 'max-kreminski'],
    ['58c7c4a5-54a0-462e-bdb1-debcef004dd7', 'balenciaga'],
    ['597d7eba-4192-4c54-8ed4-db3239ff79a6', 'takuhiro-dohta'],
    ['597f0cc6-3fb6-491e-add7-d401ba806255', 'nigel-orme'],
    ['5a87f522-5b2f-4e83-a920-b4db98948997', 'thomas-flight'],
    ['5b2d5a04-4d6d-4f23-8fa3-9479e74a4273', 'ryan-p-mcmahan'],
    ['5d42230a-b2f6-45e1-8362-844333eca0a2', 'sap'],
    ['5dbb624e-60a4-45c3-ac4a-786a0c37fdb3', 'supergiant-games'],
    ['5dd5df08-c978-428e-8142-ec32f1d3e59c', 'jordan-walke'],
    ['61556199-a221-44c8-b264-0e61b7c78dda', 'nathan-carter'],
    ['61adcde6-02f8-4459-b936-b981dc5eefa3', 'jason-yuan'],
    ['626ad6ed-6f90-432a-aae0-f49566749b6b', 'marc-ten-bosch'],
    ['62865fa9-43ff-4873-ace2-cbe6f2d9358b', 'darryn-king'],
    ['62b767f1-a7b2-400b-946d-811af251ecc4', 'complex'],
    ['6329127d-00d3-4cfe-b51f-86d3c63b59cb', 'julian-bigelow'],
    ['642e4168-95bf-443c-9370-3395e8191a59', 'the-network-state'],
    ['64516dd2-4793-4c1c-a784-43ba725f136a', 'bianca-censori'],
    ['658571b0-8410-4d9f-b4aa-b0269fbdffd8', 'american-scientist'],
    ['6719b97f-fa94-4018-bf18-e4df77cd9d70', 'apple'],
    ['67f864e2-81de-4ed3-a10e-a17ef9dfecff', 'moon-studios-gmbh'],
    ['696743e3-8e17-40dc-9e80-90fcbd55c916', 'andre-3000'],
    ['69d66f02-e55b-4b66-ab1e-3c880229656a', 'ted'],
    ['6a440db3-f471-4eea-a08a-5ddae7061093', 'joaquin-pheonix'],
    ['6b5bb734-45a7-4e89-a9ac-df58d7a53350', 'jesse-schell'],
    ['6ba76d87-8746-4de2-9e07-67d4179b496d', 'bill-cope'],
    ['6bf7a766-adb6-4971-82ae-1abd6d828d21', 'marshall-mcluhan'],
    ['6ca53536-e25d-470e-821c-e37f3bec7b76', 'computer-history-museum'],
    ['6d14971f-2b52-42d4-aef4-868f81668266', 'sean-carroll'],
    ['6d253a64-2a3e-46d1-8afd-94a302bda55a', 'melvyn-bragg'],
    ['6d548934-0f22-4502-b8bf-f827849cbbbb', 'issara-willenskomer'],
    ['6dbc844e-082f-4248-81d4-9421ba593f13', 'robert-dorit'],
    ['6e2f50f9-469f-40af-8b9e-37698763ae6e', 'erik-d-kennedy'],
    ['6ea01ae8-0577-4f5c-b71b-7c39d68212d6', 'noclip-video-game-documentaries'],
    ['6eb34da5-2565-44b4-b5b9-ceda6773570c', 'hans-zimmer'],
    ['6f7fddbd-654d-487b-8129-1e863528aff9', 'philip-glass'],
    ['7189e8bc-7226-4989-9202-4e8b1052bf8a', 'rob-phillips'],
    ['71abbc66-f153-4090-a0f2-cc58fd43f51f', 'adobe'],
    ['71c05f9a-eccc-423e-b0da-a27bc6969a33', 'agatha-yu'],
    ['71c37380-9bb6-4cdc-a83e-a5185768d9fe', 'wesley-p-clawson'],
    ['725e37e0-aaa0-46b0-99b6-15f86581bd35', 'andrew-clark'],
    ['72659811-28a8-4732-8ffd-2270b26c4de2', 'david-mccandless'],
    ['72b7b9da-6dd1-4f67-91a1-9cc68e48e20d', 'emily-riehl'],
    ['72bbb73c-431c-4eb9-b7cd-928daf429369', 'walter-isaacson'],
    ['73661618-d16e-4a2e-a807-ef032b000081', 'jennifer-hahn'],
    ['741c5e31-62ff-4af9-b4d2-e630849d64ba', 'semf'],
    ['74a9614c-ff90-418b-a29b-5e1222e75808', 'douglas-engelbart'],
    ['75987de9-cffc-4ba0-92b4-1614b6550c44', 'carlo-rovelli'],
    ['77b24104-0fee-4255-9fb3-a18c26e17d25', 'sebastian-markbage'],
    ['77c1b711-7d76-4cb6-a362-0eb78da5658e', 'kaz-nejatian'],
    ['78fadcbd-98df-415b-87ed-a67d119033c5', 'luke-igel'],
    ['7b0b9006-df32-4b61-bc3a-95f48c98543a', 'christopher-alexander'],
    ['7b36bffb-b490-4155-b565-a0c7cd9719c1', 'steven-smith'],
    ['7bc1a060-3b37-47d7-81cf-f22b863465dc', 'playarc'],
    ['7d6ebeba-390e-476b-bf02-338ebc109859', 'verizon'],
    ['7e6d26db-d0a4-4e58-8a94-f476acd73f8f', 'elizabeth-segran'],
    ['7f79d870-0acf-4994-a382-80a72d7f97cb', 'peter-v-minorsky'],
    ['7fe02b0b-f558-492a-8535-4758dd87cd75', 'anastasia-bendebury'],
    ['80891144-cf02-4a09-a1d4-1ab33dada84d', 'the-no-frauds-club'],
    ['80e59c63-1587-47f1-8cc8-b869ea479597', 'robert-alexander'],
    ['81cbb881-794e-4afe-afed-9a078d5c014b', 'john-markoff'],
    ['823cd542-aa3b-4223-bfe0-71fc658d5a19', 'john-von-neumann'],
    ['824a3db0-59a2-429b-bc62-549b6a1d71a1', 'wallpaper'],
    ['83485076-e7a2-44b0-b37b-505c2880b560', 'kid-cudi'],
    ['838d5b7f-f533-492a-ab58-951272bf34d4', 'epic-conversations'],
    ['840c16d7-6cec-4ca9-8b4b-a19cc72afe24', 'julian-warner'],
    ['84bde8b8-188b-4094-b2bc-e9f0fcd8b019', 'baidu'],
    ['84f3d0fa-6058-44a4-a288-1166c7f909c5', 'lupe-fiasco'],
    ['855a538d-96f2-46cd-a9bc-db53d8e0bd24', 'douglas-engelbart-institute'],
    ['86c4751f-27e0-4cc1-9552-e4bf7c2d0da7', 'fast-company'],
    ['89ef18c6-1e82-4f0d-bb51-ec649b246c00', 'karl-friston'],
    ['8d00a2a2-0953-4039-85fe-5ac524f6a7ae', 'william-july'],
    ['8d6062b9-4b91-43be-8996-7d5973a3decd', 'demis-hassabis'],
    ['8e58593a-c523-4e95-a2a6-14a18ca46db8', 'peter-dodds'],
    ['906fe730-a2b2-4012-845c-8ceb0b1eb7f9', 'vectorpark'],
    ['9099024b-a7f2-4e56-8394-e18edd430645', 'graham-white'],
    ['909aa261-c2a6-49b5-8d34-f4a0505fc3cb', 'charles-eames'],
    ['914b5abb-9ce5-47ea-8832-9b9106f08e51', 'morris-kline'],
    ['9266a3d0-c15c-4b13-83ed-c18479f4bb7c', 'david-eberly'],
    ['936790c8-a925-4013-9a93-585066a9e01f', 'kevin-abstract'],
    ['951c06a6-236f-4d6a-bddf-276b61305999', 'thekla-inc'],
    ['95b63039-e87c-4f2c-8794-9dd77d6710b9', 'markus-persson'],
    ['967e4ff1-ce05-4e53-ab26-fcee6e8e32aa', 'kara-swisher'],
    ['96d262eb-bf8d-41dc-aa91-ba69a30c3014', 'peter-de-bourcier'],
    ['96d42347-db23-462b-ad17-3828ab2a5f87', 'nicolas-oueijan'],
    ['96d4305f-54f8-40b4-9bd9-ce1f8c2ee202', 'ustwo-games'],
    ['97b02cb1-c24f-452b-9cc0-2f808e931b67', 'jorgen-veisdal'],
    ['9883f01f-a364-4e77-9b31-af4eb6014958', 'cantors-paradise'],
    ['9a30e2c2-acc2-4d69-aaad-d72772d63146', 'spike-jonze'],
    ['9a596259-a233-43fc-8eef-6204d0de1777', 'satoru-takizawa'],
    ['9b91651d-fcf6-4e1a-be2f-31433926eff2', 'the-new-york-times'],
    ['9d2fa3f9-39c0-448a-9b0e-513af5d811e8', 'martijn-heck'],
    ['9d452cec-d8c3-4ce5-a9be-56a896fd50ae', 'the-aspen-institute'],
    ['9da2e810-4148-4457-b506-6a17ef23ac24', 'augmented-reality-expo'],
    ['9e0830ba-15a0-487b-b71b-5607130b86e0', 'paul-levering'],
    ['9e30db0d-d311-4ce6-971d-a2a269310c14', 'dmitri-mendeleev'],
    ['9e8ee57d-8b66-427e-9dce-dcb01fc6c5b2', 'curt-jaimungal'],
    ['9eb3ad9b-1e79-4baf-aa55-a2d5fd27d140', 'es-devlin'],
    ['9f315d73-576e-45b1-901a-44f20cba50ba', 'oki-sato'],
    ['9ff91ede-6dce-4c87-953a-2478d51f4f0e', 'science-news'],
    ['a3b9612b-ae27-498e-a7e9-506a58d276bd', 'the-royal-society'],
    ['a40187c4-6edc-426d-b3f7-73942f2c655f', 'hidemaro-fujibayashi'],
    ['a47c8758-2640-4db5-ac90-f16f2a4bf009', 'mela-kocher'],
    ['a4b99ea7-1c64-43b9-8b32-5d4e910eda5a', 'christopher-j-phillips'],
    ['a5a3c267-30ab-4831-832c-88ad98717357', 'norbert-wiener'],
    ['a62f5678-1bd0-443f-805e-765702701301', 'the-royal-institution'],
    ['a78e458d-8447-488b-90a8-830db9ea5e80', 'rick-beato'],
    ['a853164c-96c5-4528-8305-bf0a28b61ea8', 'matthew-panzarino'],
    ['a86f6c3a-bb4f-4b55-9fbf-ec536b0ef63b', 'npr'],
    ['a8f867f9-fce3-46a7-9c33-196665940bf8', 'taschen'],
    ['a9068501-ef82-4bf5-93a8-6deee0314ca1', 'jonathan-bell'],
    ['aa8c8408-7e09-461a-aaa9-b5345008ba63', 'walter-ong'],
    ['abae00df-64cb-4291-a45a-9e83841d5208', 'john-wentworth'],
    ['abc4681f-47f1-4b46-abd2-67990206cf0a', 'playdead'],
    ['ac0117d2-7180-48b8-b756-274909b9acea', 'jacob-collier'],
    ['ae42f004-f5e7-4cce-99a6-816916762413', 'steven-lehar'],
    ['aeadca45-7a5a-4c64-b6f4-948659e18a0d', 'arturo-rosenblueth'],
    ['aed07629-ed27-4003-b6c7-47dfaced1c85', 'jasper-pike'],
    ['b03658b7-ab96-4e3e-ab55-f3d2a7351175', 'richard-p-feynman'],
    ['b2e3b293-cca0-40f4-8bdb-a893725c29a3', 'lisa-a-urry'],
    ['b3605b63-dc8f-4a1f-84c4-7d1c0b9c7131', 'gary-hustwit'],
    ['b378448b-99ad-442e-b4f4-4f4ccf169ea5', 'netflix'],
    ['b3a13db2-7941-4eac-918f-cd217ba191a3', 'adam-zachary-wasserman'],
    ['b3c3be19-a557-447c-a7bc-e21483e180f9', 'dpad-studio'],
    ['b4f568ad-9ba4-492b-a6d7-a1170c13e4f6', 'bjarke-ingels'],
    ['b5136c90-d1c5-43ab-a8c6-2c695347857f', 'codeparade'],
    ['b52baa26-9c25-4258-860a-4f84e2456176', 'broken-record'],
    ['b5d53eb2-2cd3-4000-a196-884ef4250782', 'dan-abramov'],
    ['b5ee613d-a9c5-4592-8848-f60a207dc70f', 'jessie-reyez'],
    ['b61036d5-3100-469d-b89f-81b7a5b2997c', 'tom-siegfried'],
    ['b64abeed-8494-4051-8a10-f60ddf74aa85', 'frank-wilczek'],
    ['b7a11028-9691-4ce2-a08e-3e20c812934c', 'ron-milo'],
    ['b90c33f7-afb6-4777-97d7-c58d12f2001b', 'warren-weaver'],
    ['bac58383-f08a-4290-9e52-c80792be0742', 'kanye-west'],
    ['bb118c18-4dad-4bcd-bc9e-371078ebd978', 'steven-pinker'],
    ['bb2021c3-409f-4f21-8a22-cab07e079fa6', 'native-instruments'],
    ['bca78782-b6f7-445d-88dd-39c144bb1546', '9th-wonder'],
    ['bcbb2112-a673-4c43-8b62-9f8224472020', '2-player-productions'],
    ['bead4e4c-3134-4cf5-8421-249c3b21e833', 'richard-hamming'],
    ['c1215cc2-2f38-405c-bd89-0a5ea0bb7045', 'shaun-raviv'],
    ['c1bcfb6b-0358-4293-856e-95d750ddd1d0', 'virgil-abloh'],
    ['c21a48a1-e85b-4f8c-acb0-af863f43ce6d', 'honeypot'],
    ['c29f1e4c-f2f2-4ad5-8b93-57573e29b953', 'mark-wilson'],
    ['c5380eb1-a6da-40cb-8226-95d65c8be18f', 'william-lidwell'],
    ['c65e3762-71bc-4e99-af76-61034ceaac23', 'ars-technica'],
    ['c764a9a4-b9a7-4829-955b-a13fe340b11e', 'claude-shannon'],
    ['c7a146cc-2b3b-4448-9538-789ee37301e6', 'lex-fridman'],
    ['c8d8e633-7176-4fbf-867c-640c94356812', 'dan-davis'],
    ['c9135456-1fc9-4258-80dd-2d51a138d551', 'david-berman'],
    ['c940c802-e8e9-4c11-988c-391cc1fa1ed8', 'demna-gvasalia'],
    ['c957a627-abcf-4d59-88c6-99fb577160fb', 'walt-mossberg'],
    ['ca4c301a-d8db-4eaf-bf3c-9c94c14bacb9', 'wired'],
    ['cad1b73c-e910-47f0-a04a-b07b9d9f6ce4', 'playabl-studios'],
    ['caedb93c-0f07-44b7-b6cd-1b74b676668b', 'bbc-radio-4'],
    ['cbc13bcf-9f2a-4e83-a5ff-30e75efb54d2', 'hempuli-oy'],
    ['cc10a6f2-0767-41df-87dc-0199add08bad', 'khan-academy'],
    ['cc225887-48d6-46f6-b5b5-28dca422d937', 'raul-gutierrez'],
    ['cdd4e6d6-03f7-4166-89d5-bca1c9ff8927', 'valerie-gibson'],
    ['cde33484-df25-4e2f-a042-6464b927cb31', 'michael-l-cain'],
    ['ce4271cf-9f54-4739-b0ea-93cb6404bdd1', 'ken-wong'],
    ['ce63b860-f8c1-4830-a32e-ac20e7e9f0a4', 'less-wrong'],
    ['cec7fad9-5a09-4f3d-a6ed-0ad6b1740a65', 'joshua-bongard'],
    ['cfb87b0d-ecb1-4d5a-b1c2-226aed5427df', 'stephen-wendel'],
    ['d007ad88-d5d5-4bac-8bb4-88f6f80042dc', 'quanta-magazine'],
    ['d0260866-be03-4d65-a541-9153e5acab49', 'childish-gambino'],
    ['d132fad4-a54e-4e63-83e6-be356f942a2d', 'noah-wardripfruin'],
    ['d13f8548-f4f2-4a2b-b7dc-5b216fc068c5', 'vladimir-voevodsky'],
    ['d211d0b0-8da5-4105-82ee-abfcec7706b3', 'lido'],
    ['d288f946-f2f6-40d7-9fb6-df9ef8db9bd9', 'bmw'],
    ['d576ebd1-a0b2-4e28-99e0-e78dbbdb5a3d', 'george-k-francis'],
    ['d5c4c632-f830-4489-bfa2-2a118ff9b4c7', 'number-none'],
    ['d694a70e-204d-4e89-a668-7b0febf8629e', 'matthew-nava'],
    ['d6b2ef01-d43e-4e61-9819-6e887b629687', 'nintendo'],
    ['d8f3e4b6-56b8-40b6-a3f3-520d9aebf0cd', 'donella-meadows'],
    ['db8f0226-6c1d-4348-b837-a5e9e57ea7f3', '3b1b-podcast'],
    ['dbad4965-34c3-4f6c-b3a7-12606c58d5f6', 'lee-byron'],
    ['dc3986fe-ec79-41fc-b4b5-3ab288d0204b', 'adam-wolff'],
    ['dcfb50dd-2271-4ca7-bc10-13154c04e13c', 'alan-kay'],
    ['dd5408c8-73cc-4c3b-bd66-c26cf48487e7', 'nick-montfort'],
    ['dd604d83-c001-413e-bf7d-083c60275b96', 'team-cherry'],
    ['dd9babf6-ef21-4aa9-9493-6ba648e80ea7', 'sophie-alpert'],
    ['df0c573f-a212-43ed-9029-cbbe98fa08e7', 'paul-owens'],
    ['dfd846ef-16c0-42a2-8329-823cb08a7102', 'devonte-hynes'],
    ['dff1974d-f8f5-430d-89f7-b5bce8926ecd', 'justin-roiland'],
    ['e0040268-b540-4852-a946-85227fe4fb85', 'uri-alon'],
    ['e22fed31-6cb1-4513-85ad-18eff475c54e', 'wesley-block'],
    ['e2a08f39-ce1d-4624-bae2-8edcb9a41c05', 'thomas-tull'],
    ['e371d163-76be-4a10-9604-3b8828852643', 'meta-open-source'],
    ['e3839def-66a4-46ef-a554-984b5f4c89de', 'bruce-headlam'],
    ['e483701c-8e97-4d2f-a3aa-4435a710e142', 'tobi-lutke'],
    ['e493ed62-c3fa-4f2a-be05-4550da3a1ba5', 'emma-taggart'],
    ['e4b49caf-1962-4e05-9c03-d1112653b3d6', 'denis-villeneuve'],
    ['e5548e4e-9a64-42bc-81c3-10fddf90e958', 'game-makers toolkit'],
    ['e5cb64e6-c689-48e8-8055-6b56d8cc745d', 'paul-saffo'],
    ['e655142f-f74a-499c-a597-fb0f3ece48d9', 'carnegie-mellon-design'],
    ['e9a8f4c1-731d-45a6-956b-66f8b270d786', 'andy-farnell'],
    ['eb2b6cf7-fd52-43e2-8f39-8f7a6022e0f7', 'thatgamecompany'],
    ['ec57e0d4-71e9-4fe3-9b9c-79e9153a16b7', 'jing-chen'],
    ['ec673331-0622-4fab-81e7-8d491737ee47', 'dan-hurd'],
    ['ecee745b-780b-42b3-996a-08f9aa44bcb5', 'michael-shilo-delay'],
    ['ed03d44c-b329-406b-899d-0fd3c908bbb7', 'michael-nielsen'],
    ['ef1905d0-ea27-4aa1-95d0-29875d38717d', 'tanil-raif'],
    ['ef8c771a-5342-4539-9d23-fd0c8636d6d2', 'ivan-poupyrev'],
    ['f0f2b422-cf96-4850-beb7-488594deffc7', 'afika-nyati'],
    ['f11917ea-76d1-4c9c-82d6-bfd263b19ff4', 'mit-comparative-media-studies'],
    ['f163b32d-2aec-4812-9c23-095ab67da690', 'figma'],
    ['f385377b-3f3c-4f4b-ae5d-0a15f78a4fa0', 'vlad-tv'],
    ['f3d5dd93-30b3-497f-8d2e-87c3297b0482', 'david-tse'],
    ['f47b05c4-ea67-48e4-8f13-4a00cbd3fb0b', 'will-welch'],
    ['f4ce7242-e69b-43ec-bb82-ed9df25afcbe', 'jeff-bhasker'],
    ['f626e9f9-8071-4776-93a8-a0f8e0d61347', 'steve-swink'],
    ['f665ce41-eb14-4208-8267-81ff0337c603', 'shigeru-miyamoto'],
    ['f697c106-8392-4983-8696-99206fecdda0', 'ellen-macarthur-foundation'],
    ['f6c40d39-a370-462f-ad56-5816c5d2eaf5', 'reactjs'],
    ['f7ad7020-1adc-4f2c-ad86-7a9ca826621f', 'james-somers'],
    ['f86e102c-fa80-4786-b338-17ae378bb4f2', 'maxwell-ramstead'],
    ['f97c5230-ee1a-430b-ae27-dd88fd1341e7', 'jordan-mechner'],
    ['f9a2ae0f-b5c0-428e-aed5-a57e2c154eed', 'harris-cauler'],
    ['f9dce8ed-2b13-49e6-9a83-67f8fbb4a7aa', 'evan-reidland'],
    ['fa5e995c-85de-45fc-b6ea-c10bfcfa9fe4', 'daniel-scheinert'],
    ['fadd76e2-708e-4f20-a665-cddfe7714fea', 'tyler-cowen'],
    ['fb480a12-d33e-4c66-bcd8-e7dc20becb1b', 'ibm'],
    ['fb6524d7-9471-484e-9bc1-2f3fc82c9676', 'galactic-cafe'],
    ['fb9d6e57-e19a-4d7b-8dcc-6b5ffd40f2be', 'institute-for-advanced-study'],
    ['fc608b31-c906-43fc-8806-e2e312b35220', 'the-demystifying-science-podcast'],
    ['ff18b90a-c2ba-4895-b670-b641b8f96a7a', 'tai-danae-bradley'],
]);

export default ID_TO_AUTHOR;

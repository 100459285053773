// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
    useMemo,
    useCallback,
}                                                       from 'react';
import { Transition }                                   from 'react-transition-group';
import {
    Link,
    useSearchParams,
}                                                       from 'react-router-dom';
import {
    css,
    FlattenSimpleInterpolation,
    Keyframes,
}                                                       from 'styled-components';
import { ReactSVG }                                     from 'react-svg';

// ===== Components =====

import Spinner                                          from '../Spinner';

// ===== Hooks =====

import {
    useTimeout,
}                                                       from '../../hooks';

// ===== Services =====

import {
    playAudio,
    isMobile,
    getFilterPath,
    getFilterTypePathKey,
    tagIDToHumanID,
    getMoveIndexRightKeyframe,
    getMoveIndexLeftKeyframe,
}                                                       from '../../services';

// ===== Constants =====

import {
    DEFAULT_AUDIO_VOLUME,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_OFFSET_DURATION,
    FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    UNASSIGNED_ERROR_MESSAGE,
    TAG_TRANSITION_DURATION,
    MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION,
    MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION,
    PIVOT_BUTTON_WIDTH,
    PIVOT_BUTTON_HEIGHT,
    PIVOT_BUTTON_X_OFFSET,
    PIVOT_BUTTON_MARGIN,
    TAGS_CONTAINER_PADDING,
    TAGS_CONTAINER_PADDING_SMALL_VIEWPORT,
    PIVOT_BUTTON_HEIGHT_SMALL_VIEWPORT,
    PIVOT_BUTTON_WIDTH_SMALL_VIEWPORT,
    HOVER_TARGET_CLASSNAME,
    BODY_FONT_SIZE,
}                                                       from '../../constants/generalConstants';
import CURSOR_SIGN                                      from '../../constants/cursorSigns';
import MEDIA_QUERY_SIZE                                 from '../../constants/mediaQuerySizes';

// ===== Enums =====

import {
    FILTER_TYPE,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    KEYFRAME_ANCHOR_TYPE,
}                                                       from '../../enums';

// ===== Interfaces =====

import {
    ITag,
    ICartridgeItem,
    IDimension,
}                                                       from '../../interfaces';

// ===== Images =====

import CrossIcon                                        from '../../images/cross.svg';

// ===== Sounds =====

import SelectorExpand                                   from '../../sounds/swoosh_in.mp3';
import SelectorContract                                 from '../../sounds/swoosh_out.mp3';

// ===== Styles =====

import { theme }                                        from '../../themes/theme-context';
import {
    Container,
    Pivot,
    SelectorExpander,
    SelectorExpanderIcon,
    PivotButton,
    PivotButtonText,
    TagsContainer,
    ProgressBar,
    ProgressBarTextContainer,
    ProgressBarPercentageText,
    ProgressBarCartridgeCount,
    FilterContainer,
    TagsList,
    ProgressBarContainer,
}                                                       from './styles';
import {
    Tag,
    TagText,
    TagItemCount,
    TagButton,
    MovingButtonBackground,
}                                                       from '../../styles';

interface Props {
    hasSound: boolean,
    viewportDimensions: IDimension,
    cartridges: Map<string, ICartridgeItem>,
    cartridgeCount: number,
    appliedFilters: ITag[],
    filters: Map<FILTER_TYPE, Map<string, ITag>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    triggerFilterAudio: () => void,
}
function FilterSelector({
    hasSound,
    viewportDimensions,
    cartridges,
    cartridgeCount,
    appliedFilters,
    filters,
    onCursorEnter,
    onCursorLeave,
    triggerFilterAudio,
}: Props): JSX.Element {
    // ===== General Constants =====

    const PROGRESS_BAR_TEXT_CONTAINER_MARGIN = 0.625 * BODY_FONT_SIZE;
    const FILTER_CONTAINER_MARGIN_TOP = 0.15625 * BODY_FONT_SIZE;
    const TAG_HEIGHT = 1.875 * BODY_FONT_SIZE;
    const PIVOT_BUTTON_COUNT = 3;
    const MOVING_BUTTON_INITIAL_INDEX = 1;

    // ===== Refs =====

    const TAGS_LIST_REF_NAME = 'tagsListRef';
    const tagsListRef = useRef<HTMLDivElement>(null);

    // ----- Sound Clips
    const selectorExpandClip = useRef<HTMLAudioElement>(new Audio());
    const selectorContractClip = useRef<HTMLAudioElement>(new Audio());

    // ===== React Router =====

    const [searchParams] = useSearchParams();

    // ===== State =====

    // indicates whether filter selector is ready to be used
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    // indicates whether filter selector is expanded
    const [pivotExpanded, setPivotExpanded] = useState<boolean>(false);
    // indicates whether filter selector is expanding
    // used for css animations
    const [pivotExpanding, setPivotExpanding] = useState<boolean>(false);
    // indicates whether filter selector is contracting
    // used for css animations
    const [pivotContracting, setPivotContracting] = useState<boolean>(false);
    // stores tags currently being used
    const [activeTags, setActiveTags] = useState<ITag[]>([]);
    // indicates whether theme tags are visible
    const [themeTagsVisible, setThemeTagsVisible] = useState<boolean>(false);
    // indicates whether type tags are visible
    const [typeTagsVisible, setTypeTagsVisible] = useState<boolean>(false);
    // indicates whether author tags are visible
    const [authorTagsVisible, setAuthorTagsVisible] = useState<boolean>(false);
    // stores height of tags container
    const [tagsContainerHeight, setTagsContainerHeight] = useState<number>(0);
    // indicates the precentage of cartridges filtered
    const [filterProgress, setFilterProgress] = useState<number>(0);
    // indicates whether theme pivot button active
    const [pivotButtonActive, setPivotButtonActive] = useState<Map<FILTER_TYPE, boolean>>(new Map([
        [FILTER_TYPE.theme, true],
        [FILTER_TYPE.type, true],
        [FILTER_TYPE.author, true],
    ]));
    // stores number of active pivot buttons
    const [pivotButtonCount, setPivotButtonCount] = useState<number>(PIVOT_BUTTON_COUNT);
    // stores stagger indices of pivot buttons
    const [
        pivotButtonStaggerIndices,
        setPivotButtonStaggerIndices,
    ] = useState<Map<FILTER_TYPE, number>>(new Map([
        [FILTER_TYPE.theme, 0],
        [FILTER_TYPE.type, 1],
        [FILTER_TYPE.author, 2],
    ]));
    // stores desired state of pivot button background
    const [movingButtonBackground, setMovingButtonBackground] = useState<{ active: boolean, desiredIndex: number}>({
        active: false,
        desiredIndex: 0,
    });
    // stores the current index of the pivot button background
    const [movingButtonCurrentIndex, setMovingButtonCurrentIndex] = useState(MOVING_BUTTON_INITIAL_INDEX);
    // stores the next index of the movingButtonCurrentIndex to be set following a timeout
    const [movingButtonNextIndex, setMovingButtonNextIndex] = useState(MOVING_BUTTON_INITIAL_INDEX + 1);

    // ===== Animation Constants =====

    const PIVOT_EXPAND_CONTRACT_DURATION = 300;
    const TAGS_CONTAINER_EXPAND_DURATION = 300;
    const CONTAINER_TRANSITION_DURATION = TAGS_CONTAINER_EXPAND_DURATION;
    const PIVOT_CONTRACT_DELAY_DURATION = 200;
    const PIVOT_TRANSITION_DURATION = 400;
    const PIVOT_BUTTON_HOVER_TRANSITION_DURATION = PIVOT_TRANSITION_DURATION;
    const PIVOT_BUTTON_EXPAND_TRANSITION_DURATION = 250;
    const PIVOT_BUTTON_FADE_IN_STAGGER_OFFSET_DURATION = 125;
    const PIVOT_BUTTON_FADE_IN_OFFSET = 15;
    const BUTTON_EXPAND_DELAY_DURATION = 200;
    const FILTER_CONTAINER_TRANSITION_DURATION = 300;
    const PROGRESS_BAR_TEXT_CONTAINER_OPACITY_DURATION = 250;
    const TAG_BUTTON_TRANSITION_DURATION = PIVOT_TRANSITION_DURATION;
    const PROGRESS_TRANSITION_DURATION = 2000;
    const TAGS_CONTAINER_CALCULATE_HEIGHT_DELAY_DURATION = 200;

    // ===== Methods =====

    const onSelectorExpanderMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.selectorExpander,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onSelectorExpanderMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onPivotButtonMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.filterPivot,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onPivotButtonMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onTagMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.tag,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onTagMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onTagRemove = (): void => {
        triggerFilterAudio();
    };

    const handleTogglePivotExpansion = (): void => {
        let overrideSound = false;
        if ((themeTagsVisible || typeTagsVisible || authorTagsVisible) && pivotExpanded) {
            // close tags container before collapsing filter selector
            if (themeTagsVisible) {
                setThemeTagsVisible(false);
            } else if (typeTagsVisible) {
                setTypeTagsVisible(false);
            } else if (authorTagsVisible) {
                setAuthorTagsVisible(false);
            }

            // Play Sound
            if (hasSound && selectorContractClip.current) {
                selectorContractClip.current.pause();
                selectorContractClip.current.currentTime = 0;
                playAudio(selectorContractClip.current);
            }

            overrideSound = true;
            clearTimeoutCollapseFilterSelector();
            timeoutCollapseFilterSelector();
        } else {
            clearTimeoutPivotExpandingContracting();
            timeoutPivotExpandingContracting();
            if (pivotExpanded) {
                setPivotContracting(true);
            } else {
                setPivotExpanding(true);
            }
            setPivotExpanded(!pivotExpanded);
        }

        // Play Sound
        if (
            !pivotExpanded
            && hasSound
            && !overrideSound
            && selectorExpandClip.current
        ) {
            selectorExpandClip.current.pause();
            selectorExpandClip.current.currentTime = 0;
            playAudio(selectorExpandClip.current);
        } else if (
            pivotExpanded
            && hasSound
            && !overrideSound
            && selectorContractClip.current
        ) {
            selectorContractClip.current.pause();
            selectorContractClip.current.currentTime = 0;
            playAudio(selectorContractClip.current);
        }
    };

    const handlePivotButtonClick = (
        type: FILTER_TYPE,
        index: number,
        e: React.MouseEvent,
    ): void => {
        e.stopPropagation();

        // Play Sound
        if (
            !themeTagsVisible
            && !typeTagsVisible
            && !authorTagsVisible
            && hasSound
            && selectorExpandClip.current
        ) {
            selectorExpandClip.current.pause();
            selectorExpandClip.current.currentTime = 0;
            playAudio(selectorExpandClip.current);
        } else if (
            (
                themeTagsVisible
                || typeTagsVisible
                || authorTagsVisible
            ) && hasSound
        ) {
            clearTimeoutPlaySelectorContractSound();
            timeoutPlaySelectorContractSound();
        }

        if (type === FILTER_TYPE.theme) {
            if (!themeTagsVisible) {
                clearTimeoutCalculateTagsListHeight();
                timeoutCalculateTagsListHeight();
            } else {
                setTagsContainerHeight(0);
            }

            setThemeTagsVisible(!themeTagsVisible);
            setActiveTags([...Array.from(filters.get(FILTER_TYPE.theme)!.values())]);

            // Only allow one type of tags to be visible at a time
            if (typeTagsVisible) {
                setTypeTagsVisible(false);
            }

            if (authorTagsVisible) {
                setAuthorTagsVisible(false);
            }
        } else if (type === FILTER_TYPE.type) {
            if (!typeTagsVisible) {
                clearTimeoutCalculateTagsListHeight();
                timeoutCalculateTagsListHeight();
            } else {
                setTagsContainerHeight(0);
            }

            setTypeTagsVisible(!typeTagsVisible);
            setActiveTags([...Array.from(filters.get(FILTER_TYPE.type)!.values())]);

            // Only allow one type of tags to be visible at a time
            if (themeTagsVisible) {
                setThemeTagsVisible(false);
            }

            if (authorTagsVisible) {
                setAuthorTagsVisible(false);
            }
        } else if (type === FILTER_TYPE.author) {
            if (!authorTagsVisible) {
                clearTimeoutCalculateTagsListHeight();
                timeoutCalculateTagsListHeight();
            } else {
                setTagsContainerHeight(0);
            }

            setAuthorTagsVisible(!authorTagsVisible);
            setActiveTags([...Array.from(filters.get(FILTER_TYPE.author)!.values())]);

            // Only allow one type of tags to be visible at a time
            if (themeTagsVisible) {
                setThemeTagsVisible(false);
            }

            if (typeTagsVisible) {
                setTypeTagsVisible(false);
            }
        }

        // If no other button is activated / moving button
        // isn't already on the screen, then make it begin
        // at button that had been pressed
        if (
            !themeTagsVisible
            && !typeTagsVisible
            && !authorTagsVisible
        ) {
            setMovingButtonCurrentIndex(index);
        }

        // set next moving button index used
        // in the timeout
        setMovingButtonNextIndex(index);
        // clear any revious delay
        clearDelayChangeMovingButtonIndex();
        // start new delay
        delayChangeMovingButtonIndex();
        // start moving the button by setting desired index
        setMovingButtonBackground({
            active: true,
            desiredIndex: index,
        });
    };

    const calculateTagsContainerHeight = (): void => {
        if (!tagsListRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(TAGS_LIST_REF_NAME));

        const tagsListRect = tagsListRef.current.getBoundingClientRect();
        const tagsPadding = viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
            ? TAGS_CONTAINER_PADDING
            : TAGS_CONTAINER_PADDING_SMALL_VIEWPORT;
        setTagsContainerHeight(tagsListRect.height + tagsPadding);
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            selectorExpandClip.current
            && selectorContractClip.current
        ) {
            // Selector Expand
            selectorExpandClip.current.volume = DEFAULT_AUDIO_VOLUME;
            selectorExpandClip.current.src = SelectorExpand;

            // Selector Contract
            selectorContractClip.current.volume = DEFAULT_AUDIO_VOLUME;
            selectorContractClip.current.src = SelectorContract;
        }

        return function cleanup() {
            if (selectorExpandClip.current) selectorExpandClip.current.remove();
            if (selectorContractClip.current) selectorContractClip.current.remove();
        };
    }, []);

    useEffect(() => {
        let progress = Math.floor(100 * (cartridges.size / cartridgeCount));
        if (progress < 0) {
            progress = 0;
        } else if (progress > 100) {
            progress = 100;
        }

        if (progress !== filterProgress && cartridgeCount > 0) {
            setFilterProgress(progress);
        }
    }, [
        cartridges,
        cartridgeCount,
    ]);

    useEffect(() => {
        // Update pivot buttons and pivot button count
        let buttonCount = 0;
        let themePivotActive = false;
        let typePivotActive = false;
        let authorPivotActive = false;

        const themeFilters = filters.get(FILTER_TYPE.theme);
        const typeFilters = filters.get(FILTER_TYPE.type);
        const authorFilters = filters.get(FILTER_TYPE.theme);

        if (
            (themeFilters && themeFilters.size > 0)
            || (typeFilters && typeFilters.size > 0)
            || (authorFilters && authorFilters.size > 0)
        ) {
            if (themeFilters && themeFilters.size > 0) {
                themePivotActive = true;
                buttonCount += 1;
            }
            if (typeFilters && typeFilters.size > 0) {
                typePivotActive = true;
                buttonCount += 1;
            }
            if (
                authorFilters
                && authorFilters.size > 0
                && appliedFilters.length > 0
            ) {
                authorPivotActive = true;
                buttonCount += 1;
            }

            const newPivotButtonActive: Map<FILTER_TYPE, boolean> = new Map([
                [FILTER_TYPE.theme, themePivotActive],
                [FILTER_TYPE.type, typePivotActive],
                [FILTER_TYPE.author, authorPivotActive],
            ]);
            setPivotButtonActive(newPivotButtonActive);
            setPivotButtonCount(buttonCount);

            // Update pivot button stagger indices
            const staggerIndices: Map<FILTER_TYPE, number> = new Map(pivotButtonStaggerIndices);
            if (themePivotActive) {
                staggerIndices.set(FILTER_TYPE.theme, 0);
            } else {
                staggerIndices.set(FILTER_TYPE.theme, -1);
            }
            if (themePivotActive && typePivotActive) {
                staggerIndices.set(FILTER_TYPE.type, 1);
            } else if (typePivotActive) {
                staggerIndices.set(FILTER_TYPE.type, 0);
            } else {
                staggerIndices.set(FILTER_TYPE.type, -1);
            }
            if (themePivotActive && typePivotActive && authorPivotActive) {
                staggerIndices.set(FILTER_TYPE.author, 2);
            } else if (
                (typePivotActive && authorPivotActive)
                || (themePivotActive && authorPivotActive)
            ) {
                staggerIndices.set(FILTER_TYPE.author, 1);
            } else if (authorPivotActive) {
                staggerIndices.set(FILTER_TYPE.author, 0);
            } else {
                staggerIndices.set(FILTER_TYPE.author, -1);
            }
            setPivotButtonStaggerIndices(staggerIndices);

            // Update tags container
            if (themeTagsVisible) {
                if (themeFilters && themeFilters.size > 0) {
                    clearTimeoutCalculateTagsListHeight();
                    timeoutCalculateTagsListHeight();
                    setActiveTags([...Array.from(filters.get(FILTER_TYPE.theme)!.values())]);
                } else {
                    setThemeTagsVisible(false);
                }
            } else if (typeTagsVisible) {
                if (typeFilters && typeFilters.size > 0) {
                    clearTimeoutCalculateTagsListHeight();
                    timeoutCalculateTagsListHeight();
                    setActiveTags([...Array.from(filters.get(FILTER_TYPE.type)!.values())]);
                } else {
                    setTypeTagsVisible(false);
                }
            } else if (authorTagsVisible) {
                if (authorFilters && authorFilters.size > 0) {
                    clearTimeoutCalculateTagsListHeight();
                    timeoutCalculateTagsListHeight();
                    setActiveTags([...Array.from(filters.get(FILTER_TYPE.author)!.values())]);
                } else {
                    setAuthorTagsVisible(false);
                }
            }

            // If author button no longer visible but we're showing author tags, hide them
            if (authorTagsVisible && !authorPivotActive) setAuthorTagsVisible(false);

            if (!hasInitialized) {
                setHasInitialized(true);
            }
        }
    }, [
        filters,
    ]);

    const {
        start: timeoutPivotExpandingContracting,
        clear: clearTimeoutPivotExpandingContracting,
    } = useTimeout(() => {
        if (pivotExpanding) {
            setPivotExpanding(false);
        }
        if (pivotContracting) {
            setPivotContracting(false);
        }
    }, PIVOT_EXPAND_CONTRACT_DURATION);

    const {
        start: timeoutCalculateTagsListHeight,
        clear: clearTimeoutCalculateTagsListHeight,
    } = useTimeout(() => {
        calculateTagsContainerHeight();
    }, TAGS_CONTAINER_CALCULATE_HEIGHT_DELAY_DURATION);

    const {
        start: timeoutCollapseFilterSelector,
        clear: clearTimeoutCollapseFilterSelector,
    } = useTimeout(() => {
        clearTimeoutPivotExpandingContracting();
        timeoutPivotExpandingContracting();
        setPivotContracting(true);
        setPivotExpanded(false);

        // Play Sound
        if (hasSound && selectorContractClip.current) {
            selectorContractClip.current.pause();
            selectorContractClip.current.currentTime = 0;
            playAudio(selectorContractClip.current);
        }
    }, Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((activeTags.length) * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION,
    ));

    const {
        start: timeoutPlaySelectorContractSound,
        clear: clearTimeoutPlaySelectorContractSound,
    } = useTimeout(() => {
        if (selectorContractClip.current) {
            selectorContractClip.current.pause();
            selectorContractClip.current.currentTime = 0;
            playAudio(selectorContractClip.current);
        }
    }, Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (activeTags.length * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION,
    ));

    const {
        start: delayChangeMovingButtonIndex,
        clear: clearDelayChangeMovingButtonIndex,
    } = useTimeout(
        useCallback(() => {
            setMovingButtonBackground({
                active: true,
                desiredIndex: movingButtonNextIndex,
            });
            // Set the new index
            setMovingButtonCurrentIndex(movingButtonNextIndex);
        }, [
            movingButtonNextIndex,
        ]),
        Math.min(
            Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex) * MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION,
            MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION,
        ),
    );

    // Determine Tag Sort Function
    let tagSortfunction: (a: ITag, b: ITag) => number;
    if (appliedFilters.length > 0 && !authorTagsVisible) {
        // Sort by item count
        // Author Tags should be in alphabetical order
        tagSortfunction = (a, b): number => {
            if (a.items.length < b.items.length) { return 1; }
            if (a.items.length > b.items.length) { return -1; }
            return 0;
        };
    } else {
        // Sort alphabetically
        tagSortfunction = (a, b): number => {
            if (a.text < b.text) { return -1; }
            if (a.text > b.text) { return 1; }
            return 0;
        };
    }

    // ===== Rendering =====

    const buttonAnimations = useMemo(() => {
        let animations: Map<string, Keyframes> = new Map();
        for (let i = 1; i < pivotButtonCount; i += 1) {
            const moveIndexLeft = getMoveIndexLeftKeyframe(
                viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                    ? PIVOT_BUTTON_WIDTH
                    : PIVOT_BUTTON_WIDTH_SMALL_VIEWPORT,
                i,
                PIVOT_BUTTON_MARGIN,
                viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
                && viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                    ? KEYFRAME_ANCHOR_TYPE.right
                    : KEYFRAME_ANCHOR_TYPE.left,
            );
            const moveIndexRight = getMoveIndexRightKeyframe(
                viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                    ? PIVOT_BUTTON_WIDTH
                    : PIVOT_BUTTON_WIDTH_SMALL_VIEWPORT,
                i,
                PIVOT_BUTTON_MARGIN,
                viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
                && viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                    ? KEYFRAME_ANCHOR_TYPE.right
                    : KEYFRAME_ANCHOR_TYPE.left,
            );
            animations = animations.set(
                `move${i}Right`,
                moveIndexRight,
            );
            animations = animations.set(
                `move${i}Left`,
                moveIndexLeft,
            );
        }

        return animations;
    }, [
        movingButtonCurrentIndex,
        pivotButtonCount,
    ]);

    let animation: FlattenSimpleInterpolation | null = null;
    if (movingButtonBackground.active) {
        const keyframe = buttonAnimations.get(
            `move${
                Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex)
            }${
                (
                    movingButtonBackground.desiredIndex > movingButtonCurrentIndex
                    && (
                        viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
                        || viewportDimensions.width < MEDIA_QUERY_SIZE.small.min
                    )
                ) || (
                    movingButtonBackground.desiredIndex < movingButtonCurrentIndex
                    && viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
                    && viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                )
                    ? 'Right'
                    : 'Left'
            }`,
        );

        // Error: https://stackoverflow.com/questions/67601508/why-do-my-styled-component-keyframes-error-with-ts-styled-plugin9999-in-react
        // eslint-disable-next-line max-len
        animation = css`${keyframe} ${Math.min(Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex) * MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION, MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION)}ms${` ${theme.motion.delayEasing}`}`;
    }

    // ===== Memoization =====

    const isMobileDevice = useMemo(() => isMobile(), []);
    const isFiltering = useMemo(() => appliedFilters.length > 0, [
        appliedFilters,
    ]);
    const tagsContainerIsExpanded = useMemo(() => (
        typeTagsVisible || themeTagsVisible || authorTagsVisible
    ), [
        typeTagsVisible,
        themeTagsVisible,
        authorTagsVisible,
    ]);
    const tagsContainerExitDelayDuration = useMemo(() => Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (activeTags.length * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION,
    ), [
        activeTags,
    ]);

    const movingButtonBackgroundIsVisible = useMemo(() => (
        hasInitialized
            && (pivotExpanded || viewportDimensions.width < MEDIA_QUERY_SIZE.small.min)
            && (
                themeTagsVisible
                || typeTagsVisible
                || authorTagsVisible
            )
    ), [
        hasInitialized,
        pivotExpanded,
        viewportDimensions,
        themeTagsVisible,
        typeTagsVisible,
        authorTagsVisible,
    ]);

    const movingButtonHeight = useMemo(() => (
        viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
            ? PIVOT_BUTTON_HEIGHT
            : PIVOT_BUTTON_HEIGHT_SMALL_VIEWPORT
    ), [
        viewportDimensions,
    ]);

    const movingButtonWidth = useMemo(() => (
        viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
            ? PIVOT_BUTTON_WIDTH
            : PIVOT_BUTTON_WIDTH_SMALL_VIEWPORT
    ), [
        viewportDimensions,
    ]);

    const movingButtonXOffset = useMemo(() => (
        viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
            ? PIVOT_BUTTON_X_OFFSET + PIVOT_BUTTON_MARGIN / 2
            : 0
    ), [
        viewportDimensions,
    ]);

    const movingButtonIndex = useMemo(() => movingButtonCurrentIndex - 1, [movingButtonCurrentIndex]);

    const themePivotButtonIsVisible = useMemo(() => (
        hasInitialized
            && (pivotExpanded || viewportDimensions.width < MEDIA_QUERY_SIZE.small.min)
            && pivotButtonActive.get(FILTER_TYPE.theme)!
    ), [
        hasInitialized,
        pivotExpanded,
        viewportDimensions,
        pivotButtonActive,
    ]);

    const typePivotButtonIsVisible = useMemo(() => (
        hasInitialized
            && (pivotExpanded || viewportDimensions.width < MEDIA_QUERY_SIZE.small.min)
            && pivotButtonActive.get(FILTER_TYPE.type)!
    ), [
        hasInitialized,
        pivotExpanded,
        viewportDimensions,
        pivotButtonActive,
    ]);

    const authorPivotButtonIsVisible = useMemo(() => (
        hasInitialized
            && (pivotExpanded || viewportDimensions.width < MEDIA_QUERY_SIZE.small.min)
            && pivotButtonActive.get(FILTER_TYPE.author)!
    ), [
        hasInitialized,
        pivotExpanded,
        viewportDimensions,
        pivotButtonActive,
    ]);

    return (
        <Container
            tagsVisible={tagsContainerIsExpanded}
            transitionDuration={CONTAINER_TRANSITION_DURATION}
        >
            <FilterContainer
                visible={isFiltering}
                topMargin={FILTER_CONTAINER_MARGIN_TOP}
                transitionDuration={FILTER_CONTAINER_TRANSITION_DURATION}
            >
                {appliedFilters.map((tag: ITag, index: number, arr: ITag[]) => (
                    <Transition
                        key={tag.text}
                        in={isFiltering}
                        timeout={{
                            enter: Math.min(
                                MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                + FADE_IN_STAGGER_TRANSITION_DURATION
                                + TAGS_CONTAINER_EXPAND_DURATION,
                            ),
                            exit: Math.min(
                                MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                ((arr.length - Math.max(index - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                + FADE_IN_STAGGER_TRANSITION_DURATION,
                            ),
                        }}
                        appear
                        mountOnEnter
                        unmountOnExit
                    >
                        {(state) => (
                            <Tag
                                floating
                                solid
                                hover
                                index={index}
                                height={TAG_HEIGHT}
                                transitionDuration={TAG_TRANSITION_DURATION}
                                color={theme.verascopeColor.blue100}
                                style={{
                                    ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                        direction: 'right',
                                        offset: 10,
                                    }),
                                    ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                        direction: 'right',
                                        offset: 10,
                                        numItems: arr.length,
                                        index,
                                        enterDelay: TAGS_CONTAINER_EXPAND_DURATION,
                                    })[state],
                                }}
                            >
                                <TagText>
                                    {tag.text}
                                </TagText>
                                <Link
                                    to={`?${getFilterPath(
                                        searchParams,
                                        tag.type,
                                        tag.humanId,
                                    ).toString()}`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <TagButton
                                        transitionDuration={TAG_BUTTON_TRANSITION_DURATION}
                                        className={HOVER_TARGET_CLASSNAME}
                                        onMouseEnter={onTagMouseEnter}
                                        onMouseLeave={onTagMouseLeave}
                                        onMouseDown={onTagRemove}
                                    >
                                        <ReactSVG
                                            src={CrossIcon}
                                        />
                                    </TagButton>
                                </Link>
                            </Tag>
                        )}
                    </Transition>
                ))}
            </FilterContainer>
            <TagsContainer
                expanded={tagsContainerIsExpanded}
                expandedHeight={tagsContainerHeight}
                authorTagsVisible={authorTagsVisible}
                transitionDuration={TAGS_CONTAINER_EXPAND_DURATION}
                exitDelayDuration={tagsContainerExitDelayDuration}
            >
                <TagsList
                    ref={tagsListRef}
                >
                    {activeTags
                        .sort(tagSortfunction)
                        .map((tag: ITag, index: number, arr: ITag[]) => {
                            const isApplied = searchParams
                                .getAll(getFilterTypePathKey(tag.type))
                                .includes(tagIDToHumanID(tag.type, tag.id));
                            return (
                                <Transition
                                    key={tag.text}
                                    in={typeTagsVisible || themeTagsVisible || authorTagsVisible}
                                    timeout={{
                                        enter: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + FADE_IN_STAGGER_TRANSITION_DURATION
                                            + TAGS_CONTAINER_EXPAND_DURATION,
                                        ),
                                        exit: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            ((arr.length - Math.max(index - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ),
                                    }}
                                    appear
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    {(state) => (
                                        <Link
                                            to={`?${getFilterPath(
                                                searchParams,
                                                tag.type,
                                                tag.humanId,
                                            ).toString()}`}
                                            style={{
                                                ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                    direction: 'right',
                                                    offset: 10,
                                                }),
                                                ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                    direction: 'right',
                                                    offset: 10,
                                                    numItems: arr.length,
                                                    index,
                                                    enterDelay: TAGS_CONTAINER_EXPAND_DURATION,
                                                })[state],
                                                textDecoration: 'none',
                                            }}
                                        >
                                            <Tag
                                                className={HOVER_TARGET_CLASSNAME}
                                                hover={!isApplied}
                                                solid={isApplied}
                                                floating={isApplied}
                                                color={theme.verascopeColor.blue100}
                                                index={index}
                                                height={TAG_HEIGHT}
                                                transitionDuration={TAG_TRANSITION_DURATION}
                                                onMouseEnter={onTagMouseEnter}
                                                onMouseLeave={onTagMouseLeave}
                                                onMouseDown={triggerFilterAudio}
                                            >
                                                <TagText>
                                                    {tag.text}
                                                </TagText>
                                                <TagItemCount>
                                                    {tag.items.length}
                                                </TagItemCount>
                                            </Tag>
                                        </Link>
                                    )}
                                </Transition>
                            );
                        })}
                </TagsList>
            </TagsContainer>
            <ProgressBarTextContainer
                visible={isFiltering}
                opacityDuration={PROGRESS_BAR_TEXT_CONTAINER_OPACITY_DURATION}
                margin={PROGRESS_BAR_TEXT_CONTAINER_MARGIN}
            >
                <ProgressBarPercentageText
                    progress={Math.max(1, filterProgress)}
                    transitionDuration={PROGRESS_TRANSITION_DURATION}
                />
                <ProgressBarCartridgeCount>
                    {`${cartridges.size} cartridge${cartridges.size === 1 ? '' : 's'}`}
                </ProgressBarCartridgeCount>
            </ProgressBarTextContainer>
            <ProgressBarContainer
                pivotExpanded={pivotExpanded}
                pivotButtonCount={pivotButtonCount}
                expandDuration={PIVOT_EXPAND_CONTRACT_DURATION}
                tagsVisible={tagsContainerIsExpanded}
                pivotExpanding={pivotExpanding}
                pivotContracting={pivotContracting}
                pivotExitDelayDuration={PIVOT_CONTRACT_DELAY_DURATION}
                tagsContainerExitDelayDuration={tagsContainerExitDelayDuration}
            >
                <ProgressBar
                    isFiltering={isFiltering}
                    progress={Math.max(1, filterProgress)}
                    transitionDuration={PROGRESS_TRANSITION_DURATION}
                />
            </ProgressBarContainer>
            <Pivot
                hasInitialized={hasInitialized}
                pivotExpanded={pivotExpanded}
                pivotExpanding={pivotExpanding}
                pivotContracting={pivotContracting}
                tagsVisible={tagsContainerIsExpanded}
                pivotButtonCount={pivotButtonCount}
                expandDuration={PIVOT_EXPAND_CONTRACT_DURATION}
                transitionDuration={PIVOT_TRANSITION_DURATION}
                exitDelayDuration={PIVOT_CONTRACT_DELAY_DURATION}
                tagsContainerExitDelayDuration={tagsContainerExitDelayDuration}
                {...hasInitialized
                    ? { onClick: handleTogglePivotExpansion }
                    : {}
                }
            >
                {hasInitialized
                    ? (
                        <SelectorExpander
                            className={HOVER_TARGET_CLASSNAME}
                            expanded={pivotExpanded}
                            expandDuration={PIVOT_EXPAND_CONTRACT_DURATION}
                            transitionDuration={PIVOT_TRANSITION_DURATION}
                            onMouseEnter={onSelectorExpanderMouseEnter}
                            onMouseLeave={onSelectorExpanderMouseLeave}
                        >
                            <SelectorExpanderIcon
                                viewBox="0 0 20 20"
                                expanded={pivotExpanded}
                                transitionDuration={PIVOT_TRANSITION_DURATION}
                            >
                                <path d="M18.8,5.1H1.2C0.5,5.1,0,4.6,0,4s0.5-1.2,1.2-1.2h17.6C19.5,2.8,20,3.3,20,4S19.5,5.1,18.8,5.1z" />
                                <path d="M15.8,11.2H4.2C3.6,11.2,3,10.6,3,10s0.5-1.2,1.2-1.2h11.6c0.6,0,1.2,0.5,1.2,1.2S16.4,11.2,15.8,11.2z" />
                                <path d="M12.7,17.2H7.3c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2h5.5c0.6,0,1.2,0.5,1.2,1.2C13.9,16.7,13.4,17.2,12.7,17.2z" />
                            </SelectorExpanderIcon>
                        </SelectorExpander>
                    ) : (
                        <Spinner />
                    )}
                <MovingButtonBackground
                    solid
                    invertOnSmallScreens
                    visible={movingButtonBackgroundIsVisible}
                    color={theme.verascopeColor.blue100}
                    top={PIVOT_BUTTON_MARGIN}
                    buttonHeight={movingButtonHeight}
                    buttonWidth={movingButtonWidth}
                    animation={animation}
                    spacing={PIVOT_BUTTON_MARGIN}
                    xOffset={movingButtonXOffset}
                    index={movingButtonIndex}
                    zIndex={2} // To put in front of ProgressBarContainer
                    transitionDuration={PIVOT_BUTTON_HOVER_TRANSITION_DURATION}
                />
                <PivotButton
                    className={HOVER_TARGET_CLASSNAME}
                    visible={themePivotButtonIsVisible}
                    isMobile={isMobileDevice}
                    selected={themeTagsVisible}
                    hoverDuration={PIVOT_BUTTON_HOVER_TRANSITION_DURATION}
                    expandDuration={PIVOT_BUTTON_EXPAND_TRANSITION_DURATION}
                    index={pivotButtonStaggerIndices.get(FILTER_TYPE.theme)!}
                    staggerOffsetDuration={PIVOT_BUTTON_FADE_IN_STAGGER_OFFSET_DURATION}
                    enterDelayDuration={BUTTON_EXPAND_DELAY_DURATION}
                    fadeInOffset={PIVOT_BUTTON_FADE_IN_OFFSET}
                    buttonCount={pivotButtonCount}
                    onMouseEnter={onPivotButtonMouseEnter}
                    onMouseLeave={onPivotButtonMouseLeave}
                    onClick={(e) => handlePivotButtonClick(
                        FILTER_TYPE.theme,
                        pivotButtonStaggerIndices.get(FILTER_TYPE.theme)! + 1,
                        e,
                    )}
                >
                    <PivotButtonText
                        selected={themeTagsVisible}
                        transitionDuration={PIVOT_BUTTON_HOVER_TRANSITION_DURATION}
                    >
                        Theme
                    </PivotButtonText>
                </PivotButton>
                <PivotButton
                    className={HOVER_TARGET_CLASSNAME}
                    visible={typePivotButtonIsVisible}
                    selected={typeTagsVisible}
                    isMobile={isMobileDevice}
                    hoverDuration={PIVOT_BUTTON_HOVER_TRANSITION_DURATION}
                    expandDuration={PIVOT_BUTTON_EXPAND_TRANSITION_DURATION}
                    index={pivotButtonStaggerIndices.get(FILTER_TYPE.type)!}
                    staggerOffsetDuration={PIVOT_BUTTON_FADE_IN_STAGGER_OFFSET_DURATION}
                    enterDelayDuration={BUTTON_EXPAND_DELAY_DURATION}
                    fadeInOffset={PIVOT_BUTTON_FADE_IN_OFFSET}
                    buttonCount={pivotButtonCount}
                    onMouseEnter={onPivotButtonMouseEnter}
                    onMouseLeave={onPivotButtonMouseLeave}
                    onClick={(e) => handlePivotButtonClick(
                        FILTER_TYPE.type,
                        pivotButtonStaggerIndices.get(FILTER_TYPE.type)! + 1,
                        e,
                    )}
                >
                    <PivotButtonText
                        selected={typeTagsVisible}
                        transitionDuration={PIVOT_BUTTON_HOVER_TRANSITION_DURATION}
                    >
                        Media
                    </PivotButtonText>
                </PivotButton>
                <PivotButton
                    className={HOVER_TARGET_CLASSNAME}
                    visible={authorPivotButtonIsVisible}
                    selected={authorTagsVisible}
                    isMobile={isMobileDevice}
                    hoverDuration={PIVOT_BUTTON_HOVER_TRANSITION_DURATION}
                    expandDuration={PIVOT_BUTTON_EXPAND_TRANSITION_DURATION}
                    index={pivotButtonStaggerIndices.get(FILTER_TYPE.author)!}
                    staggerOffsetDuration={PIVOT_BUTTON_FADE_IN_STAGGER_OFFSET_DURATION}
                    enterDelayDuration={BUTTON_EXPAND_DELAY_DURATION}
                    fadeInOffset={PIVOT_BUTTON_FADE_IN_OFFSET}
                    buttonCount={pivotButtonCount}
                    onMouseEnter={onPivotButtonMouseEnter}
                    onMouseLeave={onPivotButtonMouseLeave}
                    onClick={(e) => handlePivotButtonClick(
                        FILTER_TYPE.author,
                        pivotButtonStaggerIndices.get(FILTER_TYPE.author)! + 1,
                        e,
                    )}
                >
                    <PivotButtonText
                        selected={authorTagsVisible}
                        transitionDuration={PIVOT_BUTTON_HOVER_TRANSITION_DURATION}
                    >
                        Author
                    </PivotButtonText>
                </PivotButton>
            </Pivot>
        </Container>
    );
}

export default FilterSelector;

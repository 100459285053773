// ===== Packages =====

import {
    Node,
    Editor,
    Transforms,
    Range,
    Path,
    NodeEntry,
}                           from 'slate';

// ===== Elements =====

import LineElement          from '../elements/lineElement';
import _HeaderElement       from '../elements/headerElement';

// ===== Interfaces =====

import {
    EditorNode,
    HeaderNode,
}                           from '../interfaces';

// ===== Enums =====

import { HEADER_SIZE }      from '../elements/enums';

export const HeaderElement = _HeaderElement;

const headerSizeIsValid = (headerSize: HEADER_SIZE | undefined): boolean => !!headerSize
    && Object.values(HeaderElement.HEADER_SIZE).includes(headerSize);

const getSelectionHeaders = (editor: Editor, headerSize = HeaderElement.HEADER_SIZE.one): NodeEntry<Node>[] => {
    if (headerSizeIsValid(headerSize)) {
        const isHeaderOfSize = (node: EditorNode | Node, size: number): boolean => HeaderElement.isHeaderElement(node)
            && 'headerSize' in node
            && node.headerSize === size;
        return Array.from(Editor.nodes(editor, { match: (node) => isHeaderOfSize(node, headerSize) }));
    }
    return [];
};

const hasSelectedH1 = (editor: Editor): boolean => {
    const [match] = getSelectionHeaders(editor);
    return !!match;
};

const hasSelectedH2 = (editor: Editor): boolean => {
    const [match] = getSelectionHeaders(editor, HeaderElement.HEADER_SIZE.two);
    return !!match;
};

const setAsHeaderElement = (editor: Editor, headerSize = HeaderElement.HEADER_SIZE.one): void => {
    const newHeaderSize = headerSizeIsValid(headerSize) ? headerSize : HeaderElement.HEADER_SIZE.one;
    Editor.withoutNormalizing(editor, () => {
        Transforms.setNodes(editor, HeaderElement.newHeaderElement({ headerSize }), { match: HeaderElement.implementsLineInterface });

        // NOTE: some LineInterface elements implement the ListableInterfaceElement
        //  so we make sure that all Headers set here no longer implement the ListableInterface
        Transforms.unsetNodes(editor, 'isListable', { match: HeaderElement.isHeaderElement });
    });
};

const setAsH1 = (editor: Editor): void => setAsHeaderElement(editor, HeaderElement.HEADER_SIZE.one);

const setAsH2 = (editor: Editor): void => setAsHeaderElement(editor, HeaderElement.HEADER_SIZE.two);

const toggleH1 = (editor: Editor): void => {
    if (hasSelectedH1(editor)) {
        const matchH1 = (node: EditorNode | Node): boolean => HeaderElement.isHeaderElement(node)
            && 'headerSize' in node
            && node.headerSize === HeaderElement.HEADER_SIZE.one;
        Editor.withoutNormalizing(editor, () => {
            Array.from(Editor.nodes(editor, { match: matchH1 })).forEach(([, path]) => {
                Transforms.setNodes(editor, LineElement.newLineElement(), { at: path });
                // NOTE: no non-Header LineInterface elements should have headerSize
                //  so we remove it here
                Transforms.unsetNodes(editor, 'headerSize', { at: path });
            });
        });
    } else {
        setAsH1(editor);
    }
};

const toggleH2 = (editor: Editor): void => {
    if (hasSelectedH2(editor)) {
        const matchH2 = (node: EditorNode | Node): boolean => HeaderElement.isHeaderElement(node)
            && 'headerSize' in node
            && node.headerSize === HeaderElement.HEADER_SIZE.two;
        Editor.withoutNormalizing(editor, () => {
            Array.from(Editor.nodes(editor, { match: matchH2 })).forEach(([, path]) => {
                Transforms.setNodes(editor, LineElement.newLineElement(), { at: path });
                // NOTE: no non-Header LineInterface elements should have headerSize
                //  so we remove it here
                Transforms.unsetNodes(editor, 'headerSize', { at: path });
            });
        });
    } else {
        setAsH2(editor);
    }
};

export const HeaderHelpers = {
    getSelectionHeaders,
    headerSizeIsValid,
    setAsHeaderElement,
    hasSelectedH1,
    hasSelectedH2,
    toggleH1,
    toggleH2,
};

export const withHeaders = (editor: Editor, _readOnly?: boolean): Editor => {
    const {
        normalizeNode,
        isVoid,
        isInline,
        insertBreak,
    } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [_, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path) as HeaderNode;

        if (!HeaderElement.isHeaderElement(node)) { return normalizeNode(entry); }

        const header = node;

        if (Editor.hasBlocks(editor, header)) { return Transforms.unwrapNodes(editor, { at: path }); }

        const headerDeclaresIsLineProperty = header.isLine === true;
        if (!headerDeclaresIsLineProperty) { return Transforms.setNodes<HeaderNode>(editor, { isLine: true }, { at: path }); }

        if (!headerSizeIsValid(header.headerSize)) {
            return Transforms.setNodes<HeaderNode>(editor, { headerSize: HeaderElement.HEADER_SIZE.one }, { at: path });
        }

        const headerIsFirstChildOfEditor = Path.equals(path, [0]);
        if (headerIsFirstChildOfEditor) {
            if (node.isFirstElement !== true) {
                return Transforms.setNodes<HeaderNode>(editor, { isFirstElement: true }, { at: path });
            }
        } else if ('isFirstElement' in node) {
            return Transforms.unsetNodes(editor, 'isFirstElement', { at: path });
        }
    };

    // eslint-disable-next-line no-param-reassign
    editor.insertBreak = () => {
        const [headerEntry] = Array.from(Editor.nodes(editor, { match: HeaderElement.isHeaderElement }));
        if (headerEntry) {
            const { selection } = editor;
            const [start, end] = Range.edges(selection as Range);
            const [startHeaderEntry] = Array.from(Editor.nodes(editor, { at: start.path, match: HeaderElement.isHeaderElement }));
            const [endHeaderEntry] = Array.from(Editor.nodes(editor, { at: end.path, match: HeaderElement.isHeaderElement }));
            if (startHeaderEntry && endHeaderEntry) {
                insertBreak();
                return Editor.withoutNormalizing(editor, () => {
                    Transforms.setNodes(editor, LineElement.newLineElement());
                    Transforms.unsetNodes(editor, 'headerSize');
                });
            }
            if (startHeaderEntry) {
                const [[endNode, endPath]] = Array.from(Editor.nodes(editor, { at: end.path, match: (n) => Editor.isBlock(editor, n), mode: 'lowest' }));
                insertBreak();
                return Editor.withoutNormalizing(editor, () => {
                    Transforms.setNodes(editor, endNode as EditorNode);
                    Transforms.unsetNodes(editor, ['headerSize', 'isLine']);
                });
            }
            if (endHeaderEntry) {
                insertBreak();
                const { headerSize } = endHeaderEntry[0] as HeaderNode;
                return Transforms.setNodes(editor, HeaderElement.newHeaderElement({ headerSize }));
            }
        }

        return insertBreak();
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => (HeaderElement.isHeaderElement(element) ? false : isInline(element));

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => (HeaderElement.isHeaderElement(node) ? false : isVoid(node));

    return editor;
};

// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                       from 'react';
import {
    doc,
    getDoc,
    getDocs,
    getFirestore,
    DocumentData,
    DocumentSnapshot,
    where,
    query,
    collection,
}                                       from 'firebase/firestore';
import {
    getDownloadURL,
    getStorage,
    ref,
}                                       from 'firebase/storage';
import { ReactSVG }                     from 'react-svg';
import { Transition }                   from 'react-transition-group';

// ===== Components =====

import Spinner                          from '../Spinner';
import NotificationGroup                from '../NotificationGroup';

// ===== Services =====

import {
    playAudio,
    recordUserAction,
    detectTouchDevice,
    getStorageErrorMessage,
    updateNotificationInDB,
    applyCharacterLimit,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
    IDimension,
    INotificationItem,
    INotificationGroupItem,
}                                       from '../../interfaces';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    USER_ACTION_TYPE,
    FIRESTORE_COLLECTION,
    STORAGE_ERROR_CODE,
    NOTIFICATION_TYPE,
    NOTIFICATION_GROUP_TYPE,
}                                       from '../../enums';

// ===== Sounds =====

import DialogExpand                     from '../../sounds/swoosh_in.mp3';
// eslint-disable-next-line import/no-duplicates
import DialogContract                   from '../../sounds/swoosh_out.mp3';
// eslint-disable-next-line import/no-duplicates
import DismissNotification              from '../../sounds/swoosh_out.mp3';

// ===== Images =====

import BellIcon                         from '../../images/bell.svg';
import PixelBellIcon                    from '../../images/8-bit_bell.svg';

// ===== Constants =====

import {
    DEFAULT_AUDIO_VOLUME,
    HOVER_TARGET_CLASSNAME,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    DIALOG_BODY_CONTAINER_TRANSITION_DURATION,
    DIALOG_BODY_OPACITY_DELAY_DURATION,
    NOTIFICATION_DIALOG__BORDER_RADIUS_MULTIPLIER,
    HEADER_BUTTON_TRANSITION_DURATION,
    BODY_FONT_SIZE,
    BELL_BUTTON_HEIGHT_MULTIPLIER,
    NOTIFICATION_DIALOG_CONTRACTED_TOP_MULTIPLIER,
    NOTIFICATION_DIALOG_EXPANDED_TOP_MULTIPLIER,
    NOTIFICATION_DIALOG_BODY_WIDTH_MULTIPLIER,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';

// ===== Styles =====

import {
    Container,
    DialogBody,
    DialogBodyContainer,
    BellIconContainer,
    NoNotificationsContainer,
    NoNotificationsText,
    NoNotificationsIcon,
    NotificationBadge,
    SpinnerContainer,
}                                       from './styles';
import { theme }                        from '../../themes/theme-context';

interface Props {
    user: IUserItem | null,
    style: any,
    hasSound: boolean,
    lightBackground: boolean,
    currentSessionId: string | null,
    viewportDimensions: IDimension,
    isExpanded: boolean,
    isRecognizedUser: boolean,
    userProfileDialogExpanded: boolean,
    refreshNotifications: boolean,
    setIsExpanded: (isExpanded: boolean) => void,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setRefreshNotifications: React.Dispatch<React.SetStateAction<boolean>>,
}
function NotificationDialog({
    user,
    style,
    hasSound,
    lightBackground,
    currentSessionId,
    isExpanded,
    isRecognizedUser,
    userProfileDialogExpanded,
    refreshNotifications,
    setIsExpanded,
    onCursorEnter,
    onCursorLeave,
    setRefreshNotifications,
}: Props): JSX.Element {
    // ===== General Constants =====

    const MAX_GROUP_NAME_COUNT = 30;
    const PLACEHOLDER_HEIGHT = 2.25 * BODY_FONT_SIZE;

    // ===== Refs =====

    // ----- Sound Clips
    const dialogExpandClip = useRef<HTMLAudioElement>(new Audio());
    const dialogContractClip = useRef<HTMLAudioElement>(new Audio());
    const dismissNotifClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    const [hasFetchedNotifications, setHasFetchedNotifications] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<INotificationItem[]>([]);
    const [notificationGroups, setNotificationGroups] = useState<INotificationGroupItem[]>([]);
    const [notificationAuthors, setNotificationAuthors] = useState<Map<string, IUserItem>>(new Map());
    const [notificationAvatars, setNotificationAvatars] = useState<Map<string, string>>(new Map());

    // ===== Animation Constants =====

    const DIALOG_BODY_OPACITY_DURATION = 150;
    const NOTIFICATION_BADGE_TRANSITION_DURATION = 150;
    const NOTIFICATION_COUNT_TRANSITION_DURATION = 500;

    // ===== Methods =====

    const fetchNotifications = async (): Promise<void> => {
        if (user && refreshNotifications) {
            const notificationGroupMap: Map<string, INotificationGroupItem> = new Map();
            const fetchedNotifications: INotificationItem[] = [];
            const db = getFirestore();
            const notificationCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.notifications
                : FIRESTORE_COLLECTION.stagingNotifications;
            const notificationQuery = query(
                collection(db, notificationCollection),
                where('userId', '==', user.id),
                where('read', '==', false),
            );
            const querySnapshot = await getDocs(notificationQuery);

            querySnapshot.forEach((document) => {
                if (document.exists()) {
                    const notification = document.data() as INotificationItem;
                    fetchedNotifications.push(notification);
                    // Add notification to notification group map
                    if (
                        notification.type === NOTIFICATION_TYPE.annotationComment
                        && notification.payload?.annotationId
                    ) {
                        if (notificationGroupMap.get(notification.payload.annotationId)) {
                            // add to existing notification group
                            const group = notificationGroupMap.get(notification.payload.annotationId)!;
                            const sortedItems = [...group.items, notification];
                            sortedItems.sort((a, b) => b.timestamp - a.timestamp);
                            const updatedGroup: INotificationGroupItem = {
                                id: group.id,
                                type: NOTIFICATION_GROUP_TYPE.annotation,
                                mostRecentTimestamp: group.mostRecentTimestamp > notification.timestamp
                                    ? group.mostRecentTimestamp
                                    : notification.timestamp,
                                itemType: group.itemType,
                                groupName: group.groupName,
                                items: sortedItems,
                            };
                            notificationGroupMap.set(notification.payload.annotationId, updatedGroup);
                        } else {
                            // create new annotation group
                            const group: INotificationGroupItem = {
                                id: notification.payload.annotationId,
                                type: NOTIFICATION_GROUP_TYPE.annotation,
                                mostRecentTimestamp: notification.timestamp,
                                itemType: NOTIFICATION_TYPE.annotationComment,
                                groupName: notification.payload?.postName
                                    ? applyCharacterLimit(
                                        notification.payload.postName,
                                        MAX_GROUP_NAME_COUNT,
                                    ) : undefined,
                                items: [notification],
                            };
                            notificationGroupMap.set(notification.payload.annotationId, group);
                        }
                    } else if (
                        notification.type === NOTIFICATION_TYPE.annotationComment
                        && !notification.payload?.annotationId
                    ) {
                        throw Error(`Notification ${notification.id} did not have a comment id in its payload.`);
                    }
                }
            });

            if (
                notificationGroupMap.size !== notificationGroups.length
                || notificationGroups.length !== fetchedNotifications.length
            ) {
                fetchedNotifications.sort((a, b) => b.timestamp - a.timestamp);
                setNotifications(fetchedNotifications);
                const groups = Array.from(notificationGroupMap.values());
                groups.sort((a, b) => b.mostRecentTimestamp - a.mostRecentTimestamp);
                setNotificationGroups(groups);
                setHasFetchedNotifications(true);
            } else if (
                notificationGroupMap.size === 0
                && !hasFetchedNotifications
            ) {
                setHasFetchedNotifications(true);
            }

            setRefreshNotifications(false);
        }
    };

    const onBellEnter = (e: React.MouseEvent | React.TouchEvent | React.SyntheticEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.notificationButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onBellLeave = (e: React.MouseEvent | React.TouchEvent | React.SyntheticEvent): void => {
        onCursorLeave(e);
    };

    const onToggleExpand = async (): Promise<void> => {
        const wasExpanded = isExpanded;
        setIsExpanded(!isExpanded);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: wasExpanded
                    ? USER_ACTION_TYPE.hideNotifications
                    : USER_ACTION_TYPE.viewNotifications,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }

        // Play Sound
        if (
            !isExpanded
            && hasSound
            && dialogExpandClip.current
        ) {
            dialogExpandClip.current.pause();
            dialogExpandClip.current.currentTime = 0;
            playAudio(dialogExpandClip.current);
        } else if (
            isExpanded
            && hasSound
            && dialogContractClip.current
        ) {
            dialogContractClip.current.pause();
            dialogContractClip.current.currentTime = 0;
            playAudio(dialogContractClip.current);
        }
    };

    const dismissNotification = async (notif: INotificationItem): Promise<void> => {
        // Play Sound
        if (hasSound && dismissNotifClip.current) {
            dismissNotifClip.current.pause();
            dismissNotifClip.current.currentTime = 0;
            playAudio(dismissNotifClip.current);
        }

        const notificationCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.notifications
            : FIRESTORE_COLLECTION.stagingNotifications;
        await updateNotificationInDB({
            collection: notificationCollection,
            id: notif.id,
            read: true,
        });

        setRefreshNotifications(true);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.readNotification,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            dialogExpandClip.current
            && dialogContractClip.current
            && dismissNotifClip.current
        ) {
            // Dialog Expand
            dialogExpandClip.current.volume = DEFAULT_AUDIO_VOLUME;
            dialogExpandClip.current.src = DialogExpand;

            // Dialog Contract
            dialogContractClip.current.volume = DEFAULT_AUDIO_VOLUME;
            dialogContractClip.current.src = DialogContract;

            // Dismiss Notification
            dismissNotifClip.current.volume = DEFAULT_AUDIO_VOLUME;
            dismissNotifClip.current.src = DismissNotification;
        }

        return function cleanup() {
            if (dialogExpandClip.current) dialogExpandClip.current.remove();
            if (dialogContractClip.current) dialogContractClip.current.remove();
            if (dismissNotifClip.current) dismissNotifClip.current.remove();
        };
    }, []);

    useEffect(() => {
        if (user && refreshNotifications) {
            fetchNotifications();
        }
    }, [user, refreshNotifications]);

    /**
     * Fetches user items of all notifications
     */
    useEffect(() => {
        if (
            notifications.length > 0
            && notificationAuthors.size === 0
        ) {
            const db = getFirestore();
            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const authorPromises: Promise<DocumentSnapshot<DocumentData>>[] = [];
            for (let i = 0; i < notifications.length; i += 1) {
                const { userId } = notifications[i];
                authorPromises.push(getDoc(doc(db, usersCollection, userId)));
            }
            Promise.all(authorPromises).then((authors) => {
                const notifAuthors: Map<string, IUserItem> = new Map();
                const authorItems = authors
                    .map((authorSnap) => authorSnap.data() as IUserItem);
                authorItems.forEach((item, index) => {
                    if (item) {
                        notifAuthors.set(notifications[index].userId, item);
                    }
                });
                setNotificationAuthors(notifAuthors);
            });
        }
    }, [notifications]);

    /**
     * Fetches avatars of all notification authors
     */
    useEffect(() => {
        if (
            notificationAuthors.size > 0
            && notificationAvatars.size === 0
        ) {
            const storage = getStorage();
            const avatars: Map<string, string> = new Map();
            notificationAuthors.forEach((author) => {
                if (author && author.avatarFilePath) {
                    const pathParts = author.avatarFilePath.split('.');
                    const mediumPath = `${pathParts[0]}_medium.${pathParts[1]}`;
                    getDownloadURL(ref(storage, mediumPath)).then((imageURL) => {
                        avatars.set(author.id, imageURL);
                    }).catch((error) => {
                        // We assume cloud function has not yet generated medium image yet
                        if (error.code === STORAGE_ERROR_CODE.objectNotFound) {
                            getDownloadURL(ref(storage, author.avatarFilePath)).then((imageURL) => {
                                avatars.set(author.id, imageURL);
                            }).catch((err) => {
                                throw Error(getStorageErrorMessage(err.code as STORAGE_ERROR_CODE));
                            });
                        } else {
                            throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
                        }
                    });
                }
            });
            setNotificationAvatars(avatars);
        }
    }, [notificationAuthors]);

    // ===== Rendering =====

    return (
        <Container
            isExpanded={isExpanded}
            isRecognizedUser={isRecognizedUser}
            lightBackground={lightBackground}
            userProfileDialogExpanded={userProfileDialogExpanded}
            transitionDuration={HEADER_BUTTON_TRANSITION_DURATION}
            style={style}
        >
            <BellIconContainer
                height={BELL_BUTTON_HEIGHT_MULTIPLIER * BODY_FONT_SIZE}
                detectTouchDevice={detectTouchDevice(document)}
                lightBackground={lightBackground}
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e: React.TouchEvent) => onBellEnter(e),
                } : {
                    onMouseEnter: (e: React.MouseEvent) => onBellEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e: React.TouchEvent) => onBellLeave(e),
                } : {
                    onMouseLeave: (e: React.MouseEvent) => onBellLeave(e),
                })}
                onMouseDown={onToggleExpand}
            >
                <ReactSVG
                    src={PixelBellIcon}
                />
            </BellIconContainer>
            <Transition
                in={notificationGroups.length > 0}
                timeout={{
                    enter: NOTIFICATION_BADGE_TRANSITION_DURATION,
                    exit: NOTIFICATION_BADGE_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <NotificationBadge
                        count={notificationGroups.length}
                        transitionDuration={NOTIFICATION_COUNT_TRANSITION_DURATION}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'left',
                                offset: 10,
                                duration: NOTIFICATION_BADGE_TRANSITION_DURATION,
                                easing: theme.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'left',
                                offset: 10,
                            })[state],
                        }}
                    />
                )}
            </Transition>
            <DialogBodyContainer
                contractedTop={NOTIFICATION_DIALOG_CONTRACTED_TOP_MULTIPLIER * BODY_FONT_SIZE}
                expandedTop={NOTIFICATION_DIALOG_EXPANDED_TOP_MULTIPLIER * BODY_FONT_SIZE}
                borderRadius={NOTIFICATION_DIALOG__BORDER_RADIUS_MULTIPLIER * BODY_FONT_SIZE}
                width={NOTIFICATION_DIALOG_BODY_WIDTH_MULTIPLIER * BODY_FONT_SIZE}
                isExpanded={isExpanded}
                isRecognizedUser={isRecognizedUser}
                transitionDuration={DIALOG_BODY_CONTAINER_TRANSITION_DURATION}
            >
                <DialogBody
                    isExpanded={isExpanded}
                    opacityDuration={DIALOG_BODY_OPACITY_DURATION}
                    transitionDelayDuration={DIALOG_BODY_OPACITY_DELAY_DURATION}
                >
                    {notifications.length > 0
                        ? (
                            notificationGroups.map((group) => (
                                <NotificationGroup
                                    group={group}
                                    user={user}
                                    currentSessionId={currentSessionId}
                                    dismissNotification={dismissNotification}
                                    notificationAuthors={notificationAuthors}
                                    notificationAvatars={notificationAvatars}
                                    onCursorEnter={onCursorEnter}
                                    onCursorLeave={onCursorLeave}
                                />
                            ))
                        ) : (
                            <NoNotificationsContainer
                                height={!hasFetchedNotifications ? PLACEHOLDER_HEIGHT : undefined}
                            >
                                {hasFetchedNotifications
                                    ? (
                                        <>
                                            <NoNotificationsIcon>
                                                <ReactSVG
                                                    src={BellIcon}
                                                />
                                            </NoNotificationsIcon>
                                            <NoNotificationsText>
                                                No notifications
                                            </NoNotificationsText>
                                        </>
                                    ) : (
                                        <SpinnerContainer>
                                            <Spinner
                                                lightBackground
                                            />
                                        </SpinnerContainer>
                                    )}
                            </NoNotificationsContainer>
                        )}
                </DialogBody>
            </DialogBodyContainer>
        </Container>
    );
}

export default NotificationDialog;

// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                               from 'react';
import styled                   from 'styled-components';
import { Transition }           from 'react-transition-group';

// ===== Components =====

import {
    Button,
    Annotation,
}                               from '.';

// ===== Services =====

import {
    recordUserAction,
    setColorLightness,
}                               from '../../../services';

// ===== Interfaces =====

import {
    IUserItem,
    IAnnotationItem,
    IPostItem,
    ISnackbarItem,
    IDimension,
    IZoomLevelItem,
}                               from '../../../interfaces';

// ===== Enums =====

import {
    BUTTON_TYPE,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    USER_ACTION_TYPE,
}                               from '../../../enums';

// ===== Constants =====

import CURSOR_SIGN              from '../../../constants/cursorSigns';
import {
    BODY_FONT_SIZE,
    ANNOTATION_BUCKET_Z_INDEX,
    DETAIL_VIEW_HANDLEBAR_CONTAINER_WIDTH,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    HOVER_TARGET_CLASSNAME,
    ANNOTATION_FOCUSED_CLOSE_BUTTON_HEIGHT,
    DETAIL_VIEW_TRANSITION_DURATION,
    ERROR_BACKGROUND_LIGHTNESS_VALUE,
}                               from '../../../constants/generalConstants';

// ===== Styles =====

import { theme as themeObj }    from '../../../themes/theme-context';

interface Props {
    user: IUserItem | null,
    post: IPostItem | undefined,
    hasSound: boolean,
    currentSessionId: string | null,
    annotations: IAnnotationItem[],
    top: number,
    width: number,
    postBannerHeight: number,
    bucketIndex: number,
    bucketCount: number,
    nextBucketTop: number | null,
    viewportDimensions: IDimension,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    setTargetAnnotationID: React.Dispatch<React.SetStateAction<string | null>>,
    setCursorSigns: React.Dispatch<React.SetStateAction<string[]>>,
    postScrollTop: number,
    showAnnotationDetail: (annotation: IAnnotationItem) => void,
    hideAnnotationDetail: () => void,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    clearTimeoutAnnotationNudge: () => void,
    showWarning: boolean,
    selectedPostValuePath: number[],
    currentZoomLevel: IZoomLevelItem | null,
    setRefreshNotifications: React.Dispatch<React.SetStateAction<boolean>>,
    setIsUserProfileDialogExpanded: (isExpanded: boolean) => void,
    setNotifyUserToSignUpAfterAnnotationCreationAttempt: React.Dispatch<React.SetStateAction<boolean>>,
}
function AnnotationBucket({
    user,
    post,
    hasSound,
    currentSessionId,
    annotations,
    top,
    width,
    postBannerHeight,
    bucketIndex,
    bucketCount,
    nextBucketTop,
    viewportDimensions,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
    setTargetAnnotationID,
    setCursorSigns,
    postScrollTop,
    showAnnotationDetail,
    hideAnnotationDetail,
    setSnackbarData,
    clearTimeoutAnnotationNudge,
    showWarning,
    selectedPostValuePath,
    currentZoomLevel,
    setRefreshNotifications,
    setIsUserProfileDialogExpanded,
    setNotifyUserToSignUpAfterAnnotationCreationAttempt,
}: Props): JSX.Element {
    // ===== General Constants =====

    const ANNOTATION_MARGIN_BOTTOM = 0.3125 * BODY_FONT_SIZE;
    const ANNOTATION_HORIZONTAL_MARGIN = 0.625 * BODY_FONT_SIZE;
    const ANNOTATION_STACK_BUTTON_HEIGHT = 1.5625 * BODY_FONT_SIZE;
    const PAGE_MARGIN_BOTTOM = 0.625 * BODY_FONT_SIZE;

    // ===== Refs =====

    const containerRef = useRef<HTMLDivElement | null>(null);

    // ===== State =====

    // stores height of annotation bucket
    const [bucketHeight, setBucketHeight] = useState<number | null>(null);
    // indicates whether bucker is in stack mode
    const [annotationStackActive, setAnnotationStackActive] = useState<boolean>(false);
    // indicates whether to expand out annotations if bucket is in stack mode
    const [annotationStackExpanded, setAnnotationStackExpanded] = useState<boolean>(false);
    // indicates whether a child annotation is focused
    const [childAnnotationIsFocused, setChildAnnotationIsFocused] = useState<string | null>(null);
    // indicates whether we should remeasure bucket height
    const [remeasureBucketHeight, setRemeasureBucketHeight] = useState<boolean>(true);

    // ===== Annotation Constants =====

    const ANNOTATION_BUCKET_TRANSITION_DURATION = DETAIL_VIEW_TRANSITION_DURATION; // To sync warning transition
    const ANNOTATION_STACK_CLOSE_BUTTON_TRANSITION_DURATION = 200;

    // ===== Methods =====

    const expandAnnotationStack = async (): Promise<void> => {
        setAnnotationStackExpanded(true);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.toggleAnnotationStack,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    expanded: true,
                },
            });
        }
    };

    const closeAnnotationStack = async (): Promise<void> => {
        setAnnotationStackExpanded(false);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.toggleAnnotationStack,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    expanded: false,
                },
            });
        }
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorElementButton,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onShowAnnotationDetail = (annotation: IAnnotationItem): void => {
        setChildAnnotationIsFocused(annotation.id);
        showAnnotationDetail(annotation);
    };

    const onHideAnnotationDetail = (): void => {
        setChildAnnotationIsFocused(null);
        hideAnnotationDetail();
    };

    // ===== Side Effects =====

    // remeasure bucker height so annotation stacks
    // adjust
    useEffect(() => {
        if (remeasureBucketHeight && containerRef.current) {
            const { height } = containerRef.current.getBoundingClientRect();
            if (height !== bucketHeight) setBucketHeight(height);
            setRemeasureBucketHeight(false);
        }
    }, [
        remeasureBucketHeight,
    ]);

    // Reset bucket when scroll
    useEffect(() => {
        if (annotationStackActive && annotationStackExpanded) {
            setAnnotationStackExpanded(false);
        }
    }, [postScrollTop]);

    // Determine whether to reveal all annotations or render as a stack
    useEffect(() => {
        if (nextBucketTop && bucketHeight) {
            if (top + bucketHeight > nextBucketTop && !annotationStackActive) {
                setAnnotationStackActive(true);
            } else if (annotationStackActive) {
                setAnnotationStackActive(false);
            }
        }
    }, [
        annotations,
        top,
        bucketHeight,
        nextBucketTop,
    ]);

    return (
        <Container
            ref={containerRef}
            top={top}
            width={width}
            zIndex={bucketCount - bucketIndex}
            childIsFocused={!!childAnnotationIsFocused}
            postBannerHeight={postBannerHeight + ANNOTATION_FOCUSED_CLOSE_BUTTON_HEIGHT + 10}
            pageMarginBottom={PAGE_MARGIN_BOTTOM}
            showWarning={showWarning}
            transitionDuration={ANNOTATION_BUCKET_TRANSITION_DURATION}
        >
            <Transition
                in={annotationStackExpanded}
                timeout={{
                    enter: ANNOTATION_STACK_CLOSE_BUTTON_TRANSITION_DURATION,
                    exit: ANNOTATION_STACK_CLOSE_BUTTON_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {
                    (state) => (
                        <AnnotationStackCloseButtonContainer
                            buttonHeight={ANNOTATION_STACK_BUTTON_HEIGHT}
                            marginRight={ANNOTATION_HORIZONTAL_MARGIN}
                            marginTop={ANNOTATION_MARGIN_BOTTOM}
                            style={{
                                ...FADE_IN_DEFAULT_STYLE({
                                    direction: 'up',
                                    offset: 10,
                                    duration: ANNOTATION_STACK_CLOSE_BUTTON_TRANSITION_DURATION,
                                    easing: themeObj.motion.eagerEasing,
                                }),
                                ...FADE_IN_TRANSITION_STYLES({
                                    direction: 'up',
                                    offset: 10,
                                })[state],
                            }}
                        >
                            <Button
                                boxShadow
                                className={HOVER_TARGET_CLASSNAME}
                                type={BUTTON_TYPE.solid}
                                background={themeObj.color.neutral800}
                                height={ANNOTATION_STACK_BUTTON_HEIGHT}
                                text="Close"
                                onMouseEnter={onButtonMouseEnter}
                                onMouseLeave={onButtonMouseLeave}
                                onMouseDown={closeAnnotationStack}
                            />
                        </AnnotationStackCloseButtonContainer>
                    )
                }
            </Transition>
            {annotations
                .sort((a, b) => {
                    if (a.comments.length > b.comments.length) return 1;
                    if (a.comments.length === b.comments.length) {
                        if (a.views.length > 10 * b.views.length) return 1;
                        if (10 * a.views.length > 10 * b.views.length) return -1;
                        if (a.published < b.published) return 1;
                        if (a.published > b.published) return -1;
                        return 0;
                    }
                    return -1;
                })
                .map((annotation, index) => (
                    <Annotation
                        key={annotation.id}
                        annotation={annotation}
                        hasSound={hasSound}
                        user={user}
                        post={post}
                        bucketWidth={width}
                        currentSessionId={currentSessionId}
                        annotationStackActive={annotationStackActive}
                        index={index}
                        horizontalMargin={ANNOTATION_HORIZONTAL_MARGIN}
                        zIndex={annotations.length - index}
                        annotationCount={annotations.length}
                        hide={!!childAnnotationIsFocused && childAnnotationIsFocused !== annotation.id}
                        annotationStackExpanded={annotationStackExpanded}
                        bucketHeight={bucketHeight}
                        viewportDimensions={viewportDimensions}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        setInputFocused={setInputFocused}
                        setTargetAnnotationID={setTargetAnnotationID}
                        setCursorSigns={setCursorSigns}
                        showAnnotationDetail={onShowAnnotationDetail}
                        hideAnnotationDetail={onHideAnnotationDetail}
                        setSnackbarData={setSnackbarData}
                        setRemeasureBucketHeight={setRemeasureBucketHeight}
                        clearTimeoutAnnotationNudge={clearTimeoutAnnotationNudge}
                        {...(index === 0 && annotations.length > 0 && annotationStackActive
                            ? { toggleAnnotationStack: expandAnnotationStack }
                            : {}
                        )}
                        selectedPostValuePath={selectedPostValuePath}
                        currentZoomLevel={currentZoomLevel}
                        setRefreshNotifications={setRefreshNotifications}
                        setIsUserProfileDialogExpanded={setIsUserProfileDialogExpanded}
                        setNotifyUserToSignUpAfterAnnotationCreationAttempt={setNotifyUserToSignUpAfterAnnotationCreationAttempt}
                    />
                ))}
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    top: number,
    width: number,
    zIndex: number,
    childIsFocused: boolean,
    postBannerHeight: number,
    pageMarginBottom: number,
    showWarning: boolean,
    transitionDuration: number,
}
const Container = styled.div<ContainerProps>`
    position: ${({ childIsFocused }) => (childIsFocused ? 'fixed' : 'absolute')};
    top: ${({
        childIsFocused,
        postBannerHeight,
        top,
    }) => `${childIsFocused ? postBannerHeight : top}px`};
    left: ${({ childIsFocused }) => (childIsFocused
        ? 'auto'
        : `${DETAIL_VIEW_HANDLEBAR_CONTAINER_WIDTH}px`
    )};
    right: ${({ childIsFocused }) => (childIsFocused
        ? '0px'
        : 'auto'
    )};
    width: ${({ width }) => `${width}px`};
    height: ${({
        childIsFocused,
        postBannerHeight,
        pageMarginBottom,
    }) => (childIsFocused
        // Will be late to update because no rerender is triggered
        // by scrolling. This is a tradeoff we made to improve performance
        ? `calc(100vh - ${postBannerHeight + pageMarginBottom}px)`
        : 'auto'
    )};
    background: ${({ showWarning, theme }) => {
        if (showWarning) {
            return setColorLightness(
                theme.color.error,
                ERROR_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        return 'transparent';
    }};
    z-index: ${({ childIsFocused, zIndex }) => (childIsFocused
        ? ANNOTATION_BUCKET_Z_INDEX
        : zIndex)};
    transition: ${({ transitionDuration, theme }) => `
        background ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        left: 0px;
        background: ${({ theme }) => theme.color.white};
    }
`;

interface AnnotationStackCloseButtonContainerProps {
    buttonHeight: number,
    marginRight: number,
    marginTop: number,
}
const AnnotationStackCloseButtonContainer = styled.div<AnnotationStackCloseButtonContainerProps>`
    position: absolute;
    top: ${({ buttonHeight, marginTop }) => `-${buttonHeight + marginTop}px`};
    right: 0px;
    margin-right: ${({ marginRight }) => `${marginRight}px`};
    margin-bottom: ${`${0.625 * BODY_FONT_SIZE}px`};
    background: ${({ theme }) => theme.color.white};
`;

export default AnnotationBucket;

// ===== Packages =====

import React                                    from 'react';
import styled                                   from 'styled-components';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
}                                               from '../../../constants/generalConstants';

// ===== Styles =====

import { basicEditorFontStyles }                from '../../../styles';

interface Props {
    children: React.ReactElement,
    attributes: any,
}
function ListItem({ attributes, children }: Props): JSX.Element {
    return (
        <Container {...attributes}>
            {children}
        </Container>
    );
}

// ===== Styled Components =====

const Container = styled.li`
    ${basicEditorFontStyles}
    font-size: 1em;
    margin-top: ${`${0.625 * BODY_FONT_SIZE}px`};
`;

export default ListItem;

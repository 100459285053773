// ===== Packages =====

import styled               from 'styled-components';

// ===== Services =====

import {
    hexToRgb,
    setColorLightness,
}                           from '../../services';

// ===== Constants =====

import {
    PROGRESS_BAR_LIGHTNESS_VALUE,
    READER_LOCALIZING_NAVIGATOR_Z_INDEX,
    BODY_FONT_SIZE,
}                           from '../../constants/generalConstants';
import FONT_TYPE            from '../../constants/fontType';

const LOCALIZING_NAVIGATOR_PADDING = 0.625 * BODY_FONT_SIZE;

export const Container = styled.div`
    position: fixed;
    bottom: ${`${1.40625 * BODY_FONT_SIZE}px`};
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: ${`${25 * BODY_FONT_SIZE}px`};
    z-index: ${READER_LOCALIZING_NAVIGATOR_Z_INDEX};
`;

interface NavigationTipProps {
    isLeft?: boolean,
}
export const NavigationTip = styled.div<NavigationTipProps>`
    position: absolute;
    top: 50%;
    transform: ${({ isLeft }) => `translate(${isLeft ? '-' : ''}100%, -50%)`};
    left: ${({ isLeft }) => (isLeft ? `-${0.3125 * BODY_FONT_SIZE}px` : 'auto')};
    right: ${({ isLeft }) => (isLeft ? 'auto' : `-${0.3125 * BODY_FONT_SIZE}px`)};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    font-weight: 400;
    color: ${({ theme }) => theme.color.white};
    text-align: ${({ isLeft }) => (isLeft ? 'right' : 'left')};
    max-width: ${`${9.375 * BODY_FONT_SIZE}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px ${0.625 * BODY_FONT_SIZE}px`};
    border-radius: ${`${1.125 * BODY_FONT_SIZE}px`};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    ${({ theme }) => (() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const rgbColor = hexToRgb(theme.verascopeColor.purple300)!;
        if (CSS.supports('backdrop-filter: blur(5px)')) {
            return `
                backdrop-filter: blur(5px);
                background-color: rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.4);
            `;
        }

        return `background-color: rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.4);`;
    })()};

    ${({ theme }) => theme.mediaQuery.small} {
        display: none;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: none;
    }
`;

export const LocalizingNavigator = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.verascopeColor.purple200};
    height: ${`${3.125 * BODY_FONT_SIZE}px`};
    width: max-content;
    padding:  ${`${LOCALIZING_NAVIGATOR_PADDING}px`};
    border-radius: ${`${1.5625 * BODY_FONT_SIZE}px`};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    overflow: hidden;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: ${`${17.5 * BODY_FONT_SIZE}px`};
    }
`;

interface ProgressBarProps {
    initialProgress: number,
}
export const PostProgressBar = styled.div<ProgressBarProps>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ initialProgress }) => `${initialProgress}%`}; // Modified directly using ref in ReaderView
    background: ${({ theme }) => setColorLightness(
        theme.verascopeColor.purple200,
        PROGRESS_BAR_LIGHTNESS_VALUE,
    )};
    opacity: 0.8;
    border-radius: ${`${0.15625 * BODY_FONT_SIZE}px`};
    z-index: 0;
`;

interface ButtonContainerProps {
    isLeft?: boolean,
}
export const ButtonContainer = styled.div<ButtonContainerProps>`
    position: absolute;
    top: ${`${LOCALIZING_NAVIGATOR_PADDING}px`};
    left: ${({ isLeft }) => (isLeft ? `${LOCALIZING_NAVIGATOR_PADDING}px` : 'auto')};
    right: ${({ isLeft }) => (isLeft ? 'auto' : `${LOCALIZING_NAVIGATOR_PADDING}px`)};
`;

interface LocalizingTextContainerProps {
    marginLeft: number,
    marginRight: number,
}
export const LocalizingTextContainer = styled.div<LocalizingTextContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: ${({ marginLeft }) => `${LOCALIZING_NAVIGATOR_PADDING + marginLeft}px`};
    margin-right: ${({ marginRight }) => `${LOCALIZING_NAVIGATOR_PADDING + marginRight}px`};
    z-index: 1;
`;

export const LocalizingPrimaryText = styled.div`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    font-weight: 500;
    color: ${({ theme }) => theme.verascopeColor.purple500};
    text-align: center;
    margin-top: ${`${0.15625 * BODY_FONT_SIZE}px`};
    white-space: nowrap;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: ${`${9.375 * BODY_FONT_SIZE}px`};
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const LocalizingSecondaryText = styled.div`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.7em;
    font-weight: 400;
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    white-space: nowrap;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: none;
    }
`;

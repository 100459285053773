// ===== Packages =====

import React, {
    useRef,
}                                               from 'react';
import styled                                   from 'styled-components';

// ===== Styles =====

import { basicEditorFontStyles }                from '../../../styles';

interface Props {
    isFirstElement: boolean,
    children: React.ReactElement,
    attributes: any,
}
function HeaderTwo({
    isFirstElement = false,
    children,
    attributes,
}: Props): JSX.Element {
    // ===== Refs =====

    const headerRef = useRef<HTMLHeadingElement>(null);

    return (
        <Container
            {...attributes}
            ref={headerRef}
            isFirstElement={isFirstElement}
        >
            {children}
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    isFirstElement: boolean,
}
const Container = styled.h2<ContainerProps>`
    ${basicEditorFontStyles}
    margin: 0;
    margin-top: 0.5em;
    font-size: 1.5em;
    font-weight: 700;
    color: ${({ theme }) => theme.color.purple100};
    ${({ isFirstElement }) => isFirstElement && `
        margin-top: 0;
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 1.2em;
    }
`;

export default HeaderTwo;

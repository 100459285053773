// ** IMPORTANT **: Keep in sync with functions/src/index.ts
export enum MAIL_TRACKING_TYPE {
    orderConfirmation = 'order-confirmation',
    physicalBookSale = 'physical-book-sale',
    digitalBookFulfillment = 'digital-book-fulfillment',
    webBookFulfillment = 'web-book-fulfillment',
    physicalBookShippingNotice = 'physical-book-shipping-notice',
    annotationCommentNotice = 'annotation-comment-notice',
    weeklyCommentDigest = 'weekly-comment-digest',
    newAccount = 'new-account',
    mailingListSubscription = 'mailing-list-subscription',
    verifyEmail = 'verify-email',
    resetPassword = 'reset-password',
}

// ===== Packages =====

import styled                   from 'styled-components';

// ===== Constants =====

import FONT_TYPE                from '../../constants/fontType';

// ===== Services =====

import {
    hexToRgb,
}                               from '../../services';

interface ContainerProps {
    buttonCount: number,
    buttonMargin: number,
    buttonWidth: number,
    buttonHeight: number,
    background?: string,
}
export const Container = styled.div<ContainerProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    width: ${({
        buttonMargin,
        buttonWidth,
        buttonCount,
    }) => `${buttonMargin + buttonCount * (buttonWidth + buttonMargin)}px`};
    height: ${({
        buttonMargin,
        buttonHeight,
    }) => `${((2 * buttonMargin) + buttonHeight)}px`};
    padding: ${({ buttonMargin }) => `${buttonMargin}px`};
    background: ${({ background }) => background || 'transparent'};
    border-radius: ${({
        buttonMargin,
        buttonHeight,
    }) => `${((2 * buttonMargin) + buttonHeight) / 2}px`};
`;

interface OptionButtonProps {
    index: number,
    selected: boolean,
    disabled: boolean,
    hoverDuration: number,
    buttonMargin: number,
    buttonWidth: number,
    buttonHeight: number,
    smallButtonWidth: number,
    smallButtonHeight: number,
}
export const OptionButton = styled.button<OptionButtonProps>`
    position: absolute;
    top: ${({ buttonMargin }) => `${buttonMargin}px`};
    left: ${({
        index,
        buttonMargin,
        buttonWidth,
    }) => `${index * (buttonWidth + buttonMargin)}px`};
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${({ buttonWidth }) => `${buttonWidth}px`};
    height: ${({ buttonHeight }) => `${buttonHeight}px`};
    border-radius: ${({ buttonHeight }) => `${buttonHeight / 2}px`};
    margin: ${({ buttonMargin }) => `0px ${buttonMargin / 2}px`}; /* Half so each neighboring buttons contribute to total */
    background: transparent;
    border: none;
    outline: none;
    cursor: none;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    transition: ${({
        theme,
        hoverDuration,
    }) => `
        background ${hoverDuration}ms ${theme.motion.standardEasing}
    `};

    & h3 {
        color: ${({ selected, disabled, theme }) => {
        if (disabled) {
            return `${theme.color.neutral700} !important`;
        }

        if (selected && !disabled) {
            return `${theme.color.white} !important`;
        }

        return theme.color.neutral1000;
    }};
    }

    &:hover {
        background: ${({ theme, selected }) => (selected
        ? 'transparent'
        : `rgba(
            ${hexToRgb(theme.color.neutral300)!.r},
            ${hexToRgb(theme.color.neutral300)!.g},
            ${hexToRgb(theme.color.neutral300)!.b},
            0.6
        )`
    )};

        & h3 {
            color: ${({ theme }) => theme.color.neutral900};
        }

        & h4 {
            color: ${({ theme }) => theme.color.neutral700};
        }
    }
`;

interface OptionButtonTextProps {
    transitionDuration: number,
}
export const OptionButtonText = styled.h3<OptionButtonTextProps>`
    position: relative;
    margin: 0;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 0.9em;
    line-height: 0.9em;
    transition: ${({
        theme,
        transitionDuration,
    }) => `background ${transitionDuration}ms ${theme.motion.standardEasing}`};
`;

// ===== Packages =====

import styled           from 'styled-components';

// ===== Constants =====

import {
    CURSOR_Z_INDEX,
    BODY_FONT_SIZE,
}                       from '../../constants/generalConstants';
import FONT_TYPE        from '../../constants/fontType';

const SNACKBAR_ICON_LENGTH = 2.5 * BODY_FONT_SIZE;
const SNACKBAR_ICON_MARGIN = 0.625 * BODY_FONT_SIZE;

interface ContainerProps {
    hasFailure: boolean,
    hasSuccess: boolean,
    hasIcon: boolean,
    dismissable: boolean,
}
export const Container = styled.div<ContainerProps>`
    position: fixed;
    bottom: ${`${3.125 * BODY_FONT_SIZE}px`};
    left: 50%;
    transform: translateX(-50%);
    padding: ${`${0.625 * BODY_FONT_SIZE}px ${1.25 * BODY_FONT_SIZE}px`};
    ${({ hasIcon }) => hasIcon && `
        padding-left: ${0.625 * BODY_FONT_SIZE}px;
    `};
    border-radius: ${`${0.625 * BODY_FONT_SIZE}px`};
    max-width: ${`${31.25 * BODY_FONT_SIZE}px`};
    background-color: ${({
        theme,
        hasFailure,
        hasSuccess,
    }) => {
        if (hasFailure) {
            return theme.verascopeColor.red200;
        }

        if (hasSuccess) {
            return theme.verascopeColor.blue200;
        }

        return theme.verascopeColor.purple200;
    }};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    z-index: ${CURSOR_Z_INDEX - 1};
    pointer-events: ${({ dismissable }) => (dismissable ? 'auto' : 'none')};
`;

export const SnackbarIcon = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: ${`${SNACKBAR_ICON_LENGTH}px`};
    height: ${`${SNACKBAR_ICON_LENGTH}px`};
    padding: ${`${0.3125 * BODY_FONT_SIZE}px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 80%;
        height: 80%;
        fill: ${({ theme }) => theme.color.white};
    }
`;

interface SnackbarTextProps {
    center: boolean,
    hasIcon: boolean,
    dismissable: boolean,
    buttonLength: number,
}
export const SnackbarText = styled.div<SnackbarTextProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1em;
    font-weight: 500;
    line-height: 1.5em;
    min-width: 10ch;
    max-width: 31ch;
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    color: ${({ theme }) => theme.color.white};
    margin-left: ${({ hasIcon }) => `${hasIcon
        ? SNACKBAR_ICON_LENGTH + SNACKBAR_ICON_MARGIN
        : 0
    }px`};
    margin-right: ${({ dismissable, buttonLength }) => `${dismissable
        ? buttonLength + SNACKBAR_ICON_MARGIN
        : 0
    }px`};
`;

export const DismissButtonContainer = styled.div`
    position: absolute;
    top: ${`${0.46875 * BODY_FONT_SIZE}px`};
    right: ${`${0.625 * BODY_FONT_SIZE}px`};
`;

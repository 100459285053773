// ===== Packages =====

import {
    Node,
    Path,
    Point,
    Editor,
    NodeEntry,
    Location,
    BaseSelection,
    BaseRange,
    Transforms,
}                           from 'slate';
import { isEqual }          from 'lodash';

// ===== Elements =====

import { LineElement }      from './linesPlugin';
import _BottomBufferElement from '../elements/bottomBufferElement';

// ===== Interfaces =====

import {
    EditorNode,
}                           from '../interfaces';

export const BottomBufferElement = _BottomBufferElement;

const normalizeBottomBuffer = (editor: Editor, entry: NodeEntry<Node>): void => {
    const [, path] = entry;

    const [parent] = Editor.parent(editor, path);
    const bottomBufferIsImmediateChildOfEditor = Editor.isEditor(parent);
    if (!bottomBufferIsImmediateChildOfEditor) { return Transforms.removeNodes(editor, { at: path, voids: true }); }

    const previousEntry = Editor.previous(editor, { at: path });

    const isFirstChildOfEditor = !previousEntry;
    if (isFirstChildOfEditor) { return Transforms.insertNodes(editor, LineElement.newLineElement(), { at: path }); }

    const [previousSibling] = previousEntry;
    const bbPreviousSiblingIsLine = LineElement.isLineElement(previousSibling);
    if (!bbPreviousSiblingIsLine) { return Transforms.insertNodes(editor, LineElement.newLineElement(), { at: path }); }
};

const normalizeDocument = (editor: Editor, readOnly?: boolean): void => {
    const bottomBufferEntries = editor.children
        .map((child, i) => [child, [i]])
        .filter(([child]) => BottomBufferElement.isBottomBufferElement(child as EditorNode));

    if (bottomBufferEntries.length === 1) {
        // check if BottomBuffer is in wrong position
        const [[bb, path]] = bottomBufferEntries;
        const bottomBufferIsEditorsLastChild = isEqual(path, [editor.children.length - 1]);
        if (!bottomBufferIsEditorsLastChild) { return Transforms.removeNodes(editor, { at: path as Location }); }

        return normalizeBottomBuffer(editor, [bb as EditorNode, path as Path]);
    } if (bottomBufferEntries.length === 0 && !readOnly) {
        // must have at least 1 BottomBuffer
        // but we don't enforce in readOnly mode
        const lastImmediateChildPath = [editor.children.length];
        return Transforms.insertNodes(
            editor,
            BottomBufferElement.newBottomBufferElement(),
            { at: lastImmediateChildPath },
        );
    }
};

export const withBottomBuffers = (editor: Editor, readOnly?: boolean): Editor => {
    const { normalizeNode, isInline, isVoid } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [_, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path) as EditorNode;

        // NOTE: we return here because this is the only plugin that should own
        //  normalizing a BottomBuffer
        if (BottomBufferElement.isBottomBufferElement(node)) { return normalizeBottomBuffer(editor, [node, path]); }

        // NOTE: we don't return here because it's possible other plugins will
        //  want to normalize the Editor
        if (Editor.isEditor(node)) { normalizeDocument(editor, readOnly); }

        return normalizeNode(entry);
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => (BottomBufferElement.isBottomBufferElement(element) ? false : isInline(element));

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => (BottomBufferElement.isBottomBufferElement(node) ? true : isVoid(node));

    return editor;
};

const adjustSelectionAroundBottomBuffer = (editor: Editor): void => {
    const { selection }: { selection: BaseSelection } = editor;
    const { focus, anchor } = selection as BaseRange;
    const [, lastTextNodePath] = Editor.leaf(editor, [], { edge: 'end' });

    const lastTextNodeIsInBB = Array
        .from(Node.ancestors(editor, lastTextNodePath))
        .some(([ancestor]) => BottomBufferElement.isBottomBufferElement(ancestor));

    if (lastTextNodeIsInBB) { // if this is false, then normalizeNode hasn't been called yet
        const lastTextPoint = { path: lastTextNodePath, offset: 0 };
        const anchorIsInBB = Point.equals(anchor, lastTextPoint);
        const focusIsInBB = Point.equals(focus, lastTextPoint);

        if (anchorIsInBB && focusIsInBB) {
            return Transforms.move(editor, { reverse: true });
        }
        if (anchorIsInBB && !focusIsInBB) {
            Transforms.move(editor, { reverse: true, edge: 'anchor' });
        }
        if (!anchorIsInBB && focusIsInBB) {
            Transforms.move(editor, { reverse: true, edge: 'focus' });
        }
    }
};

export const BottomBufferHelpers = {
    adjustSelectionAroundBottomBuffer,
};
